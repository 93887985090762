import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Forest({
  color = colors.wrenGreen,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...restOfProps}
    >
      <path
        fill={color}
        d="m13.7 18-3.2-3.8 2.3.5c.3 0 .5-.2.7-.4v-.8L8 5.8A.8.8 0 0 0 7 5.6l-.2.2-5.4 7.6c-.2.3-.2.6 0 .8.1.3.4.4.7.4l2-.4-3 3.7c-.1.3-.2.6 0 .9.1.2.4.4.7.4l4.8-.7v3.2c0 .4.4.8.8.8s.8-.4.8-.8v-3.2l4.9.7c.3 0 .5-.2.7-.4l-.1-.9Z"
      />
      <path
        fill={color}
        d="M21.9 12.6v.5c0 .2-.2.3-.4.3l-3-.4v2c0 .3-.3.5-.5.5a.5.5 0 0 1-.5-.5v-2l-3.1.4c-.2 0-.3-.1-.4-.3v-.5l1.9-2.3-1.3.3c-.2 0-.4-.1-.5-.3v-.5L17.7 5v-.2c.3-.1.6 0 .7.2l3.4 4.8c.1.1.2.3 0 .5 0 .2-.2.3-.4.3l-1.5-.3 2.1 2.3ZM15 6.8c.2 0 .2.2.1.3l-.2.2-2-.3v1.3c0 .1 0 .3-.2.3a.3.3 0 0 1-.3-.3V7l-1.9.3-.3-.2v-.3l1.2-1.4-.8.2c-.1 0-.2 0-.2-.2V5l2-3h.1c.1-.1.3-.1.4 0l2.1 3v.2l-.3.2-.9-.1 1.3 1.4Z"
        opacity=".3"
      />
    </svg>
  );
}
