/** @jsxImportSource @emotion/react */
import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';
import HeroImageLanding from '../../components/frontfacing/HeroImageLanding';
import useOffsetOrderTicker from '../../hooks/useOffsetOrderTicker';
import { timeSince } from '../../lib/time';
import { ReactNode } from 'react';
import { CSSInterpolation } from '@emotion/serialize';
import Fade from '../../components/animation/Fade';
import { fadeIn } from '../../style/misc';

export default function DesktopHero({
  backgroundGradient,
  bottomMatter,
  className,
  contentStyles,
  cta,
  header,
  imageUrl,
  mainTitleWidth,
  sub,
  tickerColor,
  withSignupCounter = true,
}: {
  backgroundGradient?: string;
  bottomMatter?: ReactNode;
  className?: string;
  contentStyles?: CSSInterpolation;
  cta?: ReactNode;
  header?: ReactNode;
  imageUrl?: string;
  mainTitleWidth?: number;
  sub?: ReactNode;
  tickerColor?: string;
  withSignupCounter?: boolean;
}) {
  const offsetOrder = useOffsetOrderTicker(5000);
  const bottom =
    bottomMatter ??
    (offsetOrder && (
      <div
        css={scale([
          {
            boxSizing: 'border-box',
            marginTop: 24,
            fontWeight: '400 !important',
            background: tickerColor ?? 'rgba(31, 161, 139, 0.3)',
            backdropFilter: 'blur(4px)',
            width: '510px',
            padding: '14px 20px',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            position: 'absolute',
            fontSize: 14,
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'white',
            margin: '0 auto',
          },
          fadeIn(0.5),
        ])}
      >
        <Fade animationKey={offsetOrder.createdAt} durationMs={500}>
          <div>
            <img
              alt="Purchaser"
              src={
                offsetOrder.profileImageUrl
                  ? `${offsetOrder.profileImageUrl}?w=50`
                  : staticImageUrl('wren_profile.png', { w: 50 })
              }
              css={{
                width: 24,
                height: 24,
                borderRadius: 24,
                marginRight: 8,
                marginBottom: -6,
              }}
            />
            <span css={{ width: '360px', display: 'inline' }}>
              <b>{offsetOrder.ordererName?.substring(0, 9)}</b> offset their
              carbon footprint!{' '}
              <span css={{ opacity: 0.7, paddingLeft: 4, display: 'inline' }}>
                {timeSince(new Date(offsetOrder.createdAt))} ago
              </span>
            </span>
          </div>
        </Fade>
      </div>
    ));

  return (
    <HeroImageLanding
      className={className}
      withSignupCounter={withSignupCounter}
      header={header ?? 'Erase your carbon footprint'}
      sub={
        sub ??
        'Calculate and offset your carbon footprint through a monthly subscription.'
      }
      button={cta}
      bottomMatter={bottom}
      backgroundGradient={backgroundGradient}
      preloadGradient={
        'linear-gradient(0deg, rgba(0, 91, 75, 0.80) 100%, rgba(0,91,75,0.80) 100%)'
      }
      imageUrl={imageUrl ?? 'irrawady.png'}
      contentStyles={contentStyles}
      mainTitleWidth={mainTitleWidth}
    />
  );
}
