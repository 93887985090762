import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Saucer({
  color = colors.policyPurple,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...restOfProps}
    >
      <path
        fill={color}
        d="M12 4C9 4 6.5 6.2 6.5 8.9c0 2.1 5 2.2 5.5 2.2.6 0 5.5 0 5.5-2.2C17.5 6.2 15 4 12 4ZM4.8 7.8C2.3 8.8 1 10 1 11.6c0 1.6 1.5 2.8 3.7 3.7v3c0 .4.4.8.9.8s.9-.4.9-.9v-2.4c1.4.4 3 .6 4.6.6v2.7c0 .5.4.9.9.9s1-.4 1-.9v-2.7c1.5 0 3-.2 4.5-.6v2.4c0 .5.4 1 1 1 .4 0 .8-.5.8-1v-3c2.2-.8 3.7-2 3.7-3.6s-1.3-2.9-3.8-3.8l.1 1c0 2.6-2.7 4-7.3 4s-7.3-1.4-7.3-4v-1Z"
      />
    </svg>
  );
}
