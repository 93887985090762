/** @jsxImportSource @emotion/react */
import Desktop from './Desktop';
import Mobile from './Mobile';
import { isMobile } from '../../lib/platform';
import useLandingAndStartHeaderExperiment from '../../hooks/useLandingAndStartHeaderExperiment';

export default function Landing() {
  const heroCopy = {
    /*
     * Header and main title are provided by the landing_and_start_header experiment - the values for control are...
     * header: 'Systemic change starts with you',
     * mainTitleWidth: 500,
     */
    subheader:
      'Fund carbon removal, offsets, climate policy, and conservation–all in one subscription.',
    ...useLandingAndStartHeaderExperiment(),
  };

  return isMobile() ? (
    // @ts-expect-error: Translate to TS
    <Mobile headerText={heroCopy.header} />
  ) : (
    // @ts-expect-error: Translate to TS
    <Desktop headers={heroCopy} />
  );
}
