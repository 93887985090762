import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Plane({
  color = '#327BB3',
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...restOfProps}
    >
      <path
        fill={color}
        d="M18.9 2.3a2 2 0 0 0-1.6-.2c-.6.1-1 .5-1.3 1l-2.7 5.1-9.3.6A1.1 1.1 0 0 0 4 11l7.1 1.5-2.6 5.1-2.2.3c-.3 0-.5.2-.7.5a1 1 0 0 0 .6 1.3l3 .8 2.4 2.3c.5.4 1.1.2 1.4-.3a1 1 0 0 0 0-.8l-.9-2 2.7-5.1 5.1 5.4a1 1 0 0 0 1.8-.3v-1L17 10.2l2.7-5.1c.3-.5.3-1.1.2-1.6-.2-.6-.5-1-1-1.3Z"
      />
    </svg>
  );
}
