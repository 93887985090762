/** @jsxImportSource @emotion/react */

export default function Mail({ width, className = {}, fill = 'white' }) {
  return (
    <svg
      className={className}
      width={width}
      viewBox="0 0 54 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66658 43.3334C4.19992 43.3334 2.94481 42.8116 1.90125 41.768C0.857697 40.7245 0.33503 39.4685 0.333252 38V6.00002C0.333252 4.53335 0.855919 3.27824 1.90125 2.23469C2.94659 1.19113 4.2017 0.668465 5.66658 0.666687H48.3332C49.7999 0.666687 51.0559 1.18935 52.1012 2.23469C53.1466 3.28002 53.6684 4.53513 53.6666 6.00002V38C53.6666 39.4667 53.1448 40.7227 52.1012 41.768C51.0577 42.8134 49.8017 43.3351 48.3332 43.3334H5.66658ZM48.3332 11.3334L28.3999 23.8C28.1777 23.9334 27.9448 24.0338 27.7012 24.1014C27.4577 24.1689 27.2239 24.2018 26.9999 24.2C26.7777 24.2 26.5448 24.1671 26.3013 24.1014C26.0577 24.0356 25.8239 23.9351 25.5999 23.8L5.66658 11.3334V38H48.3332V11.3334ZM26.9999 19.3334L48.3332 6.00002H5.66658L26.9999 19.3334Z"
        fill={fill}
      />
    </svg>
  );
}
