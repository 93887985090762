/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useCart from '../../hooks/useCart';
import { formatCurrency } from '../../lib/formatCurrency';
import { isMobile } from '../../lib/platform';
import { getLatestSubscription } from '../../lib/subscriptions';
import { State } from '../../store';
import { desktop } from '../../style/mediaQueries';
import { h3 } from '../../style/text';
import { staticImageUrl } from '../../util/images';
import Button from '../buttons/Button';
import Dialog from '../layout/Dialog';

const Content = styled.div`
  max-width: 420px;
  text-align: center;
  padding: 28px 28px 42px;
`;

const Title = styled.h3(
  h3,
  `
  font-weight: 600;
  font-size: 26px;
  line-height: 105.84%;
  margin-bottom: 12px;
`
);

const Image = styled.img`
  width: 80px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 135%;
  margin-bottom: 32px;
`;

export default function CreditCardChurnNotice() {
  const [show, setShow] = useState(true);
  const user = useSelector((state: State) => state.user);
  const { cart, addSubscriptionCartItem } = useCart();
  const history = useHistory();

  const latestSubscription = getLatestSubscription(user);

  const handleConfirm = async () => {
    if (latestSubscription?.status === 'past_due') {
      history.push('/update-payment?showSetupForm=true');
    } else if (latestSubscription?.status === 'lapsed') {
      await addSubscriptionCartItem(
        {
          amountInUserCurrency: latestSubscription.amount,
          portfolioId: latestSubscription.portfolioId,
          billingCycle: latestSubscription.billingCycle,
        },
        { replaceItems: true }
      );

      history.push('/checkout/payment');
    }
  };

  useEffect(() => {
    window.analytics.track('Client: User Saw Cancelled Subscription Modal');
  }, []);

  if (!latestSubscription || !user.currency) {
    return null;
  }

  return (
    <Dialog
      withCloseButton
      id="resubscribe"
      showDialog={show}
      setShowDialog={(value: boolean) => setShow(value)}
    >
      <Content>
        <Image src={staticImageUrl('expired-credit-card.png')} />
        <Title>Please update your payment information.</Title>
        {!isMobile() && (
          <Description>
            Your payment method appears to be expired or incorrect. Update your
            payment method to resubscribe for{' '}
            {formatCurrency(latestSubscription.amount, {
              displayCurrency: latestSubscription.currency ?? 'USD',
              amountCurrency: latestSubscription.currency ?? 'USD',
              fromLowestDenominator: true,
            })}{' '}
            per {latestSubscription.billingCycle}
            {latestSubscription.feeAmount !== null && ', plus processing fee'}.
          </Description>
        )}

        <Button size="medium" type="green" onClick={() => handleConfirm()}>
          Update payment
        </Button>
      </Content>
    </Dialog>
  );
}
