import * as R from 'ramda';
import { staticImageUrl } from '../util/images';
import portfolioCopy from '../static/copy/portfolios.json';
import { Countries } from '@wren/shared';

const Afforestation = staticImageUrl('wren-icon-black-conservation.png');
const Capture = staticImageUrl('offset-strategy-2.png');
const Tech = staticImageUrl('offset-strategy-3.png');
const amex = staticImageUrl('card-amex.png');
const dinersclub = staticImageUrl('card-dinersclub.png');
const discover = staticImageUrl('card-discover.png');
const jcb = staticImageUrl('card-jcb.png');
const mastercard = staticImageUrl('card-mastercard.png');
const unionpay = staticImageUrl('card-unionpay.png');
const visa = staticImageUrl('card-visa.png');
const unknown = staticImageUrl('card-unknown.png');

export const typeIcon = (type) => {
  if (type === 'Conservation') return Afforestation;
  if (type === 'Afforestation') return Afforestation;
  if (type === 'Clean Cookstoves') return Tech;
  if (type === 'Small Biogas Digestors') return Capture;
  return null;
};

export const tapLog = R.curry((message, x) => {
  return x;
});

const splitText = (x) => x.split('\n');
const removeEmptyStrings = (x) => R.reject(R.isEmpty, x);
export const createBodyText = (x) =>
  R.compose(removeEmptyStrings, splitText)(x);

export const toMetricSqFt = (sqft) => {
  if (!R.isNil(sqft)) return Number(sqft / 10.764);
  throw Error('Cannot convert metric on nil');
};
export const fromMetricSqFt = (sqmeters) => {
  if (!R.isNil(sqmeters)) return Number(sqmeters * 10.764);
  throw Error('Cannot convert metric on nil');
};
export const toMetricMiles = (miles) => {
  if (!R.isNil(miles)) return Number(miles * 1.609344);
  throw Error('Cannot convert metric on nil');
};
export const fromMetricMiles = (km) => {
  if (!R.isNil(km)) return Number(km / 1.609344);
  throw Error('Cannot convert metric on nil');
};

// 1 = 112903/480 (liters per 100km) * (miles per gallon)
// or equivalently, there are 112903 liter miles per 48000 km gallons
// https://www.wolframalpha.com/input?i=112903%2F480+%28liters+per+100km%29+*+%28miles+per+gallon%29
const litermilesper100kmgallon = 112903 / 480;

const toMetricMpg = (mpg) => litermilesper100kmgallon / mpg;
const fromMetricMpg = (literPer100Km) =>
  litermilesper100kmgallon / literPer100Km;

export const handleUnitConversion = (value, startingUnit, endingUnit) => {
  if (startingUnit === endingUnit) return value;

  if (startingUnit === 'square feet' && endingUnit === 'square meters')
    return toMetricSqFt(value);
  if (startingUnit === 'square meters' && endingUnit === 'square feet')
    return fromMetricSqFt(value);
  if (startingUnit === 'miles' && endingUnit === 'km')
    return toMetricMiles(value);
  if (startingUnit === 'km' && endingUnit === 'miles')
    return fromMetricMiles(value);
  if (startingUnit === 'mpg' && endingUnit === 'l/100km')
    return toMetricMpg(value);
  if (startingUnit === 'l/100km' && endingUnit === 'mpg')
    return fromMetricMpg(value);
  throw Error(`Impossible Unit Conversion: ${startingUnit} to ${endingUnit}`);
};

export const truncate = (input, maxLength) => {
  if (input && input.length > maxLength) {
    return `${input.substring(0, maxLength)}...`;
  }
  return input;
};

export const getEmojiForCountryName = (country) => {
  const matchingCountry = Countries.COUNTRIES[country];
  if (matchingCountry) {
    return matchingCountry.flag_emoji;
  }
  return '';
};

export const percentOffsetByAmount = (amount, total) => (amount / total) * 100;
export const tonsOffsetByAmount = (amount, annualFootprint, total) =>
  (
    ((annualFootprint / 12) * percentOffsetByAmount(amount, total)) /
    100
  ).toFixed(1);
export const tonsInCarsByAmount = (amount, annualFootprint, total) =>
  (tonsOffsetByAmount(amount, annualFootprint, total) / 0.38).toFixed(2);

export const fixedDecimal = (num, dec) => {
  const decimal = R.defaultTo(0, dec);
  return Number(num).toFixed(decimal);
};

export const renderArray = (answers) => {
  if (R.type(answers) === 'Array') {
    return R.append(
      `${R.last(answers)}`,
      R.map((a) => `${a}, `, R.init(answers))
    );
  }
  if (R.type(answers) === 'Number') return `${answers.toFixed(0)} `;
  return `${answers}`;
};

export const percentDiff = (numerator, denom) =>
  fixedDecimal((numerator / denom - 1) * 100);

export const diffInWords = (num) => {
  switch (Math.sign(num)) {
    case 1:
      return `${num}% more than`;
    case -1:
      return `${Math.abs(num)}% less than`;
    default:
      return 'the same as ';
  }
};

export const handleToFixed = (stringToFix, dec) =>
  stringToFix ? stringToFix.toFixed(R.defaultTo(0, dec)) : 0;

export const guessCountry = (languageCode) => {
  const countriesWithLanguageProp = R.filter(
    R.has('language_code'),
    Countries.alphabetical()
  );
  const matchesLanguageCode = (country) =>
    R.includes(languageCode, country.language_code);
  const filterCountriesByCode = R.filter(
    matchesLanguageCode,
    countriesWithLanguageProp
  );
  const country = !R.isEmpty(filterCountriesByCode)
    ? R.pipe(R.head(), R.prop('country_name'))(filterCountriesByCode)
    : null;
  return country;
};

export const handleConversion = (isMetric, prop, startUnit, endUnit) =>
  isMetric && !R.isNil(prop)
    ? handleUnitConversion(prop, startUnit, endUnit).toFixed(0)
    : !R.isNil(prop)
    ? Number(prop)
    : prop;

export const cardBrandImage = (type) => {
  switch (type.toLowerCase()) {
    case 'american express':
      return amex;
    case 'diners club':
      return dinersclub;
    case 'jcb':
      return jcb;
    case 'discover':
      return discover;
    case 'mastercard':
      return mastercard;
    case 'unionpay':
      return unionpay;
    case 'visa':
      return visa;
    case 'unknown':
      return unknown;
    default:
      return unknown;
  }
};

export const isTestEmail = (emailString) => {
  return (
    R.includes('@wrentest', emailString) || R.includes('@orgtest', emailString)
  );
};

export const parseUTC = (s) => {
  const b = s.split(/\D/);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5]));
};

export const findGreatestValueOfKeyInArrayOfObjects = R.curry((key, arr) =>
  R.pipe(R.pluck(key), (x) => Math.max(...x))(arr)
);

export function isObjectEmpty(obj) {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
}

export function findCountryNameByCountryCode(countryCode) {
  let countryName = '';
  const countryFromCode = Countries.alphabetical().find(
    (country) => country.country_code === countryCode
  );
  if (countryFromCode) {
    countryName = countryFromCode.country_name;
  }
  return countryName;
}

export function canUserChangeCurrency(user) {
  return (
    user &&
    !user.stripeCustomerId &&
    !user.team &&
    user.ownedTeams &&
    !user.ownedTeams.length > 0
  );
}

export function areObjectsEquivalent(a, b) {
  // If number of properties is different, objects are not equivalent
  if (Object.values(a).length !== Object.values(b).length) {
    return false;
  }

  for (let i = 0; i < Object.values(a).length; i++) {
    const propName = Object.keys(a)[i];
    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  // If we made it this far, objects
  // are considered equivalent
  return true;
}

export function isOdd(num) {
  return num % 2;
}

export function omit(keys, obj) {
  return keys.reduce((a, e) => {
    /* eslint-disable-next-line */
    const { [e]: omitted, ...rest } = a;
    return rest;
  }, obj);
}

export function getPortfolioCopy(portfolioSlug) {
  const copy = portfolioCopy[portfolioSlug];

  if (!copy) {
    return portfolioCopy.default;
  }

  return copy;
}
