import { useSelector } from 'react-redux';
import { State } from '../store';

export default function useMainCTAText() {
  const user = useSelector((state: State) => state.user);

  if (user.completedOnboarding) {
    return 'Go to dashboard';
  }

  if (user.annualFootprint) {
    return 'Continue';
  }

  if (user.referrer) {
    return `Join ${user.referrer.firstName || user.referrer.username}`;
  }

  return 'Get started';
}
