/** @jsxImportSource @emotion/react */

interface Props {
  size?: number;
  fill?: string;
  className?: string;
}

export default function AnonymousUser({
  size = 20,
  fill = 'black',
  className,
}: Props) {
  return (
    <svg
      className={className}
      version="1.1"
      id="Layer_1"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 423 423"
    >
      <path
        d="M212,0C95,0,0,95,0,212s95,211,212,211s211-94,211-211S329,0,212,0z M212,84c34,0,63,29,63,64c0,34-29,63-63,63
    c-35,0-63-29-63-63C149,113,177,84,212,84z M202,387c-56-3-107-33-138-80c51-79,244-78,295,0C323,363,269,387,202,387z"
        fill={fill}
      />
    </svg>
  );
}
