import { scale } from '../util/scale';
import { colors, fonts, oldFontSizes } from './theme';

export const simpleLabel = scale({
  fontFamily: fonts.Sans,
  fontWeight: 600,
  fontSize: oldFontSizes.body,
  marginBottom: '8px',
  textAlign: 'left',
});

export const labelStyle = [
  simpleLabel,
  scale({
    marginLeft: '5px',
  }),
];

export const sleekStyle = scale({
  color: colors.textPrimary,
  backgroundColor: 'white',
  backgroundClip: 'padding-box',
  border: `1px solid ${colors.borderColor}`,
  transition: '200ms border ease-in-out',
  outline: 'none',
  marginBottom: 0,
  '&:focus': {
    border: `1px solid ${colors.gray5}`,
  },
  '&::placeholder': {
    color: colors.textSecondary,
  },
});

export const radioButton = (isSelected) => ({
  minWidth: 20,
  height: 20,
  borderRadius: 6,
  backgroundColor: 'white',
  border: '1px solid',
  borderColor: isSelected ? 'var(--success-green)' : '1px solid var(--gray-4)',
  marginRight: 12,
  marginTop: 4,
  padding: '2px',
  boxSizing: 'border-box',
});

export const innerRadioButton = {
  width: '100%',
  height: '100%',
  borderRadius: 4,
  backgroundColor: 'var(--success-green)',
};

export const cardElementStyle = scale({
  boxSizing: 'border-box',
  height: '42px',
  padding: '12px 12px',
  fontSize: oldFontSizes.bodyLarge,
  border: '1px solid transparent',
  marginBottom: 16,
  borderRadius: '3px',
  backgroundColor: 'white',
  color: 'var(--text-primary)',
  '&:placeholder': {
    color: 'var(--gray-8)',
  },
  '&:focus': {
    boxShadow: '0 1px 3px 0 var(--box-shadow-color)',
  },
  '&:invalid': {
    borderColor: 'var(--error-red)',
  },
  '&:-webkit-autofill': {
    backgroundColor: 'var(--background-light-blue) !important',
  },
});
