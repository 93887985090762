/** @jsxImportSource @emotion/react */
import * as R from 'ramda';
import { useRef } from 'react';

import PropTypes from 'prop-types';
import { staticImageUrl } from '../../util/images';
import {
  shareButtonStyle,
  smallShareButtonStyle,
  textStyle,
  smallTextStyle,
  logoStyle,
  smallLogoStyle,
  smallLinkStyle,
  linkStyle,
  onlyOnMobile,
} from '../../style/shareButtonStyles';
import track from '../../lib/events';

const messenger = staticImageUrl('messenger.png');

// Only displays on mobile
// PSA: This component only works if user has FB messenger installed

const FBMessengerShare = ({
  url,
  name,
  size,
  button,
  className,
  campaignName = 'share',
  utmMedium = name,
}) => {
  const anchorRef = useRef(null);

  let urlWithUtm;
  if (R.includes('?', url)) {
    urlWithUtm = R.concat(
      url,
      `&utm_source=fbmessenger&utm_campaign=${encodeURIComponent(
        campaignName || ''
      )}&utm_medium=${encodeURIComponent(utmMedium || '')}`
    );
  } else {
    urlWithUtm = R.concat(
      url,
      `?utm_source=fbmessenger&utm_campaign=${encodeURIComponent(
        campaignName || ''
      )}&utm_medium=${encodeURIComponent(utmMedium || '')}`
    );
  }

  const encodedUrl = encodeURIComponent(urlWithUtm);

  const handleOpenPopup = (event) => {
    event.preventDefault();
    track('Server: User Clicked Share Button', {
      name,
      source: 'messenger',
      campaignName,
      utmMedium,
    });
    window.open(
      anchorRef.current,
      'messenger',
      'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'
    );
    return false;
  };

  return (
    <a
      href={`fb-messenger://share/?link= ${encodedUrl}`}
      onClick={(e) => handleOpenPopup(e)}
      ref={anchorRef}
      css={[onlyOnMobile, size === 'small' ? smallLinkStyle : linkStyle]}
      className={className}
    >
      {button || (
        <button
          css={size === 'small' ? smallShareButtonStyle : shareButtonStyle}
        >
          <img
            src={messenger}
            alt=""
            css={size === 'small' ? smallLogoStyle : logoStyle}
          />
          <p css={size === 'small' ? smallTextStyle : textStyle}>Messenger</p>
        </button>
      )}
    </a>
  );
};

FBMessengerShare.defaultProps = {
  url: 'projectwren.com',
  name: '',
};

FBMessengerShare.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
};

export default FBMessengerShare;
