/** @jsxImportSource @emotion/react */

export default function Giftbox({ width = 32, color = 'black' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={width}
      height={width}
    >
      <path
        fill={color}
        d="M9 2C7.4 2 6 3.4 6 5.2c0 .3 0 .7.2 1h-2a2.1 2.1 0 0 0-1.2 4v9.7A2 2 0 0 0 5 22h6V11.5c0-.6.4-1 1-1s1 .4 1 1V22h6c1.1 0 2-1 2-2.1V10c.6-.4 1-1.1 1-2 0-1-1-1.9-2.1-1.9h-2l.1-1C18 3.4 16.6 2 15 2c-.8 0-1.5.3-2 .8l-.1.1-.9 1-.9-1H11c-.5-.6-1.2-.9-2-.9Zm0 2.1c.6 0 1 .5 1 1 0 .7-.4 1.1-1 1.1s-1-.4-1-1 .4-1 1-1Zm6 0c.6 0 1 .5 1 1 0 .7-.4 1.1-1 1.1s-1-.4-1-1 .4-1 1-1Z"
      />
    </svg>
  );
}
