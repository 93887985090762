export const PORTFOLIO_ID = 2;
export const TONS_CO2_E_PER_TREE = 0.0217724; // 21.7724kg of pine tree uptake over a year;

export function fromOffsetOrders(
  orders: { tons: number; portfolio_id: number }[]
) {
  const trees = orders
    .filter(({ portfolio_id }) => portfolio_id === PORTFOLIO_ID)
    .reduce<number>((sum, { tons }) => sum + tons / TONS_CO2_E_PER_TREE, 0);
  return Math.round(trees);
}
