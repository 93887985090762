/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { fonts, oldFontSizes } from '../../style/theme';

const errorStyle = (error, errorWidth) => css`
  border: 1px solid var(--error-red);
  color: var(--error-red);
  font-family: ${fonts.Sans};
  font-size: ${oldFontSizes.caption};
  padding: 3px 5px;
  border-radius: 4px;
  text-align: center;
  width: ${errorWidth || 'fit-content'};
  height: fit-content;
  display: ${error ? 'inline' : 'none'};
`;

function InputError(props) {
  const { error, errorWidth, injectCss, className } = props;
  return (
    <div css={[errorStyle(error, errorWidth), injectCss]} className={className}>
      {error}
    </div>
  );
}

InputError.defaultProps = {
  error: '',
  errorWidth: '',
  injectCss: {},
};

InputError.propTypes = {
  error: PropTypes.string,
  errorWidth: PropTypes.string,
  injectCss: PropTypes.objectOf(PropTypes.string),
};

export default InputError;
