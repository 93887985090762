/** @jsxImportSource @emotion/react */
import { HTMLAttributes } from 'react';
import Flex from '../UI/Flex';

const Bell = ({
  numberOfUnreadNotifications = 0,
  size = 17,
  color = 'black',
  ...restOfProps
}: {
  numberOfUnreadNotifications?: number;
  size?: number;
  color?: string;
} & HTMLAttributes<SVGSVGElement>) => {
  return (
    <Flex justify="center" align="center" css={{ position: 'relative' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        viewBox="0 0 24 24"
        width={size}
        height={size}
        {...restOfProps}
      >
        <path d="M12 1c-1 0-1.9.7-1.9 1.6v.7C7 4 4.5 6.5 4.5 9.4v6.3l-2 1.2-.3.4a1 1 0 0 0-.2.5c0 .3.1.5.4.7.2.2.5.4.9.4h17.5c.3 0 .6-.2.8-.4.3-.2.4-.4.4-.7 0-.2 0-.4-.2-.5 0-.2-.2-.3-.4-.4l-1.9-1.2V9.4c0-3-2.4-5.4-5.6-6v-.8C13.9 1.7 13 1 12 1ZM9.5 19.9c0 1.2 1.1 2.1 2.5 2.1s2.5-1 2.5-2.1h-5Z" />
      </svg>

      {numberOfUnreadNotifications > 0 && (
        <div
          css={{
            position: 'absolute',
            top: -7,
            right: -10,
          }}
        >
          <Flex
            justify="center"
            align="center"
            css={{
              width: 16,
              height: 16,
              borderRadius: '100%',
              backgroundColor: 'var(--error-red)',
            }}
          >
            <p
              css={{
                fontSize: '10px !important',
                fontWeight: 600,
                color: '#ffffff',
              }}
            >
              {numberOfUnreadNotifications}
            </p>
          </Flex>
        </div>
      )}
    </Flex>
  );
};

export default Bell;
