// If we ever eradicate all hard-coded z-indexes, we can make this an array and use the element's index in it
const layers = {
  articleContainer: 100,
  mobileActionsSubNav: 500,
  tooltipOverlay: 600,
  navbarOverlay: 999,
  navbar: 1000, // needs to be below onboarding tour
  tourOverlay: 4000,
  tourCard: 4500,
  modalOverlay: 9999,
  // in public/index.html and global.js: .highlighted { z-index: 10000; }
  modalContent: 99999,
} as const;

export default function zIndex(key: keyof typeof layers) {
  return layers[key];
}
