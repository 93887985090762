/** @jsxImportSource @emotion/react */

import { Link } from '../UI/Link';
import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';
import FooterCTA from '../frontfacing/FooterCTA';
import { noStyleLink } from '../../style/text';
import TwitterLogo from '../svgs/TwitterLogo';
import { spacing } from '../../style/theme';
import YoutubeLogo from '../svgs/YoutubeLogo';
import NewTag from '../UI/NewTag';

const wrenWhite = staticImageUrl('wren-bird-white.png', { w: 60 });

const noStyleWhite = scale({
  color: 'white !important',
  opacity: 0.8,
  '&:hover': {
    opacity: 1,
  },
});

const footerHeader = scale({
  textAlign: 'left',
  fontWeight: 600,
  fontSize: '16px',
  color: 'white',
});

const closer = scale({
  display: ['none', 'none', 'block'],
  width: '42px',
  height: '42px',
  marginRight: '6px',
});

const footerLink = scale({
  textDecoration: 'none',
  fontWeight: '400',
  borderRadius: '3px',
  padding: '3px 7px',
  marginLeft: '-7px',
  textAlign: 'left',
  color: 'white',
  fontSize: '15px',
  marginTop: '10px',
  cursor: 'pointer',
  lineHeight: 1.42857143, // from css in js. necessary for correct spacing of links
  '&:hover': {
    backgroundColor: 'black',
    color: 'white',
  },
  display: 'block',
  width: 'fit-content',
});

const footerHr = scale({
  marginTop: '16px',
});

const footer = scale({
  marginTop: ['30px', '40px', '80px'],
  width: '100%',
  paddingBottom: spacing.xxLarge,
  backgroundColor: 'var(--background-dark-green)',
  hr: {
    marginBottom: '16px',
    opacity: 0.2,
  },
  borderTop: '12px solid var(--wren-green)',
});

const copyrightText = scale({
  fontWeight: 600,
  textAlign: 'left',
  marginTop: '26px',
  opacity: '0.8',
  color: 'white',
  '&:hover': {
    color: 'white !important',
    opacity: 1,
  },
});

const policyLinks = scale({
  textAlign: 'left',
  fontSize: '15px',
  color: 'white',
});

const primaryContainer = scale({
  width: '90%',
  margin: 'auto',
  maxWidth: '1000px',
  position: 'relative',
});

const container = scale({
  width: '100%',
});

const footerColumns = scale({
  display: 'flex',
  flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
  flexDirection: ['column-reverse', 'column-reverse', 'unset', 'unset'],
});

const footerCol = scale({
  width: ['100%', '100%', '34%', '34%'],
  marginBottom: '50px',
  textAlign: 'left',
});

const footerColMedium = scale({
  width: ['100%', '100%', '32%', '32%'],
  marginBottom: '50px',
  textAlign: 'left',
});

const footerColSmall = scale({
  width: ['100%', '100%', '20%', '20%'],
  marginBottom: '50px',
  textAlign: 'left',
});

const lightText = scale({
  opacity: 0.85,
  fontSize: '14px',
  marginTop: '5px',
  color: 'white',
  marginBottom: '5px',
  textDecoration: 'none',
  fontWeight: 400,
  '&:hover': {
    color: 'white',
  },
});

const oneLiner = scale({
  width: '100%',
  textAlign: 'left',
  marginLeft: [0, 0, '10px'],
});

const oneLinerContent = scale({
  paddingRight: '15px',
  maxWidth: '200px',
  h4: {
    marginBottom: '12px',
  },
});

export default function Footer(props) {
  const { ctaClickFn, className, referralCta } = props;
  return (
    <div css={footer} className={className}>
      {referralCta && ctaClickFn && (
        <FooterCTA ctaClickFn={ctaClickFn} referralCta={referralCta} />
      )}
      <div css={primaryContainer}>
        <div css={container}>
          <div style={{ paddingTop: '50px' }}>
            <div css={footerColumns}>
              <div css={footerColMedium}>
                <div style={{ display: 'flex' }}>
                  <img
                    alt="Project Wren Carbon Bird"
                    css={closer}
                    src={wrenWhite}
                  />
                  <div css={oneLiner}>
                    <div css={oneLinerContent}>
                      <h4 css={footerHeader}>Wren</h4>
                      <p css={lightText}>
                        Take real, effective climate action with Wren's
                        all-in-one climate platform.
                      </p>
                      <div
                        css={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: spacing.small,
                          marginTop: spacing.small,
                        }}
                      >
                        <a
                          css={noStyleLink}
                          href="https://www.youtube.com/@project_wren"
                          target="_blank"
                        >
                          <YoutubeLogo size={18} />
                        </a>
                        <a
                          css={noStyleLink}
                          href="https://twitter.com/project_wren"
                          target="_blank"
                        >
                          <TwitterLogo color="white" size={18} />
                        </a>
                        <a
                          css={noStyleLink}
                          href="https://www.instagram.com/project_wren/"
                          target="_blank"
                        >
                          <img
                            src={staticImageUrl(
                              'instagram-glyph-gradient.png',
                              { w: 36 }
                            )}
                            css={{
                              filter: 'brightness(0) invert(1)',
                              width: 18,
                              height: 18,
                            }}
                          />
                        </a>
                        <a
                          css={noStyleLink}
                          href="https://www.linkedin.com/company/project-wren/"
                          target="_blank"
                        >
                          <img
                            src={staticImageUrl('linkedin.png')}
                            css={{
                              filter: 'brightness(0) invert(1)',
                              width: 18,
                              height: 18,
                            }}
                          />
                        </a>
                      </div>
                      <br />
                      <br />
                      {/* {canUserChangeCurrency(user) && <CurrencyMenu />} */}
                    </div>
                  </div>
                </div>
              </div>
              <div css={footerColSmall}>
                <h4 css={footerHeader}>Explore</h4>
                <Link css={footerLink} to="/how-it-works">
                  How it works
                </Link>
                <Link css={footerLink} to="/about">
                  About us
                </Link>
                <a href="/blog" css={footerLink}>
                  <div>Blog</div>
                </a>
                <Link css={footerLink} to="/flights">
                  Offset flights
                </Link>
                <Link css={footerLink} to="/offset-anything">
                  Offset anything
                </Link>
                <Link css={footerLink} to="/gifts">
                  Gifts
                </Link>
                <Link css={footerLink} to="/wren-api">
                  API
                </Link>
                <a
                  css={footerLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.notion.so/Wren-Receipt-Ledger-ce9131ad39ef42c3b52f7ea6943c8e18"
                >
                  Receipts ledger
                </a>
                <Link css={footerLink} to="/teams/business">
                  Wren for Business
                </Link>
                <Link css={footerLink} to="/leaderboard">
                  Leaderboard
                </Link>
                <Link css={footerLink} to="/faq">
                  FAQ
                </Link>
              </div>
              <div css={footerColSmall}>
                <h4 css={footerHeader}>Resources</h4>
                <a
                  css={footerLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/project_wren/"
                >
                  Follow on Twitter
                </a>
                <a
                  css={footerLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://instagram.com/project_wren/"
                >
                  Follow on Instagram
                </a>
                <a css={footerLink} href="mailto:support@wren.co">
                  Support
                </a>
                <a css={footerLink} href="mailto:team@wren.co">
                  Contact us
                </a>
                <a
                  css={footerLink}
                  href="https://www.notion.so/projectwren/f39023cc81034d2ca64d1fa184e685ad?v=80ee68408a4a4015a1d471485b860504"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Product roadmap
                </a>
                <a
                  css={footerLink}
                  href=" https://www.shareasale.com/shareasale.cfm?merchantID=105790"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Affiliate program
                </a>
              </div>
              <div css={footerCol}>
                <h4 css={footerHeader} style={{ display: 'inline-block' }}>
                  Portfolios
                </h4>
                <Link css={footerLink} to="/portfolios/universal-fund">
                  🌍 Classic Portfolio
                </Link>
                <Link css={footerLink} to="/portfolios/trailblazer">
                  🌅 Carbon Removal Portfolio
                </Link>
                <Link css={footerLink} to="/collective">
                  🏛️ Tax-deductible Portfolio <NewTag />
                </Link>
                <hr css={{ marginTop: 18 }} />
                <Link css={footerLink} to="/projects">
                  View all projects
                </Link>
              </div>
            </div>
          </div>
        </div>
        <a href="/charter" css={[noStyleLink, copyrightText]}>
          Wren Climate, PBC © {new Date().getFullYear()}
        </a>
        <hr css={footerHr} />
        <span css={policyLinks}>
          <a
            css={[noStyleLink, noStyleWhite]}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/projectwren/Wren-Privacy-Policy-f237e9d54e624877be18cc8362fe29cc"
          >
            Privacy Policy
          </a>{' '}
          •{' '}
          <a
            css={[noStyleLink, noStyleWhite]}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.notion.so/projectwren/Website-Data-Collection-Policy-6eaaf11f4fa24869b6754771d9cfedee"
          >
            Data Collection
          </a>{' '}
          •{' '}
          <a
            css={[noStyleLink, noStyleWhite]}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.trustpilot.com/review/projectwren.com"
          >
            TrustPilot reviews
          </a>{' '}
          •{' '}
          <a
            css={[noStyleLink, noStyleWhite]}
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:support@wren.co"
          >
            Contact support
          </a>
        </span>
      </div>
    </div>
  );
}
