/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

FacebookLogo.propTypes = {
  color: PropTypes.string,
};

FacebookLogo.defaultProps = {
  color: undefined,
};

export default function FacebookLogo({ color, size = 20 }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 180 180"
    >
      <title>Facebook</title>
      <path
        d="M109.351 33.7898H123.645V9.64987C121.183 9.32375 112.702 8.5824 102.827 8.5824C82.2168 8.5824 68.1007 21.1564 68.1007 44.2576V65.5209H45.355V92.5074H68.1007V160.418H95.9767V92.5074H117.803L121.273 65.5209H95.9767V46.9263C95.9767 39.1275 98.1414 33.7898 109.351 33.7898Z"
        class="cls-1"
        fill={color || 'black'}
      />
    </svg>
  );
}
