/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';

import { Link } from 'react-router-dom';
import { scale } from '../../util/scale';
import TextStyles from '../../style/text';
import { fonts } from '../../style/theme';
import CompactFAQSection from '../UI/CompactFAQSection';

const header = scale({
  fontSize: ['30px', '30px', '30px', '35px'],
  lineHeight: ['35px', '35px', '35px', '40px'],
});

const tableStyle = (onDarkBackground?: boolean) =>
  scale({
    textAlign: 'left',
    li: [
      TextStyles.paragraph,
      {
        '&::marker': {
          color: onDarkBackground ? 'white' : 'var(--text-primary)',
        },
      },
    ],
  });

const article = (onDarkBackground?: boolean) =>
  scale({
    maxWidth: '600px',
    width: ['90%', '90%', '95%'],
    margin: 'auto',
    color: onDarkBackground ? 'white' : undefined,
  });

interface Props {
  hideHeader?: boolean;
  seeMore?: boolean;
  onDarkBackground?: boolean;
  className?: string;
}

export default function LandingFAQ({
  hideHeader,
  seeMore,
  onDarkBackground,
  className,
}: Props) {
  return (
    <div css={article(onDarkBackground)} className={className}>
      {!hideHeader && (
        <Fragment>
          <h1
            css={[
              header,
              scale({
                fontFamily: fonts.Serif,
                fontWeight: '600',
                fontSize: [28, 28, '40px !important', '40px !important'],
                lineHeight: '115% !important',
                marginBottom: 8,
              }),
            ]}
          >
            Frequently Asked Questions
          </h1>
          <br />
        </Fragment>
      )}
      <div css={tableStyle(onDarkBackground)}>
        <CompactFAQSection
          faqTags={['minifaq']}
          onDarkBackground={onDarkBackground}
        />
      </div>
      <br />
      {seeMore && (
        <Link to="/faq">
          <p css={TextStyles.paragraph}>See more questions &rarr;</p>
        </Link>
      )}
    </div>
  );
}
