const initialState = [];

export default function (state = [], action) {
  switch (action.type) {
    case 'SET_PORTFOLIOS':
      return action.portfolios;
    case 'CLEAR_PORTFOLIOS':
      return initialState;
    default:
      return state;
  }
}
