import * as React from 'react';
import {
  ComponentType,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import useHandlerWithDisabled from '../../hooks/useHandlerWithDisabled';
import LoadingSpinner from '../UI/LoadingSpinner';
import {
  Button,
  ButtonSize,
  ButtonType,
  getFontSizeForButtonSize,
} from './ButtonNext';

export function loadingHoc<
  P extends PropsWithChildren<{
    disabled?: boolean;
  }>
>(
  ButtonComponent: ComponentType<
    P & { onClick?: (event: MouseEvent<HTMLButtonElement>) => void }
  >,
  LoadingSpinnerComponent: ComponentType<{
    color?: string;
    width?: string | number;
  }>
) {
  return forwardRef(
    (
      props: P & {
        className?: string;
        onClick?: (
          event: MouseEvent<HTMLButtonElement>
        ) => Promise<unknown> | void;
        buttonType?: ButtonType;
        color?: string;
        loading?: boolean;
        size?: ButtonSize;
      },
      ref
    ) => {
      const {
        buttonType,
        className,
        color,
        children,
        disabled,
        loading,
        onClick,
        size,
      } = props;

      const [handler, disabledByHandler] = useHandlerWithDisabled(onClick);
      const loadingSpinnerColor = buttonType === 'filled' ? 'white' : color;
      const loadingSpinnerSize =
        getFontSizeForButtonSize(size || 'default') - 2; // removing a little size here to prevent increased button height during loading

      return (
        <ButtonComponent
          {...props}
          className={
            (className ?? '') + (loading || disabledByHandler ? ' loading' : '')
          }
          disabled={disabled || disabledByHandler}
          onClick={handler}
          ref={ref}
        >
          {(loading || disabledByHandler) && (
            <LoadingSpinnerComponent
              color={loadingSpinnerColor}
              width={loadingSpinnerSize.toFixed(0)}
            />
          )}
          {children}
        </ButtonComponent>
      );
    }
  );
}

const LoadingButton = loadingHoc(Button, LoadingSpinner);

export default LoadingButton;
