import { UserCampaignComment } from '@wren/schema';
import { ObjectToCamel } from './ObjectToCamel';

export type Representatives = {
  divisions: Record<string, { name: string }>;
  offices: { name: string; divisionId: string; officialIndices: number[] }[];
  officials: {
    channels?: {
      type: string;
      id: string;
    }[];
    emails?: string[];
    name: string;
    party: 'Democratic Party' | 'Republican Party' | string;
    phones?: string[];
    photoUrl?: string;
    urls?: string[];
  }[];
};

export type RepresentativesWithAddress = {
  normalizedInput: {
    line1: string;
    line2?: string;
    city: string;
    state: string; // abbreviation
    zip: string;
  };
} & Representatives;

export type Representative = {
  activeInCwc: boolean;
  officeCode?: string;
} & RepresentativesWithAddress['officials'][number];

export interface CampaignComment {
  actionSlug: string;
  country?: string | null;
  email: string;
  firstName: string;
  hasCustomMessage: boolean;
  lastName: string;
  message: string;
  subject: string;
}

export interface CommentToCongress extends CampaignComment {
  address: string;
  houseRepresentative?: Representative;
  normalizedAddress: RepresentativesWithAddress['normalizedInput'];
  prefix: string;
  senators: Representative[];
}

// is this the kind of comment that gets sent to congress? (or conversely, merely recorded)
export function isCommentToCongress(
  comment: CampaignComment
): comment is CommentToCongress {
  return comment.hasOwnProperty('senators');
}

export interface CampaignInfo {
  actionSlug: string;
  numberOfComments: number;
  userComment: ObjectToCamel<UserCampaignComment>;
  recentComments: ObjectToCamel<
    Pick<
      UserCampaignComment,
      'message' | 'first_name' | 'last_name' | 'country' | 'created_at'
    >
  >[];
}
