/** @jsxImportSource @emotion/react */

import {
  cloneElement,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';

export type AnimationParams = {
  keyframes: Keyframe[] | PropertyIndexedKeyframes | null;
  options?: KeyframeAnimationOptions;
};

type Child = ReactElement | 0 | '' | false | null | undefined;

export type AnimateEnterAndExitProps = {
  animate?: AnimationParams;
  animationKey?: string | number | boolean | null | symbol | undefined;
  children: Child;
  enter?: AnimationParams;
  exit?: AnimationParams;
  initial?: boolean;
};

function getKey(child: Child) {
  return child ? child.key : undefined;
}

function getAnimation(
  element: HTMLElement,
  enterOrExit: AnimationParams | undefined,
  animate?: AnimationParams,
  animateDirection?: PlaybackDirection
) {
  // Defends against browser non-support
  if (!element.animate) {
    return;
  }
  if (enterOrExit) {
    return element.animate(
      enterOrExit.keyframes,
      enterOrExit.options || animate?.options
    );
  }
  if (animate) {
    return element.animate(animate.keyframes, {
      ...animate.options,
      direction: animateDirection,
    });
  }
}

/*
 * Expects to have one child. The child should have a key or you can pass in `animationKey` as a prop. Animation occurs when the key changes.
 */
export default function AnimateEnterAndExit({
  animate,
  animationKey,
  children,
  enter,
  exit,
  initial = true,
}: AnimateEnterAndExitProps) {
  // We want either a single child, or a falsy value
  // We treat all falsy values as equivalent and we return null
  const newChild = children || null;

  const animationKeyRef = useRef(animationKey);
  const [child, setChild] = useState<ReactElement | null>(newChild);
  const [isEntering, setIsEntering] = useState<boolean>(initial);
  const exitAnimationRef = useRef<Animation>();

  const animateInRef = useCallback((element: HTMLElement | null) => {
    if (element) {
      getAnimation(element, enter, animate);
    }
  }, []);

  const animateOutRef = useCallback(
    (element: HTMLElement | null) => {
      if (!element) {
        return;
      }
      if (exitAnimationRef.current) {
        exitAnimationRef.current.finish();
        exitAnimationRef.current = undefined;
      }
      const animation = getAnimation(element, exit, animate, 'reverse');
      if (animation) {
        animation.onfinish = () => {
          exitAnimationRef.current = undefined;
          setIsEntering(true);
          setChild(newChild);
        };
        exitAnimationRef.current = animation;
        return () => animation.cancel();
      }
      // when there is no exit animation, change state immediately instead
      setIsEntering(true);
      setChild(newChild);
      return;
    },
    [newChild]
  );

  function animateIn(element: Child) {
    return element
      ? cloneElement(element, {
          ref: animateInRef,
        })
      : null;
  }

  function animateOut(element: Child) {
    return element
      ? cloneElement(element, {
          ref: animateOutRef,
        })
      : null;
  }

  if (
    (animationKey === undefined && // animationKey overrides any child comparison
      (!!newChild !== !!child || getKey(newChild) !== getKey(child))) ||
    animationKey !== animationKeyRef.current
  ) {
    animationKeyRef.current = animationKey;
    if (!child) {
      setIsEntering(true);
      setChild(newChild);
      return null;
    }
    return animateOut(child);
  }
  if (isEntering) {
    return animateIn(newChild);
  }
  return newChild;
}
