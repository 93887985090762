/** @jsxImportSource @emotion/react */

export default function Link({ size = 60, fill = 'black' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M139.961 94.7516C136.52 98.1841 136.513 103.756 139.946 107.197C149.626 116.9 154.973 122.259 155.996 123.282L165.624 132.91C174.658 141.944 174.658 156.591 165.624 165.625C156.59 174.659 141.943 174.659 132.909 165.625L94.8799 127.595C85.846 118.561 85.846 103.914 94.8799 94.8804C98.3165 91.4438 98.3165 85.872 94.8799 82.4354C91.4433 78.9988 85.8715 78.9988 82.4349 82.4354C66.5278 98.3425 66.5278 124.133 82.4349 140.04L120.464 178.07C136.371 193.977 162.162 193.977 178.069 178.07C193.976 162.162 193.976 136.372 178.069 120.465L168.441 110.837C167.426 109.822 162.078 104.462 152.406 94.7665C148.973 91.3258 143.401 91.3191 139.961 94.7516Z"
        fill={fill}
      />
      <path
        d="M60.0389 105.248C63.4796 101.816 63.4862 96.2441 60.0538 92.8033C50.3738 83.1002 45.0266 77.7413 44.0035 76.7183L34.3755 67.0902C25.3415 58.0562 25.3415 43.4093 34.3755 34.3754C43.4094 25.3414 58.0564 25.3414 67.0903 34.3754L105.12 72.4047C114.154 81.4387 114.154 96.0856 105.12 105.12C101.683 108.556 101.683 114.128 105.12 117.565C108.556 121.001 114.128 121.001 117.565 117.565C133.472 101.657 133.472 75.8669 117.565 59.9597L79.5353 21.9304C63.6282 6.02321 37.8376 6.02321 21.9304 21.9304C6.02328 37.8375 6.0233 63.6281 21.9305 79.5352L31.5585 89.1633C32.5732 90.178 37.9212 95.5376 47.5939 105.233C51.0263 108.674 56.5982 108.681 60.0389 105.248Z"
        fill={fill}
      />
    </svg>
  );
}
