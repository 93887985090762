/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { staticImageUrl } from '../../util/images';

import PromoDialog from '../UI/PromoDialog';

export default function SpecialOfferPopup({ specialOffer }) {
  const [modalIsClosed, closeModal] = useState(false);

  // NOTE: this is a temporary test for Kurz DE. will have to figure long term
  // solution for supporting hyper custom modal messaging on start links
  const showKurzgesagtDECustomModal = specialOffer.token === 'kurzgesagtDE';

  return (
    <div>
      {!modalIsClosed &&
        (showKurzgesagtDECustomModal ? (
          <PromoDialog
            topImageUrl={staticImageUrl('mascot/wren-bird.png')}
            onRequestClose={() => closeModal(true)}
            title="Willkommen! 🇩🇪"
            subtitle="Ein besonderes Hallo für unsere Freunde von <b>Kurzgesagt</b>. Abonnieren Sie heute und sie bekommen den ersten Monat kostenlos."
            ctaText="Weiter"
          />
        ) : (
          <PromoDialog
            topImageUrl={staticImageUrl('mascot/wren-bird.png')}
            onRequestClose={() => closeModal(true)}
            title="Welcome to Wren!"
            subtitle={`${
              specialOffer
                ? `${specialOffer.partnerName} has invited you to Wren. `
                : ''
            }Join Wren today and we’ll ${specialOffer.incentiveDescription}.`}
          />
        ))}
    </div>
  );
}
