import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function FAQ({
  color = colors.wrenGreen,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...restOfProps}
    >
      <path
        fill={color}
        d="M15 10.3V4.1A2 2 0 0 0 13 2H3a2 2 0 0 0-2 2v6.3a2 2 0 0 0 1.6 2v2l.3.5.5.2c.2 0 .4 0 .5-.2l2.3-2.4H13c1 0 1.9-1 1.9-2Z"
      />
      <path
        fill={color}
        fill-opacity=".5"
        d="M20.8 9h-4v1.3c0 2-1.7 3.5-3.7 3.5H8v3.6c0 1.1 1 2 2.1 2h7.4l2.4 2.4.6.2c.2 0 .4 0 .6-.2l.2-.6v-1.8a2 2 0 0 0 1.7-2V11C23 9.9 22 9 20.8 9Z"
      />
    </svg>
  );
}
