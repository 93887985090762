/** @jsxImportSource @emotion/react */

import { ReactNode } from 'react';
import { colors } from '../../style/theme';
import { scale } from '../../util/scale';
import Flex from './Flex';

const portfolioIcon = (isIconInvisible: boolean) =>
  scale({
    height: '50%',
    opacity: isIconInvisible ? 0 : 1,
    transition: 'all 500ms linear',
  });

const iconContainer = (
  bgColor: string,
  isIconInvisible: boolean,
  size?: number | number[]
) =>
  scale({
    borderRadius: '100%',
    backgroundColor: bgColor,
    width: size ?? [50, 50, 70, 70],
    flexShrink: 0,
    height: size ?? [50, 50, 70, 70],
    margin: ['0 16px', '0 16px', '0 16px', '0 16px'],
    '& > *': portfolioIcon(isIconInvisible),
  });

interface Props {
  isIconInvisible?: boolean;
  bgColor?: string;
  className?: string;
  children: ReactNode;
  size?: number | number[]; // an array of sizes is for responsiveness and corresponds to our media query breakpoints
}

export default function IconCircle({
  isIconInvisible = false,
  bgColor = colors.wrenOrange,
  children,
  className,
  size, // an array of sizes is for responsiveness and corresponds to our media query breakpoints
}: Props) {
  return (
    <Flex
      className={className}
      align="center"
      justify="center"
      css={iconContainer(bgColor, isIconInvisible, size)}
    >
      {children}
    </Flex>
  );
}
