/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

TwitterLogo.propTypes = {
  color: PropTypes.string,
};

TwitterLogo.defaultProps = {
  color: undefined,
};

export default function TwitterLogo({ color, size = 20, ...restOfProps }) {
  return (
    <svg
      height={size}
      viewBox="0 0 250 250.59"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <title>Twitter</title>
      <path
        fill={color || '#1da1f2'}
        d="M78.62,226.86c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,250,47.74a102.24,102.24,0,0,1-29.46,8.07,51.46,51.46,0,0,0,22.55-28.37,102.53,102.53,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.56,145.56,0,0,1,17.4,33.08a51.33,51.33,0,0,0,15.88,68.47A50.84,50.84,0,0,1,10,95.13v.65a51.31,51.31,0,0,0,41.15,50.28,51.2,51.2,0,0,1-23.16.88,51.34,51.34,0,0,0,47.92,35.62,102.84,102.84,0,0,1-63.7,22A101.69,101.69,0,0,1,0,203.82a145.14,145.14,0,0,0,78.62,23"
      />
    </svg>
  );
}
