/** @jsxImportSource @emotion/react */
import { HTMLAttributes } from 'react';
import { colors } from '../../style/theme';

const ArrowDown = ({
  color = colors.gray3,
  size = 20,
  className,
  ...otherProps
}: {
  className?: string;
  color?: string;
  size?: number;
} & HTMLAttributes<SVGElement>) => {
  return (
    <svg
      className={className}
      fill={color}
      height={(size * 16) / 20 || '16'}
      viewBox="0 0 26 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.86203L2.86203 0L12.8894 10.0274L22.9168 0L25.7789 2.86203L15.7515 12.8894L15.7789 12.9168L12.9168 15.7789L12.8894 15.7515L12.862 15.7789L10 12.9168L10.0274 12.8894L0 2.86203Z"
      />
    </svg>
  );
};

export default ArrowDown;
