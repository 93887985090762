/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {
  ButtonHTMLAttributes,
  forwardRef,
  HTMLAttributes,
  JSXElementConstructor,
  MouseEventHandler,
  ReactNode,
  Ref,
} from 'react';
import { changeOpacity } from '../../lib/colors';
import { colors, fonts } from '../../style/theme';
import { scale } from '../../util/scale';

export type ButtonPadding = 'default' | 'xTight' | 'tight' | 'large' | 'none';
export type ButtonShape = 'rounded rectangle' | 'pill';
export type ButtonSize = 'xSmall' | 'small' | 'default' | 'medium' | 'large';
export type ButtonType = 'outlined' | 'filled' | 'text';

function buttonShapeStyle(buttonShape: ButtonShape) {
  return css({
    borderRadius: buttonShape === 'pill' ? Number.MAX_SAFE_INTEGER : 4,
  });
}

export function buttonColor(
  buttonType: ButtonType,
  color?: string,
  textColor?: string
) {
  switch (buttonType) {
    case 'filled':
      return {
        backgroundColor: color || colors.successGreen,
        borderColor: color || colors.successGreen,
        color: textColor || 'white',
        ':hover': {
          backgroundColor: color
            ? changeOpacity(color, 0.85)
            : changeOpacity(colors.successGreen, 0.85),
          color: textColor || 'white',
        },
      };
    case 'outlined':
      return {
        backgroundColor: color ? changeOpacity(color, 0.05) : 'transparent',
        borderColor: color || colors.textPrimary,
        color: textColor || color || colors.textPrimary,
        ':hover': {
          backgroundColor: changeOpacity(color || colors.textPrimary, 0.15),
          color: textColor || color || colors.textPrimary,
        },
      };
    case 'text':
      return {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: textColor || color || colors.textPrimary,
        ':hover': {
          filter: 'brightness(0.5)',
        },
      };
  }
}

export function getFontSizeForButtonSize(size: ButtonSize) {
  switch (size) {
    case 'xSmall':
      return 11;
    case 'small':
      return 12;
    case 'default':
    default:
      return 16;
    case 'medium':
      return 20;
    case 'large':
      return 24;
  }
}

export function buttonStyle(
  shape: ButtonShape,
  size: ButtonSize,
  padding: ButtonPadding,
  disabled: boolean
) {
  return scale(buttonShapeStyle(shape), {
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    boxSizing: 'border-box',
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'inline-flex',
    font: `normal ${getFontSizeForButtonSize(size)}px/1 ${fonts.Sans}`,
    gap:
      padding === 'xTight'
        ? '0.375em'
        : padding === 'tight'
        ? '0.5em'
        : padding === 'large'
        ? '1em'
        : '0.8125em',
    justifyContent: 'center',
    opacity: disabled ? 0.5 : 1,
    padding:
      padding === 'xTight'
        ? '0.375em 0.75em'
        : padding === 'tight'
        ? '0.5em 0.75em'
        : padding === 'large'
        ? '1em 1.5em'
        : padding === 'default'
        ? '0.8125em 1.125em'
        : '0',
    position: 'relative',
    textDecoration: 'none !important',
    transitionProperty: 'opacity, color, background-color, border-color',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease',
  });
}

export type ButtonProps = {
  children?: ReactNode;
  className?: string;
  color?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  padding?: ButtonPadding;
  shape?: ButtonShape;
  /** This affects font size and padding, and thus overall dimensions,
   *  but consumers can override any of those properties via css.
   **/
  size?: ButtonSize;
  buttonType?: ButtonType;
  textColor?: string;
};

export type ElementProps<CustomElementType> = {
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  ref: Ref<CustomElementType>;
};

export function buttonBaseHOC<
  CustomElementType extends HTMLElement,
  CustomElementAttributes extends HTMLAttributes<CustomElementType>
>(Component: JSXElementConstructor<ElementProps<CustomElementType>>) {
  return forwardRef<CustomElementType, ButtonProps & CustomElementAttributes>(
    (
      {
        children,
        color,
        disabled = false,
        onClick,
        padding = 'default',
        shape = 'pill',
        size = 'default',
        buttonType = 'outlined',
        textColor,
        ...restOfProps
      },
      ref
    ) => {
      return (
        <Component
          css={css([
            buttonStyle(shape, size, padding, disabled),
            buttonColor(buttonType, color, textColor),
          ])}
          disabled={disabled}
          ref={ref}
          onClick={onClick}
          {...restOfProps}
        >
          <div
            css={scale({
              height: '100%',
              width: '100%',
              minHeight: [44, 'unset'],
              minWidth: [44, 'unset'],
              position: 'absolute',
            })}
          >
            {/* extra click target area for accessibility */}
          </div>
          {children}
        </Component>
      );
    }
  );
}

export const Button = buttonBaseHOC<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(forwardRef((props, ref) => <button {...props} ref={ref} />));
