import type { ContentfulClientApi, EntryFieldTypes } from 'contentful';
import { createClient } from 'contentful';
import { JsonArray, JsonObject } from 'type-fest';
import {
  TypeClimateAction,
  TypePhotoCarousel,
  TypeProductRecommendation,
  TypeClimateActionSkeleton,
  TypePortfolioPageSkeleton,
  TypePortfolioPageFields,
  TypeProjectPageSkeleton,
  TypeMilestone,
  TypeFaqSkeleton,
  TypeDonorPortalSkeleton,
} from './contentfulTypes';

// These types help contentful know that when we fetch actions, we always fetch their
// linked categories and photo carousels, etc.

export type ClimateActionWithoutUnresolvableLinks = TypeClimateAction<
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>;

export type MilestoneWithoutUnresolvableLinks = TypeMilestone<
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>;

export type PhotoCarouselWithoutUnresolvableLinks = TypePhotoCarousel<
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>;

export type ProductRecommendationWithoutUnresolvableLinks =
  TypeProductRecommendation<'WITHOUT_UNRESOLVABLE_LINKS', string>;

async function graphQL(
  query: string,
  space: string,
  accessToken: string,
  variables: { [key: string]: string } = {}
) {
  const response = await fetch(
    `https://graphql.contentful.com/content/v1/spaces/${space}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    }
  );

  const responseBody = await response.json();

  if (responseBody.errors) {
    throw new Error(responseBody.errors[0].message);
  }

  return responseBody.data;
}

export interface ContentfulActionItemsCollection {
  slug: string;
  actionItemsCollection: {
    items: {
      slug: string;
    }[];
  };
}

export const getActionDetails = async ({
  space,
  accessToken,
}: {
  space: string;
  accessToken: string;
}) => {
  const data = await graphQL(
    `
      query ActionTree {
        climateActionCollection(limit: 500) {
          items {
            slug
            actionItemsCollection(limit: 21) {
              items {
                slug
              }
            }
          }
        }
      }
      `,
    space,
    accessToken
  );

  return data.climateActionCollection
    .items as ContentfulActionItemsCollection[];
};

export const initializeClient = ({
  space,
  accessToken,
}: {
  space: string;
  accessToken: string;
}) => {
  const client = createClient({ space, accessToken });
  return client;
};

export const getActions = async (client: ContentfulClientApi<undefined>) => {
  const data =
    await client.withoutUnresolvableLinks.getEntries<TypeClimateActionSkeleton>(
      {
        content_type: 'climateAction',
        include: 10,
        limit: 1000,
      }
    );

  return data.items;
};

export const getFaqs = async (client: ContentfulClientApi<undefined>) => {
  const data =
    await client.withoutUnresolvableLinks.getEntries<TypeFaqSkeleton>({
      content_type: 'faq',
      include: 10,
      limit: 1000,
    });

  return data.items;
};

export interface PortfolioPageFieldsWithImgix extends TypePortfolioPageFields {
  icon?: EntryFieldTypes.Object<ImgixObject>;
  heroImage?: EntryFieldTypes.Object<ImgixObject>;
}

export interface PortfolioPageSkeletonWithImgix
  extends TypePortfolioPageSkeleton {
  fields: PortfolioPageFieldsWithImgix;
}

export function getPortfolioPage(
  slug: string,
  client: ContentfulClientApi<undefined>
) {
  return client.withoutUnresolvableLinks.getEntries<PortfolioPageSkeletonWithImgix>(
    {
      content_type: 'portfolioPage',
      include: 10,
      limit: 1,
      'fields.slug': slug,
    }
  );
}

export function getProjectPage(
  slug: string,
  client: ContentfulClientApi<undefined>
) {
  return client.withoutUnresolvableLinks.getEntries<TypeProjectPageSkeleton>({
    content_type: 'projectPage',
    include: 10,
    limit: 1,
    'fields.slug': slug,
  });
}

export function getDonorPortalPage(
  portfolioSlug: string,
  client: ContentfulClientApi<undefined>
) {
  return client.withoutUnresolvableLinks.getEntries<TypeDonorPortalSkeleton>({
    content_type: 'donorPortal',
    include: 10,
    limit: 1,
    'fields.portfolioSlug': portfolioSlug,
  });
}

export interface ImgixObject extends JsonObject {
  attributes: {
    colors?: {
      dominant_colors?: string;
    };
    content_type?: string;
    media_height?: number;
    media_width?: number;
    media_kind?: 'IMAGE' | string;
  };
  src: string;
}

export function isImgixObject(
  object: JsonObject | JsonArray | null | undefined
): object is ImgixObject {
  if (!object || typeof object?.length === 'number') {
    return false;
  }
  return typeof (object as JsonObject).media_kind === 'string';
}

// Returned in descending order of score
export function parseDominantColors(object: ImgixObject): string[] {
  const dominantColorsString = object.attributes.colors?.dominant_colors;
  if (!dominantColorsString) {
    return [];
  }
  const hash = JSON.parse(dominantColorsString);
  const results: { color: string; score: number }[] = [];
  for (const key in hash) {
    results.push({ color: key, score: hash[key] });
  }
  results.sort((a, b) => b.score - a.score);
  return results.map((x) => x.color);
}
