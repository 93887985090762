import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Pulse({
  color = colors.wrenGreen,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...restOfProps}
    >
      <path
        fill={color}
        d="M19.7 12.8c-.4 0-.9.1-1.2.4-.4.3-.7.7-.9 1.1h-1.8l-1.3-4c0-.2-.2-.4-.3-.5a.7.7 0 0 0-1 0l-.2.4-2 7.5-2.5-15c0-.2-.1-.4-.3-.5a.7.7 0 0 0-1.2.4L4.2 14.3H1.7c-.1 0-.3 0-.5.2a.8.8 0 0 0 0 1.1c.2.2.4.2.5.2h3l.5-.1.3-.4 2.1-8.8L10 21.4l.2.4.5.2c.2 0 .4 0 .5-.2l.3-.4 2.3-8.3.7 2.2c0 .2.2.3.3.4l.4.1h2.4c.2.4.4.8.7 1a2.2 2.2 0 0 0 3.3-.4 2.3 2.3 0 0 0-.7-3.3c-.3-.2-.7-.3-1.2-.3Z"
      />
      <defs>
        <linearGradient
          x1="22.5"
          x2="-.6"
          y1="14.7"
          y2="14.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".6" stop-color={color} />
          <stop offset="1" stop-color={color} stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
