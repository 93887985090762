/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

import { colors, fonts } from '../../style/theme';
import { scale } from '../../util/scale';

import HoverArrow from '../svgs/HoverArrow';
import LoadingSpinner from '../UI/LoadingSpinner';
import { changeOpacity } from '../../lib/colors';
import { Link } from 'react-router-dom';
import Fade from '../animation/Fade';

const buttonBase = scale({
  fontFamily: fonts.Sans,
  fontWeight: 500,
  textAlign: 'center',
  cursor: 'pointer',
  fontSize: '16px !important',
  borderRadius: '20px !important',
  padding: '6px 16px !important',
  boxSizing: 'border-box',
  display: 'inline-flex',
  transition: 'all 0.3s ease',
  border: 'none !important',
  textDecoration: 'none !important',
});

const smallStyles = scale({
  fontSize: '12px !important',
  borderRadius: '50px !important',
  padding: '2px 8px !important',
  fontWeight: 500,
});

function getTextColor(color) {
  if (color === 'transparent') return 'var(--link-blue)';
  if (color === 'gray') return 'var(--text-primary)';
  return 'white';
}

function getBackgroundColor(color) {
  if (color === 'transparent') return 'transparent';
  if (color === 'orange') return 'var(--wren-orange)';
  if (color === 'gray') return 'var(--gray-9)';
  if (color === 'darkgreen') return '#1d4827';
  if (color === 'blue') return 'var(--conservation-blue)';
  if (color === 'purple') return 'var(--policy-purple)';
  if (color === 'red') return 'var(--error-red)';
  return 'var(--success-green)';
}

function getBackgroundHoverColor(color) {
  if (color === 'transparent') return 'transparent';
  if (color === 'orange') return 'var(--wren-orange-hover)';
  if (color === 'gray') return 'var(--border-color)';
  if (color === 'darkgreen') return '#296838';
  if (color === 'blue') return changeOpacity(colors.conservationBlue, 0.8);
  if (color === 'purple') return changeOpacity(colors.policyPurple, 0.8);
  if (color === 'red') return changeOpacity(colors.errorRed, 0.8);
  return 'var(--success-green-hover)';
}

export default function RoundedButton(props) {
  const {
    color,
    onClick,
    children,
    className,
    id,
    to,
    size,
    noArrow,
    loading = false,
    openInNewTab,
    textColorOverride,
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const [delayedLoad, setDelayedLoad] = useState(false);

  const textColor = textColorOverride ?? getTextColor(color);
  const backgroundColor = getBackgroundColor(color);
  const backgroundHoverColor = getBackgroundHoverColor(color);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedLoad(loading);
    }, 50);
    return () => clearTimeout(timer);
  }, [loading]);

  const colorStyle = {
    backgroundColor: `${backgroundColor} !important`,
    color: `${textColor} !important`,
    '&:hover': {
      backgroundColor: `${backgroundHoverColor} !important`,
    },
  };

  const handleClick = (event) => {
    onClick?.(event);
  };

  const LinkComponent = to?.startsWith('http') ? 'a' : Link;
  const Component = to ? LinkComponent : 'button';

  return (
    <Component
      css={[buttonBase, colorStyle, size === 'small' && smallStyles]}
      className={className}
      tabIndex={0}
      type="button"
      onClick={handleClick}
      to={LinkComponent === Link ? to : undefined}
      href={LinkComponent === 'a' ? to : undefined}
      target={openInNewTab ? '_blank' : null}
      id={id}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        css={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          width: '100%',
        }}
      >
        <span>{children}</span>
        {!noArrow && (
          <Fade animationKey={delayedLoad}>
            <div
              css={{
                marginLeft: 6,
                marginTop: size === 'small' ? 0 : 2,
                display: 'flex',
                alignItem: 'center',
              }}
            >
              {delayedLoad ? (
                <div css={{ padding: '4px 0px' }}>
                  <LoadingSpinner color="white" width={12} />
                </div>
              ) : (
                <HoverArrow
                  color={textColor}
                  width={size === 'small' ? 8 : 12}
                  isHovering={isHovering}
                />
              )}
            </div>
          </Fade>
        )}
      </div>
    </Component>
  );
}
