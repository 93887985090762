/** @jsxImportSource @emotion/react */
// Not the best typed file lol.

import { scale } from '../../util/scale';

import CountrySelect from './CountrySelect';
import { colors, fonts, spacing } from '../../style/theme';
import { ArrowNavButton } from '../buttons/ArrowNavButton';

const BORDER_RADIUS = 36;
const BORDER_RADIUS_MOBILE = 28;
const HEIGHT = 72;
const HEIGHT_MOBILE = 56;

const wrapperStyle = scale({
  width: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  cursor: 'pointer',
  padding: 0,
});

const buttonStyle = scale({
  position: 'absolute',
  right: [8, 16],
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: [18, 20],
});

const roundedInputStyles = {
  option: (provided: any) => ({
    ...provided,
    cursor: 'pointer !important',
    fontFamily: `${fonts.Sans} !important`,
    padding: `${spacing.default}px ${spacing.small}px`,
    borderBottom: `1px solid ${colors.backgroundBeige}`,
    alignItems: 'center',
    fontSize: [18, 20],
    color: colors.textPrimary,
  }),
  menu: (provided: any) =>
    scale({
      ...provided,
      marginTop: 0,
      borderRadius: [
        `0px 0px ${BORDER_RADIUS_MOBILE}px ${BORDER_RADIUS_MOBILE}px`,
        `0px 0px ${BORDER_RADIUS}px ${BORDER_RADIUS}px`,
      ],
      overflow: 'hidden',
      boxSizing: 'border-box',
      width: '100%',
      border: `1px solid ${colors.borderColor}`,
      borderTop: 'none',
      boxShadow: [
        '0px 5.38505px 6.73131px rgba(0, 0, 0, 0.15)',
        '0px 8px 10px rgba(0, 0, 0, 0.3)',
      ],
    }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: '18px !important',
    textAlign: 'left',
  }),
  control: (provided: any, state: any) =>
    scale({
      'min-height': [HEIGHT_MOBILE, HEIGHT],
      paddingLeft: spacing.small,
      paddingRight: spacing.small,
      alignItems: 'center',
      textAlign: 'left',
      display: '-webkit-flex',
      WebkitFlexWrap: 'wrap',
      MsFlexWrap: 'wrap',
      MsFlexPack: 'justify',
      fontSize: [18, 20],
      color: 'black',
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: colors.borderColor,
      boxShadow: [
        '0px 5.38505px 6.73131px rgba(0, 0, 0, 0.15)',
        '0px 8px 10px rgba(0, 0, 0, 0.3)',
      ],
      borderRadius: state.menuIsOpen
        ? [
            `${BORDER_RADIUS_MOBILE}px ${BORDER_RADIUS_MOBILE}px 0px 0px`,
            `${BORDER_RADIUS}px ${BORDER_RADIUS}px 0px 0px`,
          ]
        : [BORDER_RADIUS_MOBILE, BORDER_RADIUS],
    }),
  indicatorsContainer: () => ({ display: 'none' }),
  singleValue: (provided: any) => ({
    ...provided,
    transition: 'opacity 300ms',
    top: '50%',
  }),
};

export default function RoundedCountrySelectWithCTA({
  handleSubmitCountry,
  handleCTAClick,
}: {
  handleSubmitCountry: (country: string) => void;
  handleCTAClick: (event?: React.MouseEvent) => void;
}) {
  return (
    <CountrySelect
      css={wrapperStyle}
      placeholder="Enter your country"
      /* @ts-expect-error incompatible style type seems to work anyway */
      styles={roundedInputStyles}
      showFlagEmojis
      showCommonSearches
      handleSubmit={handleSubmitCountry}
      button={
        <ArrowNavButton
          href="/continue"
          id="landing-cta"
          css={buttonStyle}
          buttonType="filled"
          color={colors.wrenOrange}
          onClick={handleCTAClick}
          padding="tight"
          size="medium"
        >
          Start
        </ArrowNavButton>
      }
    />
  );
}
