/** @jsxImportSource @emotion/react */

export default function HoverArrow({
  width = 22,
  className = '',
  isHovering = false,
  color = 'white',
}) {
  const arrowTail = (isHovering) => ({
    transition: 'opacity 100ms ease-in',
    opacity: isHovering ? 1 : 0,
  });

  const arrowHead = (isHovering) => ({
    transition: 'transform 100ms ease-in',
    transform: isHovering ? 'translate(0px)' : `translate(-${width / 2}px)`,
  });

  return (
    <svg
      className={className}
      width={width}
      height={(23 * width) / 22}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="9"
        width="15"
        height="5"
        fill={color}
        css={arrowTail(isHovering)}
      />
      <path
        css={arrowHead(isHovering)}
        d="M22 11.4999L10.5714 0L7.00002 3.59373L14.8572 11.5L7 19.4062L10.5714 23L22 11.4999Z"
        fill={color}
      />
    </svg>
  );
}
