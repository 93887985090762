/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';
import { HTMLAttributes, PropsWithChildren } from 'react';
import type * as CSS from 'csstype';

const fadeKeyframes = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
`;

export default function SkeletonLine({
  children,
  dark,
  height,
  margin,
  width,
  ...restOfProps
}: PropsWithChildren<{
  dark?: boolean;
}> &
  HTMLAttributes<HTMLDivElement> &
  Pick<CSS.Properties<string | number>, 'height' | 'margin' | 'width'>) {
  return (
    <div
      css={{
        animation: `${fadeKeyframes} 1.5s ease infinite`,
        backgroundColor: dark ? 'var(--gray-8)' : 'var(--gray-9)',
        borderRadius: `${1 / 5}em`,
        color: 'transparent',
        height: height ?? (children ? 'auto' : '1.125em'),
        margin: margin ?? '0 0 12px 0',
        maxWidth: '100%',
        transition: 'all .5s ease-out',
        width: width ?? '100%',
      }}
      {...restOfProps}
    >
      {children}
    </div>
  );
}
