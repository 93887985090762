/** @jsxImportSource @emotion/react */
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

const LocationPin = ({ fill = '#ffffff', className }) => {
  return (
    <svg
      width="10px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 296.3 409"
      className={className}
    >
      <path
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill,
        }}
        d="M148.2,0C230,0,296.3,68.5,296.3,153c0,74.6-71.9,180.5-127.3,242.8c-11.1,12.5-30.6,12.5-41.7,0
		C71.9,333.5,0,227.6,0,153C0,68.5,66.3,0,148.2,0z M148.2,75.7c43.8,0,79.3,35.5,79.3,79.3s-35.5,79.3-79.3,79.3
		S68.8,198.8,68.8,155S104.3,75.7,148.2,75.7z"
      />
    </svg>
  );
};

LocationPin.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

LocationPin.defaultProps = {
  color: undefined,
  size: 48,
};

export default withRouter(LocationPin);
