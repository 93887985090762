/** @jsxImportSource @emotion/react */

import { scale } from '../../util/scale';
import { Headings } from './TextStyles';
import RoundedButton from '../buttons/RoundedButton';
import Popup from './Popup';

const promoStyles = scale({
  li: {
    marginBottom: ['12px !important', '18px !important'],
    fontSize: ['15px', '16px', '18px', '18px'],
    lineHeight: ['20px', '24px'],
  },
  ol: {
    margin: [
      '24px 4px 16px 0px',
      '25px 10px 24px 0px',
      '30px 10px 24px 20px',
      '30px 10px 24px 20px',
    ],
  },
});

const marginBottom = scale({
  marginBottom: [24, 40],
});

export default function PromoDialog({
  onRequestClose,
  title,
  subtitle,
  list = [],
  addendum,
  topImageUrl,
  ctaText,
}) {
  return (
    <Popup css={promoStyles} onRequestClose={onRequestClose}>
      {topImageUrl && (
        <img
          src={topImageUrl}
          css={scale({ width: [150, 150, 300, 300] })}
          alt={title}
        />
      )}
      <Headings.Large font="sans" css={{ fontWeight: 'bold !important' }}>
        {title}
      </Headings.Large>
      <Headings.Medium
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></Headings.Medium>
      <ol>
        {list.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ol>
      <p css={marginBottom}>{addendum}</p>
      <RoundedButton color="orange" onClick={() => onRequestClose()}>
        {ctaText || 'Continue'}
      </RoundedButton>
    </Popup>
  );
}
