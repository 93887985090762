/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import { scale } from '../../util/scale';
import { Headings, Paragraph } from './TextStyles';

const container = scale({
  textAlign: 'center',
  maxWidth: '600px',
  marginBottom: '30px',
  margin: ['30px 10px', '30px 20px', '80px auto 40px auto'],
});

const smallCapsLight = scale({
  fontSize: '14px',
  opacity: '0.4',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  fontWeight: 700,
  marginBottom: '5px',
});

// Cleanup TODO: Move to UI folder
const HeaderBlock = (props) => {
  const { preamble, header, description, className } = props;

  return (
    <div css={container} className={className}>
      <p css={smallCapsLight}>{preamble}</p>
      <Headings.Large font="sans">{header}</Headings.Large>
      <Paragraph>{description}</Paragraph>
    </div>
  );
};

HeaderBlock.defaultProps = {
  header: '',
  description: '',
  preamble: '',
};

HeaderBlock.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  preamble: PropTypes.string,
};

export default HeaderBlock;
