import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Certification({
  color = colors.wrenGreen,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...restOfProps}
    >
      <path
        fill={color}
        d="m21.7 11.28-.88-.91a3 3 0 0 1-.82-2.5l.18-1.27c.08-.55-.3-1.06-.85-1.16l-1.25-.22a3.01 3.01 0 0 1-2.14-1.55l-.6-1.12a1.04 1.04 0 0 0-1.37-.44l-1.15.55c-.83.4-1.8.4-2.64 0l-1.15-.55c-.5-.25-1.1-.05-1.37.44l-.6 1.12a3 3 0 0 1-2.14 1.55l-1.25.22c-.56.1-.93.61-.85 1.16L3 7.86a3 3 0 0 1-.82 2.5l-.89.92c-.39.4-.39 1.04 0 1.44l.89.91a3 3 0 0 1 .82 2.5l-.18 1.27c-.08.55.3 1.06.85 1.16l1.25.22c.92.16 1.7.73 2.14 1.55l.6 1.12c.26.5.87.69 1.37.44l1.15-.55c.83-.4 1.8-.4 2.64 0l1.15.55c.5.25 1.1.05 1.37-.44l.6-1.12a3.01 3.01 0 0 1 2.14-1.55l1.26-.22c.55-.1.92-.61.84-1.16l-.17-1.26a3 3 0 0 1 .81-2.5l.89-.92c.39-.4.39-1.04 0-1.44ZM9.8 15.71l-2.3-2.3a1 1 0 0 1 0-1.41 1 1 0 0 1 1.41 0l1.6 1.58L15.1 9a1 1 0 0 1 1.42 0 1 1 0 0 1 0 1.41l-5.31 5.3a1 1 0 0 1-1.42 0Z"
      />
    </svg>
  );
}
