/** @jsxImportSource @emotion/react */

import PropTypes from 'prop-types';
import TooltipWrapper from './TooltipWrapper';
import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';

const questionMark = staticImageUrl('wren-definition-question-mark.png');

const bodyStyle = scale({
  overflowWrap: 'break-word',
  a: {
    color: 'var(--text-primary)',
    textDecoration: 'underline',
  },
});

const definition = scale({
  color: 'inherit',
  paddingLeft: '3px',
  paddingRight: '3px',
  textDecoration: 'underline',
  textDecorationColor: 'lightgrey',
  textDecorationSkip: 'auto',
});

const icon = scale({
  display: 'inline-block',
  width: '15px',
  marginRight: '5px',
});

// import mq to override media query breakpoints for a given page
export default function Definition(props) {
  const { body, title, children, plain, small } = props;

  return plain ? (
    <TooltipWrapper
      title={title}
      onPageContent={<div>{children}</div>}
      plain
      small={small}
    >
      <div css={bodyStyle} dangerouslySetInnerHTML={{ __html: body }} />
    </TooltipWrapper>
  ) : (
    <TooltipWrapper
      title={title}
      onPageContent={
        <span>
          <span css={definition}>{children}</span>
          <img css={icon} alt="definition" src={questionMark} />
        </span>
      }
    >
      <div css={bodyStyle} dangerouslySetInnerHTML={{ __html: body }} />
    </TooltipWrapper>
  );
}

Definition.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
  plain: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Definition.defaultProps = {
  body: '',
  title: '',
  plain: '',
  children: undefined,
};
