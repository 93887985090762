/** @jsxImportSource @emotion/react */

import { HTMLAttributes } from 'react';

export default function Times({
  size = 63,
  color = '#000',
  ...restOfProps
}: {
  color?: string;
  size?: string | number;
} & HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...restOfProps}
    >
      <path d="M5.39 2.75a.62.62 0 0 0-.88 0L2.75 4.5a.62.62 0 0 0 0 .88l6.7 6.7-6.7 6.7a.62.62 0 0 0 0 .88l1.76 1.76a.62.62 0 0 0 .88 0l6.7-6.7 6.7 6.7a.62.62 0 0 0 .88 0l1.76-1.76a.62.62 0 0 0 0-.88l-6.7-6.7 6.7-6.7a.62.62 0 0 0 0-.88l-1.76-1.76a.62.62 0 0 0-.88 0l-6.7 6.7-6.7-6.7Z" />
    </svg>
  );
}
