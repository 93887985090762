/** @jsxImportSource @emotion/react */

import { forwardRef, useState } from 'react';
import { NavButton } from './NavButton';
import { buttonColor, getFontSizeForButtonSize } from './ButtonNext';
import HoverArrow from '../svgs/HoverArrow';
import { PropsOf } from '@emotion/react';

export const ArrowNavButton = forwardRef<
  HTMLAnchorElement,
  PropsOf<typeof NavButton>
>(({ children, size, ...props }, ref) => {
  const [hover, setHover] = useState(false);
  return (
    <NavButton
      // CSS :hover would be better
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      size={size}
      {...props}
      ref={ref}
    >
      {children}
      <HoverArrow
        isHovering={hover}
        width={(getFontSizeForButtonSize(size || 'default') * 2) / 3}
        color={
          buttonColor(
            props.buttonType || 'outlined',
            props.color,
            props.textColor
          ).color
        }
      />
    </NavButton>
  );
});
