export type ContributionInterval =
  | {
      name: 'product_purchased';
      intervalCount: number;
    }
  | {
      name: 'revenue_earned';
      intervalAmountInUSDCents: number;
    }
  | {
      name: 'order_placed';
      intervalCount: number;
    };

export interface TreeContributionDetails {
  type: 'tree_orders';
  numberOfTrees: number;
  treeProviderId: number;
  interval: ContributionInterval;
}

export interface OffsetOrderContributionDetails {
  type: 'offset_orders';
  amountInUSDCents: number;
  portfolioId: number;
  interval: ContributionInterval;
}

export type ContributionDetails =
  | TreeContributionDetails
  | OffsetOrderContributionDetails;

export type ProtectedShopifyField =
  | 'owner_id'
  | 'id'
  | 'access_token'
  | 'shopify_domain';

export const PROTECTED_SHOPIFY_FIELD: ProtectedShopifyField[] = [
  'owner_id',
  'id',
  'access_token',
  'shopify_domain',
];

export type PrivateShopifyField = 'accessToken' | 'upcomingInvoice';
export const PRIVATE_SHOPIFY_FIELDS: PrivateShopifyField[] = [
  'accessToken',
  'upcomingInvoice',
];
