const initialState = 0;

function SetCalculatorQuestionIndex(state, action) {
  const calculatorLength = action.calculatorKeys.length;
  if (action.questionIndex <= calculatorLength) {
    return action.questionIndex;
  }
  return 0;
}

function ShowNextQuestion(state, action) {
  const calculatorLength = action.calculatorKeys.length;
  if (state < calculatorLength) {
    return state + 1;
  }
  return 0;
}

function ShowPreviousQuestion(state, action) {
  const calculatorLength = action.calculatorKeys.length;
  if (state > 0) {
    return state - 1;
  }
  return calculatorLength - 1;
}

export default function (state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case 'SET_CALCULATOR_QUESTION_INDEX':
      return SetCalculatorQuestionIndex(state, action);
    case 'SHOW_NEXT_QUESTION':
      return ShowNextQuestion(state, action);
    case 'SHOW_PREVIOUS_QUESTION':
      return ShowPreviousQuestion(state, action);
    default:
      return state;
  }
}
