/** @jsxImportSource @emotion/react */

import { colors } from '../../style/theme';
import { scale } from '../../util/scale';
import Flex from '../UI/Flex';
import Link from '../UI/Link';

const topAnnouncement = (color = 'var(--wren-green)') =>
  scale({
    textAlign: 'center',
    backgroundColor: color,
    color: 'white',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '14px 8px',
  });

export default function EventBanner() {
  return (
    <Link
      to="https://us06web.zoom.us/webinar/register/7316987744283/WN_2-7OmiZhT_eAKd-tf3oJBw"
      css={{ textDecoration: 'none', fontWeight: 500 }}
    >
      <Flex
        css={topAnnouncement(colors.conservationBlue)}
        align="center"
        justify="center"
      >
        Register for our free webinar to accelerate your sustainability journey
        🌱
      </Flex>
    </Link>
  );
}
