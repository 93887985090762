/** @jsxImportSource @emotion/react */
import { forwardRef, InputHTMLAttributes } from 'react';
import { sleekStyle } from '../../style/inputs';
import { colors, fonts, oldFontSizes } from '../../style/theme';
import { scale } from '../../util/scale';
import { withFieldContainer as withFieldContainer } from './FieldContainer';

const inputBox = (
  marginRight: string | number | undefined,
  maxWidth: string | number | undefined,
  paddingRight: string | number | undefined,
  height: string | number | undefined,
  width: string | number | undefined
) =>
  scale({
    boxSizing: 'border-box',
    alignSelf: 'stretch',
    height: height || '44px',
    padding: '10px',
    paddingRight,
    position: 'relative',
    backgroundColor: 'rgba(27, 60, 52, 0.08)',
    fontFamily: fonts.Sans,
    fontSize: oldFontSizes.body,
    borderRadius: '3px',
    border: 'transparent',
    transition: 'border 300ms ease-in-out',
    width: width || '100%',
    maxWidth,
    outline: 'none',
    display: 'inline',
    color: 'rgb(27, 60, 52)',
    marginBottom: '10px',
    marginRight: `${marginRight || '0px'}`,
    '::placeholder': {
      color: 'rgba(27, 60, 52, 0.45)',
    },
  });

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  errorBorder?: boolean;
  width?: string | number;
  maxWidth?: string;
  handleInputChange?: (value: string) => void;
  marginRight?: string;
  paddingRight?: string;
  isRequired?: boolean;
};

const redBorder = {
  border: `1px solid ${colors.errorRed}`,
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      errorBorder,
      width,
      maxWidth,
      handleInputChange,
      marginRight,
      onChange,
      paddingRight,
      height,
      isRequired,
      type = 'text',
      className,
      ...otherProps
    },
    ref
  ) => {
    return (
      <input
        css={[
          inputBox(marginRight, maxWidth, paddingRight, height, width),
          sleekStyle,
          errorBorder && redBorder,
        ]}
        ref={ref}
        required={isRequired}
        type={type}
        {...otherProps}
        onChange={(event) => {
          onChange?.(event);
          handleInputChange?.(event.target.value);
        }}
        className={className}
      />
    );
  }
);

const TextInputField = withFieldContainer<TextInputProps>(TextInput);

export default TextInputField;
