/* eslint-disable @typescript-eslint/no-var-requires */
const { default: zIndex } = require('../lib/zIndex');
const { scale } = require('../util/scale');
const { simpleParagraph } = require('./text');
const { pageWidth } = require('./theme');

const body = scale({
  maxWidth: ['90%', '90%', '650px', '1020px'],
  margin: 'auto',
  paddingTop: ['80px', '80px', '20px', '20px'],
});

const primaryContainer = scale({
  width: '90%',
  margin: 'auto',
  maxWidth: '1000px',
  position: 'relative',
  marginTop: '42px',
});

const wideBody = scale({
  width: ['85%', '85%', '70%', '100%'],
  maxWidth: '1035px',
  margin: 'auto',
  marginBottom: ['55px', 'inherit', 'inherit'],
});

const contentContainer = scale({
  maxWidth: '850px',
  marginTop: '30px',
  width: ['90%', '90%', '95%'],
});

const contentContainerWide = scale({
  maxWidth: '870px',
  margin: 'auto',
  width: ['90%', '90%', '95%'],
});

const narrowContainer = scale({
  maxWidth: '800px',
  width: ['90%', '90%', '95%'],
  margin: 'auto',
  p: {
    margin: 'auto',
    maxWidth: pageWidth,
  },
});

const article = {
  maxWidth: pageWidth,
  width: '100%',
  margin: 'auto',
  h1: {
    fontSize: '30px',
    lineHeight: '36px',
    marginBottom: '0px !important',
    paddingBottom: '8px',
  },
  li: simpleParagraph,
};

const smallColumn = scale({
  width: '100%',
  maxWidth: ['100%', '500px', '300px', '350px'],
  margin: 'auto',
  marginTop: ['20px', '20px', 0, 0],
});

const largeColumn = scale({
  width: '100%',
  maxWidth: ['100%', '500px', '600px', '500px'],
  margin: 'auto',
  marginTop: ['20px', '20px', 0, 0],
});

const relative = scale({
  position: 'relative',
});

const absolute = (top, right, bottom, left) =>
  scale({
    position: 'absolute',
    top,
    right,
    bottom,
    left,
  });

const inlineBlock = scale({
  display: 'inline-block',
  position: 'relative',
});

const messageCard = scale({
  width: ['100%', '90%', '90%', '80%'],
  marginTop: ['120px', '120px', '130px', '140px'],
  margin: 'auto',
  textAlign: 'center',
});

const centerForm = scale({
  margin: 'auto',
  paddingTop: ['80px', '120px', '150px'],
  width: '100%',
  textAlign: 'center',
  maxWidth: '300px',
});

const formContainer = scale({
  maxWidth: ['100%', '100%', '400px'],
});

const overflowHidden = scale({
  overflow: 'hidden',
});

const hideOnSmall = scale({
  display: ['none', 'none', 'block', 'block'],
});

const showOnSmall = scale({
  display: ['block', 'block', 'none', 'none'],
});

const hideOnMedium = scale({
  display: ['none', 'none', 'none', 'block'],
});

const showOnMedium = scale({
  display: ['block', 'block', 'block', 'none'],
});

const scrollableOnMobile = scale({
  marginBottom: ['250px', '0', '0', '0'],
  height: '100vh',
  overflowY: 'visible',
});

const modalStyle = scale({
  position: 'fixed',
  borderRadius: '5px',
  zIndex: zIndex('modalContent'),
  margin: 'auto',
  top: '5%',
  bottom: '80px',
  left: '5%',
  right: '5%',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxWidth: '750px',
  backgroundColor: 'white',
  padding: '20px',
});

const darkener = scale({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: '0px',
  left: '0px',
  zIndex: zIndex('modalOverlay'),
});

module.exports = {
  body,
  smallColumn,
  largeColumn,
  wideBody,
  hideOnSmall,
  showOnSmall,
  hideOnMedium,
  showOnMedium,
  relative,
  contentContainer,
  contentContainerWide,
  narrowContainer,
  inlineBlock,
  messageCard,
  centerForm,
  overflowHidden,
  modalStyle,
  darkener,
  primaryContainer,
  formContainer,
  scrollableOnMobile,
  absolute,
  article,
};
