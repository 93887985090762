/** @jsxImportSource @emotion/react */

import { useSelector } from 'react-redux';
import Flex from '../../components/UI/Flex';
import { Headings, Paragraph } from '../../components/UI/TextStyles';
import { formatNumber } from '../../lib/format';
import { colors, spacing } from '../../style/theme';
import { staticImageUrl } from '../../util/images';
import { CalloutBox } from './CalloutBox';
import { categoryColors } from './CategoryIcon';
import { State } from '../../store';

export default function EarnedReferralBonuses() {
  const user = useSelector((state: State) => state.user);

  return (
    <div css={{ marginBottom: spacing.medium }}>
      <CalloutBox backgroundColor={categoryColors.reach.background}>
        <Flex align="center" gap={spacing.medium}>
          <img
            src={staticImageUrl('solo-sparkly-tree.png', { w: 200 })}
            css={{ height: 50 }}
            alt="Solo sparkly tree"
          />
          <Headings.Massive
            font="sans"
            weight="600"
            css={{ padding: 0, margin: 0, lineHeight: 1 }}
          >
            {formatNumber(user.earnedReferralBonuses.trees, 0)}
          </Headings.Massive>
          <Paragraph css={{ color: colors.textSecondary, maxWidth: 150 }}>
            trees planted via your referrals
          </Paragraph>
        </Flex>
      </CalloutBox>
      {user.earnedReferralBonuses.hectares > 0 && (
        <CalloutBox backgroundColor={categoryColors.reach.background}>
          <Flex align="center" gap={spacing.medium}>
            <img
              src={staticImageUrl('solo-sparkly-tree.png', { w: 200 })}
              css={{ height: 50 }}
              alt="Solo sparkly tree"
            />
            <Headings.Massive
              font="sans"
              weight="600"
              css={{ padding: 0, margin: 0, lineHeight: 1 }}
            >
              {formatNumber(user.earnedReferralBonuses.hectares, 1)}
            </Headings.Massive>
            <Paragraph css={{ color: colors.textSecondary, maxWidth: 150 }}>
              hectares of rainforest protected for you and your friends
            </Paragraph>
          </Flex>
        </CalloutBox>
      )}
    </div>
  );
}
