export const SetToast = (toast) => ({
  type: 'SET_TOAST',
  toast,
});

export const SetError = (error) => ({
  type: 'SET_TOAST',
  toast: {
    text: error,
    type: 'alert',
    isOnRight: true,
  },
});

export const SetSuccessToast = (text) => ({
  type: 'SET_TOAST',
  toast: {
    text: text,
    type: 'success',
    isOnRight: true,
  },
});

export const DismissToast = () => ({
  type: 'DISMISS_TOAST',
});

export const ClearToast = () => ({
  type: 'CLEAR_TOAST',
});
