import { createContext, useContext } from 'react';
import * as AB from '../lib/ab';

export interface Options {
  onlyActivateIf?: () => boolean;
}

export function useExperimentClientSide(
  experimentName: AB.ExperimentName,
  userId: number,
  options: Options = {}
): AB.Treatment {
  if (AB.hasUserActivated(experimentName)) {
    return AB.getAssignmentForExperiment(userId, experimentName);
  }

  const allowActivation = !options.onlyActivateIf || options.onlyActivateIf();

  if (allowActivation) {
    return AB.getTreatmentAndActivate(userId, experimentName);
  }

  return 'control';
}

export const ExperimentContext = createContext<{
  userId: number | null;
  useExperimentServerSide: (
    experimentName: AB.ExperimentName,
    options: Options
  ) => AB.Treatment;
}>({
  userId: null,
  useExperimentServerSide: () => 'control',
});

export default function useExperiment(
  experimentName: AB.ExperimentName,
  options: Options = {}
) {
  const context = useContext(ExperimentContext);
  if (typeof window !== 'undefined' && context.userId !== null) {
    return useExperimentClientSide(experimentName, context.userId, options);
  }
  return context.useExperimentServerSide(experimentName, options);
}
