/** @jsxImportSource @emotion/react */

import { motion } from 'framer-motion';

import LayoutStyles from '../../style/layout';
import TistGrove from '../../components/svgs/TistGrove';
import ArrowRight from '../../components/svgs/ArrowRight';
import { scale } from '../../util/scale';
import { fonts } from '../../style/theme';
import { staticImageUrl } from '../../util/images';

const illustrationsContainer = (orientation) =>
  scale({
    display: 'grid',
    gridTemplateColumns: orientation === 'vertical' ? '1fr' : '1fr 1fr 1fr',
    gridGap: orientation === 'vertical' ? 30 : 0,
    marginBottom: 40,
    position: 'relative',
  });

const illustrationsContainerResponsive = scale({
  display: 'grid',
  gridTemplateColumns: ['1fr', '1fr', '1fr 1fr 1fr'],
  gridGap: [0, 0, 30],
  marginBottom: 40,
  position: 'relative',
});

const customP = {
  fontSize: '17px',
  marginBottom: 4,
  textAlign: 'center',
};

const wrapper = (orientation) =>
  scale({
    height: orientation === 'horizontal' ? 150 : 'auto',
    marginTop: orientation === 'horizontal' ? 0 : 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  });

const responsiveWrapper = scale({
  height: ['auto', 'auto', 150],
  marginTop: [24, 24, 0],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
});

export function EmissionsGraph() {
  return (
    <div
      css={{
        width: 'fit-content',
        margin: 'auto',
        marginBottom: 16,
        position: 'relative',
      }}
    >
      <motion.div
        key="house"
        css={LayoutStyles.absolute(24, null, null, -72)}
        animate={{ y: 8, x: 2, rotate: -4 }}
        transition={{
          ease: 'easeInOut',
          duration: 3.2,
          flip: Infinity,
        }}
      >
        <img
          src={staticImageUrl('illustrated-steps/house-blob.png')}
          css={{ width: 20 }}
        />
      </motion.div>
      <motion.div
        key="meat"
        css={LayoutStyles.absolute(16, null, null, -48)}
        animate={{ y: -4, x: 2, rotate: 4 }}
        transition={{
          ease: 'easeInOut',
          duration: 2.4,
          flip: Infinity,
        }}
      >
        <img
          src={staticImageUrl('illustrated-steps/meat-blob.png')}
          css={{ width: 33 }}
        />
      </motion.div>
      <img
        src={staticImageUrl('illustrated-steps/bar-chart-icon.png')}
        css={{ width: 74 }}
      />
      <motion.div
        key="car"
        css={LayoutStyles.absolute(16, -40)}
        animate={{ y: -4, x: -2, rotate: 4 }}
        transition={{
          ease: 'easeInOut',
          duration: 2.4,
          flip: Infinity,
        }}
      >
        <img
          src={staticImageUrl('illustrated-steps/car-blob.png')}
          css={{ width: 33 }}
        />
      </motion.div>
      <motion.div
        key="energy"
        css={LayoutStyles.absolute(24, -64)}
        animate={{ y: 8, x: -2, rotate: -4 }}
        transition={{
          ease: 'easeInOut',
          duration: 3.2,
          flip: Infinity,
        }}
      >
        <img
          src={staticImageUrl('illustrated-steps/energy-blob.png')}
          css={{ width: 23 }}
        />
      </motion.div>
    </div>
  );
}

export function TistAndRff({ className }) {
  return (
    <div
      css={{
        position: 'relative',
        width: 'fit-content',
        margin: 'auto',
        marginBottom: 16,
      }}
      className={className}
    >
      <span css={{ marginRight: 8 }}>
        <img src={staticImageUrl('tist-farmer.png')} css={{ width: 57 }} />
      </span>
      <span css={{ marginRight: 8 }}>
        <img
          src={staticImageUrl('rainforest-protector.png')}
          css={{ width: 32 }}
        />
      </span>
      <motion.div
        key="drone"
        css={LayoutStyles.absolute(10, -50)}
        animate={{ y: -10, x: 5, rotate: 5 }}
        transition={{
          ease: 'easeInOut',
          duration: 3.2,
          flip: Infinity,
        }}
      >
        <img
          src={staticImageUrl('illustrated-steps/drone.png')}
          css={{ width: 48 }}
        />
      </motion.div>
    </div>
  );
}

function ProjectSteps() {
  return (
    <div
      css={{
        marginBottom: 16,
        display: 'flex',
        flexWrap: 'no-wrap',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }}
    >
      <img
        src={staticImageUrl('illustrated-steps/tist-sapling.png')}
        css={{ width: 50 }}
      />
      <motion.div
        key="arrow right 1"
        css={{ margin: 'auto 8px' }}
        animate={{ opacity: 0 }}
        transition={{
          ease: 'easeInOut',
          duration: 1.5,
          flip: Infinity,
        }}
      >
        <ArrowRight />
      </motion.div>
      <TistGrove />
      <motion.div
        key="arrow right 2"
        css={{ margin: 'auto 8px' }}
        animate={{ opacity: 0 }}
        transition={{
          delay: 1.5,
          ease: 'easeInOut',
          duration: 1.5,
          flip: Infinity,
        }}
      >
        <ArrowRight />
      </motion.div>{' '}
      <img
        src={staticImageUrl('illustrated-steps/tist-paid-farmer.png')}
        css={{ width: 50 }}
      />
    </div>
  );
}

export function StepWrapper({ icon, title, description, orientation }) {
  return (
    <div>
      <div css={orientation ? wrapper(orientation) : responsiveWrapper}>
        {icon}
      </div>
      <p css={customP}>
        <strong>{title}</strong>
      </p>
      <p
        css={{
          maxWidth: '340px',
          margin: 'auto',
          marginTop: 8,
          textAlign: 'center',
          lineHeight: 1.4,
          fontSize: 16,
          fontFamily: fonts.Sans,
          color: 'var(--gray-4)',
        }}
      >
        {description}
      </p>
    </div>
  );
}

export function IllustratedStepsTemplate({ orientation, steps, className }) {
  if (orientation) {
    return (
      <div css={illustrationsContainer(orientation)} className={className}>
        <StepWrapper orientation={orientation} {...steps[0]} />
        <StepWrapper orientation={orientation} {...steps[1]} />
        {/* {orientation === 'horizontal' && <SquigglyArrowTop css={topArrow} />} */}
        <StepWrapper orientation={orientation} {...steps[2]} />
      </div>
    );
  }
  return (
    <div css={illustrationsContainerResponsive} className={className}>
      <StepWrapper {...steps[0]} />
      <StepWrapper {...steps[1]} />
      <StepWrapper {...steps[2]} />
    </div>
  );
}

export default function IllustratedSteps({ orientation }) {
  const steps = [
    {
      icon: <EmissionsGraph />,
      title: '1. Work out your carbon footprint',
      description:
        'Use our simple calculator to find out how much CO₂ you emit.',
    },
    {
      icon: <TistAndRff />,
      title: '2. Fund projects that remove CO₂',
      description:
        'Fund carbon reduction & removal projects to neutralize your emissions.',
    },
    {
      icon: <ProjectSteps />,
      title: '3. See your impact grow',
      description:
        'Receive updates with data, photos, and stories that prove your impact.',
    },
  ];

  return <IllustratedStepsTemplate orientation={orientation} steps={steps} />;
}
