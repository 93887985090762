export function getOrdinal(i: number) {
  if (i == null) {
    return null;
  }

  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}

export const capitalizeFirstCharacter = (word: string) => {
  if (!word) return '';
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const capitalizeWords = (text: string) => {
  if (!text) return '';
  return text.replace(/\w+/g, capitalizeFirstCharacter);
};
