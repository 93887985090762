import { combineReducers } from 'redux';
import User from './user';
import Calculator from './calculator';
import CalculatorQuestionIndex from './calculatorQuestionIndex';
import Flights from './flights';
import Portfolios from './portfolios';
import Cart from './cart';
import Stripe from './stripe';
import Toaster from './toaster';
import ExchangeRates from './exchangeRates';

const rootReducer = combineReducers({
  user: User,
  flights: Flights,
  calculator: Calculator,
  calculatorQuestionIndex: CalculatorQuestionIndex,
  portfolios: Portfolios,
  cart: Cart,
  stripe: Stripe,
  toaster: Toaster,
  exchangeRates: ExchangeRates,
});

export default rootReducer;
