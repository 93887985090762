/** @jsxImportSource @emotion/react */

export default function YoutubeLogo({ width = 20, ...restOfProps }) {
  return (
    <svg
      width={width}
      viewBox="0 0 295 206"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M262.605 6.33144C275.204 9.72332 285.137 19.6567 288.529 32.2551C294.828 55.2715 294.586 103.242 294.586 103.242C294.586 103.242 294.586 150.971 288.529 173.987C285.137 186.586 275.204 196.519 262.605 199.911C239.589 205.968 147.523 205.968 147.523 205.968C147.523 205.968 55.7002 205.968 32.4416 199.669C19.8431 196.277 9.90976 186.344 6.51787 173.745C0.460938 150.971 0.460938 103 0.460938 103C0.460938 103 0.460938 55.2715 6.51787 32.2551C9.90976 19.6567 20.0854 9.48105 32.4416 6.08916C55.4579 0.0322266 147.523 0.0322266 147.523 0.0322266C147.523 0.0322266 239.589 0.0322266 262.605 6.33144ZM194.768 103L118.208 147.095V58.9058L194.768 103Z"
        fill="white"
      />
    </svg>
  );
}
