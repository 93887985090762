import * as AB from './ab';
import { storage, cookies } from './storage';

export function getSessionToken() {
  return cookies.get('sessionToken');
}

export function setSessionToken(token: string) {
  return cookies.set('sessionToken', token);
}

export function removeSessionToken() {
  return cookies.remove('sessionToken');
}

export function clearSession() {
  AB.reset(cookies);
  removeSessionToken();
  storage.clear();
}
