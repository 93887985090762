import { scale } from '../util/scale';
import { fontSizes, fonts } from './theme';

export const headingMassive = scale({
  fontSize: [
    fontSizes.headingMassiveMobile,
    fontSizes.headingMassiveMobile,
    fontSizes.headingMassive,
  ],
  lineHeight: 1.2,
  fontFamily: fonts.Serif,
  fontStyle: 'normal',
  letterSpacing: '-1px',
  fontDisplay: 'swap',
  fontWeight: 600,
});

export const headingXLarge = scale({
  fontSize: [
    fontSizes.headingXLargeMobile,
    fontSizes.headingXLargeMobile,
    fontSizes.headingXLarge,
  ],
  lineHeight: 1.2,
  fontFamily: fonts.Serif,
  fontStyle: 'normal',
  margin: 'auto',
  paddingBottom: 10,
  fontWeight: 600,
});

export const headingLarge = scale({
  fontSize: [
    fontSizes.headingLargeMobile,
    fontSizes.headingLargeMobile,
    fontSizes.headingLarge,
  ],
  lineHeight: 1.2,
  fontFamily: fonts.Serif,
  fontStyle: 'normal',
  margin: 'auto',
  paddingBottom: 10,
  fontWeight: 600,
});

export const headingMedium = scale({
  fontSize: [fontSizes.headingMediumMobile, fontSizes.headingMedium],
  lineHeight: 1.4,
  fontFamily: fonts.Sans,
  fontStyle: 'normal',
  margin: 'auto',
  paddingBottom: 10,
  fontWeight: 400,
});

export const headingSmall = scale({
  fontSize: [
    fontSizes.headingSmallMobile,
    fontSizes.headingSmallMobile,
    fontSizes.headingSmall,
  ],
  lineHeight: 1.4,
  fontFamily: fonts.Sans,
  fontStyle: 'normal',
  fontWeight: 400,
});

export const paragraph = scale({
  fontSize: [
    fontSizes.paragraphMobile,
    fontSizes.paragraphMobile,
    fontSizes.paragraph,
  ],
  lineHeight: 1.4,
  fontFamily: fonts.Sans,
  fontStyle: 'normal',
  margin: 0,
  fontWeight: 400,
});

export const citation = scale({
  fontSize: fontSizes.citation,
  lineHeight: 1.3,
  fontFamily: fonts.Sans,
  fontStyle: 'normal',
  fontWeight: 400,
});
