/** @jsxImportSource @emotion/react */

import { Link } from 'react-router-dom';
import Button from '../components/buttons/Button';
import { staticImageUrl } from '../util/images';
import { fonts, oldFontSizes } from '../style/theme';
import { scale } from '../util/scale';

const placeholderImg = staticImageUrl('placeholder.png');

const container = scale({
  margin: 'auto',
  textAlign: 'center',
  padding: '50px',
  position: 'relative',
  paddingTop: ['110px', '120px', '180px', '200px'],
  img: { width: 100, marginBottom: 16 },
  h1: {
    fontFamily: fonts.Sans,
    fontWeight: 700,
    marginBottom: 4,
  },
  p: {
    fontSize: oldFontSizes.bodyLarge,
    maxWidth: '400px',
    lineHeight: '25px',
    margin: 'auto',
  },
});

export default function NotFound() {
  return (
    <div css={container}>
      <img src={placeholderImg} alt="a plant" />
      <h1>Whoops!</h1>
      <p>
        We couldn't find this page{' '}
        <span role="img" aria-label="sad face">
          😔
        </span>
      </p>
      <p>
        If you think this is an error, please{' '}
        <a href="mailto:team@wren.co?subject=I found a bug on wren.co!">
          reach out.
        </a>
      </p>
      <div css={{ maxWidth: '200px', margin: 'auto', marginTop: 16 }}>
        <Link to="/">
          <Button text=" ← Go back home" size="large" />
        </Link>
      </div>
    </div>
  );
}
