/* eslint-disable @typescript-eslint/no-var-requires */
const { fontSizes, fonts } = require('./theme');
const { scale } = require('../util/scale');

const shareButtonsContainer = scale({
  display: 'flex',
  height: ['auto', 'fit-content', 'fit-content'],
  width: ['auto', 'auto', 'auto'],
  margin: '-5px',
  justifyContent: 'center',
  flexWrap: 'wrap',
  flexBasis: ['100%', 'auto'],
});

const onlyOnMobile = scale({
  display: ['block', 'none'],
});

const linkStyle = scale({
  width: ['100% !important', '45% !important', 'auto !important'],
  margin: '5px',
  textDecoration: 'none !important',
  cursor: 'pointer',
});

const smallLinkStyle = [
  linkStyle,
  scale({
    width: ['45% !important', '45% !important', 'auto !important'],
  }),
];

const shareButtonStyle = scale({
  width: ['100%', '100%', '140px'],
  minWidth: '10px',
  height: '52px !important',
  backgroundColor: 'white',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid var(--border-color)',
  padding: '0 8px',
  textAlign: 'center',
  cursor: 'pointer',
  margin: 'auto !important',
  transition: 'background-color 200ms ease-in-out, border 200ms ease-in-out',
  '&:hover': {
    backgroundColor: 'white',
    border: '1px solid var(--gray-7)',
  },
  '&:focus': {
    backgroundColor: 'white',
    border: '1px solid var(--gray-7)',
  },
});

const textStyle = scale({
  color: 'var(--gray-3)',
  fontFamily: `${fonts.Sans} !important`,
  fontSize: fontSizes.bodyLarge,
  fontWeight: 600,
  margin: 0,
  padding: 0,
  marginLeft: '6px',
});

const logoStyle = scale({
  width: '20px',
  height: '20px',
});

const smallShareButtonStyle = [
  shareButtonStyle,
  scale({
    padding: ['0 3px', '0 3px', 0],
    height: '25px !important',
    maxWidth: ['90px', '90px', '100px'],
    margin: 'auto !important',
  }),
];

const smallLogoStyle = scale({
  width: [12, 12, 15],
  height: [12, 12, 15],
});

const smallTextStyle = scale({
  color: 'var(--gray-3)',
  fontSize: ['13px', '13px', '14px'],
  fontWeight: 600,
  margin: 0,
  padding: 0,
  marginLeft: ['3px', '3px', '5px'],
});

const icon = scale({
  cursor: 'pointer',
  position: 'relative',
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  backgroundColor: 'var(--background-gray)',
  border: '2px solid var(--gray-9)',
  transition: '0.2s ease-in-out background-color',
  '*': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

module.exports = {
  shareButtonsContainer,
  smallLinkStyle,
  smallShareButtonStyle,
  shareButtonStyle,
  smallTextStyle,
  textStyle,
  logoStyle,
  smallLogoStyle,
  linkStyle,
  onlyOnMobile,
  icon,
};
