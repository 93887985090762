/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { scale } from '../../util/scale';

const separator = scale({
  width: '100%',
  height: '1px',
  backgroundColor: 'rgba(0,0,0,0.15)',
});

export default function HorizontalSeparator({ className }) {
  return <div css={separator} className={className} />;
}

HorizontalSeparator.propTypes = {
  className: PropTypes.string,
};
