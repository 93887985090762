/** @jsxImportSource @emotion/react */

import { PropsOf } from '@emotion/react';
import { lazy } from 'react';
import { colors } from '../../../style/theme';
import { asPopupButton } from './PopUpButton';

const SignUpPopupButton = asPopupButton(
  lazy(() => import('../popups/SignUpPopup'))
);

export default function SignUpButton(props: PropsOf<typeof SignUpPopupButton>) {
  return (
    <SignUpPopupButton
      buttonType="filled"
      color={colors.successGreen}
      css={
        {
          fontWeight: 'normal',
          textAlign: 'left',
        } /* reset styles from DesktopNav */
      }
      padding="xTight"
      {...props}
    >
      Sign up
    </SignUpPopupButton>
  );
}
