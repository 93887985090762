export const TAX_DEDUCTIBLE_PORTFOLIO_IDS = [24, 29, 32];

export const DONOR_PORTAL_PORTFOLIO_IDS = {
  32: '/terraformation',
};

export const CLIMATE_COLLECTIVE_PORTFOLIO_ID = 24;

export function isDonorPortalPortfolio(
  portfolioId: number
): portfolioId is keyof typeof DONOR_PORTAL_PORTFOLIO_IDS {
  return Object.keys(DONOR_PORTAL_PORTFOLIO_IDS).includes(
    portfolioId.toString()
  );
}

export function getDonorPortalPortfolioSlug(portfolioId: number) {
  if (!isDonorPortalPortfolio(portfolioId)) {
    throw new Error('Not a donor portal portfolio');
  }
  return DONOR_PORTAL_PORTFOLIO_IDS[portfolioId];
}
