/** @jsxImportSource @emotion/react */

export default function WhatsAppLogo({ color = 'black', size = 20 }) {
  return (
    <svg
      width={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6172 84.1387L29.1298 85.0309C35.4822 88.7843 42.7654 90.7692 50.193 90.7729H50.2089C73.0188 90.7729 91.5829 72.2963 91.5922 49.5864C91.5963 38.5814 87.296 28.2328 79.4822 20.4484C71.6685 12.664 61.2791 8.37488 50.2247 8.37024C27.3971 8.37024 8.83302 26.8449 8.82463 49.553C8.8209 57.3346 11.0086 64.9139 15.151 71.4719L16.1357 73.0297L11.9542 88.2273L27.6172 84.1387ZM0 100L7.06399 74.323C2.70716 66.8087 0.41498 58.2834 0.417778 49.5502C0.428974 22.2281 22.7633 0 50.2089 0C63.5274 0.00646746 76.0281 5.16641 85.43 14.5328C94.8291 23.8992 100.005 36.3487 100 49.5901C99.9879 76.9104 77.6498 99.1422 50.2089 99.1422H50.1874C41.8552 99.1388 33.6675 97.058 26.3946 93.1096L0 100Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.5877 28.8549C36.6562 26.7958 35.6763 26.7541 34.7914 26.718C34.0667 26.6874 33.2376 26.6892 32.4095 26.6892C31.5805 26.6892 30.2345 26.9989 29.0962 28.2366C27.957 29.4742 24.7461 32.466 24.7461 38.5514C24.7461 44.6369 29.1996 50.5165 29.82 51.3425C30.4413 52.1677 38.4167 65.0543 51.0488 70.0115C61.5458 74.1315 63.6818 73.3119 65.9611 73.1061C68.2396 72.9003 73.3134 70.1144 74.3483 67.2265C75.3842 64.3386 75.3842 61.8641 75.074 61.3468C74.7628 60.8304 73.9347 60.5217 72.6912 59.9033C71.4486 59.285 65.3389 56.2923 64.1997 55.8798C63.0604 55.4672 62.2323 55.2614 61.4033 56.499C60.5752 57.7367 58.1952 60.5217 57.4696 61.3468C56.7449 62.1729 56.0202 62.2758 54.7766 61.6574C53.534 61.0372 49.5314 59.7328 44.7835 55.5191C41.0892 52.2409 38.5956 48.1923 37.87 46.9546C37.1453 45.7169 37.7926 45.0476 38.4158 44.4311C38.9738 43.8766 39.6584 42.9866 40.2807 42.2644C40.9001 41.5422 41.1078 41.0268 41.5214 40.2026C41.9359 39.3765 41.7292 38.6543 41.418 38.0359C41.1078 37.4176 38.6925 31.3006 37.5877 28.8549Z"
        fill={color}
      />
    </svg>
  );
}
