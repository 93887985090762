/** @jsxImportSource @emotion/react */
import { Fragment, HTMLAttributes, useState } from 'react';

import { DropdownSection as DropdownSectionType } from '../../config/navDropdowns';
import { inlineBlock } from '../../style/layout';
import * as misc from '../../style/misc';
import { noStyleLink } from '../../style/text';
import { scale } from '../../util/scale';
import HorizontalSeparator from '../UI/HorizontalSeparator';
import NewTag from '../UI/NewTag';
import Fade from '../animation/Fade';
import ArrowDown from '../svgs/ArrowDown';
import DropdownLink from './DropdownLink';

const container = scale({
  position: 'absolute',
  outline: 'none',
  zIndex: 1234,
  paddingTop: '10px',
});

const innerContainer = (isWide?: boolean) =>
  scale({
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0px 10px 25px var(--box-shadow-color)',
    cursor: 'auto',
    width: isWide ? '470px' : '200px',
    maxWidth: '90vw',
    height: '100%',
  });

const leftStyle = (isWide?: boolean) =>
  scale({
    top: isWide ? 30 : 25,
    left: isWide ? -40 : 0,
  });

const rightStyle = (isWide?: boolean) =>
  scale({
    top: isWide ? 30 : 25,
    right: isWide ? -40 : 0,
  });

const centerStyle = scale({
  left: '50%',
  transform: 'translate(-50%)',
});

const sectionStyle = scale({
  padding: '6px 0px',
  fontSize: '12px !important',
  p: {
    textTransform: 'uppercase',
    fontSize: '12px !important',
    fontWeight: 700,
    letterSpacing: '1px',
    color: 'rgba(55, 53, 47, 0.4)',
    marginLeft: '14px',
    marginTop: '5px',
    marginBottom: '2px',
  },
});

function DropdownSection({
  section,
  onDropdownClose,
}: {
  section: DropdownSectionType;
  onDropdownClose?: () => void;
}) {
  return (
    <div css={sectionStyle}>
      {section.title && (
        <p css={{ paddingTop: 4 }}>
          <span
            css={{
              fontSize: '12px !important',
              letterSpacing: 0.5,
            }}
          >
            {section.title}
          </span>
        </p>
      )}
      {section.links.map((link) => {
        if (!link) return;
        const href = link.to + (link.hash ? `#${link.hash}` : '');
        const externalLinkAttributes = link.to.includes('http') // This is a bit hacky
          ? {
              target: '_blank',
              rel: 'noopener noreferrer',
            }
          : {};
        return (
          <DropdownLink
            key={href}
            to={href}
            onClick={() => onDropdownClose?.()}
            {...externalLinkAttributes}
          >
            {link.name}
            {link.isNew && <NewTag />}
          </DropdownLink>
        );
      })}
    </div>
  );
}

const Dropdown = (
  props: {
    name?: string;
    customNavItem?: React.ReactNode;
    dropdownContent?: DropdownSectionType[];
    position?: 'left' | 'right' | 'center';
    isWide?: boolean;
    renderDropdownContent?: (
      dropdownContent?: DropdownSectionType[]
    ) => React.ReactNode;
  } & HTMLAttributes<HTMLDivElement>
) => {
  const {
    name,
    customNavItem,
    dropdownContent,
    position,
    isWide,
    renderDropdownContent,
    ...restOfProps
  } = props;

  const [showDropdown, setShowDropdown] = useState(false);

  const positionStyle = position
    ? {
        left: leftStyle(isWide),
        right: rightStyle(isWide),
        center: centerStyle,
      }[position]
    : centerStyle;

  return (
    <div
      css={noStyleLink}
      onMouseLeave={() => setShowDropdown(false)}
      onMouseEnter={() => setShowDropdown(true)}
      key={name}
      {...restOfProps}
    >
      {customNavItem || (
        <Fragment>
          <span css={inlineBlock}>{name}</span>
          <span css={misc.marginLeft(5)}>
            <ArrowDown color="currentColor" size={10} />
          </span>
        </Fragment>
      )}
      <div css={[container, positionStyle]}>
        <Fade>
          {showDropdown && (
            <div css={innerContainer(isWide)}>
              {renderDropdownContent
                ? renderDropdownContent(dropdownContent)
                : dropdownContent &&
                  dropdownContent.length > 0 &&
                  dropdownContent
                    .filter((section) => section)
                    .map((section, index) => (
                      <Fragment key={index}>
                        {index !== 0 && <HorizontalSeparator />}
                        <DropdownSection
                          onDropdownClose={() => setShowDropdown(false)}
                          section={section}
                        />
                      </Fragment>
                    ))}
            </div>
          )}
        </Fade>
      </div>
    </div>
  );
};

export default Dropdown;
