/** @jsxImportSource @emotion/react */

import { HTMLAttributes, ReactNode, useState } from 'react';
import { buttonCssReset } from '../../style/misc';
import { colors, fontSizes, spacing } from '../../style/theme';
import Times from '../svgs/Times';

type Props = {
  backgroundColor?: string;
  expanded?: boolean;
  heading: ReactNode;
  onRequestExpanded?: (expanded: boolean) => void;
} & HTMLAttributes<HTMLDivElement>;

export function ExpandableControlled({
  backgroundColor = 'rgba(0, 0, 0, 0.1)',
  children,
  expanded,
  heading,
  onRequestExpanded,
  ...restOfProps
}: Props) {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      {...restOfProps}
    >
      <button
        aria-expanded={expanded}
        css={[
          buttonCssReset,
          {
            alignItems: 'baseline',
            display: 'flex',
            fontSize: fontSizes.headingSmall,
            gap: spacing.default,
            lineHeight: 1.4,
            '& > div': {
              background: expanded ? backgroundColor : '',
            },
            ':hover': {
              '& > div': {
                background: backgroundColor,
              },
            },
          },
        ]}
        onClick={() => onRequestExpanded?.(!expanded)}
      >
        <div
          css={{
            borderRadius: spacing.xxSmall,
            padding: '5px 11px 7px', // make this div a square - the icon inside isn't a square because of line height because it's inline, but we want it to be inline so that it can be aligned with the heading's baseline
          }}
        >
          <Times
            css={{
              transition: 'transform 200ms ease-in-out',
              transform: expanded
                ? 'translate(0, 2px) rotate(-90deg)'
                : 'translate(0, 2px) rotate(-45deg)',
            }}
            color={colors.textPrimary}
            size={16}
          />
        </div>
        <span
          css={{
            fontSize: fontSizes.headingSmall,
            lineHeight: 1.4,
            fontWeight: 600,
            margin: 0,
            padding: `0 ${spacing.default}px 0 0`,
          }}
        >
          {heading}
        </span>
      </button>
      <div
        css={{
          borderLeft: `2px solid ${backgroundColor}`,
          marginLeft: 18,
          paddingLeft: 35,
        }}
        hidden={!expanded}
      >
        {children}
      </div>
    </div>
  );
}

export function Expandable({
  initiallyExpanded,
  ...restOfProps
}: Omit<Props, 'expanded'> & { initiallyExpanded?: boolean }) {
  const [expanded, setExpanded] = useState<boolean>(initiallyExpanded ?? false);
  return (
    <ExpandableControlled
      expanded={expanded}
      onRequestExpanded={setExpanded}
      {...restOfProps}
    />
  );
}
