import { Storage } from '@wren/shared';

const { storage } = Storage;

export const loadState = () => {
  try {
    const serializedState = storage.get('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return err;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    storage.set('state', serializedState);
  } catch (err) {
    console.error(err);
  }

  return state;
};
