/** @jsxImportSource @emotion/react */

import { AnchorHTMLAttributes, forwardRef } from 'react';
import { Link } from '../UI/Link';
import { buttonBaseHOC } from './ButtonNext';

export const NavButton = buttonBaseHOC<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement>
>(
  forwardRef(
    (
      { disabled, href, ...restOfProps }: { disabled?: boolean; href?: string },
      ref
    ) => <Link ref={ref} to={(!disabled && href) || ''} {...restOfProps} />
  )
);
