/** @jsxImportSource @emotion/react */
import { useRef } from 'react';

import { staticImageUrl } from '../../util/images';
import {
  shareButtonStyle,
  textStyle,
  logoStyle,
  smallShareButtonStyle,
  smallTextStyle,
  smallLogoStyle,
  smallLinkStyle,
  linkStyle,
} from '../../style/shareButtonStyles';
import track from '../../lib/events';

const twitter = staticImageUrl('twitter.svg');

const TwitterShare = ({
  name = '',
  text = 'Check out projectwren.com',
  options = {
    related: 'bnstnfld,mims_tz,landon__brand',
    via: 'project_wren',
    hashtags: 'climatechoice, climatebreakdown',
    url: 'projectwren.com',
  },
  size,
  button,
  className,
  campaignName = 'share',
  utmMedium,
}: {
  name: string;
  text: string;
  options: {
    related: string;
    via: string;
    hashtags: string;
    url: string;
  };
  size?: 'small';
  button?: React.ReactNode;
  className?: string;
  campaignName?: string;
  utmMedium?: string;
}) => {
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const url = new URL(options.url);
  url.searchParams.set('utm_source', 'twitter-organic');
  url.searchParams.set('utm_campaign', campaignName);
  url.searchParams.set('utm_medium', utmMedium || name);

  const urlWithUtm = url.toString();

  const optionsWithUtm = {
    ...options,
    url: urlWithUtm,
  };

  const encodedText = encodeURIComponent(text);
  const encodedOptions = Object.entries(optionsWithUtm).reduce(
    (acc, [key, value]) => {
      acc[key] = encodeURIComponent(value);
      return acc;
    },
    {} as { [key: string]: string }
  );

  const handleOpenPopup = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    track('Server: User Clicked Share Button', {
      name,
      source: 'twitter',
      campaignName,
      utmMedium,
    });
    window.open(
      anchorRef.current?.href || '',
      'twitter window',
      'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'
    );
    return false;
  };

  // Only include certain queries on the url if they're passed in
  const tweet = `url=${encodedOptions.url}&text=${encodedText}`;
  // ${encodedOptions.related &&`&related=${encodedOptions.related}`}

  return (
    <a
      href={`
        https://twitter.com/intent/tweet?${tweet}
      `}
      onClick={(e) => handleOpenPopup(e)}
      ref={anchorRef}
      css={size === 'small' ? smallLinkStyle : linkStyle}
      className={className}
      rel="noreferrer noopener"
      target="_blank"
    >
      {button || (
        <button
          css={[shareButtonStyle, size === 'small' && smallShareButtonStyle]}
        >
          <img
            src={twitter}
            alt=""
            css={size === 'small' ? smallLogoStyle : logoStyle}
          />
          <p css={size === 'small' ? smallTextStyle : textStyle}>Tweet</p>
        </button>
      )}
    </a>
  );
};

export default TwitterShare;
