/** @jsxImportSource @emotion/react */
import { HTMLAttributes } from 'react';
import { useNotifications } from '../../hooks/useNotifications';
import { noStyleButton } from '../../style/misc';
import { Button } from '../buttons/ButtonNext';
import Bell from '../svgs/Bell';
import { spacing } from '../../style/theme';

export const NotificationsButton = (
  props: HTMLAttributes<HTMLButtonElement>
) => {
  const { numberOfUnreadNotifications } = useNotifications();
  return (
    <Button
      css={[
        noStyleButton,
        {
          cursor: 'pointer',
          borderRadius: '100%',
          paddingInline: spacing.small,
          boxSizing: 'border-box',
        },
      ]}
      {...props}
    >
      <Bell
        numberOfUnreadNotifications={numberOfUnreadNotifications}
        size={20}
        color="currentColor"
        css={{
          '&:hover': {
            fill: 'currentColor !important',
          },
        }}
      />
    </Button>
  );
};
