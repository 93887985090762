function cleanUpRoute(route: string) {
  return (
    route
      // replace dynamic routing that starts with /:{text}
      .replace(/:\w+/g, "([\\w-_!%@\\$\\'\\(\\)\\*\\+,;=&]+)")
      // remove trailing slash
      .replace(/\/$/, '')
  );
}

function getParams(route: string, match: RegExpMatchArray) {
  // Extract captured values for :{text} segments
  const parameterNames = route.match(/:\w+/g) || [];
  const matchedValues = match.slice(1); // get all the matched values

  // Create an object to store matched values
  const matchedParams = Object() as Record<string, string>;

  parameterNames.forEach((paramName, index) => {
    matchedParams[paramName.slice(1)] = decodeURIComponent(
      matchedValues[index]
    );
  });

  return matchedParams;
}

export function matchRoute(
  pathname: string,
  route: string,
  options?: { isExact?: boolean }
) {
  // Create the regex pattern
  const cleanedRoute = cleanUpRoute(route);
  const regexPattern = `^${cleanedRoute}/?${options?.isExact ? '$' : ''}`;
  const regex = new RegExp(regexPattern);

  // For pathname we only need to remove trailing slash
  const cleanedPathname = pathname.replace(/\/$/, '');
  const match = cleanedPathname.match(regex); // Use match to capture values

  if (!match) return null;

  const params = getParams(route, match);

  return { params };
}
