import styled from '@emotion/styled';
import { changeOpacity } from '../../lib/colors';
import { noStyleLink } from '../../style/text';
import { spacing } from '../../style/theme';

export const CalloutBox = styled.div<{ backgroundColor: string; to?: string }>(
  ({ backgroundColor }) => [
    noStyleLink,
    {
      display: 'block',
      width: 'auto',
      marginTop: spacing.default,
      backgroundColor: changeOpacity(backgroundColor, 0.5),
      padding: `${spacing.medium}px ${spacing.default}px`,
      borderRadius: 6,
    },
  ]
);
