/** @jsxImportSource @emotion/react */

import Fade from '../animation/Fade';

const Darkener = ({ show, setShow, name }) => {
  const defaultStyle = {
    backgroundColor: 'rgba(0,0,0,0.2)',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    overscrollBehavior: 'contain',
    position: 'fixed',
    top: '0px',
    left: '0px',
    zIndex: 2999,
  };

  return (
    <Fade>
      {show && (
        <div
          key={name}
          style={defaultStyle}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Escape') setShow(false);
          }}
          onClick={() => setShow(false)}
        />
      )}
    </Fade>
  );
};

export default Darkener;
