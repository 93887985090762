/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import { withRouter, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';

import { SetUserInfo, SetCompletedOnboarding } from '../../actions/user';
import { SetToast, SetError } from '../../actions/toaster';

import { Network } from '@wren/shared';
import { userPropType, userDefaultProp } from '../../util/propTypes';
import { scale } from '../../util/scale';

import TextInput from '../inputs/TextInput';
import text from '../../style/text';
import { centerForm } from '../../style/layout';
import { marginAuto } from '../../style/misc';
import Button from '../buttons/Button';
import InputError from '../inputs/InputError';
import CardContainer from '../UI/CardContainer';
import CardContent from '../UI/CardContent';
import {
  getFirstFormValidationError,
  isRequired,
  getEmailValidationError,
  getPasswordValidationError,
} from '../../lib/validation';

const userInfoForm = scale({
  maxWidth: ['100%', '100%', '360px'],
  margin: 'auto',
});

const errorSpacing = scale({
  margin: 'auto',
  marginBottom: '20px',
  display: 'block',
});

const ForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);

  const { location } = props;

  let parsed;
  if (location.search) {
    parsed = queryString.parse(location.search);
  }

  const [formData, setFormData] = useState({
    email: parsed.e,
    tempPass: parsed.temp,
    newPassword: '',
  });
  const { email, tempPass, newPassword } = formData;

  const [error, setError] = useState('');
  const history = useHistory();

  const saveNewPassword = async (email, tempPass, newPassword) => {
    const [response, responseBody] = await Network.post(
      'account/reset-password',
      {
        email,
        tempPass,
        newPassword,
      }
    );
    if (response.ok) {
      props.SetToast({
        text: 'You have successfully changed your password.',
        type: 'success',
        isOnRight: true,
      });
      history.push({
        pathname: '/login',
        search: `?email=${encodeURIComponent(email)}`,
      });
    } else {
      props.SetError(responseBody.message);
    }
    return null;
  };

  const submitAsync = async () => {
    setLoading(true);
    setError('');
    await saveNewPassword(email, tempPass, newPassword);
    setLoading(false);
  };

  const clearErrors = () => {
    setLoading(false);
    setError('');
  };

  const checkForm = async (event) => {
    event.preventDefault();
    const error = getFirstFormValidationError(
      { email, tempPass, newPassword },
      ['email', getEmailValidationError],
      ['tempPass', isRequired('Invalid credentials')],
      ['newPassword', getPasswordValidationError]
    );
    if (error) {
      setError(error);
      return;
    }
    if (!loading) {
      submitAsync();
    }
  };

  return (
    <div>
      <Helmet>
        <title>Wren | Update Password</title>
      </Helmet>
      <form autoComplete="on" noValidate onSubmit={(event) => checkForm(event)}>
        <div css={[centerForm, userInfoForm]}>
          <CardContainer>
            <CardContent>
              <h3 css={[text.center, marginAuto]}>
                Enter a new password to reset the password on your account.
              </h3>
              <br />
              <InputError error={error} injectCss={error && errorSpacing} />
              <TextInput
                handleInputChange={(answer) => {
                  setFormData(R.assoc('email', answer, formData));
                  clearErrors();
                }}
                name="email"
                type="email"
                errorBorder={R.includes('Email', error)}
                placeholder="Email"
                label="Email"
                autoComplete="email"
                value={email}
              />
              <TextInput
                handleInputChange={(answer) => {
                  setFormData(R.assoc('newPassword', answer, formData));
                  clearErrors();
                }}
                name="password"
                type="password"
                autoComplete="new-password"
                errorBorder={
                  R.includes('Password', error) &&
                  !R.includes('confirmation', error)
                }
                placeholder="Set a new password"
                value={newPassword}
                label="Password"
              />
            </CardContent>
          </CardContainer>
          <br />
          <Button text="Update Password" size="medium" loading={loading} />
        </div>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    annualFootprint: state.calculator.annualFootprint,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      SetUserInfo,
      SetCompletedOnboarding,
      SetToast,
      SetError,
    },
    dispatch
  );
}

ForgotPassword.defaultProps = {
  user: userDefaultProp,
};

ForgotPassword.propTypes = {
  user: userPropType,
  SetUserInfo: PropTypes.func.isRequired,
  SetCompletedOnboarding: PropTypes.func.isRequired,
  SetToast: PropTypes.func.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
