import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function WrenHeart({
  color = colors.wrenGreen,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...restOfProps}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        d="m20.6 9.2-2.8-.7a4 4 0 0 0-1.7-2.3c-1.9-1-6 1.2-9 2.7-2 .9-3.7 1-3.7 1s2.6 9.5 9.3 8.3c4.5-.8 6-5.1 5.4-8.4l2.5-.6Zm-10.5 4c-2.2.4-3-2.3-3-2.3s1.1.3 1.7-.1c.9-.6 1.8-1.2 2.5-1 1 .6 1 3-1.2 3.4Zm5-4.3c-.4.1-.7 0-.8-.4-.1-.4 0-.8.4-.9.3 0 .7.2.8.5 0 .4-.1.8-.5.8Z"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        fill-opacity=".4"
        d="M7.2 2.4c-.3-.1-.6-.2-1 0a1 1 0 0 0-.3.1l-.2.2a3.3 3.3 0 0 0-.3.5 2.4 2.4 0 0 0-.9-.2 1 1 0 0 0-.3 0c-.4.1-.6.3-.8.6-.1.2-.2.6 0 1l.1.2a1.6 1.6 0 0 0 .5.5 2.6 2.6 0 0 0 .3.1l2 1h.2l1-2c.3-.5.4-.9.2-1.3 0-.3-.3-.6-.5-.7ZM19.6 19.6 19 19h-.5a2.4 2.4 0 0 0-.4 0 1.7 1.7 0 0 0-.4-.5l-.1-.1a.9.9 0 0 0-.7-.1c-.3 0-.4.2-.5.5v.2a1.1 1.1 0 0 0 0 .5 1.9 1.9 0 0 0 0 .2l.6 1.5v.1h.2l1.5-.5c.4-.2.6-.4.7-.6.1-.3.2-.5 0-.7Z"
      />
    </svg>
  );
}
