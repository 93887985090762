import { useEffect, useState } from 'react';
import { Network } from '@wren/shared';
import { PublicOffsetOrder } from '../types';
import useInterval from './useInterval';

export default function useOffsetOrderTicker(
  ms = 5000
): PublicOffsetOrder | undefined {
  const [orders, setOrders] = useState<PublicOffsetOrder[]>([]);
  const [index, setIndex] = useState(0);

  const fetchOrders = async () => {
    const [response, responseBody] = await Network.get<{
      offsetOrders: PublicOffsetOrder[];
    }>('offset-orders/public');

    if (response.ok && responseBody) {
      setOrders(responseBody.offsetOrders);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  useInterval(() => {
    setIndex((current) => (current + 1) % orders.length);
  }, ms);

  return orders[index];
}
