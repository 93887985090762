/** @jsxImportSource @emotion/react */

import { matchRoute } from '@wren/shared';
import SignUpButton from '../../routes/Actions/buttons/SignUpButton';
import { colors } from '../../style/theme';
import { SerializedUser } from '../../types';
import { scale } from '../../util/scale';
import { ArrowNavButton } from '../buttons/ArrowNavButton';
import { GetInTouchButton } from '../buttons/GetInTouchButton';
import { Button } from '../buttons/ButtonNext';
import ArrowDown from '../svgs/ArrowDown';

export default function CtaButton({
  user,
  pathname,
  handleCTAClick,
  customizedOffsetButton,
  handleOffsetClick,
}: {
  user?: SerializedUser;
  pathname: string;
  handleCTAClick: (eventLocation: string) => void;
  handleOffsetClick?: () => void;
  customizedOffsetButton?: React.ReactElement;
}) {
  const hasStartedCalculator =
    user && !user.completedOnboarding && (user.email || user.country);
  const ctaText = user?.annualFootprint ? 'Continue' : 'Get started';

  if (
    matchRoute(pathname, '/intro') ||
    matchRoute(pathname, '/calculator') ||
    matchRoute(pathname, '/choose-plan') ||
    matchRoute(pathname, '/checkout')
  ) {
    return <></>;
  }

  if (
    matchRoute(pathname, '/teams/business') ||
    matchRoute(pathname, '/teams/signup') ||
    matchRoute(pathname, '/teams', { isExact: true }) ||
    matchRoute(pathname, '/business')
  ) {
    return <GetInTouchButton />;
  }

  if (user?.hasPassword && user?.completedOnboarding) {
    if (customizedOffsetButton) return customizedOffsetButton;
    return (
      <Button
        buttonType="filled"
        color={colors.wrenOrange}
        onClick={handleOffsetClick}
        padding="xTight"
        css={scale({ fontSize: ['inherit', 16], fontWeight: 500 })}
      >
        Offset
        <ArrowDown
          css={{ transform: 'rotate(-90deg)' }}
          size={12}
          color="white"
        />
      </Button>
    );
  }

  if (user?.hasPassword && !user?.completedOnboarding) {
    <ArrowNavButton
      buttonType="filled"
      padding="xTight"
      color={colors.wrenOrange}
      href="/checkout"
      id="mobile-cta"
      onClick={() => handleCTAClick('nav-cta')}
      css={scale({ fontSize: ['inherit', 16], fontWeight: 500 })}
    >
      Subscribe
    </ArrowNavButton>;
  }

  if (matchRoute(pathname, '/actions') && !user?.hasPassword) {
    return <SignUpButton />;
  }

  if (hasStartedCalculator && !user?.hasPassword) {
    return (
      <ArrowNavButton
        buttonType="filled"
        color={colors.wrenOrange}
        href="/continue"
        padding="xTight"
        onClick={() => handleCTAClick('nav-cta')}
        css={scale({ fontSize: ['inherit', 16], fontWeight: 500 })}
      >
        {ctaText}
      </ArrowNavButton>
    );
  }

  return (
    <ArrowNavButton
      buttonType="filled"
      color={colors.wrenOrange}
      href="/continue"
      padding="xTight"
      onClick={() => handleCTAClick('nav-cta')}
      css={scale({ fontSize: ['inherit', 16], fontWeight: 500 })}
    >
      Get started
    </ArrowNavButton>
  );
}
