import { GetAndSetFootprint } from './calculator';
import { SetUserInfo } from './user';
import { Session } from '@wren/shared';
import { Network } from '@wren/shared';
import * as AB from '../lib/ab';
import * as Sentry from '@sentry/react';

const { setSessionToken } = Session;

// resolves with an error if it happened, null otherwise
export const Login = (email, password) => async (dispatch) => {
  const [response, responseBody] = await Network.post('sessions', {
    email,
    password,
  });
  if (response.ok) {
    AB.reset();
    setSessionToken(responseBody.sessionToken);
    await dispatch(SetUserInfo(responseBody.user));
    dispatch(GetAndSetFootprint());

    Sentry.setUser({
      id: responseBody.user.userId,
      email: responseBody.user.email,
      username: responseBody.user.username,
    });

    return { user: responseBody.user, error: null };
  }
  if (responseBody) {
    return { user: null, error: responseBody.error || responseBody.message };
  }
  return { user: null, error: 'There was an error logging in' };
};

export function LoginWithGoogle(googleAccessToken) {
  return async (dispatch) => {
    const [response, responseBody] = await Network.post('sessions', {
      googleAccessToken,
    });

    if (response.ok) {
      AB.reset();
      setSessionToken(responseBody.sessionToken);
      dispatch(SetUserInfo(responseBody.user));

      Sentry.setUser({
        id: responseBody.user.userId,
        email: responseBody.user.email,
        username: responseBody.user.username,
      });

      dispatch(GetAndSetFootprint());
      return { user: responseBody.user, error: null };
    }

    if (responseBody) {
      return { user: null, error: responseBody.error || responseBody.message };
    }
    return { user: null, error: 'There was an error logging in' };
  };
}
