/** @jsxImportSource @emotion/react */
import { useState, useEffect, ReactNode, MouseEventHandler } from 'react';

import PropTypes from 'prop-types';

import { fonts } from '../../style/theme';
import { marginAuto, fullWidth } from '../../style/misc';
import {
  noWrapFlex,
  alignCenter,
  justifyContentCenter,
} from '../../style/flex';
import { scale } from '../../util/scale';

import LoadingSpinner from '../UI/LoadingSpinner';
import DisabledWrapper from '../tooltips/DisableContentWrapper';
import Fade from '../animation/Fade';

const loadingStyle = scale({
  marginRight: 4,
});

const buttonStyle = scale({
  fontFamily: fonts.Sans,
  fontWeight: 500,
  textAlign: 'center',
  backgroundColor: 'var(--wren-orange)',
  fontSize: '12px',
  color: 'white',
  border: 0,
  borderRadius: '4px',
  cursor: 'pointer',
  padding: ['5px 7px', '6px 8px'],
  width: '100%',
  minWidth: 'fit-content',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'var(--wren-orange-hover)',
    boxShadow: 'none',
  },
});

const tinyStyle = scale({
  padding: '3px 7px !important',
  fontSize: 12,
  minHeight: '20px',
});

const mediumStyle = scale({
  padding: '10px 16px !important',
  fontSize: '16px',
  minHeight: '20px',
});

const largeStyle = scale({
  padding: '13px 24px !important',
  fontSize: '18px',
});

const extraLargeStyle = scale({
  padding: '13px 36px !important',
  fontSize: '22px !important',
});

const orangeStyle = scale({
  backgroundColor: 'var(--wren-orange)',
  border: `1px solid var(--wren-orange)`,
  '&:hover': {
    backgroundColor: 'var(--wren-orange-hover)',
  },
});

const greenStyle = scale({
  backgroundColor: 'var(--success-green)',
  border: `1px solid var(--success-green)`,
  '&:hover': {
    backgroundColor: 'var(--success-green-hover)',
  },
});

const wrenGreenStyle = scale({
  backgroundColor: 'var(--wren-green)',
  border: '1px solid var(--wren-green)',
  '&:hover': {
    backgroundColor: '#02735F',
  },
});

const invertedGreenStyle = scale({
  backgroundColor: 'transparent',
  border: '1px solid var(--success-green)',
  color: 'var(--success-green)',
  transition: 'background-color 200ms ease-in-out',
  '&:hover': {
    backgroundColor: 'var(--success-green-hover)',
    color: 'white',
  },
});

const redStyle = scale({
  backgroundColor: 'transparent',
  color: 'var(--error-red)',
  border: '1px solid #f5a3a3',
  '&:hover': {
    backgroundColor: '#fdeeee',
  },
});

const grayStyle = scale({
  backgroundColor: 'transparent',
  color: 'var(--gray-2)',
  border: '1px solid var(--border-color)',
  '&:hover': {
    backgroundColor: 'var(--gray-9)',
  },
});

const actuallyGrayStyle = scale({
  backgroundColor: 'var(--background-gray)',
  color: 'var(--text-primary)',
  border: '1px solid var(--border-color)',
  '&:hover': {
    backgroundColor: 'var(--gray-9)',
  },
});

const whiteStyle = scale({
  backgroundColor: 'white',
  color: 'var(--text-primary)',
  minHeight: 42,
  fontSize: 15,
  '&:hover': {
    backgroundColor: 'var(--background-gray)',
  },
});

const grayUppercaseStyle = scale({
  backgroundColor: 'rgba(0,0,0,0.05)',
  color: 'var(--gray-0)',
  border: '1px solid rgba(0,0,0,0.05)',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
});

const blackStyle = scale({
  backgroundColor: 'var(--gray-0)',
  color: 'white',
  border: 'none',
  '&:hover': {
    backgroundColor: 'var(--gray-0)',
    opacity: 0.9,
  },
});

const blueStyle = scale({
  backgroundColor: 'var(--blue)',
  color: 'white',
  border: 'none',
  '&:hover': {
    backgroundColor: '#275ea3',
  },
});

const disabledStyle = scale({
  cursor: 'not-allowed !important',
  pointerEvents: 'none',
});

const bareStyle = scale({
  backgroundColor: 'transparent',
  border: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: 'var(--text-primary)',
  display: 'inline-flex',
  width: 'auto',
});

const roundGreenStyle = [
  greenStyle,
  {
    display: 'inline-flex',
    width: 'auto',
    borderRadius: 62,
  },
];

const COLORS = {
  gray: 'transparent',
  actuallyGray: 'var(--gray-9)',
  grayUppercase: 'var(--gray-8)',
  red: '#f5a3a3',
  green: 'rgba(33, 150, 83, 0.5)',
  invertedGreen: 'var(--success-green)',
  black: 'var(--gray-0)',
  wrenGreen: 'var(--wren-green)',
  blue: 'var(--blue)',
  white: 'white',
  roundGreen: 'var(--success-green-hover)',
  bare: 'transparent',
  orange: 'var(--wren-orange)',
};

const LOADING_COLORS: Partial<typeof COLORS> = {
  gray: 'var(--gray-2)',
  grayUppercase: 'var(--gray-2)',
  red: 'var(--error-red)',
  green: 'white',
};

const generateGlow = (buttonType: keyof typeof COLORS) => {
  const color = COLORS[buttonType];

  return {
    boxShadow: `0px 2px 5px 0.6px ${color}`,
  };
};

interface Props {
  text?: string;
  submit?: boolean;
  type?: keyof typeof COLORS;
  loading?: boolean;
  size?: 'tiny' | 'medium' | 'large' | 'extraLarge';
  children?: ReactNode;
  isDisabled?: boolean;
  disabledText?: string;
  className?: string;
  id?: string;
  icon?: ReactNode;
  glow?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onClickFn?: MouseEventHandler<HTMLButtonElement>;
}

const Button = (props: Props) => {
  const {
    text,
    submit,
    type = 'orange',
    loading,
    size = 'tiny',
    children,
    isDisabled,
    disabledText,
    className,
    id,
    icon,
    glow = false,
  } = props;
  const [delayedLoad, setDelayedLoad] = useState(false);

  const onClickFn = props.onClick || props.onClickFn;

  const colorStyleToApply = {
    gray: grayStyle,
    actuallyGray: actuallyGrayStyle,
    grayUppercase: grayUppercaseStyle,
    red: redStyle,
    green: greenStyle,
    invertedGreen: invertedGreenStyle,
    black: blackStyle,
    wrenGreen: wrenGreenStyle,
    blue: blueStyle,
    white: whiteStyle,
    roundGreen: roundGreenStyle,
    bare: bareStyle,
    orange: orangeStyle,
  }[type];

  const loadingColor = LOADING_COLORS[type];

  const sizeStyleToApply = {
    tiny: tinyStyle,
    medium: mediumStyle,
    large: largeStyle,
    extraLarge: extraLargeStyle,
  }[size];

  const glowStyleToApply = glow ? generateGlow(type) : {};

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedLoad(!!loading);
    }, 50);
    return () => clearTimeout(timer);
  }, [loading]);

  if ((submit && !isDisabled) || (!onClickFn && !isDisabled)) {
    return (
      <button
        css={[
          buttonStyle,
          colorStyleToApply,
          sizeStyleToApply,
          glowStyleToApply,
        ]}
        tabIndex={0}
        type="submit"
        className={className}
        id={id}
      >
        <div
          css={[
            noWrapFlex,
            alignCenter,
            justifyContentCenter,
            marginAuto,
            fullWidth,
          ]}
        >
          <Fade animationKey={delayedLoad}>
            {delayedLoad ? (
              <div css={loadingStyle}>
                <LoadingSpinner color={loadingColor || 'white'} width="12px" />
              </div>
            ) : icon ? (
              <div css={loadingStyle}>{icon}</div>
            ) : (
              <div />
            )}
          </Fade>
          <span>{children || text}</span>
        </div>
      </button>
    );
  }

  if (isDisabled) {
    return (
      <DisabledWrapper text={disabledText}>
        <button
          css={[
            buttonStyle,
            colorStyleToApply,
            sizeStyleToApply,
            disabledStyle,
            glowStyleToApply,
          ]}
          className={className}
          tabIndex={0}
          id={id}
        >
          <div
            css={[
              noWrapFlex,
              alignCenter,
              justifyContentCenter,
              marginAuto,
              fullWidth,
            ]}
          >
            <Fade animationKey={delayedLoad}>
              {(delayedLoad || icon) && (
                <div css={loadingStyle}>
                  {delayedLoad ? (
                    <LoadingSpinner
                      color={loadingColor || 'white'}
                      width="12px"
                    />
                  ) : (
                    icon
                  )}
                </div>
              )}
            </Fade>
            <span>{children || text}</span>
          </div>
        </button>
      </DisabledWrapper>
    );
  }

  return (
    <button
      className={className}
      css={[buttonStyle, colorStyleToApply, sizeStyleToApply, glowStyleToApply]}
      tabIndex={0}
      type="button"
      onClick={onClickFn}
      id={id}
    >
      <div
        css={[
          noWrapFlex,
          alignCenter,
          justifyContentCenter,
          marginAuto,
          fullWidth,
        ]}
      >
        <Fade animationKey={delayedLoad}>
          {(delayedLoad || icon) && (
            <div css={loadingStyle}>
              {delayedLoad ? (
                <LoadingSpinner color={loadingColor || 'white'} width="12px" />
              ) : (
                icon
              )}
            </div>
          )}
        </Fade>
        <span>{children || text}</span>
      </div>
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  disabledText: PropTypes.string,
  onClickFn: PropTypes.func,
  size: PropTypes.oneOf(['tiny', 'medium', 'large', 'extraLarge']),
  submit: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
};

Button.defaultProps = {
  onClickFn: undefined,
  submit: false,
};

export default Button;
