import useExperiment from './useExperiment';

export default function useLandingAndStartHeaderExperiment() {
  const variant = useExperiment('landing_and_start_header');
  switch (variant) {
    case 'all_in_one':
      return {
        header: 'Wren is your all-in-one climate subscription.',
        mainTitleWidth: 700,
      };
    case 'simple':
      return {
        header: 'Wren helps you take simple, effective climate action.',
        mainTitleWidth: 750,
      };
    case 'control':
    default:
      return {
        header: 'Systemic change starts with you',
        mainTitleWidth: 500,
      };
  }
}
