export const PORTFOLIO_ID = 3;
export const COST_IN_USD_CENTS_PER_HECTARE = 681; // See wren.co/projects/tech-enabled-rainforest-protection

export function fromOffsetOrders(
  orders: { amount_paid_by_customer: number; portfolio_id: number }[]
) {
  return orders
    .filter(({ portfolio_id }) => portfolio_id === PORTFOLIO_ID)
    .reduce<number>(
      (sum, { amount_paid_by_customer }) =>
        sum + amount_paid_by_customer / COST_IN_USD_CENTS_PER_HECTARE,
      0
    );
}
