/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import { fadeIn } from '../style/misc';
import { scale } from '../util/scale';
import LoadingSpinner from './UI/LoadingSpinner';

const loadingStyle = scale({
  margin: 'auto',
  textAlign: 'center',
  paddingTop: 2,
});

const SuspenseFallback = ({
  show = false,
  delay,
  width = '40px',
  className,
  color = 'var(--wren-orange-hover)',
}: {
  show?: boolean;
  delay?: number;
  width?: string | number;
  className?: string;
  color?: string;
}) => {
  const [isShown, setIsShown] = useState(delay ? false : show);

  useEffect(() => {
    if (show && delay && !isShown) {
      const timeout = setTimeout(() => setIsShown(true), delay);
      return () => clearTimeout(timeout);
    }
  }, [show, delay, isShown]);

  if (!isShown) {
    return null;
  }

  return (
    <div css={loadingStyle}>
      {show && (
        <LoadingSpinner
          /* @ts-expect-error: translate to TS */
          className={className}
          color={color}
          css={fadeIn()}
          speed="1000ms"
          width={width}
        />
      )}
    </div>
  );
};

export default SuspenseFallback;
