/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import mapValues from 'lodash/mapValues';
import {
  Fragment,
  JSXElementConstructor,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { colors } from '../../style/theme';

export type Wrappers = Partial<
  {
    [key in Intl.NumberFormatPartTypes]: JSXElementConstructor<
      PropsWithChildren<unknown>
    >;
  } & { fallback: JSXElementConstructor<PropsWithChildren<unknown>> }
>;

const SmallCentsStyle = styled.span({
  bottom: '.8em',
  borderBottom: `1px solid ${colors.textSecondary}`,
  color: colors.textSecondary,
  fontSize: '45%',
  paddingBottom: '.05em',
  position: 'relative',
});

function ZeroWidthSpaceAfter({ children }: PropsWithChildren<unknown>) {
  return <>{children}&#8203;</>; // Adds a breaking zero-width space
}

export const zeroWidthSpaceAfterPunctuation = {
  decimal: ZeroWidthSpaceAfter,
  group: ZeroWidthSpaceAfter,
};

export const smallDecimalAndFraction = {
  decimal: SmallCentsStyle,
  fraction: SmallCentsStyle,
};

// This is a convenience function for applying more than one wrapper to the same number format part
export function nest(outer: Wrappers, inner: Wrappers) {
  const combinedForKeys = { ...outer, ...inner };
  return mapValues(combinedForKeys, (_value, key) => {
    const partType = key as Intl.NumberFormatPartTypes;
    const Outer: JSXElementConstructor<PropsWithChildren<unknown>> | undefined =
      outer[partType];
    const Inner: JSXElementConstructor<PropsWithChildren<unknown>> | undefined =
      inner[partType];
    if (Outer && Inner) {
      return ({ children }: PropsWithChildren<unknown>) => (
        <Outer key={key}>
          <Inner>{children}</Inner>
        </Outer>
      );
    }
    return Outer || Inner;
  });
}

export type PriceProps = {
  allowBreakAfterPunctuation?: boolean;
  amount: number;
  className?: string;
  currencyCode: string;
  currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name';
  smallCents?: boolean;
  wrappers?: Wrappers;
} & Intl.NumberFormatOptions;

function isMaximumFractionDigitsIsLessThanMinimum(
  formatOptions: Intl.NumberFormatOptions
) {
  return (
    typeof formatOptions.maximumFractionDigits === 'number' &&
    formatOptions.maximumFractionDigits <
      (formatOptions.minimumFractionDigits ?? 2)
  );
}

// Try to minimize the number of text nodes by combining adjacent ones
function combineTextNodes(array: ReactNode[]) {
  return array.reduce<ReactNode[]>((output, node) => {
    if (
      output.length > 0 &&
      typeof node === 'string' &&
      typeof output[output.length - 1] === 'string'
    ) {
      output[output.length - 1] += node;
    } else {
      output.push(node);
    }
    return output;
  }, []);
}

export default function Price({
  allowBreakAfterPunctuation = true,
  amount,
  className,
  currencyCode,
  smallCents,
  wrappers = {},
  ...formatOptions
}: PriceProps) {
  if (isMaximumFractionDigitsIsLessThanMinimum(formatOptions)) {
    // This is usually not a problem, but Safari < 14.1 may complain if we don't fix it
    formatOptions.minimumFractionDigits = formatOptions.maximumFractionDigits;
  }
  let numberFormat;
  try {
    numberFormat = Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
      ...formatOptions,
    });
  } catch (error) {
    // Older iOS doesn't support narrowSymbol
    if (formatOptions.currencyDisplay === 'narrowSymbol') {
      numberFormat = Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currencyCode,
        ...formatOptions,
        currencyDisplay: 'symbol',
      });
    } else {
      throw error;
    }
  }
  // Fallback for Safari < 13 and Internet Explorer (no wrappers are applied except 'fallback')
  if (!numberFormat.formatToParts) {
    const Wrapper = wrappers?.['fallback'] || Fragment;
    return (
      <span css={{ overflowWrap: 'anywhere' }} className={className}>
        <Wrapper>{numberFormat.format(amount)}</Wrapper>
      </span>
    );
  }
  if (allowBreakAfterPunctuation) {
    wrappers = nest(zeroWidthSpaceAfterPunctuation, wrappers);
  }
  if (smallCents) {
    wrappers = nest(smallDecimalAndFraction, wrappers);
  }
  return (
    <span css={{ overflowWrap: 'break-word' }} className={className}>
      {combineTextNodes(
        numberFormat.formatToParts(amount).map(({ type, value }, index) => {
          const Wrapper = wrappers?.[type];
          return Wrapper ? <Wrapper key={index}>{value}</Wrapper> : value;
        })
      )}
    </span>
  );
}
