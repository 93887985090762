import { MouseEvent, useRef, useState } from 'react';

/***
 * For asynchronous handlers: when the handler is called, this will prevent further events from
 * being handled until the handler finishes (when the promise it returns resolves or rejects).
 * The `disabled` variable is in React state, so may change asynchronously *after* the true state changes.
 * Returns [handler: (event: EventType) => void, disabled: boolean].
 ***/
export default function useHandlerWithDisabled<
  ResultType = void,
  ElementType = HTMLButtonElement,
  EventType = MouseEvent<ElementType>
>(
  asyncHandler?: (event: EventType) => Promise<ResultType> | void
): [(event: EventType) => void, boolean] {
  // We use state for disabled so that it can trigger a rerender, but it can update asynchronously
  const [disabled, setDisabled] = useState(false);
  // We need a ref rather than just state, because state may update asynchronously,
  // and the handler needs to be called (or not called) synchronously (so event.stopPropagation works, for example).
  const resultRef = useRef<Promise<ResultType> | void>(undefined);
  const done = () => {
    resultRef.current = undefined;
    setDisabled(false);
  };
  const handler = (event: EventType) => {
    if (!asyncHandler) {
      // There is no handler right now, so ignore this event
      return;
    }
    if (resultRef.current) {
      // A handler is already running, so ignore this event
      return;
    }
    // The handler must be called synchronously, so that it may affect the event itself (e.g. stopPropagation)
    resultRef.current = asyncHandler(event);
    // We only need to do something if the handler returns a promise.
    // If it returns nothing, we assume it finished synchronously.
    if (resultRef.current) {
      setDisabled(true);
      resultRef.current.then(done, done);
    }
  };
  return [handler, disabled];
}
