/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { colors } from '../../style/theme';

import { scale } from '../../util/scale';

const cardStyle = (enableScaleOnHover) =>
  scale({
    position: 'relative',
    boxSizing: 'border-box',
    margin: 'auto',
    padding: 0,
    backgroundColor: 'white',
    borderRadius: '5px',
    width: '100%',
    height: '100%',
    boxShadow: `0px 8px 10px 0 ${colors.boxShadowColor}`,
    webkitTransition: 'box-shadow 80ms ease',
    transition:
      'transform 150ms cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 80ms ease',
    '&:hover': {
      boxShadow: enableScaleOnHover && `${colors.boxShadowColor} 0px 1px 1rem`,
      transform: enableScaleOnHover && 'scale(1.02, 1.02)',
      cursor: enableScaleOnHover && 'pointer',
    },
    '&:focus': {
      boxShadow: enableScaleOnHover && `${colors.boxShadowColor} 0px 1px 1rem`,
      transform: enableScaleOnHover && 'scale(1.02, 1.02)',
      cursor: enableScaleOnHover && 'pointer',
    },
  });

const CardContainer = (props) => {
  const { children, enableScaleOnHover, className, id } = props;
  return (
    <div css={cardStyle(enableScaleOnHover)} id={id} className={className}>
      {children}
    </div>
  );
};

export default CardContainer;
