import { useState, useEffect } from 'react';
import { Network } from '@wren/shared';

export default function useNewSignups() {
  const [newSignups, setNewSignups] = useState<number | undefined>(undefined);

  const fetchNewSignups = async () => {
    const [response, responseBody] = await Network.get('stats/new-signups');

    if (response.ok) {
      setNewSignups((responseBody as { totalSignups: number })?.totalSignups);
    }
  };

  useEffect(() => {
    fetchNewSignups();
  }, []);

  return newSignups;
}
