/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { spacing } from '../../style/theme';

export default styled.div(
  (props: { padding?: 'tight' | 'default' | 'medium' }) => ({
    padding:
      props.padding === 'tight'
        ? spacing.small
        : props.padding === 'medium'
        ? spacing.medium
        : '20px',
    marginBottom: 'inherit',
  })
);
