/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { userPropType, userDefaultProp } from '../util/propTypes';
import useQueryParams from '../hooks/useQueryParams';
import { Session } from '@wren/shared';
const { clearSession } = Session;

function LogOut() {
  const { getQueryParam } = useQueryParams();

  useEffect(() => {
    const redirectTo = getQueryParam('redirectTo');

    clearSession();

    // Clear session has to happen before page hard refreshes
    if (!redirectTo || redirectTo.includes('logout')) {
      // prevent infinite logout loops
      window.location.href = '/';
    } else {
      window.location.href = redirectTo;
    }
  }, []);

  return <div className="contentContainer" />;
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

LogOut.defaultProps = {
  user: userDefaultProp,
};

LogOut.propTypes = {
  Logout: PropTypes.func.isRequired,
  user: userPropType,
};

export default withRouter(connect(mapStateToProps)(LogOut));
