// import { CURRENCIES } from '../../../backend/src/lib/currency';

import memoize from 'lodash/memoize';

const data = {
  Aruba: {
    country_name: 'Aruba',
    country_code: 'ABW',
    two_char_country_code: 'AW',
    flag_emoji: '🇦🇼',
    population: 106766,
    emissions_in_kt_co2e: 616,
    supported_by_cool_climate: false,
  },
  Afghanistan: {
    country_name: 'Afghanistan',
    country_code: 'AFG',
    two_char_country_code: 'AF',
    flag_emoji: '🇦🇫',
    population: 38928341,
    emissions_in_kt_co2e: 98920,
    supported_by_cool_climate: false,
  },
  Angola: {
    country_name: 'Angola',
    country_code: 'AGO',
    two_char_country_code: 'AO',
    flag_emoji: '🇦🇴',
    population: 32866268,
    emissions_in_kt_co2e: 79730,
    supported_by_cool_climate: true,
  },
  Albania: {
    country_name: 'Albania',
    country_code: 'ALB',
    two_char_country_code: 'AL',
    currency_code: 'EUR',
    flag_emoji: '🇦🇱',
    population: 2837743,
    emissions_in_kt_co2e: 10080,
    supported_by_cool_climate: true,
  },
  'United Arab Emirates': {
    country_name: 'United Arab Emirates',
    country_code: 'ARE',
    two_char_country_code: 'AE',
    flag_emoji: '🇦🇪',
    population: 9890400,
    emissions_in_kt_co2e: 263240,
    supported_by_cool_climate: true,
  },
  Argentina: {
    country_name: 'Argentina',
    country_code: 'ARG',
    two_char_country_code: 'AR',
    flag_emoji: '🇦🇷',
    population: 45376763,
    emissions_in_kt_co2e: 365650,
    supported_by_cool_climate: true,
  },
  Armenia: {
    country_name: 'Armenia',
    country_code: 'ARM',
    two_char_country_code: 'AM',
    flag_emoji: '🇦🇲',
    population: 2963234,
    emissions_in_kt_co2e: 9360,
    supported_by_cool_climate: true,
  },
  'American Samoa': {
    country_name: 'American Samoa',
    country_code: 'ASM',
    two_char_country_code: 'AS',
    currency_code: 'USD',
    flag_emoji: '🇦🇸',
    population: 55197,
    emissions_in_kt_co2e: 58,
    supported_by_cool_climate: false,
  },
  'Antigua and Barbuda': {
    country_name: 'Antigua and Barbuda',
    country_code: 'ATG',
    two_char_country_code: 'AG',
    flag_emoji: '🇦🇬',
    population: 97928,
    emissions_in_kt_co2e: 1210,
    supported_by_cool_climate: false,
  },
  Australia: {
    country_name: 'Australia',
    country_code: 'AUS',
    two_char_country_code: 'AU',
    currency_code: 'AUD',
    flag_emoji: '🇦🇺',
    population: 25687041,
    emissions_in_kt_co2e: 615380,
    supported_by_cool_climate: true,
  },
  Austria: {
    country_name: 'Austria',
    country_code: 'AUT',
    two_char_country_code: 'AT',
    currency_code: 'EUR',
    flag_emoji: '🇦🇹',
    population: 8917205,
    emissions_in_kt_co2e: 74980,
    supported_by_cool_climate: true,
  },
  Azerbaijan: {
    country_name: 'Azerbaijan',
    country_code: 'AZE',
    two_char_country_code: 'AZ',
    flag_emoji: '🇦🇿',
    population: 10110116,
    emissions_in_kt_co2e: 80260,
    supported_by_cool_climate: true,
  },
  Burundi: {
    country_name: 'Burundi',
    country_code: 'BDI',
    two_char_country_code: 'BI',
    flag_emoji: '🇧🇮',
    population: 11890781,
    emissions_in_kt_co2e: 5520,
    supported_by_cool_climate: false,
  },
  Belgium: {
    country_name: 'Belgium',
    country_code: 'BEL',
    two_char_country_code: 'BE',
    currency_code: 'EUR',
    flag_emoji: '🇧🇪',
    population: 11555997,
    emissions_in_kt_co2e: 108750,
    supported_by_cool_climate: true,
  },
  Benin: {
    country_name: 'Benin',
    country_code: 'BEN',
    two_char_country_code: 'BJ',
    flag_emoji: '🇧🇯',
    population: 12123198,
    emissions_in_kt_co2e: 17380,
    supported_by_cool_climate: true,
  },
  'Burkina Faso': {
    country_name: 'Burkina Faso',
    country_code: 'BFA',
    two_char_country_code: 'BF',
    flag_emoji: '🇧🇫',
    population: 20903278,
    emissions_in_kt_co2e: 30850,
    supported_by_cool_climate: false,
  },
  Bangladesh: {
    country_name: 'Bangladesh',
    country_code: 'BGD',
    two_char_country_code: 'BD',
    flag_emoji: '🇧🇩',
    population: 164689383,
    emissions_in_kt_co2e: 198970,
    supported_by_cool_climate: true,
  },
  Bulgaria: {
    country_name: 'Bulgaria',
    country_code: 'BGR',
    two_char_country_code: 'BG',
    currency_code: 'EUR',
    flag_emoji: '🇧🇬',
    population: 6927288,
    emissions_in_kt_co2e: 53330,
    supported_by_cool_climate: true,
  },
  Bahrain: {
    country_name: 'Bahrain',
    country_code: 'BHR',
    two_char_country_code: 'BH',
    flag_emoji: '🇧🇭',
    population: 1701583,
    emissions_in_kt_co2e: 48950,
    supported_by_cool_climate: true,
  },
  'Bahamas, The': {
    country_name: 'Bahamas, The',
    country_code: 'BHS',
    two_char_country_code: 'BS',
    currency_code: 'USD',
    flag_emoji: '🇧🇸',
    population: 393248,
    emissions_in_kt_co2e: 2640,
    supported_by_cool_climate: false,
  },
  'Bosnia and Herzegovina': {
    country_name: 'Bosnia and Herzegovina',
    country_code: 'BIH',
    two_char_country_code: 'BA',
    flag_emoji: '🇧🇦',
    population: 3280815,
    emissions_in_kt_co2e: 27190,
    supported_by_cool_climate: true,
  },
  Belarus: {
    country_name: 'Belarus',
    country_code: 'BLR',
    two_char_country_code: 'BY',
    currency_code: 'EUR',
    flag_emoji: '🇧🇾',
    population: 9398861,
    emissions_in_kt_co2e: 88140,
    supported_by_cool_climate: true,
  },
  Belize: {
    country_name: 'Belize',
    country_code: 'BLZ',
    two_char_country_code: 'BZ',
    flag_emoji: '🇧🇿',
    population: 397621,
    emissions_in_kt_co2e: 1560,
    supported_by_cool_climate: false,
  },
  Bermuda: {
    country_name: 'Bermuda',
    country_code: 'BMU',
    two_char_country_code: 'BM',
    flag_emoji: '🇧🇲',
    population: 63903,
    emissions_in_kt_co2e: 632,
    supported_by_cool_climate: false,
  },
  Bolivia: {
    country_name: 'Bolivia',
    country_code: 'BOL',
    two_char_country_code: 'BO',
    flag_emoji: '🇧🇴',
    population: 11673029,
    emissions_in_kt_co2e: 56870,
    supported_by_cool_climate: true,
  },
  Brazil: {
    country_name: 'Brazil',
    country_code: 'BRA',
    two_char_country_code: 'BR',
    flag_emoji: '🇧🇷',
    population: 212559409,
    emissions_in_kt_co2e: 1032640,
    supported_by_cool_climate: true,
  },
  Barbados: {
    country_name: 'Barbados',
    country_code: 'BRB',
    two_char_country_code: 'BB',
    flag_emoji: '🇧🇧',
    population: 287371,
    emissions_in_kt_co2e: 3790,
    supported_by_cool_climate: false,
  },
  'Brunei Darussalam': {
    country_name: 'Brunei Darussalam',
    country_code: 'BRN',
    two_char_country_code: 'BN',
    flag_emoji: '🇧🇳',
    population: 437483,
    emissions_in_kt_co2e: 16620,
    supported_by_cool_climate: true,
  },
  Bhutan: {
    country_name: 'Bhutan',
    country_code: 'BTN',
    two_char_country_code: 'BT',
    flag_emoji: '🇧🇹',
    population: 771612,
    emissions_in_kt_co2e: 2470,
    supported_by_cool_climate: false,
  },
  Botswana: {
    country_name: 'Botswana',
    country_code: 'BWA',
    two_char_country_code: 'BW',
    flag_emoji: '🇧🇼',
    population: 2351625,
    emissions_in_kt_co2e: 16120,
    supported_by_cool_climate: true,
  },
  Canada: {
    country_name: 'Canada',
    country_code: 'CAN',
    two_char_country_code: 'CA',
    currency_code: 'CAD',
    flag_emoji: '🇨🇦',
    population: 38005238,
    emissions_in_kt_co2e: 724930,
    supported_by_cool_climate: true,
  },
  Switzerland: {
    country_name: 'Switzerland',
    country_code: 'CHE',
    two_char_country_code: 'CH',
    currency_code: 'CHF',
    flag_emoji: '🇨🇭',
    population: 8636896,
    emissions_in_kt_co2e: 46020,
    supported_by_cool_climate: true,
  },
  Chile: {
    country_name: 'Chile',
    country_code: 'CHL',
    two_char_country_code: 'CL',
    flag_emoji: '🇨🇱',
    population: 19116209,
    emissions_in_kt_co2e: 109590,
    supported_by_cool_climate: true,
  },
  China: {
    country_name: 'China',
    country_code: 'CHN',
    two_char_country_code: 'CN',
    flag_emoji: '🇨🇳',
    population: 1402112000,
    emissions_in_kt_co2e: 12355240,
    supported_by_cool_climate: true,
  },
  "Cote d'Ivoire": {
    country_name: "Cote d'Ivoire",
    country_code: 'CIV',
    two_char_country_code: 'CI',
    flag_emoji: '🇨🇮',
    population: 26378275,
    emissions_in_kt_co2e: 22900,
    supported_by_cool_climate: true,
  },
  Cameroon: {
    country_name: 'Cameroon',
    country_code: 'CMR',
    two_char_country_code: 'CM',
    flag_emoji: '🇨🇲',
    population: 26545864,
    emissions_in_kt_co2e: 89050,
    supported_by_cool_climate: true,
  },
  'Congo, Dem. Rep.': {
    country_name: 'Congo, Dem. Rep.',
    country_code: 'COD',
    two_char_country_code: 'CD',
    flag_emoji: '🇨🇩',
    population: 89561404,
    emissions_in_kt_co2e: 58700,
    supported_by_cool_climate: false,
  },
  'Congo, Rep.': {
    country_name: 'Congo, Rep.',
    country_code: 'COG',
    two_char_country_code: 'CG',
    flag_emoji: '🇨🇬',
    population: 5518092,
    emissions_in_kt_co2e: 9370,
    supported_by_cool_climate: true,
  },
  Colombia: {
    country_name: 'Colombia',
    country_code: 'COL',
    two_char_country_code: 'CO',
    flag_emoji: '🇨🇴',
    population: 50882884,
    emissions_in_kt_co2e: 184100,
    supported_by_cool_climate: true,
  },
  Comoros: {
    country_name: 'Comoros',
    country_code: 'COM',
    two_char_country_code: 'KM',
    flag_emoji: '🇰🇲',
    population: 869595,
    emissions_in_kt_co2e: 590,
    supported_by_cool_climate: false,
  },
  'Cabo Verde': {
    country_name: 'Cabo Verde',
    country_code: 'CPV',
    two_char_country_code: 'CV',
    flag_emoji: '🇨🇻',
    population: 555988,
    emissions_in_kt_co2e: 830,
    supported_by_cool_climate: false,
  },
  'Costa Rica': {
    country_name: 'Costa Rica',
    country_code: 'CRI',
    two_char_country_code: 'CR',
    flag_emoji: '🇨🇷',
    population: 5094114,
    emissions_in_kt_co2e: 15810,
    supported_by_cool_climate: true,
  },
  Cuba: {
    country_name: 'Cuba',
    country_code: 'CUB',
    two_char_country_code: 'CU',
    flag_emoji: '🇨🇺',
    population: 11326616,
    emissions_in_kt_co2e: 41860,
    supported_by_cool_climate: true,
  },
  'Cayman Islands': {
    country_name: 'Cayman Islands',
    country_code: 'CYM',
    two_char_country_code: 'KY',
    flag_emoji: '🇰🇾',
    population: 65720,
    emissions_in_kt_co2e: 0,
    supported_by_cool_climate: false,
  },
  Cyprus: {
    country_name: 'Cyprus',
    country_code: 'CYP',
    two_char_country_code: 'CY',
    currency_code: 'EUR',
    flag_emoji: '🇨🇾',
    population: 1207361,
    emissions_in_kt_co2e: 8600,
    supported_by_cool_climate: true,
  },
  'Czech Republic': {
    country_name: 'Czech Republic',
    country_code: 'CZE',
    two_char_country_code: 'CZ',
    currency_code: 'EUR',
    flag_emoji: '🇨🇿',
    population: 10698896,
    emissions_in_kt_co2e: 122840,
    supported_by_cool_climate: true,
  },
  Germany: {
    country_name: 'Germany',
    country_code: 'DEU',
    two_char_country_code: 'DE',
    currency_code: 'EUR',
    flag_emoji: '🇩🇪',
    population: 83240525,
    emissions_in_kt_co2e: 806090,
    supported_by_cool_climate: true,
  },
  Djibouti: {
    country_name: 'Djibouti',
    country_code: 'DJI',
    two_char_country_code: 'DJ',
    flag_emoji: '🇩🇯',
    population: 988002,
    emissions_in_kt_co2e: 1480,
    supported_by_cool_climate: false,
  },
  Dominica: {
    country_name: 'Dominica',
    country_code: 'DMA',
    two_char_country_code: 'DM',
    flag_emoji: '🇩🇲',
    population: 71991,
    emissions_in_kt_co2e: 240,
    supported_by_cool_climate: false,
  },
  Denmark: {
    country_name: 'Denmark',
    country_code: 'DNK',
    two_char_country_code: 'DK',
    currency_code: 'DKK',
    flag_emoji: '🇩🇰',
    population: 5831404,
    emissions_in_kt_co2e: 45870,
    supported_by_cool_climate: true,
  },
  'Dominican Republic': {
    country_name: 'Dominican Republic',
    country_code: 'DOM',
    two_char_country_code: 'DO',
    flag_emoji: '🇩🇴',
    population: 10847904,
    emissions_in_kt_co2e: 39060,
    supported_by_cool_climate: true,
  },
  Algeria: {
    country_name: 'Algeria',
    country_code: 'DZA',
    two_char_country_code: 'DZ',
    flag_emoji: '🇩🇿',
    population: 43851043,
    emissions_in_kt_co2e: 218910,
    supported_by_cool_climate: true,
  },
  Ecuador: {
    country_name: 'Ecuador',
    country_code: 'ECU',
    two_char_country_code: 'EC',
    currency_code: 'USD',
    flag_emoji: '🇪🇨',
    population: 17643060,
    emissions_in_kt_co2e: 65680,
    supported_by_cool_climate: true,
  },
  Egypt: {
    country_name: 'Egypt',
    country_code: 'EGY',
    two_char_country_code: 'EG',
    flag_emoji: '🇪🇬',
    population: 102334403,
    emissions_in_kt_co2e: 329220,
    supported_by_cool_climate: true,
  },
  Eritrea: {
    country_name: 'Eritrea',
    country_code: 'ERI',
    two_char_country_code: 'ER',
    flag_emoji: '🇪🇷',
    population: 0,
    emissions_in_kt_co2e: 7480,
    supported_by_cool_climate: true,
  },
  Spain: {
    country_name: 'Spain',
    country_code: 'ESP',
    two_char_country_code: 'ES',
    currency_code: 'EUR',
    flag_emoji: '🇪🇸',
    population: 47351567,
    emissions_in_kt_co2e: 326940,
    supported_by_cool_climate: true,
  },
  Estonia: {
    country_name: 'Estonia',
    country_code: 'EST',
    two_char_country_code: 'EE',
    currency_code: 'EUR',
    flag_emoji: '🇪🇪',
    population: 1331057,
    emissions_in_kt_co2e: 18610,
    supported_by_cool_climate: true,
  },
  Ethiopia: {
    country_name: 'Ethiopia',
    country_code: 'ETH',
    two_char_country_code: 'ET',
    flag_emoji: '🇪🇹',
    population: 114963583,
    emissions_in_kt_co2e: 172230,
    supported_by_cool_climate: true,
  },
  Finland: {
    country_name: 'Finland',
    country_code: 'FIN',
    two_char_country_code: 'FI',
    currency_code: 'EUR',
    flag_emoji: '🇫🇮',
    population: 5530719,
    emissions_in_kt_co2e: 54490,
    supported_by_cool_climate: true,
  },
  Fiji: {
    country_name: 'Fiji',
    country_code: 'FJI',
    two_char_country_code: 'FJ',
    flag_emoji: '🇫🇯',
    population: 896444,
    emissions_in_kt_co2e: 2820,
    supported_by_cool_climate: false,
  },
  France: {
    country_name: 'France',
    country_code: 'FRA',
    two_char_country_code: 'FR',
    currency_code: 'EUR',
    flag_emoji: '🇫🇷',
    population: 67391582,
    emissions_in_kt_co2e: 423350,
    supported_by_cool_climate: true,
  },
  'Micronesia, Fed. Sts.': {
    country_name: 'Micronesia, Fed. Sts.',
    country_code: 'FSM',
    two_char_country_code: 'FM',
    currency_code: 'USD',
    flag_emoji: '🇫🇲',
    population: 115021,
    emissions_in_kt_co2e: 270,
    supported_by_cool_climate: false,
  },
  Gabon: {
    country_name: 'Gabon',
    country_code: 'GAB',
    two_char_country_code: 'GA',
    flag_emoji: '🇬🇦',
    population: 2225728,
    emissions_in_kt_co2e: 7460,
    supported_by_cool_climate: true,
  },
  'United Kingdom': {
    country_name: 'United Kingdom',
    country_code: 'GBR',
    two_char_country_code: 'GB',
    currency_code: 'GBP',
    flag_emoji: '🇬🇧',
    population: 67215293,
    emissions_in_kt_co2e: 452080,
    supported_by_cool_climate: true,
  },
  Georgia: {
    country_name: 'Georgia',
    country_code: 'GEO',
    two_char_country_code: 'GE',
    flag_emoji: '🇬🇪',
    population: 3714000,
    emissions_in_kt_co2e: 16900,
    supported_by_cool_climate: true,
  },
  Ghana: {
    country_name: 'Ghana',
    country_code: 'GHA',
    two_char_country_code: 'GH',
    flag_emoji: '🇬🇭',
    population: 31072945,
    emissions_in_kt_co2e: 44500,
    supported_by_cool_climate: true,
  },
  Gibraltar: {
    country_name: 'Gibraltar',
    country_code: 'GIB',
    two_char_country_code: 'GI',
    currency_code: 'GBP',
    flag_emoji: '🇬🇮',
    population: 33691,
    emissions_in_kt_co2e: 473,
    supported_by_cool_climate: true,
  },
  Guinea: {
    country_name: 'Guinea',
    country_code: 'GIN',
    two_char_country_code: 'GN',
    flag_emoji: '🇬🇳',
    population: 13132792,
    emissions_in_kt_co2e: 28890,
    supported_by_cool_climate: false,
  },
  'Gambia, The': {
    country_name: 'Gambia, The',
    country_code: 'GMB',
    two_char_country_code: 'GM',
    flag_emoji: '🇬🇲',
    population: 2416664,
    emissions_in_kt_co2e: 2790,
    supported_by_cool_climate: false,
  },
  'Guinea-Bissau': {
    country_name: 'Guinea-Bissau',
    country_code: 'GNB',
    two_char_country_code: 'GW',
    flag_emoji: '🇬🇼',
    population: 1967998,
    emissions_in_kt_co2e: 2800,
    supported_by_cool_climate: false,
  },
  'Equatorial Guinea': {
    country_name: 'Equatorial Guinea',
    country_code: 'GNQ',
    two_char_country_code: 'GQ',
    flag_emoji: '🇬🇶',
    population: 1402985,
    emissions_in_kt_co2e: 19270,
    supported_by_cool_climate: false,
  },
  Greece: {
    country_name: 'Greece',
    country_code: 'GRC',
    two_char_country_code: 'GR',
    currency_code: 'EUR',
    flag_emoji: '🇬🇷',
    population: 10715549,
    emissions_in_kt_co2e: 84750,
    supported_by_cool_climate: true,
  },
  Grenada: {
    country_name: 'Grenada',
    country_code: 'GRD',
    two_char_country_code: 'GD',
    flag_emoji: '🇬🇩',
    population: 112519,
    emissions_in_kt_co2e: 2360,
    supported_by_cool_climate: false,
  },
  Guatemala: {
    country_name: 'Guatemala',
    country_code: 'GTM',
    two_char_country_code: 'GT',
    flag_emoji: '🇬🇹',
    population: 16858333,
    emissions_in_kt_co2e: 35400,
    supported_by_cool_climate: true,
  },
  Guam: {
    country_name: 'Guam',
    country_code: 'GUM',
    two_char_country_code: 'GU',
    currency_code: 'USD',
    flag_emoji: '🇬🇺',
    population: 168783,
    emissions_in_kt_co2e: 85,
    supported_by_cool_climate: false,
  },
  Guyana: {
    country_name: 'Guyana',
    country_code: 'GUY',
    two_char_country_code: 'GY',
    flag_emoji: '🇬🇾',
    population: 786559,
    emissions_in_kt_co2e: 5040,
    supported_by_cool_climate: false,
  },
  'Hong Kong SAR, China': {
    country_name: 'Hong Kong SAR, China',
    country_code: 'HKG',
    two_char_country_code: 'HK',
    flag_emoji: '🇭🇰',
    population: 7481800,
    emissions_in_kt_co2e: 58633,
    supported_by_cool_climate: false,
  },
  Honduras: {
    country_name: 'Honduras',
    country_code: 'HND',
    two_char_country_code: 'HN',
    flag_emoji: '🇭🇳',
    population: 9904608,
    emissions_in_kt_co2e: 22400,
    supported_by_cool_climate: true,
  },
  Croatia: {
    country_name: 'Croatia',
    country_code: 'HRV',
    two_char_country_code: 'HR',
    currency_code: 'EUR',
    flag_emoji: '🇭🇷',
    population: 4047200,
    emissions_in_kt_co2e: 22550,
    supported_by_cool_climate: true,
  },
  Haiti: {
    country_name: 'Haiti',
    country_code: 'HTI',
    two_char_country_code: 'HT',
    flag_emoji: '🇭🇹',
    population: 11402533,
    emissions_in_kt_co2e: 9930,
    supported_by_cool_climate: true,
  },
  Hungary: {
    country_name: 'Hungary',
    country_code: 'HUN',
    two_char_country_code: 'HU',
    currency_code: 'EUR',
    flag_emoji: '🇭🇺',
    population: 9749763,
    emissions_in_kt_co2e: 60920,
    supported_by_cool_climate: true,
  },
  Indonesia: {
    country_name: 'Indonesia',
    country_code: 'IDN',
    two_char_country_code: 'ID',
    flag_emoji: '🇮🇩',
    population: 273523621,
    emissions_in_kt_co2e: 969580,
    supported_by_cool_climate: true,
  },
  India: {
    country_name: 'India',
    country_code: 'IND',
    two_char_country_code: 'IN',
    flag_emoji: '🇮🇳',
    population: 1380004385,
    emissions_in_kt_co2e: 3374990,
    supported_by_cool_climate: true,
  },
  Ireland: {
    country_name: 'Ireland',
    country_code: 'IRL',
    two_char_country_code: 'IE',
    currency_code: 'EUR',
    flag_emoji: '🇮🇪',
    population: 4994724,
    emissions_in_kt_co2e: 65090,
    supported_by_cool_climate: true,
  },
  'Iran, Islamic Rep.': {
    country_name: 'Iran, Islamic Rep.',
    country_code: 'IRN',
    two_char_country_code: 'IR',
    flag_emoji: '🇮🇷',
    population: 83992953,
    emissions_in_kt_co2e: 828280,
    supported_by_cool_climate: true,
  },
  Iraq: {
    country_name: 'Iraq',
    country_code: 'IRQ',
    two_char_country_code: 'IQ',
    flag_emoji: '🇮🇶',
    population: 40222503,
    emissions_in_kt_co2e: 215690,
    supported_by_cool_climate: true,
  },
  Iceland: {
    country_name: 'Iceland',
    country_code: 'ISL',
    two_char_country_code: 'IS',
    flag_emoji: '🇮🇸',
    population: 366425,
    emissions_in_kt_co2e: 3440,
    supported_by_cool_climate: true,
  },
  Israel: {
    country_name: 'Israel',
    country_code: 'ISR',
    two_char_country_code: 'IL',
    flag_emoji: '🇮🇱',
    population: 9216900,
    emissions_in_kt_co2e: 87830,
    supported_by_cool_climate: true,
  },
  Italy: {
    country_name: 'Italy',
    country_code: 'ITA',
    two_char_country_code: 'IT',
    currency_code: 'EUR',
    flag_emoji: '🇮🇹',
    population: 59554023,
    emissions_in_kt_co2e: 399600,
    supported_by_cool_climate: true,
  },
  Jamaica: {
    country_name: 'Jamaica',
    country_code: 'JAM',
    two_char_country_code: 'JM',
    flag_emoji: '🇯🇲',
    population: 2961161,
    emissions_in_kt_co2e: 10020,
    supported_by_cool_climate: true,
  },
  Jordan: {
    country_name: 'Jordan',
    country_code: 'JOR',
    two_char_country_code: 'JO',
    flag_emoji: '🇯🇴',
    population: 10203140,
    emissions_in_kt_co2e: 35810,
    supported_by_cool_climate: true,
  },
  Japan: {
    country_name: 'Japan',
    country_code: 'JPN',
    two_char_country_code: 'JP',
    currency_code: 'JPY',
    flag_emoji: '🇯🇵',
    population: 125836021,
    emissions_in_kt_co2e: 1186770,
    supported_by_cool_climate: true,
  },
  Kazakhstan: {
    country_name: 'Kazakhstan',
    country_code: 'KAZ',
    two_char_country_code: 'KZ',
    flag_emoji: '🇰🇿',
    population: 18754440,
    emissions_in_kt_co2e: 274220,
    supported_by_cool_climate: true,
  },
  Kenya: {
    country_name: 'Kenya',
    country_code: 'KEN',
    two_char_country_code: 'KE',
    flag_emoji: '🇰🇪',
    population: 53771300,
    emissions_in_kt_co2e: 78830,
    supported_by_cool_climate: true,
  },
  'Kyrgyz Republic': {
    country_name: 'Kyrgyz Republic',
    country_code: 'KGZ',
    two_char_country_code: 'KG',
    flag_emoji: '🇰🇬',
    population: 6591600,
    emissions_in_kt_co2e: 18120,
    supported_by_cool_climate: true,
  },
  Cambodia: {
    country_name: 'Cambodia',
    country_code: 'KHM',
    two_char_country_code: 'KH',
    flag_emoji: '🇰🇭',
    population: 16718971,
    emissions_in_kt_co2e: 37460,
    supported_by_cool_climate: true,
  },
  Kiribati: {
    country_name: 'Kiribati',
    country_code: 'KIR',
    two_char_country_code: 'KI',
    currency_code: 'AUD',
    flag_emoji: '🇰🇮',
    population: 119446,
    emissions_in_kt_co2e: 110,
    supported_by_cool_climate: false,
  },
  'St. Kitts and Nevis': {
    country_name: 'St. Kitts and Nevis',
    country_code: 'KNA',
    two_char_country_code: 'KN',
    flag_emoji: '🇰🇳',
    population: 53192,
    emissions_in_kt_co2e: 370,
    supported_by_cool_climate: false,
  },
  'South Korea': {
    country_name: 'South Korea',
    country_code: 'KOR',
    two_char_country_code: 'KR',
    flag_emoji: '🇰🇷',
    population: 51780579,
    emissions_in_kt_co2e: 718880,
    supported_by_cool_climate: true,
  },
  Kuwait: {
    country_name: 'Kuwait',
    country_code: 'KWT',
    two_char_country_code: 'KW',
    flag_emoji: '🇰🇼',
    population: 4270563,
    emissions_in_kt_co2e: 112970,
    supported_by_cool_climate: true,
  },
  'Lao PDR': {
    country_name: 'Lao PDR',
    country_code: 'LAO',
    two_char_country_code: 'LA',
    flag_emoji: '🇱🇦',
    population: 7275556,
    emissions_in_kt_co2e: 29270,
    supported_by_cool_climate: false,
  },
  Lebanon: {
    country_name: 'Lebanon',
    country_code: 'LBN',
    two_char_country_code: 'LB',
    flag_emoji: '🇱🇧',
    population: 6825442,
    emissions_in_kt_co2e: 34350,
    supported_by_cool_climate: true,
  },
  Liberia: {
    country_name: 'Liberia',
    country_code: 'LBR',
    two_char_country_code: 'LR',
    flag_emoji: '🇱🇷',
    population: 5057677,
    emissions_in_kt_co2e: 9200,
    supported_by_cool_climate: false,
  },
  Libya: {
    country_name: 'Libya',
    country_code: 'LBY',
    two_char_country_code: 'LY',
    flag_emoji: '🇱🇾',
    population: 6871287,
    emissions_in_kt_co2e: 103040,
    supported_by_cool_climate: true,
  },
  'St. Lucia': {
    country_name: 'St. Lucia',
    country_code: 'LCA',
    two_char_country_code: 'LC',
    flag_emoji: '🇱🇨',
    population: 183629,
    emissions_in_kt_co2e: 740,
    supported_by_cool_climate: false,
  },
  'Sri Lanka': {
    country_name: 'Sri Lanka',
    country_code: 'LKA',
    two_char_country_code: 'LK',
    flag_emoji: '🇱🇰',
    population: 21919000,
    emissions_in_kt_co2e: 35240,
    supported_by_cool_climate: true,
  },
  Lesotho: {
    country_name: 'Lesotho',
    country_code: 'LSO',
    two_char_country_code: 'LS',
    flag_emoji: '🇱🇸',
    population: 2142252,
    emissions_in_kt_co2e: 5850,
    supported_by_cool_climate: false,
  },
  Lithuania: {
    country_name: 'Lithuania',
    country_code: 'LTU',
    two_char_country_code: 'LT',
    currency_code: 'EUR',
    flag_emoji: '🇱🇹',
    population: 2794700,
    emissions_in_kt_co2e: 18840,
    supported_by_cool_climate: true,
  },
  Luxembourg: {
    country_name: 'Luxembourg',
    country_code: 'LUX',
    two_char_country_code: 'LU',
    currency_code: 'EUR',
    flag_emoji: '🇱🇺',
    population: 632275,
    emissions_in_kt_co2e: 10220,
    supported_by_cool_climate: true,
  },
  Latvia: {
    country_name: 'Latvia',
    country_code: 'LVA',
    two_char_country_code: 'LV',
    currency_code: 'EUR',
    flag_emoji: '🇱🇻',
    population: 1901548,
    emissions_in_kt_co2e: 11660,
    supported_by_cool_climate: true,
  },
  'Macao SAR, China': {
    country_name: 'Macao SAR, China',
    country_code: 'MAC',
    two_char_country_code: 'MO',
    flag_emoji: '🇲🇴',
    population: 649342,
    emissions_in_kt_co2e: 1686,
    supported_by_cool_climate: false,
  },
  Morocco: {
    country_name: 'Morocco',
    country_code: 'MAR',
    two_char_country_code: 'MA',
    flag_emoji: '🇲🇦',
    population: 36910558,
    emissions_in_kt_co2e: 94290,
    supported_by_cool_climate: true,
  },
  Moldova: {
    country_name: 'Moldova',
    country_code: 'MDA',
    two_char_country_code: 'MD',
    currency_code: 'EUR',
    flag_emoji: '🇲🇩',
    population: 2617820,
    emissions_in_kt_co2e: 13180,
    supported_by_cool_climate: true,
  },
  Madagascar: {
    country_name: 'Madagascar',
    country_code: 'MDG',
    two_char_country_code: 'MG',
    flag_emoji: '🇲🇬',
    population: 27691019,
    emissions_in_kt_co2e: 30570,
    supported_by_cool_climate: false,
  },
  Maldives: {
    country_name: 'Maldives',
    country_code: 'MDV',
    two_char_country_code: 'MV',
    flag_emoji: '🇲🇻',
    population: 540542,
    emissions_in_kt_co2e: 2380,
    supported_by_cool_climate: false,
  },
  Mexico: {
    country_name: 'Mexico',
    country_code: 'MEX',
    two_char_country_code: 'MX',
    currency_code: 'MXN',
    flag_emoji: '🇲🇽',
    population: 128932753,
    emissions_in_kt_co2e: 679880,
    supported_by_cool_climate: true,
  },
  'Marshall Islands': {
    country_name: 'Marshall Islands',
    country_code: 'MHL',
    two_char_country_code: 'MH',
    currency_code: 'USD',
    flag_emoji: '🇲🇭',
    population: 59194,
    emissions_in_kt_co2e: 240,
    supported_by_cool_climate: false,
  },
  'North Macedonia': {
    country_name: 'North Macedonia',
    country_code: 'MKD',
    two_char_country_code: 'MK',
    currency_code: 'EUR',
    flag_emoji: '🇲🇰',
    population: 2083380,
    emissions_in_kt_co2e: 10510,
    supported_by_cool_climate: true,
  },
  Mali: {
    country_name: 'Mali',
    country_code: 'MLI',
    two_char_country_code: 'ML',
    flag_emoji: '🇲🇱',
    population: 20250834,
    emissions_in_kt_co2e: 43740,
    supported_by_cool_climate: false,
  },
  Malta: {
    country_name: 'Malta',
    country_code: 'MLT',
    two_char_country_code: 'MT',
    currency_code: 'EUR',
    flag_emoji: '🇲🇹',
    population: 525285,
    emissions_in_kt_co2e: 2040,
    supported_by_cool_climate: true,
  },
  Myanmar: {
    country_name: 'Myanmar',
    country_code: 'MMR',
    two_char_country_code: 'MM',
    flag_emoji: '🇲🇲',
    population: 54409794,
    emissions_in_kt_co2e: 119650,
    supported_by_cool_climate: true,
  },
  Mongolia: {
    country_name: 'Mongolia',
    country_code: 'MNG',
    two_char_country_code: 'MN',
    flag_emoji: '🇲🇳',
    population: 3278292,
    emissions_in_kt_co2e: 52620,
    supported_by_cool_climate: true,
  },
  'Northern Mariana Islands': {
    country_name: 'Northern Mariana Islands',
    country_code: 'MNP',
    two_char_country_code: 'MP',
    currency_code: 'USD',
    flag_emoji: '🇲🇵',
    population: 57557,
    emissions_in_kt_co2e: 12,
    supported_by_cool_climate: false,
  },
  Mozambique: {
    country_name: 'Mozambique',
    country_code: 'MOZ',
    two_char_country_code: 'MZ',
    flag_emoji: '🇲🇿',
    population: 31255435,
    emissions_in_kt_co2e: 35040,
    supported_by_cool_climate: true,
  },
  Mauritania: {
    country_name: 'Mauritania',
    country_code: 'MRT',
    two_char_country_code: 'MR',
    flag_emoji: '🇲🇷',
    population: 4649660,
    emissions_in_kt_co2e: 14080,
    supported_by_cool_climate: false,
  },
  Mauritius: {
    country_name: 'Mauritius',
    country_code: 'MUS',
    two_char_country_code: 'MU',
    flag_emoji: '🇲🇺',
    population: 1265740,
    emissions_in_kt_co2e: 6740,
    supported_by_cool_climate: true,
  },
  Malawi: {
    country_name: 'Malawi',
    country_code: 'MWI',
    two_char_country_code: 'MW',
    flag_emoji: '🇲🇼',
    population: 19129955,
    emissions_in_kt_co2e: 18320,
    supported_by_cool_climate: false,
  },
  Malaysia: {
    country_name: 'Malaysia',
    country_code: 'MYS',
    two_char_country_code: 'MY',
    flag_emoji: '🇲🇾',
    population: 32365998,
    emissions_in_kt_co2e: 306670,
    supported_by_cool_climate: true,
  },
  Namibia: {
    country_name: 'Namibia',
    country_code: 'NAM',
    two_char_country_code: 'NA',
    flag_emoji: '🇳🇦',
    population: 2540916,
    emissions_in_kt_co2e: 11930,
    supported_by_cool_climate: true,
  },
  'New Caledonia': {
    country_name: 'New Caledonia',
    country_code: 'NCL',
    two_char_country_code: 'NC',
    flag_emoji: '🇳🇨',
    population: 271960,
    emissions_in_kt_co2e: 0,
    supported_by_cool_climate: false,
  },
  Niger: {
    country_name: 'Niger',
    country_code: 'NER',
    two_char_country_code: 'NE',
    flag_emoji: '🇳🇪',
    population: 24206636,
    emissions_in_kt_co2e: 45050,
    supported_by_cool_climate: true,
  },
  Nigeria: {
    country_name: 'Nigeria',
    country_code: 'NGA',
    two_char_country_code: 'NG',
    flag_emoji: '🇳🇬',
    population: 206139587,
    emissions_in_kt_co2e: 311450,
    supported_by_cool_climate: true,
  },
  Nicaragua: {
    country_name: 'Nicaragua',
    country_code: 'NIC',
    two_char_country_code: 'NI',
    flag_emoji: '🇳🇮',
    population: 6624554,
    emissions_in_kt_co2e: 19070,
    supported_by_cool_climate: true,
  },
  Netherlands: {
    country_name: 'Netherlands',
    country_code: 'NLD',
    two_char_country_code: 'NL',
    currency_code: 'EUR',
    flag_emoji: '🇳🇱',
    population: 17441139,
    emissions_in_kt_co2e: 178640,
    supported_by_cool_climate: true,
  },
  Norway: {
    country_name: 'Norway',
    country_code: 'NOR',
    two_char_country_code: 'NO',
    currency_code: 'EUR',
    flag_emoji: '🇳🇴',
    population: 5379475,
    emissions_in_kt_co2e: 47340,
    supported_by_cool_climate: true,
  },
  Nepal: {
    country_name: 'Nepal',
    country_code: 'NPL',
    two_char_country_code: 'NP',
    flag_emoji: '🇳🇵',
    population: 29136808,
    emissions_in_kt_co2e: 51240,
    supported_by_cool_climate: true,
  },
  'New Zealand': {
    country_name: 'New Zealand',
    country_code: 'NZL',
    two_char_country_code: 'NZ',
    currency_code: 'NZD',
    flag_emoji: '🇳🇿',
    population: 5084300,
    emissions_in_kt_co2e: 81330,
    supported_by_cool_climate: true,
  },
  Oman: {
    country_name: 'Oman',
    country_code: 'OMN',
    two_char_country_code: 'OM',
    flag_emoji: '🇴🇲',
    population: 5106622,
    emissions_in_kt_co2e: 82300,
    supported_by_cool_climate: true,
  },
  Pakistan: {
    country_name: 'Pakistan',
    country_code: 'PAK',
    two_char_country_code: 'PK',
    flag_emoji: '🇵🇰',
    population: 220892331,
    emissions_in_kt_co2e: 431220,
    supported_by_cool_climate: true,
  },
  Panama: {
    country_name: 'Panama',
    country_code: 'PAN',
    two_char_country_code: 'PA',
    currency_code: 'USD',
    flag_emoji: '🇵🇦',
    population: 4314768,
    emissions_in_kt_co2e: 18010,
    supported_by_cool_climate: true,
  },
  Peru: {
    country_name: 'Peru',
    country_code: 'PER',
    two_char_country_code: 'PE',
    flag_emoji: '🇵🇪',
    population: 32971846,
    emissions_in_kt_co2e: 96280,
    supported_by_cool_climate: true,
  },
  Philippines: {
    country_name: 'Philippines',
    country_code: 'PHL',
    two_char_country_code: 'PH',
    flag_emoji: '🇵🇭',
    population: 109581085,
    emissions_in_kt_co2e: 232340,
    supported_by_cool_climate: true,
  },
  'Papua New Guinea': {
    country_name: 'Papua New Guinea',
    country_code: 'PNG',
    two_char_country_code: 'PG',
    flag_emoji: '🇵🇬',
    population: 8947027,
    emissions_in_kt_co2e: 22970,
    supported_by_cool_climate: false,
  },
  Poland: {
    country_name: 'Poland',
    country_code: 'POL',
    two_char_country_code: 'PL',
    flag_emoji: '🇵🇱',
    population: 37950802,
    emissions_in_kt_co2e: 389650,
    supported_by_cool_climate: true,
  },
  'Puerto Rico': {
    country_name: 'Puerto Rico',
    country_code: 'PRI',
    two_char_country_code: 'PR',
    currency_code: 'USD',
    flag_emoji: '🇵🇷',
    population: 3194034,
    emissions_in_kt_co2e: 3293,
    supported_by_cool_climate: false,
  },
  'North Korea': {
    country_name: 'North Korea',
    country_code: 'PRK',
    two_char_country_code: 'KP',
    flag_emoji: '🇰🇵',
    population: 25778815,
    emissions_in_kt_co2e: 41910,
    supported_by_cool_climate: true,
  },
  Portugal: {
    country_name: 'Portugal',
    country_code: 'PRT',
    two_char_country_code: 'PT',
    currency_code: 'EUR',
    flag_emoji: '🇵🇹',
    population: 10305564,
    emissions_in_kt_co2e: 66820,
    supported_by_cool_climate: true,
  },
  Paraguay: {
    country_name: 'Paraguay',
    country_code: 'PRY',
    two_char_country_code: 'PY',
    flag_emoji: '🇵🇾',
    population: 7132530,
    emissions_in_kt_co2e: 49170,
    supported_by_cool_climate: true,
  },
  'French Polynesia': {
    country_name: 'French Polynesia',
    country_code: 'PYF',
    two_char_country_code: 'PF',
    flag_emoji: '🇵🇫',
    population: 280904,
    emissions_in_kt_co2e: 610,
    supported_by_cool_climate: false,
  },
  Qatar: {
    country_name: 'Qatar',
    country_code: 'QAT',
    two_char_country_code: 'QA',
    flag_emoji: '🇶🇦',
    population: 2881060,
    emissions_in_kt_co2e: 99830,
    supported_by_cool_climate: true,
  },
  Romania: {
    country_name: 'Romania',
    country_code: 'ROU',
    two_char_country_code: 'RO',
    currency_code: 'EUR',
    flag_emoji: '🇷🇴',
    population: 19286123,
    emissions_in_kt_co2e: 109010,
    supported_by_cool_climate: true,
  },
  'Russian Federation': {
    country_name: 'Russian Federation',
    country_code: 'RUS',
    two_char_country_code: 'RU',
    flag_emoji: '🇷🇺',
    population: 144104080,
    emissions_in_kt_co2e: 2543400,
    supported_by_cool_climate: true,
  },
  Rwanda: {
    country_name: 'Rwanda',
    country_code: 'RWA',
    two_char_country_code: 'RW',
    flag_emoji: '🇷🇼',
    population: 12952209,
    emissions_in_kt_co2e: 6170,
    supported_by_cool_climate: false,
  },
  'Saudi Arabia': {
    country_name: 'Saudi Arabia',
    country_code: 'SAU',
    two_char_country_code: 'SA',
    flag_emoji: '🇸🇦',
    population: 34813867,
    emissions_in_kt_co2e: 638120,
    supported_by_cool_climate: true,
  },
  Sudan: {
    country_name: 'Sudan',
    country_code: 'SDN',
    two_char_country_code: 'SD',
    flag_emoji: '🇸🇩',
    population: 43849269,
    emissions_in_kt_co2e: 109630,
    supported_by_cool_climate: true,
  },
  Senegal: {
    country_name: 'Senegal',
    country_code: 'SEN',
    two_char_country_code: 'SN',
    flag_emoji: '🇸🇳',
    population: 16743930,
    emissions_in_kt_co2e: 29990,
    supported_by_cool_climate: true,
  },
  Singapore: {
    country_name: 'Singapore',
    country_code: 'SGP',
    two_char_country_code: 'SG',
    flag_emoji: '🇸🇬',
    population: 5685807,
    emissions_in_kt_co2e: 66640,
    supported_by_cool_climate: true,
  },
  'Solomon Islands': {
    country_name: 'Solomon Islands',
    country_code: 'SLB',
    two_char_country_code: 'SB',
    flag_emoji: '🇸🇧',
    population: 686878,
    emissions_in_kt_co2e: 940,
    supported_by_cool_climate: false,
  },
  'Sierra Leone': {
    country_name: 'Sierra Leone',
    country_code: 'SLE',
    two_char_country_code: 'SL',
    flag_emoji: '🇸🇱',
    population: 7976985,
    emissions_in_kt_co2e: 7190,
    supported_by_cool_climate: false,
  },
  'El Salvador': {
    country_name: 'El Salvador',
    country_code: 'SLV',
    two_char_country_code: 'SV',
    currency_code: 'USD',
    flag_emoji: '🇸🇻',
    population: 6486201,
    emissions_in_kt_co2e: 12350,
    supported_by_cool_climate: true,
  },
  Somalia: {
    country_name: 'Somalia',
    country_code: 'SOM',
    two_char_country_code: 'SO',
    currency_code: 'USD',
    flag_emoji: '🇸🇴',
    population: 15893219,
    emissions_in_kt_co2e: 26970,
    supported_by_cool_climate: false,
  },
  'Sao Tome and Principe': {
    country_name: 'Sao Tome and Principe',
    country_code: 'STP',
    two_char_country_code: 'ST',
    flag_emoji: '🇸🇹',
    population: 219161,
    emissions_in_kt_co2e: 190,
    supported_by_cool_climate: false,
  },
  Suriname: {
    country_name: 'Suriname',
    country_code: 'SUR',
    two_char_country_code: 'SR',
    flag_emoji: '🇸🇷',
    population: 586634,
    emissions_in_kt_co2e: 3780,
    supported_by_cool_climate: true,
  },
  'Slovak Republic': {
    country_name: 'Slovak Republic',
    country_code: 'SVK',
    two_char_country_code: 'SK',
    currency_code: 'EUR',
    flag_emoji: '🇸🇰',
    population: 5458827,
    emissions_in_kt_co2e: 39930,
    supported_by_cool_climate: true,
  },
  Slovenia: {
    country_name: 'Slovenia',
    country_code: 'SVN',
    two_char_country_code: 'SI',
    currency_code: 'EUR',
    flag_emoji: '🇸🇮',
    population: 2100126,
    emissions_in_kt_co2e: 17170,
    supported_by_cool_climate: true,
  },
  Sweden: {
    country_name: 'Sweden',
    country_code: 'SWE',
    two_char_country_code: 'SE',
    currency_code: 'EUR',
    flag_emoji: '🇸🇪',
    population: 10353442,
    emissions_in_kt_co2e: 46350,
    supported_by_cool_climate: true,
  },
  Eswatini: {
    country_name: 'Eswatini',
    country_code: 'SWZ',
    two_char_country_code: 'SZ',
    flag_emoji: '🇸🇿',
    population: 1160164,
    emissions_in_kt_co2e: 3120,
    supported_by_cool_climate: false,
  },
  Seychelles: {
    country_name: 'Seychelles',
    country_code: 'SYC',
    two_char_country_code: 'SC',
    flag_emoji: '🇸🇨',
    population: 98462,
    emissions_in_kt_co2e: 780,
    supported_by_cool_climate: false,
  },
  'Syrian Arab Republic': {
    country_name: 'Syrian Arab Republic',
    country_code: 'SYR',
    two_char_country_code: 'SY',
    flag_emoji: '🇸🇾',
    population: 17500657,
    emissions_in_kt_co2e: 46320,
    supported_by_cool_climate: true,
  },
  'Turks and Caicos Islands': {
    country_name: 'Turks and Caicos Islands',
    country_code: 'TCA',
    two_char_country_code: 'TC',
    currency_code: 'USD',
    flag_emoji: '🇹🇨',
    population: 38718,
    emissions_in_kt_co2e: 0,
    supported_by_cool_climate: false,
  },
  Chad: {
    country_name: 'Chad',
    country_code: 'TCD',
    two_char_country_code: 'TD',
    flag_emoji: '🇹🇩',
    population: 16425859,
    emissions_in_kt_co2e: 80680,
    supported_by_cool_climate: false,
  },
  Taiwan: {
    country_name: 'Taiwan',
    country_code: 'TWN',
    two_char_country_code: 'TW',
    flag_emoji: '🇹🇼',
    population: 23570000,
    emissions_in_kt_co2e: 270347,
    supported_by_cool_climate: false,
  },
  Togo: {
    country_name: 'Togo',
    country_code: 'TGO',
    two_char_country_code: 'TG',
    flag_emoji: '🇹🇬',
    population: 8278737,
    emissions_in_kt_co2e: 8420,
    supported_by_cool_climate: true,
  },
  Thailand: {
    country_name: 'Thailand',
    country_code: 'THA',
    two_char_country_code: 'TH',
    flag_emoji: '🇹🇭',
    population: 69799978,
    emissions_in_kt_co2e: 416950,
    supported_by_cool_climate: true,
  },
  Tajikistan: {
    country_name: 'Tajikistan',
    country_code: 'TJK',
    two_char_country_code: 'TJ',
    flag_emoji: '🇹🇯',
    population: 9537642,
    emissions_in_kt_co2e: 15210,
    supported_by_cool_climate: true,
  },
  Turkmenistan: {
    country_name: 'Turkmenistan',
    country_code: 'TKM',
    two_char_country_code: 'TM',
    flag_emoji: '🇹🇲',
    population: 6031187,
    emissions_in_kt_co2e: 125080,
    supported_by_cool_climate: true,
  },
  'Timor-Leste': {
    country_name: 'Timor-Leste',
    country_code: 'TLS',
    two_char_country_code: 'TL',
    currency_code: 'USD',
    flag_emoji: '🇹🇱',
    population: 1318442,
    emissions_in_kt_co2e: 6190,
    supported_by_cool_climate: false,
  },
  Tonga: {
    country_name: 'Tonga',
    country_code: 'TON',
    two_char_country_code: 'TO',
    flag_emoji: '🇹🇴',
    population: 105697,
    emissions_in_kt_co2e: 350,
    supported_by_cool_climate: false,
  },
  'Trinidad and Tobago': {
    country_name: 'Trinidad and Tobago',
    country_code: 'TTO',
    two_char_country_code: 'TT',
    flag_emoji: '🇹🇹',
    population: 1399491,
    emissions_in_kt_co2e: 22900,
    supported_by_cool_climate: true,
  },
  Tunisia: {
    country_name: 'Tunisia',
    country_code: 'TUN',
    two_char_country_code: 'TN',
    flag_emoji: '🇹🇳',
    population: 11818618,
    emissions_in_kt_co2e: 41100,
    supported_by_cool_climate: true,
  },
  Turkey: {
    country_name: 'Turkey',
    country_code: 'TUR',
    two_char_country_code: 'TR',
    flag_emoji: '🇹🇷',
    population: 84339067,
    emissions_in_kt_co2e: 502520,
    supported_by_cool_climate: true,
  },
  Tuvalu: {
    country_name: 'Tuvalu',
    country_code: 'TUV',
    two_char_country_code: 'TV',
    currency_code: 'AUD',
    flag_emoji: '🇹🇻',
    population: 11792,
    emissions_in_kt_co2e: 30,
    supported_by_cool_climate: false,
  },
  Tanzania: {
    country_name: 'Tanzania',
    country_code: 'TZA',
    two_char_country_code: 'TZ',
    flag_emoji: '🇹🇿',
    population: 59734213,
    emissions_in_kt_co2e: 105380,
    supported_by_cool_climate: true,
  },
  Uganda: {
    country_name: 'Uganda',
    country_code: 'UGA',
    two_char_country_code: 'UG',
    flag_emoji: '🇺🇬',
    population: 45741000,
    emissions_in_kt_co2e: 54870,
    supported_by_cool_climate: false,
  },
  Ukraine: {
    country_name: 'Ukraine',
    country_code: 'UKR',
    two_char_country_code: 'UA',
    flag_emoji: '🇺🇦',
    population: 44134693,
    emissions_in_kt_co2e: 274510,
    supported_by_cool_climate: true,
  },
  Uruguay: {
    country_name: 'Uruguay',
    country_code: 'URY',
    two_char_country_code: 'UY',
    flag_emoji: '🇺🇾',
    population: 3473727,
    emissions_in_kt_co2e: 36160,
    supported_by_cool_climate: true,
  },
  'United States': {
    country_name: 'United States',
    country_code: 'USA',
    two_char_country_code: 'US',
    currency_code: 'USD',
    flag_emoji: '🇺🇸',
    population: 329484123,
    emissions_in_kt_co2e: 6023620,
    supported_by_cool_climate: true,
    supports_zipcode: true,
  },
  Uzbekistan: {
    country_name: 'Uzbekistan',
    country_code: 'UZB',
    two_char_country_code: 'UZ',
    flag_emoji: '🇺🇿',
    population: 34232050,
    emissions_in_kt_co2e: 235510,
    supported_by_cool_climate: true,
  },
  'St. Vincent and the Grenadines': {
    country_name: 'St. Vincent and the Grenadines',
    country_code: 'VCT',
    two_char_country_code: 'VC',
    flag_emoji: '🇻🇨',
    population: 110947,
    emissions_in_kt_co2e: 370,
    supported_by_cool_climate: false,
  },
  'Venezuela, RB': {
    country_name: 'Venezuela, RB',
    country_code: 'VEN',
    two_char_country_code: 'VE',
    flag_emoji: '🇻🇪',
    population: 28435943,
    emissions_in_kt_co2e: 233920,
    supported_by_cool_climate: true,
  },
  'British Virgin Islands': {
    country_name: 'British Virgin Islands',
    country_code: 'VGB',
    two_char_country_code: 'VG',
    currency_code: 'USD',
    flag_emoji: '🇻🇬',
    population: 30237,
    emissions_in_kt_co2e: 74,
    supported_by_cool_climate: false,
  },
  'Virgin Islands (U.S.)': {
    country_name: 'Virgin Islands (U.S.)',
    country_code: 'VIR',
    two_char_country_code: 'VI',
    currency_code: 'USD',
    flag_emoji: '🇻🇮',
    population: 106290,
    emissions_in_kt_co2e: 61,
    supported_by_cool_climate: false,
  },
  Vietnam: {
    country_name: 'Vietnam',
    country_code: 'VNM',
    two_char_country_code: 'VN',
    flag_emoji: '🇻🇳',
    population: 97338583,
    emissions_in_kt_co2e: 376530,
    supported_by_cool_climate: true,
  },
  Vanuatu: {
    country_name: 'Vanuatu',
    country_code: 'VUT',
    two_char_country_code: 'VU',
    flag_emoji: '🇻🇺',
    population: 307150,
    emissions_in_kt_co2e: 870,
    supported_by_cool_climate: false,
  },
  Samoa: {
    country_name: 'Samoa',
    country_code: 'WSM',
    two_char_country_code: 'WS',
    flag_emoji: '🇼🇸',
    population: 198410,
    emissions_in_kt_co2e: 690,
    supported_by_cool_climate: false,
  },
  Serbia: {
    country_name: 'Serbia',
    country_code: 'SRB',
    two_char_country_code: 'RS',
    flag_emoji: '🇷🇸',
    population: 6908000,
    emissions_in_kt_co2e: 34125,
    supported_by_cool_climate: false,
  },
  'Yemen, Rep.': {
    country_name: 'Yemen, Rep.',
    country_code: 'YEM',
    two_char_country_code: 'YE',
    flag_emoji: '🇾🇪',
    population: 29825968,
    emissions_in_kt_co2e: 21760,
    supported_by_cool_climate: true,
  },
  'South Africa': {
    country_name: 'South Africa',
    country_code: 'ZAF',
    two_char_country_code: 'ZA',
    flag_emoji: '🇿🇦',
    population: 59308690,
    emissions_in_kt_co2e: 513440,
    supported_by_cool_climate: true,
  },
  Zambia: {
    country_name: 'Zambia',
    country_code: 'ZMB',
    two_char_country_code: 'ZM',
    flag_emoji: '🇿🇲',
    population: 18383956,
    emissions_in_kt_co2e: 40670,
    supported_by_cool_climate: true,
  },
  Zimbabwe: {
    country_name: 'Zimbabwe',
    country_code: 'ZWE',
    two_char_country_code: 'ZW',
    flag_emoji: '🇿🇼',
    population: 14862927,
    emissions_in_kt_co2e: 31380,
    supported_by_cool_climate: true,
  },
} as const;
export interface Country {
  country_name: string;
  country_code: string;
  two_char_country_code: string;
  flag_emoji: string;
  population: number;
  emissions_in_kt_co2e: number;
  supported_by_cool_climate: boolean;
  currency_code?: string;
  supports_zipcode?: boolean;
}

export const COUNTRIES: typeof data & Record<string, Country> = data;

export type SupportedCountryName = keyof typeof data;

export type SupportedCountry = (typeof data)[keyof typeof data];

export type IsoAlpha2 = SupportedCountry['two_char_country_code'];

const alphabeticalComparator = (a: Country, b: Country) => {
  const textA = a.country_name.toUpperCase();
  const textB = b.country_name.toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
};

export const alphabetical = memoize(() =>
  Object.values(data).sort(alphabeticalComparator)
);

export function isSupportedCountryName(
  countryName: string | null | undefined
): countryName is SupportedCountryName {
  return typeof countryName === 'string' && countryName in data;
}

export function hasCurrency(
  country: SupportedCountry
): country is SupportedCountry & { currency_code: string } {
  return country.hasOwnProperty('currency_code');
}
