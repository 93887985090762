const IMAGE_SERVER = 'https://projectwren.imgix.net';

interface Options {
  url?: string;
  [key: string]: string | number | boolean | undefined;
}

export function staticImageUrl(imagePath: string, options: Options = {}) {
  const url = new URL(options.url || IMAGE_SERVER);

  delete options.url;
  const searchParams = new URLSearchParams();

  // compressing gifs disables animation
  if (!imagePath.endsWith('.gif')) {
    searchParams.set('auto', ['format', 'compress'].toString());
    searchParams.set('q', '35');
  }

  for (const [key, value] of Object.entries(options)) {
    if (value) {
      searchParams.set(key, value.toString());
    } else {
      searchParams.delete(key);
    }
  }

  url.pathname = imagePath.startsWith('/') ? imagePath : `/${imagePath}`;
  url.search = searchParams.toString();
  return url.toString();
}

export function modifySearchParams(givenUrl: string, options: Options) {
  const url = new URL(givenUrl);
  const searchParams = new URLSearchParams(url.search);

  for (const [key, value] of Object.entries(options)) {
    if (value !== undefined) {
      searchParams.set(key, value.toString());
    } else {
      searchParams.delete(key);
    }
  }

  url.search = searchParams.toString();
  return url.toString();
}

export function tistImageUrl(imageId: string, options = {}) {
  return staticImageUrl(`${imageId}.jpg`, {
    ...options,
    url: 'https://wren-tist.imgix.net',
  });
}

export function getImageSetForStaticImageUrl(imageUrl: string) {
  return `${imageUrl}&dpr=1 1x, ${imageUrl}&dpr=2 2x, ${imageUrl}&dpr=3 3x`;
}

export function preloadImages(imageUrls: string[]) {
  return imageUrls.map((url) => {
    const img = new Image();
    img.src = url;
    return img;
  });
}
