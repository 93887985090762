// Monkey patch to prevent site-translation / React error
// based on https://github.com/facebook/react/issues/11538#issuecomment-417504600
// Run before ReactDOM.render
export default function patchNode() {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function <T extends Node>(child: T) {
      if (child.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot remove a child from a different parent',
            child,
            this
          );
        }
        return child;
      }
      return originalRemoveChild.call<Node, [T], T>(this, child);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function <T extends Node>(
      newNode: T,
      referenceNode: Node | null
    ) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this
          );
        }
        return newNode;
      }
      return originalInsertBefore.call<Node, [T, Node | null], T>(
        this,
        newNode,
        referenceNode
      );
    };
  }
}
