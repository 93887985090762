/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';
import { scale } from '../../util/scale';

const fill = keyframes`
  0% {
    background-position: right bottom;
  }

  100% {
    background-position: left bottom;
  }
`;

const carouselDash = (selected, duration) =>
  scale({
    position: 'absolute',
    width: ['36px', '50px'],
    height: '6px',
    opacity: selected ? 1 : 0.3,
    animation: selected ? `${fill} ${duration || '5s'} ease infinite` : '',
    background: selected
      ? 'linear-gradient(to right, var(--gray-2) 50%, rgba(0, 0, 0, 0.3) 50%)'
      : 'var(--gray-2)',
    backgroundSize: '200% 100%',
    backgroundPosition: 'right bottom',
    transition: 'all .5s ease-out',
    '&:hover': {
      opacity: 1,
    },
    top: 0,
    bottom: 0,
    margin: 'auto',
    right: 0,
    left: 0,
    borderRadius: '15px',
  });

const carouselCircle = (selected) =>
  scale({
    width: '12px',
    height: '12px',
    opacity: selected ? 1 : 0.3,
    backgroundColor: 'white',
    borderRadius: '15px',
    transition: 'all .5s ease-out',
    '&:hover': {
      opacity: 1,
    },
  });

const carouselDotContainer = (style) =>
  scale({
    position: 'relative',
    height: '20px',
    width: style === 'new' ? 'auto' : '100%',
    maxWidth: ['40px', '48px'],
    margin: '0px 8px',
    boxSizing: 'border-box',
    display: 'inline-block',
    cursor: 'pointer',
  });

export default function CarouselDot(props) {
  const { carouselCycle, position, onClickFn, duration, style } = props;
  const selected = carouselCycle === position;
  return (
    <div css={carouselDotContainer(style)} onClick={() => onClickFn(position)}>
      <div
        css={
          style === 'new'
            ? carouselCircle(selected, duration)
            : carouselDash(selected, duration)
        }
      />
    </div>
  );
}
