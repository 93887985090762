/** @jsxImportSource @emotion/react */
import { isMobile } from '@wren/shared';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import {
  SetCalculatorQuestionIndex,
  SetCountry,
} from '../../actions/calculator';
import { SetError } from '../../actions/toaster';
import { GetStarted, LinkUserToReferrer } from '../../actions/user';
import PromoDialog from '../../components/UI/PromoDialog';
import useExperiment from '../../hooks/useExperiment';
import { State } from '../../store';
import { hideOnMobile, showOnMobile } from '../../style/misc';
import { ReferrerUser } from '../../types';
import { staticImageUrl } from '../../util/images';
import { scale } from '../../util/scale';
import LandingContent from '../Landing/LandingContent';

const HowItWorksLazy = lazy(
  () => import('../../components/landing/HowItWorks')
);

const HowItWorks = () => (
  <Suspense fallback={<p></p>}>
    {/* TODO: skeleton */}
    <HowItWorksLazy />
  </Suspense>
);

function useJoinLinksExperiment(referrer?: ReferrerUser) {
  const experimentName = isMobile()
    ? 'join_links_landing_header_mobile'
    : 'join_links_header_desktop';
  const treatment = useExperiment(experimentName);
  const firstName = referrer?.firstName || 'Your friend';
  switch (treatment) {
    case 'control':
    default:
      return {
        headers: {
          header: 'Join Wren and your first month is free.',
          mainTitleWidth: 600,
          subheader: referrer?.referralMessage || (
            <span>
              <b>{firstName}</b> is inviting you to Wren! Join them by
              calculating and offsetting your carbon footprint.
            </span>
          ),
        },
        ctaText: `Join ${firstName}`,
        withSignupCounter: false,
        popUp: false,
      };
    case 'start_link_header':
      return {
        headers: {
          header: 'Systemic change starts with you.',
          mainTitleWidth: 600,
          subheader: referrer?.referralMessage || (
            <span>
              <b>{firstName}</b> has invited you to Wren! Join them by
              calculating and offsetting your carbon footprint.
            </span>
          ),
        },
        ctaText: `Get started`,
        withSignupCounter: true,
        popUp: isMobile(),
      };
    case 'explainer_header':
      return {
        headers: {
          header: (
            <div
              css={scale({
                fontSize: ['smaller', undefined],
                lineHeight: [1.2, undefined],
              })}
            >
              Wren is your all-in-one climate subscription.
            </div>
          ),
          mainTitleWidth: 600,
          subheader: referrer?.referralMessage || (
            <span>
              <b>{firstName}</b> has invited you to Wren! Join them by
              calculating and offsetting your carbon footprint.
            </span>
          ),
        },
        ctaText: `Get started`,
        withSignupCounter: true,
        popUp: isMobile(),
        valueProofHeader: (
          <>
            <span css={showOnMobile}>
              Join {firstName} by calculating and offsetting your carbon
              footprint.
            </span>
            <span css={hideOnMobile}>
              Fund carbon removal, offsets, climate policy, and conservation–all
              in one subscription.
            </span>
          </>
        ),
      };
  }
}

export default function ReferralLanding() {
  const match = useRouteMatch<{ referralUsername: string | undefined }>();
  const history = useHistory();
  const [modalIsClosed, closeModal] = useState(false);
  const user = useSelector((state: State) => state.user);
  const dispatch = useDispatch();

  const linkUserToReferrer = async (username: string) => {
    // Should result in a special offer being claimed and an update to the user state
    const error = await dispatch(LinkUserToReferrer(username));

    if (error) {
      dispatch(SetError(error));
      history.replace('/');
    }
  };

  const isViewingOwnReferralLink =
    match.params.referralUsername === user.username;

  useEffect(() => {
    if (typeof match.params.referralUsername === 'string') {
      if (!isViewingOwnReferralLink) {
        linkUserToReferrer(match.params.referralUsername);
      }
    }
  }, [match.params.referralUsername, isViewingOwnReferralLink]);

  // Referrer will be undefined for a bit while the users are getting linked
  const referrer = isViewingOwnReferralLink ? user : user.referrer;

  const { popUp, ...experimentalProps } = useJoinLinksExperiment(referrer);

  function handleCTAClick(eventLocation: string) {
    dispatch(
      GetStarted({
        history: history,
        eventLocation: eventLocation,
        platform: isMobile() ? 'mobile' : 'desktop',
      })
    );
  }

  async function handleSubmitCountry(countryName: string) {
    dispatch(SetCountry(countryName));
    dispatch(SetCalculatorQuestionIndex(1));
    handleCTAClick('hero');
  }

  return (
    <>
      {popUp && !modalIsClosed && (
        <PromoDialog
          addendum=""
          ctaText="Continue"
          topImageUrl={staticImageUrl('mascot/wren-bird.png', { w: 250 })}
          onRequestClose={() => closeModal(true)}
          title="Welcome to Wren!"
          subtitle={`${
            referrer?.firstName || 'Your friend'
          } is inviting you to Wren. Subscribe and get your first month free.`}
        />
      )}
      <div css={{ background: 'white' }}>
        <LandingContent
          country={user?.country}
          onCtaClick={handleCTAClick}
          handleSubmitCountry={handleSubmitCountry}
          howItWorks={<HowItWorks />}
          {...experimentalProps}
        />
      </div>
    </>
  );
}
