/** @jsxImportSource @emotion/react */

import AnimateEnterAndExit, {
  AnimateEnterAndExitProps,
} from './AnimateEnterAndExit';

export default function SlideOut({
  durationMs: durationMs = 150,
  ...restOfProps
}: { durationMs?: number } & AnimateEnterAndExitProps) {
  return (
    <AnimateEnterAndExit
      animate={{
        keyframes: [
          { opacity: 0, clipPath: 'inset(0 0 100% 0)' },
          { opacity: 1, offset: 0.75 },
          { opacity: 1, clipPath: 'inset(0)' },
        ],
        options: { duration: durationMs },
      }}
      {...restOfProps}
    />
  );
}
