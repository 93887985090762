/** @jsxImportSource @emotion/react */

import { Helmet } from 'react-helmet';
import { scale } from '../util/scale';
import { withRouter } from 'react-router-dom';
import { Fragment } from 'react';
import Footer from '../components/frontfacing/Footer';

import {
  whiteArticle,
  articleContainer,
  articleContentContainer,
  serifParagraph,
} from '../style/article';
import HeroImage from '../components/frontfacing/HeroImage';
import Article from '../components/layout/Article';

import { fonts, pageWidth } from '../style/theme';
import OrderedList from '../components/UI/OrderedList';

const wrapper = scale({
  p: serifParagraph,
});

const reportingBackground = scale({
  width: ['100vw', '100vw', '100%', '100%'],
  backgroundColor: 'rgb(68, 157, 124)',
  marginLeft: ['-6%', '-12%', 0, 0],
});

const reportingSection = scale({
  fontFamily: `${fonts.Sans} !important`,
  width: ['90%', '80%', '100%'],
  margin: 'auto',
  marginTop: '42px',
  marginBottom: '62px',
  color: 'white',
  padding: '6px 42px 32px 42px',
  h2: {
    marginBottom: '12px',
  },
  p: {
    opacity: 0.8,
    color: 'white',
    lineHeight: '24px',
    width: '100%',
    maxWidth: ['80vw', '80vw', pageWidth],
    display: 'block !important',
  },
  li: {
    color: 'white !important',
    fontSize: '18px',
    marginBottom: '6px',
  },
  a: {
    color: 'white !important',
    textDecorationColor: 'rgba(265, 265, 265, 0.3) !important',
  },
});

const subHeaderStyles = scale({
  h2: {
    fontFamily: `${fonts.Sans} !important`,
    fontSize: '28px',
    lineHeight: '38px',
    marginTop: '42px',
    paddingBottom: 0,
  },
});

const tenetStyle = scale({
  width: '100%',
  marginTop: '8px',
  h2: {
    fontWeight: 600,
    lineHeight: 1.2,
  },
});

function CharterTenet({ tenet, items }) {
  return (
    <div css={tenetStyle}>
      <h2 css={{ marginBottom: 24 }}>{tenet}</h2>
      <OrderedList listStyleType="upper-alpha">
        {items.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </OrderedList>
    </div>
  );
}

const Charter = () => {
  const body = (
    <Fragment>
      <CharterTenet
        tenet="Wren's mission is to help reverse the climate crisis"
        items={[
          'Wren will offer tools, services, and resources that help people take action to reverse the climate crisis.',
          'Wren will strive to help other organizations working on reversing climate change even if they could be considered competitors.',
          'Wren recognizes that climate change is a multifaceted global problem and will solicit and consider input from a global audience across ages, genders, ethnicities, abilities, backgrounds, and identities.',
        ]}
      />
      <CharterTenet
        tenet="Wren will be radically transparent"
        items={[
          'Wren will strive to make its operations and plans transparent to anyone inside or outside the organization.',
          'Wren will publish ongoing financial and operating metrics showing our impact, efficiency, and effectiveness.',
        ]}
      />
      <CharterTenet
        tenet="Wren will rigorously evaluate its effectiveness"
        items={[
          'Wren will heavily vet the effectiveness and efficiency of any climate change solution it proposes to users.',
          'Wren will share its methodologies for evaluating climate change solutions publicly.',
        ]}
      />
      <CharterTenet
        tenet="Wren will be a role model for how all corporations should act"
        items={[
          'Wren will mitigate its environmental harm, and will always remove or offset at least twice the amount of carbon the organization and its employees are responsible for.',
          'Wren will take a stance on political issues that further its mission, but will not lobby or campaign purely for its own financial benefit.',
          'Wren will never sell user data to third parties. It will adamantly defend the privacy rights of its users and embrace new legislation that improves privacy.',
          'Wren will not use deceptive but legal tax management strategies to reduce its tax burden. It will be transparent in reporting the percentage of taxes it pays.',
          'Wren will report on team and leadership demographics, executive and CEO pay ratios, and programs and strategies employed to build a diverse, inclusive, and equitable organization.',
        ]}
      />
      <div css={reportingBackground}>
        <div css={reportingSection}>
          <h2>Reporting</h2>
          <p>
            Wren will deliver an annual report outlining its continuous
            commitments to the charter, in addition to ongoing public
            documentation:
          </p>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.notion.so/projectwren/f39023cc81034d2ca64d1fa184e685ad?v=80ee68408a4a4015a1d471485b860504"
                rel="noreferrer"
              >
                Public roadmap
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.notion.so/Wren-Receipt-Ledger-ce9131ad39ef42c3b52f7ea6943c8e18"
                rel="noreferrer"
              >
                Public receipts ledger
              </a>
            </li>
            <li>
              <a target="_blank" href="/impact">
                Impact live feed
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://projectwren.imgix.net/wren_climate_pbc.pdf"
                rel="noreferrer"
              >
                Public Benefit incorporation document
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/year-1-financial-report"
                rel="noreferrer"
              >
                Wren annual report (Y1)
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/wren-financial-report-year-two"
                rel="noreferrer"
              >
                Wren annual report (Y2)
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/wren-financial-report-year-three"
                rel="noreferrer"
              >
                Wren annual report (Y3)
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/wren-financial-report-year-four"
                rel="noreferrer"
              >
                Wren annual report (Y4)
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.wren.co/blog/wren-financial-report-year-five"
                rel="noreferrer"
              >
                Wren annual report (Y5)
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p>
        Special thanks to <i>Yancey Strickler</i>, our friend and mentor, for
        exposing us to what a 21st century company can and should look like.
      </p>
      <p>
        Thank you to Wren members{' '}
        <i>
          <a
            target="_blank"
            href="https://www.wren.co/profile/pratimamanga"
            rel="noreferrer"
          >
            Pratima
          </a>
          ,{' '}
          <a
            target="_blank"
            href="https://www.wren.co/profile/alistairbudge"
            rel="noreferrer"
          >
            Alastair
          </a>
          ,{' '}
          <a
            target="_blank"
            href="https://www.wren.co/profile/nateargroves"
            rel="noreferrer"
          >
            Nate
          </a>
          ,{' '}
          <a
            target="_blank"
            href="https://www.wren.co/profile/alexallain"
            rel="noreferrer"
          >
            Alex
          </a>
          ,{' '}
          <a
            target="_blank"
            href="https://www.wren.co/profile/michaelcoren"
            rel="noreferrer"
          >
            Michael
          </a>
        </i>
        , and{' '}
        <i>
          <a
            href="https://www.wren.co/profile/taylorlapeyre"
            target="_blank"
            rel="noreferrer"
          >
            Taylor
          </a>{' '}
        </i>
        (who joined Wren as our first engineer!) for providing valuable feedback
        on the first draft of our charter.
      </p>
      <br />
      <br />
    </Fragment>
  );
  return (
    <div style={{ 'overflow-x': 'hidden' }}>
      <Helmet>
        <title>Wren | Charter</title>
      </Helmet>
      <HeroImage
        imageUrl="charter-hero.jpeg"
        imageParams={{ h: 800 }}
        noPaddingBottom
      />

      <div css={articleContainer}>
        <div css={whiteArticle}>
          <div css={[articleContentContainer, wrapper, subHeaderStyles]}>
            <Article
              hideDateAndReadTime
              hideSocialIcons
              post={{
                injectHTML: false,
                title: "Wren's Charter",
                group: 'Mimi, Landon, & Ben',
                group_title: 'Wren Founders',
                authors: [
                  {
                    profile_image: 'aboutus-mimi.jpg',
                    name: 'Mimi Tran Zambetti',
                    title: 'Co-founder, Wren',
                  },
                  {
                    profile_image: 'small-landon.jpg',
                    name: 'Landon Brand',
                    title: 'Co-founder & CEO, Wren',
                  },
                  {
                    profile_image: 'aboutus-ben.jpg',
                    name: 'Ben Stanfield',
                    title: 'Co-founder, Wren',
                  },
                ],
                excerpt:
                  "We started Wren because as a planet, we aren't moving fast enough to end the climate crisis. The following legally binding charter outlines our commitments to the world and serves as a compass for every decision we make.",
                html: body,
              }}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(Charter);
