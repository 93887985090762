/** @jsxImportSource @emotion/react */

import Marquee from 'react-fast-marquee';
import { colors, spacing } from '../../style/theme';
import { staticImageUrl } from '../../util/images';
import { scale } from '../../util/scale';
import { Paragraph, Citation } from '../UI/TextStyles';

interface Endorsement {
  source: string;
  author: string;
  text: string;
  img?: string;
  title?: string;
  link?: string;
  css?: any;
  speed?: number;
  width?: number;
}

export const endorsements: Endorsement[] = [
  {
    source: 'article',
    author: 'New York Times',
    img: staticImageUrl('wren-press-nyt.png'),
    text: 'Wren asks a series of questions to determine how much carbon you emit.',
    link: 'https://www.nytimes.com/2021/07/16/your-money/fight-climate-change.html',
    css: { img: { maxWidth: 200, marginBottom: -2 } },
    speed: 6500,
  },
  {
    source: 'Trustpilot',
    author: 'Ann',
    img: staticImageUrl('ann-trustpilot.png'),
    title: 'Fighting the climate crisis is important',
    text: 'I really like how Wren contextualizes the offsets, and I look forward to seeing what new projects they start funding!',
  },
  {
    source: 'article',
    author: 'Wired Magazine',
    img: staticImageUrl('wren-press-wired.png'),
    text: 'Wren is making it easier for consumers to offset their carbon emissions.',
    link: 'https://www.wired.co.uk/article/net-zero-innovation-business',
    css: { img: { maxWidth: 135, marginBottom: 6 } },
  },
  {
    source: 'Trustpilot',
    author: 'Armin Samii',
    img: staticImageUrl('armin-samii.jpg', { w: 100 }),
    title: 'Seems incredibly transparent and realistic',
    text: 'Looked into other carbon offsets and felt they were just doing lip service [...] Wren feels real, with obvious, researchable, meaningful offsets.',
  },
  {
    source: 'Trustpilot',
    author: 'Ryan Dougherty',
    title: 'This is a hard industry to trust.',
    text: "I've been subscribed to Wren for over a year now and they have earned my trust. They are very transparent with where the money is going, and I receive constant updates on the projects I'm helping to fund. It seems like the real deal to me.",
  },
  {
    source: 'article',
    author: 'Treehugger',
    img: staticImageUrl('wren-press-treehugger.png'),
    text: 'Wren takes care of the vetting process, allowing you to pay a monthly subscription to cover real, tangible carbon offsets.',
    link: 'https://www.treehugger.com/eco-tech-awards-2021-5185022',
    css: { img: { maxWidth: 180 }, marginBottom: 2 },
  },
  {
    source: 'Trustpilot',
    author: 'Sally Rogers',
    img: staticImageUrl('sally-profile.jpeg', { w: 100 }),
    title: 'Feel really connected with where your dollar goes.',
    text: 'Wren has figured out the balance of offsetting your carbon emissions in both a simple and educational way. [...] Their project updates are awesome.',
  },
  {
    source: 'Trustpilot',
    author: 'Jhett Koo',
    title: 'Superb level of transparency',
    text: "I'm normally skeptical of carbon offsets but Wren is super legit. Superb level of transparency on the governance side, and well thought-out projects for maximum impact.",
  },
  {
    source: 'Trustpilot',
    author: 'Korey Krauskopf',
    text: 'Wren is very open with both their intention, their processes and their execution. Very impressed with this service over the past year, will not only continue to be part of it but will increase my contribution.',
  },
  {
    source: 'Trustpilot',
    author: 'Paul Yacoubian',
    img: staticImageUrl('investors/wren-paul-yacoubian.jpg', { w: 100 }),
    text: 'I reviewed about a dozen other carbon offset platforms out there, but none of the others gave me the level of transparency into the underlying projects as Wren. I’m a happy subscriber!',
  },
];

export default function EndorsementMarquee() {
  return (
    <Marquee gradient={false} pauseOnHover speed={40}>
      <div
        css={scale({
          display: 'flex',
          position: 'relative',
          justifyContent: 'flex-start',
          margin: '24px auto',
          '&:first-child': {
            marginLeft: [20, 24, 32],
          },
        })}
      >
        {endorsements.map((endorsement) => (
          <div
            css={scale({
              position: 'relative',
              width: '100%',
              maxWidth: [320, 340, 360],
              margin: `0px ${spacing.small}px`,
              ...endorsement.css,
            })}
          >
            {endorsement.source === 'article' ? (
              <img
                src={endorsement.img}
                alt="Wren Press"
                css={{
                  width: '100%',
                  marginBottom: '3px',
                  filter: 'grayscale(100%)',
                  maxWidth: '140px',
                  opacity: 0.8,
                  margin: 0,
                }}
              />
            ) : (
              <div css={{ color: colors.wrenOrange }}>★★★★★</div>
            )}
            <Paragraph
              weight={500}
              css={{
                color: colors.textPrimary,
                margin: `${spacing.xSmall}px 0px`,
                width: 300,
              }}
            >
              {endorsement.title}
            </Paragraph>
            <Citation
              css={{
                color: colors.textSecondary,
                display: 'block',
                margin: `${spacing.xxSmall}px 0px ${spacing.xSmall}px`,
              }}
            >
              {endorsement.text}
            </Citation>
            <Citation css={{ display: 'block', color: colors.gray7 }}>
              — {endorsement.author} via {endorsement.source}
            </Citation>
          </div>
        ))}
      </div>
    </Marquee>
  );
}
