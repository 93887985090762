/** @jsxImportSource @emotion/react */
import { useState, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SetToast, SetError } from '../../actions/toaster';
import { isMobile } from '../../lib/platform';
import { scale } from '../../util/scale';
import { getEmojiForCountryName } from '../../util/util';
import usePersistedState from '../../hooks/usePersistedState';

import * as Theme from '../../style/theme';

import ShareButtons from '../sharing/ShareButtons';
import CopyLink from '../sharing/CopyLink';

import getStore from '../../store';
import { TistAndRff } from '../frontfacing/IllustratedSteps';
import CardContent from '../UI/CardContent';
import EmailsInput from '../inputs/EmailsInput';
import { Headings, Paragraph } from '../UI/TextStyles';
import { ReferFriendsViaEmails } from '../../actions/user';
import EarnedReferralBonuses from '../../routes/DashboardV2/EarnedReferralBonuses';
import Fade from '../animation/Fade';

function referralLink(username) {
  return `${window.location.protocol}//www.wren.co/join/${username}`;
}

const buttonBar = scale({
  borderBottom: '1px solid var(--border-color)',
  marginBottom: 24,
  display: 'flex',
});

const toggleButton = (isSelected) =>
  scale({
    marginBottom: '-2px',
    padding: '4px 12px 12px 12px',
    fontSize: 16,
    transition: 'borderBottomColor 200ms ease-in-out, color 200ms ease-in-out',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontFamily: Theme.fonts.Sans,
    backgroundColor: 'transparent',
    borderBottom: '3px solid',
    borderBottomColor: isSelected ? 'var(--wren-green)' : 'transparent',
    color: isSelected ? 'var(--wren-green)' : 'var(--text-primary)',
    fontWeight: isSelected ? 500 : 400,
    display: 'flex',
    alignItems: 'center',
  });

const referredUser = {
  width: '100%',
  display: 'flex',
  fontWeight: 400,
  justifyContent: 'space-between',
  margin: '8px 0',
  textDecoration: 'none',
};

const notification = {
  backgroundColor: 'var(--error-red)',
  color: 'white',
  borderRadius: 8,
  minWidth: 16,
  height: 16,
  fontSize: '12px',
  fontWeight: 500,
  marginLeft: 4,
};

export const referralsTreeIcon = scale({
  width: 'fit-content',
  margin: 'auto',
  fontSize: 32,
  position: 'relative',
  marginBottom: 4,
  span: {
    fontSize: 15,
    color: 'var(--wren-green)',
    fontWeight: 700,
    position: 'absolute',
    top: 2,
    right: -36,
  },
});

export function ReferralsHeader({ children, className, referralBonus }) {
  return (
    <div css={{ textAlign: 'center' }} className={className}>
      <TistAndRff css={{ margin: '8px auto' }} />
      {children || (
        <Fragment>
          <Headings.Medium css={{ margin: '16px auto 0px' }}>
            Invite friends to Wren!
          </Headings.Medium>
          <Paragraph css={{ margin: 'auto', marginBottom: 8 }}>
            For every friend who subscribes, we’ll have {referralBonus} in
            partnership with Eden Reforestation.
          </Paragraph>
        </Fragment>
      )}
    </div>
  );
}
function Referrals(props) {
  const { user, className, id } = props;
  const [success, setSuccess] = useState(false);
  const [emailInvites, setEmailInvites] = usePersistedState(
    [],
    props.localStorageKey
  );
  const [panel, setPanel] = useState('share');

  const hasVisited = (user) => !user.completedOnboarding && !user.username;
  const hasCreatedAccount = (user) =>
    !user.completedOnboarding && user.username;
  // we don't get user.hasPassword as part of referredUser object
  const isSubscribed = (user) => user.completedOnboarding;

  async function handleSendInvites(emails) {
    await ReferFriendsViaEmails(emails);
    const emailsToAdd = emails.filter((email) => !emailInvites.includes(email));
    setEmailInvites([...emailInvites, ...emailsToAdd]);
    setSuccess(true);
  }

  return (
    <div className={className}>
      <CardContent
        id={id}
        css={{
          backgroundColor: 'white',
          borderRadius: 6,
          position: 'relative',
        }}
      >
        <ReferralsHeader referralBonus={user.referralBonusEmailDescription} />
      </CardContent>

      <CardContent css={{ paddingTop: 0 }}>
        <div css={buttonBar}>
          <button
            onClick={() => {
              setPanel('share');
              setSuccess(false);
            }}
            css={toggleButton(panel === 'share')}
          >
            Share
          </button>
          <button
            onClick={() => {
              setPanel('invitations');
              setSuccess(false);
            }}
            css={toggleButton(panel === 'invitations')}
          >
            Invitations
            <Fade durationMs={100}>
              {success && <div css={notification}>1</div>}
            </Fade>
          </button>
        </div>

        <Fade animationKey={panel} initial={false} durationMs={100}>
          <div>
            {panel === 'share' && (
              <Fragment>
                <div css={{ marginBottom: 24 }}>
                  <h4>Send email invites</h4>
                  <EmailsInput
                    onSend={handleSendInvites}
                    localStorageKey="invitedEmails"
                  />
                </div>
                <h4>Share your invite link</h4>
                <br />
                <CopyLink
                  isExpandedLink
                  url={referralLink(user.username)}
                  name="profile_referral_link"
                  onCopy={() => {
                    window.plausible('Copy Referral Link');
                  }}
                />
                <ShareButtons
                  css={{
                    position: 'relative',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gridGap: '4px',
                    maxWidth: '200px',
                    marginTop: 16,
                  }}
                  name="profile_tour"
                  text={`Climate change is a crisis — but we have solutions to start addressing it. I’m now funding a project on @project_wren to offset my own CO₂. ${user.referralBonusDescription}`}
                  url={`https://www.wren.co/join/${user.username}`}
                  iconStyle
                  socialsArr={
                    isMobile()
                      ? ['twitter', 'fb', 'messenger', 'sms', 'whats app']
                      : ['twitter', 'fb', 'linkedin', 'email', 'copy']
                  }
                />
              </Fragment>
            )}

            {panel === 'invitations' && (
              <Fragment>
                <EarnedReferralBonuses />
                {user.referredUsers.length === 0 &&
                  emailInvites.length === 0 && (
                    <p>Looks like you haven't referred anybody yet!</p>
                  )}
                {user.referredUsers.filter(isSubscribed).map((user) => (
                  <Link to={`/profile/${user.username}`} css={referredUser}>
                    <p>
                      {getEmojiForCountryName(user.country)}{' '}
                      {user.firstName && user.lastName
                        ? `${user.firstName} ${user.lastName}`
                        : user.email}
                    </p>
                    <p>Subscribed!</p>
                  </Link>
                ))}
                {user.referredUsers.filter(hasCreatedAccount).map((user) => (
                  <div css={referredUser}>
                    <p>
                      {getEmojiForCountryName(user.country)}{' '}
                      {user.firstName && user.lastName
                        ? `${user.firstName} ${user.lastName}`
                        : user.email}
                    </p>
                    <p>Created Account</p>
                  </div>
                ))}
                {emailInvites
                  .filter((email) => !user.referredUsers.includes(email))
                  .map((email) => (
                    <div css={referredUser}>
                      <p>{email}</p>
                      <p>Invited!</p>
                    </div>
                  ))}
                <p css={{ margin: '12px 0' }}>
                  + {user.referredUsers.filter(hasVisited).length} views to
                  referral link
                </p>
              </Fragment>
            )}
          </div>
        </Fade>
      </CardContent>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { SetToast, SetError, ReferFriendsViaEmails },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Referrals)
);

export function atFirstAppropriateReferralEvent(history, sendEvent) {
  function after3Minutes() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000 * 60 * 3);
    });
  }

  function whenUserLeavesPage() {
    return new Promise((resolve) => {
      window.onbeforeunload = () => {
        resolve();
      };
    });
  }

  function whenUserVisitsLogout() {
    return new Promise((resolve) => {
      history.listen(() => {
        if (history.location.pathname.includes('/logout')) {
          resolve();
        }
      });
    });
  }

  function whenUserUploadsPhoto() {
    const doesUserHavePhoto = () => {
      const { user } = getStore().getState();
      if (user.profileImageUrl) {
        return true;
      }
    };

    return new Promise((resolve) => {
      if (doesUserHavePhoto()) {
        resolve();
      }

      getStore().subscribe(() => {
        if (doesUserHavePhoto()) {
          resolve();
        }
      });
    });
  }

  Promise.race([
    after3Minutes(),
    whenUserLeavesPage(),
    whenUserVisitsLogout(),
    whenUserUploadsPhoto(),
  ]).then(sendEvent);
}
