/** @jsxImportSource @emotion/react */

import { formatNumber } from '../../lib/format';
import { scale } from '../../util/scale';
import Definition from '../tooltips/Definition';
import { getEmojiForCountryName } from '../../util/util';
import { capitalizeFirstCharacter } from '../../lib/format';
import { Citation } from './TextStyles';
import Flex from './Flex';

const supportersText = scale({
  fontSize: '14px',
  opacity: 0.7,
  marginLeft: '8px',
});

const circleMargin = (isSmall) =>
  scale({
    margin: isSmall ? '2px 0px' : '3px 0px',
  });

const orangeCircle = (isSmall, size) => {
  const widthAndHeight = (() => {
    if (size !== undefined) {
      return size;
    }

    if (isSmall) {
      return '24px';
    }

    return '30px';
  })();

  return scale({
    borderRadius: '100%',
    backgroundColor: 'rgba(0, 131, 108, 0.5)',
    position: 'relative',
    width: widthAndHeight,
    height: widthAndHeight,
    padding: '0',
    boxShadow: '0 0 0 2px white',
    textAlign: 'center',
    outline: 'none',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
};

const profile = (profileImage) =>
  scale({
    background: `url(${profileImage})`,
    backgroundImage: `url(${profileImage}?w=100&q=35&auto=compress,format)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  });

const UserWithFlag = ({ user, isSmall, className, size }) => {
  const firstLetter = capitalizeFirstCharacter(user.firstName)[0];
  const countryEmoji = getEmojiForCountryName(user.country) || '🎁';
  const emojiAndName = `${countryEmoji} ${user.firstName}`;
  return (
    <div css={circleMargin(isSmall)} key={user.firstName} className={className}>
      <Definition title={emojiAndName} plain small>
        <div
          css={[
            orangeCircle(isSmall, size),
            user.profileImageUrl && profile(user.profileImageUrl),
          ]}
        >
          {!user.profileImageUrl && (
            <p
              css={{
                color: 'white',
                fontSize: isSmall ? '14px' : '16px',
                fontWeight: '600',
                lineHeight: isSmall ? '24px' : '30px',
              }}
            >
              {firstLetter}
            </p>
          )}
        </div>
      </Definition>
    </div>
  );
};

export default function Supporters({
  isSmall,
  supporters,
  size,
  className,
  showAdditionalSupportersCircle = false,
  supporterType = 'subscribers',
}) {
  const recentSupporters = isSmall
    ? supporters.latestFourSupporters.slice(0, 3)
    : supporters.latestFourSupporters;

  const additionalSupporters =
    supporters.totalNumberOfSupporters - (isSmall ? 3 : 4);

  return (
    <Flex
      align="center"
      css={{
        width: 'fit-content',
        marginTop: isSmall ? '0' : '8px',
        marginBottom: isSmall ? '0' : '8px',
      }}
      className={className}
    >
      <Flex align="center">
        {recentSupporters.map((user, index) => (
          <UserWithFlag
            size={size}
            user={user}
            key={user.firstName}
            isSmall={isSmall}
            css={{ zIndex: recentSupporters.length - index }}
          />
        ))}
        {showAdditionalSupportersCircle && additionalSupporters > 0 && (
          <div css={circleMargin(isSmall)} className={className}>
            <div
              css={[
                orangeCircle(isSmall, size),
                { backgroundColor: 'var(--success-green)' },
              ]}
            >
              <Citation
                css={{ color: 'white', fontWeight: 500, marginBottom: 2 }}
              >
                +{additionalSupporters}
              </Citation>
            </div>
          </div>
        )}
      </Flex>
      {supporters.totalNumberOfSupporters > 0 &&
        !showAdditionalSupportersCircle && (
          <p css={supportersText}>
            {formatNumber(supporters.totalNumberOfSupporters)}{' '}
            {isSmall ? 'backers' : supporterType}
          </p>
        )}
    </Flex>
  );
}
