import invariant from '../lib/invariant';
import { Contentful } from '@wren/shared';
import { ContentfulClientApi } from 'contentful';

const { initializeClient } = Contentful;

let client: ContentfulClientApi<undefined> | null = null;

export default function useContentful() {
  const space =
    process.env.REACT_APP_CONTENTFUL_SPACE_ID ||
    process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
  invariant(
    space,
    'missing env REACT_APP_CONTENTFUL_SPACE_ID or NEXT_PUBLIC_CONTENTFUL_SPACE_ID'
  );

  const accessToken =
    process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ||
    process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
  invariant(
    accessToken,
    'missing env REACT_APP_CONTENTFUL_ACCESS_TOKEN or NEXT_PUBLIC_CONTENTFUL_SPACE_ID'
  );

  if (!client) {
    client = initializeClient({
      space,
      accessToken,
    });
  }

  return { client };
}
