/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';
import useInterval from '../../hooks/useInterval';
import Flex from '../UI/Flex';
import { paragraph } from '../../style/text';
import Fade from '../animation/Fade';

const nyt = staticImageUrl('wren-press-nyt.png');
const wired = staticImageUrl('wren-press-wired.png');
const forbes = staticImageUrl('wren-press-forbes-small.png');
const bloomberg = staticImageUrl('wren-press-bloomberg.png');
const techcrunch = staticImageUrl('wren-press-techcrunch.png');
const treehugger = staticImageUrl('wren-press-treehugger.png');

const newsContainer = scale({
  width: '100%',
  height: 128,
  display: 'flex',
  textAlign: 'center',
  paddingTop: 15,
  boxSizing: 'border-box',
  paddingBottom: 15,
  backgroundColor: 'var(--background-gray)',
});

interface Quote {
  img: string | undefined;
  text: string;
  articleLink: string;
  css: any;
  speed?: number;
  width?: number;
}

export const quotes: Quote[] = [
  {
    img: nyt,
    text: 'Wren asks a series of questions to determine how much carbon you emit.',
    articleLink:
      'https://www.nytimes.com/2021/07/16/your-money/fight-climate-change.html',
    css: { img: { maxWidth: 200, marginBottom: -2 } },
    speed: 6500,
  },
  {
    img: wired,
    text: 'Wren is making it easier for consumers to offset their carbon emissions.',
    articleLink: 'https://www.wired.co.uk/article/net-zero-innovation-business',
    css: { img: { maxWidth: 135, marginBottom: 6 } },
  },
  {
    img: forbes,
    width: 150,
    text: 'For less than $20 a month, Wren will offset your carbon footprint.',
    articleLink:
      'https://www.forbes.com/sites/haleykim/2019/08/23/ycombinator-climate-change-wren-this-startup-will-offset-your-carbon-footprint/',
    css: { img: { maxWidth: 90, marginBottom: 2 } },
  },
  {
    img: bloomberg,
    width: 150,
    text: 'Wren is one of a new breed of climate-centric startups for consumers.',
    articleLink:
      'https://www.bloomberg.com/news/articles/2019-09-02/startups-sell-spotify-like-apps-to-offset-your-carbon-footprint',
    css: { img: { maxWidth: 150 }, marginBottom: 2 },
  },
  {
    img: techcrunch,
    width: 150,
    text: 'Wren is aiming to make carbon offsets more consumer-friendly.',
    articleLink:
      'https://techcrunch.com/2019/07/07/y-combinator-backed-project-wren-is-aiming-to-make-carbon-offsets-more-consumer-friendly/',
    css: { img: { maxWidth: 180 }, marginBottom: 2 },
  },
  {
    img: treehugger,
    width: 150,
    text: 'Wren takes care of the vetting process, allowing you to pay a monthly subscription to cover real, tangible carbon offsets.',
    articleLink: 'https://www.treehugger.com/eco-tech-awards-2021-5185022',
    css: { img: { maxWidth: 180 }, marginBottom: 2 },
  },
];

function RotatingPressBox() {
  const [quoteIndex, setQuoteIndex] = useState(0);

  useInterval(() => {
    setQuoteIndex((current) => (current + 1) % 4);
  }, quotes[quoteIndex].speed || 3000);

  return (
    <Fade durationMs={300} animationKey={quoteIndex}>
      <PressQuote {...quotes[quoteIndex]} />
    </Fade>
  );
}

function PressBox({ className }: { className?: string }) {
  return (
    <div className={className} css={newsContainer}>
      {/* Mobile & Small desktop screens */}
      <div css={scale({ display: ['block', 'block', 'none'], margin: 'auto' })}>
        <RotatingPressBox />
      </div>

      {/* Desktop screens */}
      <Flex
        justify="space-around"
        align="center"
        css={scale({
          display: ['none', 'none', 'flex'],
          maxWidth: 1250,
          margin: 'auto',
        })}
      >
        {quotes.slice(0, 4).map((quote) => (
          <PressQuote key={quote.text} {...quote} />
        ))}
      </Flex>
    </div>
  );
}

export default PressBox;

const outletLogoContainer = (hideOnSmallerScreen?: boolean) =>
  scale({
    display: hideOnSmallerScreen
      ? ['none', 'none', 'none', 'inherit']
      : 'inherit',
    margin: ['auto', 'auto', 0, 0],
    img: {
      width: '100%',
      marginBottom: '3px',
      filter: 'grayscale(100%)',
      maxWidth: '140px',
      opacity: 0.8,
      margin: 0,
    },
    width: ['100%', '100%', '30%', '30%'],
    padding: '0px 12px',
    maxWidth: '250px',
    '&:hover': {
      p: {
        opacity: 1,
      },
      img: {
        opacity: 1,
        filter: 'grayscale(0)',
      },
    },
  });

const ultrasmall = scale({
  fontSize: '13px !important',
  lineHeight: '16px !important',
  maxWidth: '250px',
  opacity: 0.6,
  paddingTop: 2,
});

const noStyleLink = scale({
  fontWeight: 400,
  textDecoration: 'none !important',
});

type Props = Quote & { className?: string; hideOnSmallerScreen?: boolean };

function PressQuote(props: Props) {
  const { img, text, articleLink, hideOnSmallerScreen, className } = props;
  return (
    <div css={outletLogoContainer(hideOnSmallerScreen)} className={className}>
      <a
        href={articleLink}
        target="_blank"
        rel="noopener noreferrer"
        css={noStyleLink}
      >
        <img src={img} alt="Wren Press" />
        <p css={[paragraph, ultrasmall]}>&ldquo;{text}&rdquo;</p>
      </a>
    </div>
  );
}
