/** @jsxImportSource @emotion/react */

import { HTMLAttributes, ReactNode } from 'react';
import { scale } from '../../util/scale';
import styled from '@emotion/styled';

export type FlexProps = {
  align?: string;
  mobileAlign?: string;
  justify?: string;
  mobileJustify?: string;
  wrap?: boolean;
  inline?: boolean;
  direction?: string;
  mobileDirection?: string;
  gap?: number | string | (number | string)[];
} & HTMLAttributes<HTMLDivElement>;

const Flex = styled.div<FlexProps>(
  ({
    align,
    mobileAlign = align,
    justify,
    mobileJustify = justify,
    wrap,
    inline = false,
    direction = 'row',
    mobileDirection = direction,
    gap,
  }) =>
    scale({
      display: inline ? 'inline-flex' : 'flex',
      alignItems: [mobileAlign, mobileAlign, align, align],
      justifyContent: [mobileJustify, mobileJustify, justify, justify],
      flexDirection: [mobileDirection, mobileDirection, direction, direction],
      flexWrap: wrap === true ? 'wrap' : wrap,
      gap,
    })
);

export default Flex;
