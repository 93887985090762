// See https://stripe.com/pricing/local-payment-methods
function getPaymentMethodFeeStructure(
  paymentMethodType:
    | AcceptedPaymentMethodType
    | PreviouslyAcceptedPaymentMethodType
) {
  switch (paymentMethodType) {
    case 'card':
    case 'link':
      return {
        fixedUsdCents: 30,
        percent: 2.9 / 100,
        cappedUsdCents: undefined,
      };
    case 'sepa_debit':
      return {
        fixedUsdCents: 30,
        percent: 0.8 / 100,
        cappedUsdCents: 600,
      };
    case 'us_bank_account':
    case 'acss_debit':
      return {
        fixedUsdCents: 0,
        percent: 0.8 / 100,
        cappedUsdCents: 500,
      };
  }
}

function getCompleteFeeStructure(
  paymentMethodType:
    | AcceptedPaymentMethodType
    | PreviouslyAcceptedPaymentMethodType,
  isCurrencyConversionRequired: boolean
) {
  const method = getPaymentMethodFeeStructure(paymentMethodType);
  return {
    ...method,
    percent:
      method.percent +
      (isCurrencyConversionRequired && paymentMethodType !== 'sepa_debit' // We don't get charged for conversion on SEPA (not sure why)
        ? currencyConversionFeePercent
        : 0),
  };
}

const currencyConversionFeePercent = 1 / 100;

// Returns whole USD cents
export function calculateFeeFromGoal(
  goalAmountUsdCents: number, // The amount we want to receive in the Stripe balance transaction in USD cents
  paymentMethodType:
    | AcceptedPaymentMethodType
    | PreviouslyAcceptedPaymentMethodType,
  isCurrencyConversionRequired: boolean // When the user currency is anything but USD
) {
  if (goalAmountUsdCents < 0) {
    throw new Error('Cannot calculate fee for negative amount');
  }

  if (goalAmountUsdCents === 0) {
    return 0; // Stripe collects no fee on $0 invoices - there should be no charge
  }

  const fee = getCompleteFeeStructure(
    paymentMethodType,
    isCurrencyConversionRequired
  );

  // The basic formula is here: https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
  return Math.min(
    Math.round(
      // round vs. ceil was determined empirically
      (goalAmountUsdCents + fee.fixedUsdCents) / (1 - fee.percent) -
        goalAmountUsdCents
    ),
    fee.cappedUsdCents || Number.POSITIVE_INFINITY
  );
}

// Returns USD cents
// If a negative number is returned, the payment amount cannot even cover the fee
export function calculateFeeFromTotal(
  totalPaymentAmountUsdCents: number, // The total payment amount
  paymentMethodType:
    | AcceptedPaymentMethodType
    | PreviouslyAcceptedPaymentMethodType,
  isCurrencyConversionRequired: boolean
) {
  if (totalPaymentAmountUsdCents < 0) {
    throw new Error('Cannot calculate fee for negative amount');
  }
  const fee = getCompleteFeeStructure(
    paymentMethodType,
    isCurrencyConversionRequired
  );
  return Math.round(
    Math.min(
      fee.fixedUsdCents +
        fee.percent * (totalPaymentAmountUsdCents - fee.fixedUsdCents),
      fee.cappedUsdCents ?? Number.POSITIVE_INFINITY
    )
  );
}

export const ACCEPTED_STRIPE_PAYMENT_TYPE = {
  acss_debit: 'acss_debit' as const,
  card: 'card' as const,
  sepa_debit: 'sepa_debit' as const,
  us_bank_account: 'us_bank_account' as const,
};

export type AcceptedPaymentMethodType =
  keyof typeof ACCEPTED_STRIPE_PAYMENT_TYPE;

export const ACCEPTED_STRIPE_PAYMENT_TYPES = Object.keys(
  ACCEPTED_STRIPE_PAYMENT_TYPE
) as string[];

export function isAcceptedPaymentMethodType(
  type: string
): type is AcceptedPaymentMethodType {
  return ACCEPTED_STRIPE_PAYMENT_TYPES.includes(type);
}

export function hasAcceptedPaymentMethodType<
  T extends {
    type: string;
  }
>(paymentMethod: T): paymentMethod is T & { type: AcceptedPaymentMethodType } {
  return isAcceptedPaymentMethodType(paymentMethod.type);
}

export const PREVIOUSLY_ACCEPTED_PAYMENT_TYPE = {
  link: 'link' as const,
};

export type PreviouslyAcceptedPaymentMethodType =
  keyof typeof PREVIOUSLY_ACCEPTED_PAYMENT_TYPE;

export const PRODUCTION_STRIPE_PRODUCTS = {
  subscription: 'prod_MKvObzW6lq2aUC',
  'team-subscription': 'prod_OSjaya2hooHQat',
  'custom-duration': 'prod_MKvPFSDZsKXKxA',
  'transaction-fee': 'prod_NK2e5RTo6vUyJp',
  'subscription-transaction-fee': 'prod_NK3IgCjiJIaHfs',
  'adjusted-fee': 'prod_NKlTpgFYfkR9zB',
  'custom-duration-payment-plan': 'prod_OFGqacFrf0FAzh',
} as const;

export const DEVELOPMENT_STRIPE_PRODUCTS = {
  subscription: 'prod_MATV2y6RWiMNGJ',
  'team-subscription': 'prod_OSja3HShXSib49',
  'custom-duration': 'prod_MATPJa3eCifIij',
  'transaction-fee': 'prod_NK2fc6vgEP82jx',
  'subscription-transaction-fee': 'prod_NK3JPITuHTuPMA',
  'adjusted-fee': 'prod_NKkiCmMoSHkHjN',
  'custom-duration-payment-plan': 'prod_OFGp2SRNL9hw5R',
} as const;

export const TEAM_STRIPE_PRODUCT_REGEX = /^Project Wren • .* Team Offset$/;

export const STRIPE_PRODUCTS =
  process.env.NODE_ENV === 'production'
    ? PRODUCTION_STRIPE_PRODUCTS
    : DEVELOPMENT_STRIPE_PRODUCTS;

export function getSupportedPaymentTypes(
  currencyCode: string
): AcceptedPaymentMethodType[] {
  switch (currencyCode.toUpperCase()) {
    case 'CAD':
      return ['card', 'acss_debit'];
    case 'EUR':
      return ['card', 'sepa_debit'];
    case 'USD':
      return ['card', 'us_bank_account'];
    default:
      return ['card'];
  }
}
