/** @jsxImportSource @emotion/react */
import { Helmet } from 'react-helmet';

import { fadeIn } from '../../style/misc';
import { colors, spacing } from '../../style/theme';
import { scale } from '../../util/scale';
import CardContainer from '../UI/CardContainer';
import CardContent from '../UI/CardContent';
import HeaderBlock from '../UI/HeaderBlock';
import LoadingButton from '../buttons/LoadingButton';
import StripeSetupForm from './StripeSetupForm';
import { useEffect } from 'react';
import { State } from '../../store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const primaryCol = scale({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: ['center', 'center', 'center', 'space-around'],
  alignContent: ['center', 'center', 'center', 'space-around'],
  flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'row'],
  maxWidth: '900px',
  width: ['90%', '100%'],
  margin: 'auto',
  marginBottom: '50px',
  paddingTop: [spacing.xLarge, spacing.xLarge, spacing.medium],
  paddingBottom: [spacing.large, spacing.xLarge],
});

function UpdateTeamPaymentMethod() {
  const history = useHistory();
  const user = useSelector((state: State) => state.user);

  useEffect(() => {
    if (user.ownedTeams.length === 0) {
      history.replace('/');
    }
  }, [user]);

  return (
    <div>
      <Helmet>
        <title>Wren | Update Payment Method</title>
      </Helmet>
      <div css={primaryCol}>
        <div css={fadeIn()}>
          {
            <div>
              <HeaderBlock
                preamble="UPDATE INFO"
                header="Update your payment method"
                description="Enter your preferred credit card information with the card name to change the payment method for your next payment."
              />
              <CardContainer css={{ maxWidth: 400 }}>
                <CardContent>
                  <StripeSetupForm
                    forTeam
                    redirectTo="/success"
                    redirectQueryParams={{
                      finalPath: '/settings/team/subscription',
                    }}
                    button={
                      <LoadingButton
                        buttonType="filled"
                        color={colors.successGreen}
                        css={{ width: '100%' }}
                        size="medium"
                        shape="rounded rectangle"
                      >
                        Add payment method
                      </LoadingButton>
                    }
                    isCheckout={false}
                  />
                </CardContent>
              </CardContainer>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default UpdateTeamPaymentMethod;
