import { useEffect, useState } from 'react';

export default function useMediaQuery(
  query: string,
  defaultValue?: boolean // This value is used by SSR/SSG and first render - do not provide a default if the call is within an effect
): boolean {
  const getMatches = (query: string): boolean => {
    return window.matchMedia(query).matches;
  };

  const [matches, setMatches] = useState<boolean>(
    typeof defaultValue === 'undefined' ? getMatches(query) : defaultValue
  );

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    if (matchMedia.addEventListener) {
      matchMedia.addEventListener('change', handleChange);
    } else {
      matchMedia.addListener(handleChange); // Deprecated, but required for Safari < 14
    }

    return () => {
      if (matchMedia.removeEventListener) {
        matchMedia.removeEventListener('change', handleChange);
      } else {
        matchMedia.removeListener(handleChange); // Deprecated, but required for Safari < 14
      }
    };
  }, [query]);

  return matches;
}
