import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function TrailblazerLogo({
  color = colors.trailblazerOrange,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number; color?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...restOfProps}
    >
      <path
        fill="#EF473F"
        fillRule="evenodd"
        d="M2 12A10 10 0 0 1 12 2v1.7A8.3 8.3 0 0 0 3.7 12H2Zm10.5 10A10 10 0 0 0 22 12h-1.7c0 4.4-3.5 8-7.8 8.3V22Z"
        clipRule="evenodd"
      />
      <circle cx="18" cy="6" r="2.9" stroke="#EF473F" stroke-width="2" />
      <mask
        id="a"
        width="6"
        height="6"
        x="2.1"
        y="16.8"
        fill="#000"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M2.1 16.8h6v6h-6z" />
        <path
          fillRule="evenodd"
          d="M7.1 21.8c0-2.7-2.2-4.9-4.9-4.9v1.3c2 0 3.6 1.6 3.6 3.6H7Z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="#EF473F"
        fillRule="evenodd"
        d="M7.1 21.8c0-2.7-2.2-4.9-4.9-4.9v1.3c2 0 3.6 1.6 3.6 3.6H7Z"
        clipRule="evenodd"
      />
      <path
        fill="#EF473F"
        d="M7.1 21.8h.1-.1Zm-4.9-4.9Zm0 1.3v.1Zm3.6 3.6h-.1Zm1.4 0H7h.2Zm0 0a5 5 0 0 0-5-5v.2C5 17 7 19.1 7 21.8h.2Zm-5-4.9v1.3h.1V17h-.2Zm0 1.4c2 0 3.5 1.6 3.5 3.5h.2c0-2-1.7-3.6-3.7-3.6v.1Zm3.5 3.5h.2-.2Zm0 0h1.4v-.1H5.8v.2Z"
        mask="url(#a)"
      />
      <mask
        id="b"
        width="6"
        height="6"
        x="4.9"
        y="12.2"
        fill="#000"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M4.9 12.2h6v6h-6z" />
        <path
          fillRule="evenodd"
          d="M5.9 13.2c0 2.7 2.1 4.9 4.8 4.9v-1.4c-2 0-3.5-1.5-3.5-3.5H5.9Z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="#EF473F"
        fillRule="evenodd"
        d="M5.9 13.2c0 2.7 2.1 4.9 4.8 4.9v-1.4c-2 0-3.5-1.5-3.5-3.5H5.9Z"
        clipRule="evenodd"
      />
      <path
        fill="#EF473F"
        d="M5.9 13.2h-.1Zm4.8 4.9h.1Zm0-1.4h.1Zm-3.5-3.5h.1-.1Zm-1.4 0a5 5 0 0 0 5 5V18A4.8 4.8 0 0 1 6 13.2h-.2Zm5 4.9v-1.4h-.2v1.4h.2Zm0-1.5a3.4 3.4 0 0 1-3.5-3.4h-.2c0 2 1.6 3.6 3.6 3.6v-.2Zm-3.6-3.5H5.9v.2h1.3v-.2Z"
        mask="url(#b)"
      />
      <mask
        id="c"
        width="6"
        height="6"
        x="4.9"
        y="16.8"
        fill="#000"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M4.9 16.8h6v6h-6z" />
        <path
          fillRule="evenodd"
          d="M5.9 21.8c0-2.7 2.1-4.9 4.8-4.9v1.3c-2 0-3.5 1.6-3.5 3.6H5.9Z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="#EF473F"
        fillRule="evenodd"
        d="M5.9 21.8c0-2.7 2.1-4.9 4.8-4.9v1.3c-2 0-3.5 1.6-3.5 3.6H5.9Z"
        clipRule="evenodd"
      />
      <path
        fill="#EF473F"
        d="M5.9 21.8h-.1Zm4.8-4.9Zm0 0h.1Zm0 1.3h.1v.1Zm-3.5 3.6h.1-.1Zm-1.4 0H6h-.2Zm0 0a5 5 0 0 1 5-5v.2A4.8 4.8 0 0 0 6 21.8h-.2Zm5-5v.2-.2Zm0 .1v1.3h-.2V17h.2Zm0 1.4v-.1.1Zm0 0a3.4 3.4 0 0 0-3.5 3.5h-.2c0-2 1.6-3.6 3.6-3.6v.1Zm-3.5 3.5h-.2.2Zm-.1 0H5.9v-.1h1.3v.2Z"
        mask="url(#c)"
      />
      <mask
        id="d"
        width="6"
        height="6"
        x="2.1"
        y="12.2"
        fill="#000"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M2.1 12.2h6v6h-6z" />
        <path
          fillRule="evenodd"
          d="M7.1 13.2c0 2.7-2.2 4.9-4.9 4.9v-1.4c2 0 3.6-1.5 3.6-3.5H7Z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="#EF473F"
        fillRule="evenodd"
        d="M7.1 13.2c0 2.7-2.2 4.9-4.9 4.9v-1.4c2 0 3.6-1.5 3.6-3.5H7Z"
        clipRule="evenodd"
      />
      <path
        fill="#EF473F"
        d="M7.1 13.2h.1-.1Zm-4.9 4.9Zm0-1.4Zm3.6-3.5h-.1Zm1.4 0a5 5 0 0 1-5 5V18C5 18 7 15.8 7 13.2h.2Zm-5 4.9v-1.4h.1v1.4h-.2Zm0-1.5c2 0 3.5-1.5 3.5-3.4h.2c0 2-1.7 3.6-3.7 3.6v-.2Zm3.6-3.5H7v.2H5.8v-.2Z"
        mask="url(#d)"
      />
    </svg>
  );
}
