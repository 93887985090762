/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';

import { Link } from 'react-router-dom';

import { scale } from '../../util/scale';
import CardContent from '../UI/CardContent';
import text from '../../style/text';
import * as misc from '../../style/misc';
import Button from '../buttons/Button';
import { staticImageUrl } from '../../util/images';
import { capitalizeFirstCharacter } from '../../lib/format';

const wrenProfile = staticImageUrl('wren_profile.svg');

const CTACard = scale({
  backgroundColor: 'var(--wren-green)',
  width: '100%',
  textAlign: 'center',
  padding: ['20px 0px', '25px 0px', '30px 0px'],
});

const roundImage = (image) =>
  scale({
    position: 'relative',
    width: 64,
    height: 64,
    borderRadius: '50%',
    margin: 'auto',
    marginBottom: 20,
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'var(--background-gray)',
  });

const ctaHeader = [
  misc.maxWidth(600),
  text.mediumThinHeader,
  text.textColor('white'),
  misc.marginAuto,
];

const FooterCTA = (props) => {
  return (
    <div css={CTACard}>
      <CardContent>
        <Fragment>
          <div
            css={roundImage(props.referralCta.profileImageUrl || wrenProfile)}
          >
            <img
              src={staticImageUrl('gift-transparent.png')}
              css={{
                position: 'absolute',
                bottom: -4,
                right: -12,
                width: 50,
              }}
            />
          </div>
          <h2 css={ctaHeader}>
            {capitalizeFirstCharacter(
              props.referralCta.referralBonusDescription
            )}
          </h2>
          <Link to={`/join/${props.referralCta.username}`}>
            <Button
              text="Get started"
              size="large"
              type="wrenGreen"
              css={{ width: 'fit-content', marginTop: 16 }}
            />
          </Link>
        </Fragment>
      </CardContent>
    </div>
  );
};

export default FooterCTA;
