/** @jsxImportSource @emotion/react */

import { colors, fontSizes, spacing } from '../../style/theme';
import { SerializedUser } from '../../types';
import Flex from '../UI/Flex';
import ArrowDown from '../svgs/ArrowDown';
import { DropdownType } from './MobileNav';
import * as dropdownContent from '../../config/navDropdowns';
import { header } from './MobileNavDropdown';
import NewTag from '../UI/NewTag';
import CurrencyMenu from './CurrencyMenu';
import { getCurrencyObjFromCode } from '../../util/conversions';
import Link from '../UI/Link';
import { noStyleLink } from '../../style/text';

function NavLink({
  onClick,
  name,
  hasBottomBorder,
  hasArrow,
  isNew,
  to,
}: {
  onClick?: () => void;
  name: string;
  hasBottomBorder?: boolean;
  to?: string;
  hasArrow?: boolean;
  isNew?: boolean;
}) {
  return (
    <Link to={to ?? '#'} css={noStyleLink}>
      <Flex
        align="center"
        justify="space-between"
        onClick={onClick}
        css={{
          width: '100%',
          padding: `28px ${spacing.medium}px`,
          fontSize: fontSizes.paragraph,
          boxSizing: 'border-box',
          borderBottom: hasBottomBorder
            ? `1px solid ${colors.borderColor}`
            : 'none',
          cursor: 'pointer',
        }}
      >
        <p css={{ fontWeight: 600, color: colors.textPrimary }}>
          {name}
          {isNew && (
            <NewTag
              css={{
                marginLeft: spacing.xxSmall,
                backgroundColor: colors.wrenOrange,
                textTransform: 'none',
              }}
            />
          )}
        </p>
        {hasArrow && (
          <ArrowDown
            css={{ transform: 'rotate(-90deg)' }}
            size={12}
            color={colors.textSecondary}
          />
        )}
      </Flex>
    </Link>
  );
}
export default function MobileMainMenuNav({
  user,
  setDropdownType,
  onCurrencyChange,
}: {
  user?: SerializedUser;
  setDropdownType: (dropdownType: DropdownType) => void;
  onCurrencyChange: (currencyCode: string) => void;
}) {
  const userCurrencyObj = getCurrencyObjFromCode(user?.currency || 'USD');
  return (
    <div>
      <div>
        {user?.hasPassword && <div css={header}>Explore</div>}
        <NavLink
          name="Products"
          onClick={() => setDropdownType('Products')}
          hasBottomBorder
          hasArrow
        />
        <NavLink
          name="Resources"
          onClick={() => setDropdownType('Resources')}
          hasBottomBorder
          hasArrow
        />
        {!user?.team && (
          <NavLink
            name="For business"
            to="/teams"
            hasBottomBorder
            isNew
            onClick={() => setDropdownType('')}
          />
        )}
        {!user && (
          <NavLink
            name="Actions"
            to="/actions"
            hasBottomBorder
            onClick={() => setDropdownType('')}
          />
        )}
      </div>
      {user?.hasPassword ? (
        <>
          <div css={header}>Account</div>
          {dropdownContent.profile(user).map((section, idx) => (
            <div key={idx}>
              {section.links
                .filter((link) => link?.name !== 'Logout')
                .map((link, i) => {
                  if (!link) return;
                  return (
                    <NavLink
                      key={i}
                      name={link.name}
                      to={link.to}
                      hasBottomBorder
                      onClick={() => setDropdownType('')}
                    />
                  );
                })}
            </div>
          ))}
        </>
      ) : (
        <CurrencyMenu user={user} onCurrencyChange={onCurrencyChange}>
          <NavLink name={`Currency (${userCurrencyObj.code})`} />
        </CurrencyMenu>
      )}
    </div>
  );
}
