/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

LinkedinLogo.propTypes = {
  color: PropTypes.string,
};

LinkedinLogo.defaultProps = {
  color: undefined,
};

export default function LinkedinLogo({ color, size = 20 }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110 110"
    >
      <title>Linkedin</title>
      <path d="M30.3333 39H13V86.6667H30.3333V39Z" fill={color || 'black'} />
      <path
        d="M21.6667 30.3333C26.4531 30.3333 30.3333 26.4531 30.3333 21.6667C30.3333 16.8802 26.4531 13 21.6667 13C16.8802 13 13 16.8802 13 21.6667C13 26.4531 16.8802 30.3333 21.6667 30.3333Z"
        fill={color || 'black'}
      />
      <path
        d="M71.5 35.75C68.5791 35.758 65.6979 36.426 63.0715 37.7043C60.4452 38.9825 58.1417 40.8378 56.3333 43.1315V39H39V86.6667H56.3333V56.3333C56.3333 54.0348 57.2464 51.8304 58.8717 50.2051C60.4971 48.5798 62.7015 47.6667 65 47.6667C67.2985 47.6667 69.5029 48.5798 71.1283 50.2051C72.7536 51.8304 73.6667 54.0348 73.6667 56.3333V86.6667H91V55.25C91 50.0783 88.9455 45.1184 85.2886 41.4614C81.6316 37.8045 76.6717 35.75 71.5 35.75V35.75Z"
        fill={color || 'black'}
      />
    </svg>
  );
}
