import PropTypes from 'prop-types';

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const userPropType = PropTypes.shape({
  completedOnboarding: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  annualFootprint: PropTypes.number,
  userId: PropTypes.number,
  startDate: PropTypes.string,
  organizationId: PropTypes.number,
  organizationAdmin: PropTypes.bool,
  status: PropTypes.integer,
});

export const projectPropType = PropTypes.shape({
  projectId: PropTypes.number,
  costPerTon: PropTypes.number,
  name: PropTypes.string,
  cardTitle: PropTypes.string,
  cardDescription: PropTypes.string,
  type: PropTypes.string,
  link: PropTypes.string,
  location: PropTypes.string,
  image: PropTypes.string,
  default: PropTypes.bool,
  projectPartner: PropTypes.string,
  whySupport: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.object),
  hidden: PropTypes.bool,
});

export const projectDefaultProp = {
  projectId: 11,
  costPerTon: 16,
  name: 'Clean cooking fuel for refugees',
  cardTitle: 'Clean cooking fuel for refugees',
  cardDescription:
    'Provide clean cooking fuel for the 1.3MM refugees in Uganda, which means healthier lungs and less deforestation.',
  type: 'Clean Cookstoves',
  link: 'reparle',
  location: 'Uganda',
  image: 'https://i.imgur.com/9g7JfCO.jpg',
  body: [],
  default: false,
  tags: [
    {
      name: 'clean cookstoves',
      icon: 'cookstoves',
    },
    {
      name: 'Uganda',
      icon: 'location',
    },
  ],
  projectPartner: 'Mandulis Energy',
  whySupport:
    'Choose this project to provide clean energy to refugees and for more sustainable agriculture.',
  hidden: false,
};

export const locationPropType = PropTypes.shape({
  pathname: PropTypes.string,
  search: PropTypes.string,
});

export const userDefaultProp = {
  completedOnboarding: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  annualFootprint: undefined,
  userId: undefined,
  startDate: undefined,
  organizationId: undefined,
  organizationAdmin: undefined,
  status: undefined,
};

export const calculatorDefaultProp = {
  country: 'United States',
  countryAverage: 19.5,
  zipDefaults: {
    // AVG DIET //
    dietRedMeatPerWeek: 6,
    dietOtherProteinsPerWeek: 16,
    dietDairyPerWeek: 12,
    // AVG TRANSPORT //
    transportMethod: 'car, transitRail, bus, interCityRail',
    transportMilesDrivenPerWeek: 253.84615384615384,
    transportCarMpg: 22,
    transportCarIsElectric: 0,
    transportBusMilesPerWeek: 2.877760692307692,
    transportTransitRailMilesPerWeek: 3.5972008653846155,
    transportDomesticFlightsPerYear: 0,
    transportInternationalFlightsPerYear: 0,
    transportInterCityRailMilesPerYear: 37.410889,
    // AVG ELECTRICITY //
    electricityKwhPerMonth: 319.7442046666667,
    electricityPercentRenewable: 0,
    // AVG NATURAL GAS //
    naturalGasBurnedPerMonth: 19.69057666666667,
    naturalGasBurnedUnit: null,
    // AVG HOME //
    homeSquareFeet: 1591,
    // AVG GOODS //
    goodsFurnitureAppliancesDollarsSpentPerMonth: 17.595344083333334,
    goodsClothesDollarsSpentPerMonth: 13.453505666666667,
    goodsOtherDollarsSpentPerMonth: 31.342971333333335,
    // AVG SERVICES //
    servicesDollarsSpentPerMonth: 132.27904258333334,
    // MISC //
    zipcode: null,
    surveyResponseId: null,
    userId: null,
    annualFootprint: {
      dietTotal: 2.800765,
      transportTotal: 7.512601018163617,
      electricityTotal: 1.265784,
      naturalGasTotal: 1.292488,
      homeTotal: 3.73617,
      goodsTotal: 4.4394422851946675,
      servicesTotal: 4.6248380611102835,
      grandTotal: 0,
    },
  },
  annualFootprint: {
    dietTotal: 2.800765,
    transportTotal: 7.512601018163617,
    electricityTotal: 1.265784,
    naturalGasTotal: 1.292488,
    homeTotal: 3.73617,
    goodsTotal: 4.4394422851946675,
    servicesTotal: 4.6248380611102835,
    grandTotal: 0,
  },
  userAnswers: {
    // AVG DIET //
    dietRedMeatPerWeek: null,
    dietOtherProteinsPerWeek: null,
    dietDairyPerWeek: null,
    // AVG TRANSPORT //
    transportMethod: null,
    transportMilesDrivenPerWeek: null,
    transportCarMpg: null,
    transportCarIsElectric: null,
    transportBusMilesPerWeek: null,
    transportTransitRailMilesPerWeek: null,
    transportInterCityRailMilesPerYear: null,
    transportDomesticFlightsPerYear: null,
    transportInternationalFlightsPerYear: null,
    // AVG ELECTRICITY //
    electricityKwhPerMonth: null,
    electricityPercentRenewable: null,
    // AVG NATURAL GAS //
    naturalGasBurnedPerMonth: null,
    naturalGasBurnedUnit: null,
    // AVG HOME //
    homeSquareFeet: null,
    // AVG GOODS //
    goodsFurnitureAppliancesDollarsSpentPerMonth: null,
    goodsClothesDollarsSpentPerMonth: null,
    goodsOtherDollarsSpentPerMonth: null,
    // AVG SERVICES //
    servicesDollarsSpentPerMonth: null,
    // MISC //
    zipcode: null,
    surveyResponseId: null,
    userId: null,
  },
};

export const subscriptionPropType = PropTypes.shape({
  offsetPercentage: PropTypes.number,
  total: PropTypes.number,
});

export const subscriptionDefaultProp = {
  offsetPercentage: 0,
  total: 0,
};

export const calculatorPropType = PropTypes.shape({
  country: PropTypes.string,
  countryAverage: PropTypes.string,
  zipDefaults: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  annualFootprint: PropTypes.objectOf(PropTypes.number),
  userAnswers: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
});

export const projectPropTypes = PropTypes.shape({
  projectId: PropTypes.number,
  body: PropTypes.arrayOf(PropTypes.object),
  cardDescription: PropTypes.string,
  cardTitle: PropTypes.string,
  costPerTon: PropTypes.number,
  default: PropTypes.bool,
  image: PropTypes.string,
  link: PropTypes.string,
  location: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
});
