import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Fundraiser({
  color = colors.purple,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      {...restOfProps}
    >
      <path
        fill={color}
        d="M13.6 1c-.4 0-.8 0-1.3.2L7.8 3a3 3 0 0 0-1.2 1L4 7C3.4 8 3 9 3 10.2V14a3 3 0 0 0 2 2.8v.2a6 6 0 0 0 6 6.1 6 6 0 0 0 5.7-7.8l.7-.6c.5-.3 2.4-2 2.5-2.6.2-.6 1.1-3.1 1.1-6.3v-.5l-5.3-3.6c-.6-.4-1.3-.6-2-.6Zm-2 6.8c.4 0 .8 0 1.1.3.6.3 1.2 1 1.3 1.7v1.6l-.1.2-1.5 1.5-.8.8c-1 1-.9 2.4.1 3.3.6.5 1.5.6 2 .1l1.3-.8v.4a4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 2.7-3.8l2-2.2A6 6 0 0 0 7 12.4v-.5c0-.6.3-1.2.7-1.6l2.8-2.2c.4-.2.7-.3 1.1-.3Z"
      />
    </svg>
  );
}
