/** @jsxImportSource @emotion/react */

import { CSSInterpolation } from '@emotion/serialize';
import { HTMLAttributes } from 'react';
import AnonymousUser from '../svgs/AnonymousUser';

const container: CSSInterpolation = {
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
};

const avatarContainer = {
  marginRight: '4px',
};

function profileImage(img: string): CSSInterpolation {
  return {
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    width: 26,
    height: 26,
    borderRadius: 26,
  };
}

type Props = HTMLAttributes<HTMLDivElement> & {
  fill?: string;
  user: {
    profileImageUrl?: string | null;
  };
};

export default function UserCircleAvatar({
  fill = 'var(--gray-2)',
  user,
  ...props
}: Props) {
  // if user profile pic return that instead
  // ${profile.profileImageUrl}?auto=compress,format&w=200
  return (
    <div css={container} {...props}>
      {user.profileImageUrl ? (
        <div
          css={[
            avatarContainer,
            profileImage(`${user.profileImageUrl}?auto=compress,format&w=80`),
          ]}
        />
      ) : (
        <AnonymousUser size={26} css={avatarContainer} fill={fill} />
      )}
    </div>
  );
}
