/** @jsxImportSource @emotion/react */
import { useState, useRef, HTMLAttributes } from 'react';

import { scale } from '../../util/scale';
import {
  textStyle,
  smallLinkStyle,
  linkStyle,
  shareButtonStyle,
  smallShareButtonStyle,
  smallTextStyle,
  logoStyle,
  smallLogoStyle,
  icon,
} from '../../style/shareButtonStyles';
import Link from '../svgs/Link';
import Checkmark from '../svgs/Checkmark';
import track from '../../lib/events';
import { colors, fontSizes, fonts, spacing } from '../../style/theme';
import { Copy } from '../svgs/Copy';
import Flex from '../UI/Flex';
import { SetSuccessToast } from '../../actions/toaster';
import { useDispatch } from 'react-redux';

const hiddenInput = scale({
  opacity: '0',
  position: 'absolute',
  top: 0,
  left: 0,
  pointerEvents: 'none',
});

type CopyLinkProps = {
  url: string;
  name?: string;
  isExpandedLink?: boolean;
  noUtm?: boolean;
  size?: string;
  iconStyle?: boolean;
  className?: string;
  campaignName?: string;
  utmMedium?: string;
  onCopy?: () => void;
  textToCopy?: string;
} & HTMLAttributes<HTMLDivElement>;

const CopyLink = ({
  url,
  name,
  isExpandedLink = false,
  noUtm = false,
  size,
  iconStyle,
  className,
  campaignName = 'share',
  utmMedium = name,
  textToCopy = '',
  onCopy,
  ...restOfProps
}: CopyLinkProps) => {
  const [copied, setCopied] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();

  const urlWithUtm = (() => {
    if (noUtm) {
      return url;
    }

    if (url.includes('?')) {
      return url.concat(
        `&utm_campaign=${encodeURIComponent(
          campaignName || ''
        )}&utm_medium=${encodeURIComponent(utmMedium || '')}`
      );
    }
    return url.concat(
      `?utm_campaign=${encodeURIComponent(
        campaignName || ''
      )}&utm_medium=${encodeURIComponent(utmMedium || '')}`
    );
  })();

  const handleCopyLink = () => {
    if (!textAreaRef.current) return;

    textAreaRef.current.select();
    navigator.clipboard
      .writeText(textToCopy || textAreaRef.current.value)
      .then(() => {
        onCopy?.();
        track('Server: User Clicked Share Button', {
          name,
          source: 'copy link',
          utmMedium,
          campaignName,
        });
        setCopied(true);
        dispatch(SetSuccessToast('Link copied to clipboard'));
        setTimeout(() => {
          setCopied(false);
        }, 5000);
      });
  };

  if (isExpandedLink) {
    return (
      <Flex
        align="center"
        css={{
          width: '100%',
          border: `1px solid ${colors.borderColor}`,
          boxSizing: 'border-box',
          borderRadius: 4,
        }}
        className={className}
        {...restOfProps}
      >
        <input
          value={urlWithUtm}
          contentEditable={false}
          readOnly
          css={{
            border: 'none',
            flex: 1,
            padding: spacing.small,
            paddingRight: spacing.xxSmall,
            borderRadius: 8,
            textDecoration: 'underline',
            textUnderlineOffset: 3,
            fontWeight: 500,
            fontSize: fontSizes.paragraph,
            fontFamily: fonts.Sans,
            cursor: 'pointer',
            '&:focus': {
              outline: 'none',
            },
          }}
          onClick={() => handleCopyLink()}
          onCopy={() => {
            track('Server: User Clicked Share Button', {
              name,
              source: 'copy link',
              utmMedium,
              campaignName,
            });
          }}
        />
        <textarea
          ref={textAreaRef}
          value={urlWithUtm}
          id="link"
          css={hiddenInput}
          contentEditable={false}
          readOnly
        />
        <button
          type="button"
          css={{
            position: 'relative',
            cursor: 'pointer',
            backgroundColor: 'white',
            border: 'none',
            paddingTop: spacing.xSmall,
          }}
          onClick={() => handleCopyLink()}
        >
          <Copy />
        </button>
      </Flex>
    );
  }
  if (!isExpandedLink) {
    return (
      <div
        css={size === 'small' ? smallLinkStyle : linkStyle}
        className={className}
        onClick={() => handleCopyLink()}
        {...restOfProps}
      >
        <textarea
          ref={textAreaRef}
          //type="hidden"
          value={urlWithUtm}
          id="link"
          css={hiddenInput}
          contentEditable={false}
          readOnly
        />

        {iconStyle ? (
          <div css={icon}>
            {copied ? <Checkmark size={16} fill="black" /> : <Link size={16} />}
          </div>
        ) : (
          <button
            type="button"
            css={
              size === 'small'
                ? [smallTextStyle, smallShareButtonStyle]
                : [textStyle, shareButtonStyle]
            }
          >
            {copied ? (
              '✅'
            ) : (
              <div css={size === 'small' ? smallLogoStyle : logoStyle}>
                <Link size={16} fill="var(--gray-3)" />
              </div>
            )}
            <p css={size === 'small' ? smallTextStyle : textStyle}>
              {copied ? ' Done!' : '  Copy link'}
            </p>
          </button>
        )}
      </div>
    );
  }
  return null;
};

export default CopyLink;
