/** @jsxImportSource @emotion/react */

import { ReactNode } from 'react';
import { downloadableDocument } from '../../style/article';

export default function DownloadableDocumentLink({
  title,
  href,
  type,
}: {
  title: ReactNode;
  href: string;
  type: ReactNode;
}) {
  return (
    <div css={downloadableDocument}>
      <span>{type}</span>
      <p>{title}</p>
      <a target="_blank" href={href} rel="noreferrer">
        Download
      </a>
    </div>
  );
}
