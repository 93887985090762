/** @jsxImportSource @emotion/react */

import React, { HTMLAttributes, MouseEvent, ReactNode, useEffect } from 'react';
import { scale } from '../../util/scale';
import { colors, spacing } from '../../style/theme';
import Times from '../svgs/Times';
import zIndex from '../../lib/zIndex';
import { css, PropsOf } from '@emotion/react';
import HorizontalSeparator from './HorizontalSeparator';

const darkener = scale({
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  bottom: 0,
  left: 0,
  overflow: 'auto',
  overscrollBehavior: 'contain',
  padding: '5%',
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: zIndex('modalOverlay'),

  // hide the scrollbar
  scrollbarWidth: 'none',
  '-ms-overflow-style': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const container = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  minWidth: '100%',
  minHeight: 'calc(100% + 1px)', // This is a trick to activate the overscroll-behavior of its parent (the darkener), even when the modal doesn't overflow the viewport
});

const modalStyle = scale({
  backgroundColor: 'white',
  borderRadius: '6px',
  boxSizing: 'border-box',
  height: 'fit-content',
  maxWidth: '470px',
  padding: [
    `${spacing.xLarge}px ${spacing.large}px ${spacing.xLarge}px`,
    `${spacing.xLarge}px`,
  ],
  position: 'relative',
  textAlign: 'center',
});

// TODO: trap focus

export default function Popup({
  children,
  className,
  closeIcon = <Times color={colors.textPrimary} size={spacing.default} />,
  onRequestClose,
  backdropContent,
}: {
  children?: ReactNode;
  className?: string;
  closeIcon?: ReactNode;
  backdropContent?: ReactNode;
  onRequestClose: (event: MouseEvent | KeyboardEvent) => void;
}) {
  // Handle escape key
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onRequestClose(event);
      }
    };
    if (document && document.addEventListener) {
      document.addEventListener('keydown', handleKeydown);
      return () => document.removeEventListener('keydown', handleKeydown);
    }
  }, [onRequestClose]);

  return (
    <div
      css={darkener}
      onClick={(event) => {
        onRequestClose(event);
        event.stopPropagation();
      }}
    >
      <div css={container}>
        {backdropContent}
        <div
          className={className}
          css={modalStyle}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {children}
          <div
            css={{
              position: 'absolute',
              top: spacing.small,
              right: spacing.small,
            }}
          >
            {closeIcon && (
              <button
                css={{
                  border: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                  lineHeight: 0,
                  padding: spacing.small,
                }}
                onClick={(event) => onRequestClose(event)}
              >
                {closeIcon}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function PopupWithButtons({
  buttons,
  buttonAreaAttributes,
  children,
  contentAreaAttributes,
  ...restOfProps
}: {
  buttons: ReactNode;
  buttonAreaAttributes?: HTMLAttributes<HTMLDivElement>;
  contentAreaAttributes?: HTMLAttributes<HTMLDivElement>;
} & PropsOf<typeof Popup>) {
  return (
    <Popup
      css={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0 !important',
      }}
      {...restOfProps}
    >
      <div
        css={{ padding: spacing.default, paddingTop: spacing.medium }}
        {...contentAreaAttributes}
      >
        {children}
      </div>
      <HorizontalSeparator />
      <div
        css={{
          gap: spacing.default,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'right',
          padding: spacing.default,
        }}
        {...buttonAreaAttributes}
      >
        {buttons}
      </div>
    </Popup>
  );
}
