import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Calculator({
  color = colors.wrenOrange,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...restOfProps}
    >
      <path
        fill={color}
        d="M18.8 1H5.2C4 1 3 2 3 3.2v17.6C3 22 4 23 5.3 23h13.4c1.3 0 2.3-1 2.3-2.2V3.2C21 2 20 1 18.7 1Zm-1.2 6.6H6.4c-.6 0-1.2-.5-1.2-1.1V4.3c0-.6.6-1.1 1.2-1.1h11.2c.6 0 1.1.5 1.1 1.1v2.2c0 .6-.5 1.1-1 1.1Zm-1.1 13.2H12c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1h4.5c.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1Zm-7.9-1.1a1.1 1.1 0 0 1-2.3 0c0-.6.6-1.1 1.2-1.1s1.1.5 1.1 1.1Zm0-4.4a1.1 1.1 0 0 1-2.3 0c0-.6.6-1.1 1.2-1.1s1.1.5 1.1 1.1Zm0-4.4a1.1 1.1 0 0 1-2.3 0c0-.6.6-1.1 1.2-1.1s1.1.5 1.1 1.1Zm4.5 4.4a1.1 1.1 0 0 1-2.3 0c0-.6.6-1.1 1.2-1.1s1.1.5 1.1 1.1Zm0-4.4a1.1 1.1 0 0 1-2.3 0c0-.6.6-1.1 1.2-1.1s1.1.5 1.1 1.1Zm4.5 4.4a1.1 1.1 0 0 1-2.3 0c0-.6.6-1.1 1.2-1.1s1.1.5 1.1 1.1Zm0-4.4a1.1 1.1 0 0 1-2.3 0c0-.6.6-1.1 1.2-1.1s1.1.5 1.1 1.1Z"
      />
    </svg>
  );
}
