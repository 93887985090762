import { GetUserInfo } from './user';
import { Network } from '@wren/shared';
import { Dispatch } from '../store';

export function RecordExperience(props: {
  name: string;
  progress?: number;
  completed: boolean;
}) {
  const { name, progress, completed } = props;
  return async (dispatch: Dispatch) => {
    const [response] = await Network.post(`experiences/${name}`, {
      progress, // optional
      completed,
    });

    if (response.ok) {
      return dispatch(GetUserInfo());
    }
  };
}
