/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { isMobile } from '../../lib/platform';
import { ClaimSpecialOffer } from '../../actions/user';

import SuspenseFallback from '../../components/SuspenseFallback';
import { SpecialOfferTokensThatBetterBeInTheDb } from '../../config/constants';
import { Redirect, useRouteMatch } from 'react-router-dom';

export default function SpecialOfferLanding() {
  const match = useRouteMatch();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [cleanedToken, setCleanedToken] = useState(
    match.params.token.replace(/["']/g, '').toLowerCase()
  );

  const claim = async (token) => {
    const error = await dispatch(ClaimSpecialOffer(token));

    if (error) {
      setCleanedToken(SpecialOfferTokensThatBetterBeInTheDb.FALLBACK_BONUS);
    }
  };

  useEffect(() => {
    claim(cleanedToken);
  }, [cleanedToken]);

  const specialOffer = user.specialOfferClaims.find(
    (claim) => claim.specialOffer.token.toLowerCase() === cleanedToken
  )?.specialOffer;

  if (user.completedOnboarding) {
    return <Redirect to="/dashboard" />;
  }

  if (!specialOffer) {
    return (
      <div css={{ paddingTop: 100 }}>
        <SuspenseFallback show />
      </div>
    );
  }

  return isMobile() ? (
    <Mobile specialOffer={specialOffer} />
  ) : (
    <Desktop specialOffer={specialOffer} />
  );
}
