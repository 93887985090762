import subYears from 'date-fns/subYears';
import startOfYear from 'date-fns/startOfYear';
import invariant from './invariant';
import sumBy from 'lodash/sumBy';
import min from 'lodash/min';

export type OffsetMonth = {
  year: number;
  month: number;
  offsetTons: number;
  footprintTons: number;
  isIncludedInPaymentPlan: boolean;
};

export type CustomDurationPurchaseIntent =
  | {
      type: 'year';
      year: number;
    }
  | {
      type: 'month';
      month: number;
      year: number;
    }
  | {
      type: 'decade';
      decade: number;
    }
  | {
      type: 'life';
      birthdate: string;
    };

export interface CustomDurationAddOnDetails {
  type?: // All caps type keys are legacy, we now use intent types
  'THIS_YEAR_SO_FAR' | 'PAST_YEAR' | 'PAST_DECADE' | 'LIFE' | 'CUSTOM_DURATION';
  startDate?: string;
  endDate?: string;
  intent?: CustomDurationPurchaseIntent;
}

export function isInTheFuture(month: number, year: number) {
  const today = new Date();

  return (
    year > today.getFullYear() ||
    (year === today.getFullYear() && month > today.getMonth())
  );
}

export function splitDateRangeIntoMonthRanges(start: Date, end: Date) {
  const months: {
    year: number;
    month: number;
    startDate: Date;
    endDate: Date;
  }[] = [];

  let currentDate = new Date(
    Date.UTC(start.getUTCFullYear(), start.getUTCMonth(), 1)
  );

  while (currentDate < end) {
    const endOfTheMonth = new Date(
      Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 1)
    );

    months.push({
      year: currentDate.getUTCFullYear(),
      month: currentDate.getUTCMonth(),
      startDate: currentDate,
      endDate: endOfTheMonth,
    });

    currentDate = endOfTheMonth;
  }

  return months;
}

export function getDurationOffsetTemplatesForAddOn(
  addOnDetails: CustomDurationAddOnDetails,
  annualFootprint: number,
  existingDurationOffsets: OffsetMonth[]
) {
  const [startDate, endDate] = addOnDetails.intent
    ? getDateRangeFromIntent(addOnDetails.intent)
    : addOnDetails.startDate && addOnDetails.endDate
    ? [new Date(addOnDetails.startDate), new Date(addOnDetails.endDate)]
    : calculateDateRangeForAddOn(addOnDetails);
  const months = splitDateRangeIntoMonthRanges(startDate, endDate);

  // TODO: track tonsToDisperse and throw error if there is a lot left over

  return months.map(({ month, year, startDate, endDate }) => {
    const data = existingDurationOffsets.find(
      (monthData) => monthData.month === month && monthData.year === year
    );

    // `data` will often be defined even for months where the user was not a member on wren,
    // but the offsetTons may be 0.
    const footprintTons = data?.footprintTons || annualFootprint / 12;
    const offsetTons = data?.offsetTons || 0;

    return {
      start_date: startDate,
      end_date: endDate,
      tons: Math.max(footprintTons - offsetTons, 0),
    };
  });
}

export function getDateRangeFromIntent(
  intent: CustomDurationPurchaseIntent & Record<string, unknown>
): [Date, Date] {
  const thisMonth = new Date(Date.now()).getUTCMonth();
  const thisYear = new Date(Date.now()).getUTCFullYear();
  const beginningOfThisMonth = new Date(Date.UTC(thisYear, thisMonth, 1));

  switch (intent.type) {
    case 'decade':
      return [
        new Date(Date.UTC(intent.decade, 0, 1)),
        new Date(
          Math.min(
            Date.UTC(intent.decade + 10, 0, 1),
            beginningOfThisMonth.getTime()
          )
        ),
      ];
    case 'year': {
      return [
        new Date(Date.UTC(intent.year, 0, 1)),
        new Date(
          Math.min(
            Date.UTC(intent.year + 1, 0, 1),
            beginningOfThisMonth.getTime()
          )
        ),
      ];
    }
    case 'month':
      const start = new Date(Date.UTC(intent.year, intent.month, 1));
      const endDate = new Date(Date.UTC(intent.year, intent.month + 1, 1));
      return [start, endDate];
    case 'life':
      return [new Date(intent.birthdate), beginningOfThisMonth];
  }
}

export function calculateDateRangeForAddOn(
  addOn: CustomDurationAddOnDetails,
  endDate?: Date
): [Date, Date] {
  let startDate: Date = new Date();
  endDate ||= new Date();
  const thisMonth = new Date(Date.now()).getUTCMonth();
  const thisYear = new Date(Date.now()).getUTCFullYear();
  const beginningOfThisMonth = new Date(Date.UTC(thisYear, thisMonth, 1));

  switch (addOn.type) {
    case 'PAST_YEAR':
      endDate ||= beginningOfThisMonth;
      startDate = subYears(endDate, 1);
      break;
    case 'PAST_DECADE':
      endDate ||= beginningOfThisMonth;
      startDate = subYears(endDate, 10);
      break;
    case 'THIS_YEAR_SO_FAR':
      endDate ||= beginningOfThisMonth;
      startDate = startOfYear(endDate);
      break;
    case 'LIFE':
      endDate ||= beginningOfThisMonth;
      invariant(addOn.startDate, 'LIFE add-on without startDate');
      startDate = new Date(addOn.startDate);
      break;
    case 'CUSTOM_DURATION':
      invariant(addOn.intent, 'CUSTOM_DURATION offset without intent');
      [startDate, endDate] = getDateRangeFromIntent(addOn.intent);
      break;
  }

  return [startDate, endDate];
}

export function getTonsToOffsetByIntent(
  intent: CustomDurationPurchaseIntent,
  annualFootprint: number,
  existingOffsets: OffsetMonth[]
) {
  const templates = getDurationOffsetTemplatesForAddOn(
    { type: 'CUSTOM_DURATION', intent },
    annualFootprint,
    existingOffsets
  );

  return sumBy(templates, 'tons');
}

export function startOfUtcDay(d: Date) {
  const copy = new Date(d);
  copy.setUTCHours(0);
  copy.setUTCMinutes(0);
  copy.setUTCSeconds(0);
  copy.setUTCMilliseconds(0);
  return copy;
}

export function startOfUtcMonth(d: Date) {
  const output = startOfUtcDay(d);
  output.setUTCDate(1);
  return output;
}

export const MIN_YEAR = 1900;
export const MIN_DATE = Date.UTC(-271821, 3, 21);
export const MAX_DATE = new Date(8640000000000000);

function clamp(x: number) {
  if (x < 0) {
    return 0;
  }
  if (x > 1) {
    return 1;
  }
  return x;
}

// Returns the rate at which the `base` is overlapped by the `period`: 1 is totally, 0 is not at all. 0.5 is half the base
export function getRateOfOverlap(
  base: { start: number; end: number },
  period: { start: number; end: number }
) {
  const baseDuration = base.end - base.start;
  // "relative" time: less than zero is before offset, 0 is beginning of offset, 1 is end of offset, > 1 is after
  const relativeStart = (period.start - base.start) / baseDuration;
  const relativeEnd = (period.end - base.start) / baseDuration;
  const overlapStart = clamp(relativeStart);
  const overlapEnd = clamp(relativeEnd);
  return overlapEnd - overlapStart;
}

export function isBoundaryOfMonth(date: Date) {
  return (
    date.getUTCDate() === 1 &&
    date.getUTCHours() === 0 &&
    date.getUTCMinutes() === 0 &&
    date.getUTCSeconds() === 0 &&
    date.getUTCMilliseconds() === 0
  );
}

export function getStartOfMonth(date: Date) {
  const start = new Date(date);
  start.setUTCDate(1);
  start.setUTCHours(0);
  start.setUTCMinutes(0);
  start.setUTCSeconds(0);
  start.setUTCMilliseconds(0);
  return start;
}

// Returns the end of the month, or the same time if it is already the end of a month
export function getEndOfMonth(date: Date) {
  const end = getStartOfMonth(date);
  if (end.getTime() === date.getTime()) {
    // The date is already the end of a month
    return end;
  }
  end.setUTCMonth(end.getUTCMonth() + 1);
  return end;
}

function getLengthOfMonth(date: Date) {
  const start = getStartOfMonth(date);
  const end = new Date(new Date(start).setUTCMonth(start.getUTCMonth() + 1));
  return end.getTime() - start.getTime();
}

function getElapsedTimeInMonth(date: Date) {
  return date.getTime() - getStartOfMonth(date).getTime();
}

export function getOffsetPeriodEndDate(
  start: Date,
  numberOfOffsetYears: number
): Date {
  const lengthOfStartMonth = getLengthOfMonth(start);
  const remainingTimeInStartMonth =
    lengthOfStartMonth - getElapsedTimeInMonth(start);
  const remainingOffsetMonthsInStartMonth =
    remainingTimeInStartMonth / lengthOfStartMonth;

  if (remainingOffsetMonthsInStartMonth >= numberOfOffsetYears * 12) {
    // we're ending in the first month
    return new Date(
      Math.round(
        start.getTime() + numberOfOffsetYears * 12 * lengthOfStartMonth
      )
    );
  }

  numberOfOffsetYears -= remainingOffsetMonthsInStartMonth / 12;

  return getOffsetPeriodEndDate(
    new Date(Math.round(start.getTime() + remainingTimeInStartMonth)),
    numberOfOffsetYears
  );
}

export function getOffsetYears(start: Date, end: Date) {
  if (start.getTime() === end.getTime()) {
    return 0;
  }

  const beginningOfWholeMonths = getEndOfMonth(start);
  const endOfWholeMonths = getStartOfMonth(end);
  const durationOfWholeMonths =
    endOfWholeMonths.getTime() - beginningOfWholeMonths.getTime();

  if (durationOfWholeMonths < 0) {
    // start and end are in the same month
    // no whole months, and only one partial month
    return (end.getTime() - start.getTime()) / getLengthOfMonth(start);
  }

  const numberOfWholeOffsetMonths =
    durationOfWholeMonths === 0
      ? 0
      : (endOfWholeMonths.getUTCFullYear() -
          beginningOfWholeMonths.getUTCFullYear()) *
          12 +
        endOfWholeMonths.getUTCMonth() -
        beginningOfWholeMonths.getUTCMonth();

  // Because of the early return above, we know that the start and end are in different months, so each represents a possible partial month
  let partialOffsetMonths = 0;
  if (!isBoundaryOfMonth(start)) {
    partialOffsetMonths +=
      (getEndOfMonth(start).getTime() - start.getTime()) /
      getLengthOfMonth(start);
  }
  if (!isBoundaryOfMonth(end)) {
    partialOffsetMonths +=
      (end.getTime() - getStartOfMonth(end).getTime()) / getLengthOfMonth(end);
  }

  return (numberOfWholeOffsetMonths + partialOffsetMonths) / 12;
}

export function getTimes({ start, end }: { start: Date; end: Date }) {
  return {
    start: start.getTime(),
    end: end.getTime(),
  };
}

// This is useful because when we get duration offsets, it is implied that the initial footprint applies into the past indefinitely
export function getInitialAnnualFootprintFromDurations(data: OffsetMonth[]) {
  const firstYear = min(data.map(({ year }) => year));
  const firstYearOfData = data.filter(({ year }) => year === firstYear);
  const firstMonth = min(firstYearOfData.map(({ month }) => month)); // This will probably be 0, but this works for partial years as well
  const firstDataPoint = data.find(
    ({ year, month }) => year === firstYear && month === firstMonth
  );

  return (firstDataPoint?.footprintTons ?? 0) * 12;
}
