import { getSessionToken } from './session';

const API_URL = process.env.REACT_APP_API_URL;

export type ResponseTuple<ResponseType> = [Response, ResponseType | null];

function generateHeaders() {
  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-Page-Url': window.location.href,
  });

  const token = getSessionToken();

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  // Verifes that the request is coming from Wren's web app
  if (process.env.REACT_APP_VERIFICATION_TOKEN) {
    headers.set('Verification-Token', process.env.REACT_APP_VERIFICATION_TOKEN);
  }

  return headers;
}

async function makeRequest<ResponseType>(
  request: Request
): Promise<ResponseTuple<ResponseType>> {
  const response = await fetch(request);

  if (response.status === 401) {
    window.location.href = `/logout?redirectTo=${encodeURIComponent(
      window.location.href
    )}`;

    return [response, null];
  }

  const contentType = response.headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    const responseBody = await response.json();
    return [response, responseBody as ResponseType];
  }

  return [response, null];
}

export async function post<ResponseType>(endpoint: string, body?: unknown) {
  const request = new Request(`${API_URL}/${endpoint}`, {
    method: 'POST',
    headers: generateHeaders(),
    body: JSON.stringify(body),
  });

  return makeRequest<ResponseType>(request);
}

export async function put<ResponseType>(endpoint: string, body?: unknown) {
  const request = new Request(`${API_URL}/${endpoint}`, {
    method: 'PUT',
    headers: generateHeaders(),
    body: JSON.stringify(body),
  });

  return makeRequest<ResponseType>(request);
}

type GetParams = { [key: string]: string };

export async function get<ResponseType>(
  endpoint: string,
  params: GetParams = {}
): Promise<ResponseTuple<ResponseType>> {
  const url = `${API_URL}/${endpoint}`;
  const searchParams = new URLSearchParams();

  for (const key in params) {
    searchParams.append(key, params[key]);
  }

  const request = new Request(
    `${url}${
      Object.keys(params).length > 0 ? `?${searchParams.toString()}` : ''
    }`,
    {
      method: 'GET',
      headers: generateHeaders(),
    }
  );

  return makeRequest<ResponseType>(request);
}

export async function httpDelete<ResponseType>(
  endpoint: string,
  body?: unknown
): Promise<ResponseTuple<ResponseType>> {
  const request = new Request(`${API_URL}/${endpoint}`, {
    method: 'DELETE',
    headers: generateHeaders(),
    body: JSON.stringify(body),
  });

  return makeRequest<ResponseType>(request);
}

export default {
  get,
  post,
  httpDelete,
};
