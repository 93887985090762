/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { scale, sidebarScale } from '../util/scale';
import * as fontStyles from './fontStyles';
import { fonts, oldFontSizes, pageWidth } from './theme';
import zIndex from '../lib/zIndex';

export const linkStyle = scale({
  fontWeight: 'normal',
  color: 'var(--link-blue)',
  textDecorationColor: 'rgba(50, 104, 145, 0.3)',
  '&:hover': {
    textDecorationColor: 'rgba(50, 104, 145, 0.6)',
  },
});

export const serifParagraph = scale({
  fontFamily: 'Blanco, serif !important',
  fontSize: '20px !important',
  lineHeight: 1.6,
  color: 'var(--text-primary)',
  marginBottom: '12px',
  'p:last-child': {
    marginBottom: '20px',
  },
  a: linkStyle,
});

export const closingGlyph = {
  '&::after': {
    content: '""',
    display: 'inline-block',
    marginLeft: '6px',
    marginBottom: '2px',
    width: '8px',
    height: '8px',
    backgroundColor: 'var(--wren-orange)',
  },
};

export const statsTitle = scale({
  marginBottom: -20,
  fontWeight: 600,
});

export const articleStyles = scale({
  p: {
    fontFamily: 'Blanco, Georgia, serif',
    fontSize: '20px',
    lineHeight: ['32px', '32px'],
    color: 'var(--text-primary)',
    marginBottom: '12px',
    // TODO: Add this back when it's easily configurable
    // '&:first-child:first-letter': {
    //   float: 'left',
    //   fontSize: 75,
    //   fontWeight: 700,
    //   lineHeight: 0.8,
    //   paddingRight: spacing.small,
    //   paddingBottom: spacing.xxSmall,
    // },
  },
  h3: {
    marginTop: '38px',
    marginBottom: '6px',
    fontSize: '24px',
    fontWeight: 600,
  },
  h2: {
    fontFamily: fonts.Sans,
    padding: 0,
    marginTop: '36px',
    marginBottom: '8px',
    fontSize: '28px',
    lineHeight: '140%',
    fontWeight: 600,
    '&::before': {
      display: 'block',
      content: '""',
      marginTop: '-80px',
      height: '80px',
      visibility: 'hidden',
      pointerEvents: 'none',
    },
    '&:target': {
      textDecoration: 'underline',
    },
  },
  // normally heading small is asigned to h3, but articleStyles has other plans for h3
  h4: [fontStyles.headingMedium, { fontWeight: 500 }],
  li: [serifParagraph, { marginBottom: '8px' }],
  hr: {
    border: 'none',
    height: 1,
    backgroundColor: '#ddd',
    marginTop: '32px',
    marginBottom: '32px',
  },
  figure: {
    margin: '40px 0px 20px 0px',
    img: {
      width: ['100vw', '100vw', '100%', '100%'],
      marginLeft: ['-6%', '-5.5%', 0, 0],
      height: 'auto',
    },
  },
  figcaption: {
    opacity: 0.7,
    textAlign: ['left', 'left', 'center', 'center'],
    padding: '8px 6px 12px 6px',
    lineHeight: 1.4,
    borderBottom: ['none', '2px solid var(--gray-9)'],
    fontSize: '14px',
  },
  aside: {
    margin: '24px 0',
    '*': {
      fontSize: '18px !important',
      lineHeight: '26px',
      fontFamily: `${fonts.Sans} !important`,
    },
    h4: {
      fontWeight: 600,
      fontSize: '20px !important',
      lineHeight: '32px',
    },
    'ul, ol': {
      marginTop: 16,
    },
    backgroundColor: 'var(--background-gray)',
    padding: '20px',
    borderRadius: 4,
  },
  iframe: {
    borderStyle: 'none',
    width: '100%',
    maxHeight: ['300px', '300px', 'none', 'none'],
    minHeight: '300px',
    backgroundColor: 'var(--background-gray)',
    marginTop: '12px',
    marginBottom: '18px',
  },
  'p:last-of-type': {
    display: 'inline',
  },
  button: {
    fontFamily: fonts.Sans,
    backgroundColor: 'var(--success-green)',
    padding: 12,
    color: 'white',
    border: 'none',
    display: 'block',
    marginTop: 12,
    marginBottom: 12,
    fontSize: 16,
    borderRadius: 4,
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: 'var(--success-green-hover)',
    },
  },
  '.hr-faded': {
    border: '1px solid var(--border-color)',
    marginTop: 54,
    marginBottom: 54,
  },
  '.btn-large': {
    fontFamily: fonts.Sans,
    fontWeight: 600,
    backgroundColor: 'var(--wren-orange)',
    padding: '12px 18px',
    color: 'white',
    border: 'none',
    display: 'block',
    marginTop: 18,
    marginBottom: 12,
    fontSize: 18,
    borderRadius: 4,
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: 'var(--wren-orange)',
    },
  },
  blockquote: {
    marginLeft: 0,
    paddingLeft: '26px',
    borderLeft: `4px solid var(--wren-orange)`,
    fontSize: '26px',
    lineHeight: 1.6,
    fontFamily: 'Blanco, serif',
    color: 'var(--text-primary)',
  },
  formula: {
    margin: '24px 0px',
    display: 'block',
    borderTop: '2px solid var(--gray-9)',
    borderBottom: '2px solid var(--gray-9)',
    padding: '8px',
    textAlign: 'center',
    img: {
      maxWidth: '100%',
      width: 'auto',
      maxHeight: '32px',
      margin: 'auto',
    },
  },
  '#table-container': {
    overflowX: 'scroll',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse !important',
    margin: '32px 0px',
    '#red': {
      color: 'var(--error-red)',
      fontWeight: 600,
    },
    '#blue': {
      fontWeight: 600,
      color: 'var(--blue)',
    },
    tr: {
      borderBottom: '2px solid var(--border-color)',
    },
    th: {
      padding: 8,
      fontWeight: 600,
      textAlign: 'left',
      paddingBottom: 4,
      border: 'none !important',
      borderBottom: `2px solid var(--border-color) !important`,
    },
    td: {
      border: `2px solid var(--gray-9) !important`,
      padding: '8px !important',
      paddingRight: '16px !important',
      '&:first-child': {
        borderLeft: '0 !important',
      },
      '&:last-child': {
        borderRight: '0 !important',
      },
    },
  },
  a: linkStyle,
  '.callout': {
    backgroundColor: 'var(--background-gray)',
    border: '1px solid var(--border-color)',
    padding: 16,
    borderRadius: 4,
    fontSize: 16,
    lineHeight: '160%',
    margin: '16px 0px',
  },
  '.callout button': {
    borderRadius: 50,
    backgroundColor: 'var(--link-blue)',
    padding: '12px 18px',
    color: 'white',
    border: 'none',
    display: 'block',
    marginTop: 18,
    marginBottom: 12,
    fontSize: 16,
    fontWeight: 600,
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: 'var(--link-blue)',
    },
  },
  '.callout a': {
    textDecoration: 'none',
  },
  '.callout-emoji': {
    float: 'left',
    marginRight: 16,
    fontSize: 28,
    marginTop: 12,
  },
  '.callout-body': {
    overflow: 'hidden',
  },
  '.callout-body p': {
    marginBottom: 0,
  },
});

export const smallTextContainer = scale({
  position: 'relative',
  width: '140px',
  margin: ['auto', '0px 10px 0px 0px'],
  fontSize: oldFontSizes.body,
});

export const tags = scale({
  margin: ['4px 0 15px 0', '8px 0 10px 0'],
  span: {
    padding: '3px 10px',
    fontSize: '14px',
    fontWeight: 600,
    borderRadius: '3px',
    backgroundColor: 'var(--success-green-low-opacity)',
    color: 'var(--background-dark-green)',
    marginRight: '8px',
    marginBottom: '8px',
    display: 'inline-block',
  },
});

export const imageContainer = scale({
  height: 'fit-content',
  marginBottom: ['15px', '20px', '20px'],
  marginRight: [0, 0, '25px'],
});

export const specialPadding = scale({
  padding: ['2px', '3px', '3px', '7px'],
});

export const imageStyle = (imageUrl) =>
  scale({
    alignSelf: 'stretch',
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    height: '100%',
  });

export const imageBox = scale({
  height: ['250px', '350px', '400px'],
});

export const articleContentContainer = scale({
  padding: 0,
  width: ['90%', '90%', '100%'],
  margin: 'auto',
  overflowX: 'hidden !important',
});

export const subHeader = scale({
  marginTop: '38px',
  marginBottom: '6px',
  fontSize: '32px',
  fontFamily: fonts.Sans,
  fontWeight: 600,
  padding: 0,
});

export const whiteArticle = scale({
  width: ['100%', '100%', '90%'],
  backgroundColor: 'white',
  maxWidth: pageWidth,
  margin: 'auto',
  marginTop: [0, '-80px', '-80px'],
  padding: [
    '25px 0px 35px 0px',
    '25px 0px 35px 0px',
    '35px 45px 35px 45px',
    '25px 45px 35px 45px',
  ],
  marginBottom: ['-100px', 0],
  borderBottom: [`2px solid var(--border-color)`, 'none'],
});

export const listItem = scale({
  listStyleType: 'square !important',
  li: {
    marginBottom: '15px !important',
    fontSize: ['17px !important', '18px !important'],
  },
});

export const articleHeader = scale({
  fontFamily: fonts.Serif,
  fontSize: ['25px', '32px'],
  marginBottom: '0px',
  fontWeight: 700,
  marginTop: ['18px', '22px'],
  paddingBottom: ['3px', '5px'],
});

export const callout = scale({
  backgroundColor: 'var(--background-gray)',
  border: '1px solid var(--border-color)',
  padding: 24,
  borderRadius: 4,
  fontSize: 16,
  lineHeight: '160%',
  margin: '16px 0px',
  h2: {
    fontSize: 18,
    fontWeight: 600,
  },
  p: {
    marginBottom: 12,
  },
});

export const large = scale({
  fontSize: ['35px', '40px', '40px', '45px'],
  lineHeight: 1.0,
  paddingBottom: ['10px', '12px'],
});

export const articleContainer = scale({
  width: ['100%', '100%', '90%', '90%'],
  margin: 'auto',
  maxWidth: '1000px',
  position: 'relative',
  marginBottom: [120, 40, 120],
  zIndex: zIndex('articleContainer'), // places the article in front of the background image location tag on smaller browser widths
});

export const backgroundHero = (img) =>
  scale({
    width: '100%',
    height: '270px',
    color: 'var(--backgorund-dark-green)',
    background: [
      `url(${img})`,
      `linear-gradient(0deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, .5)), url(${img})`,
    ],
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center center !important',
    backgroundSize: 'cover !important',
  });

export const header = scale({
  fontSize: ['23px', '30px', '30px', '30px'],
  lineHeight: 1.3,
  fontFamily: fonts.Sans,
  marginBottom: '5px',
  fontWeight: 600,
});

export const widerThanParentFigure = scale({
  width: ['100%', '100%', '100%', '110%'],
  margin: ['26px 0px', '26px 0px', '26px 0px', '26px 0px 26px -5%'],
  img: {
    width: '100%',
  },
  figcaption: {
    lineHeight: 1.4,
    fontSize: 15,
    color: 'var(--text-primary)',
    opacity: 0.7,
    borderBottom: '2px solid var(--gray-9)',
    padding: '12px 22px 16px 22px',
  },
});

export const downloadables = scale({
  marginTop: 32,
});

export const downloadableDocument = scale({
  display: 'flex',
  span: {
    color: 'var(--gray-6)',
    borderRadius: 50,
    fontWeight: 600,
    marginRight: 12,
    fontSize: 18,
  },
  p: {
    lineHeight: '130%',
    fontSize: 18,
    paddingRight: 12,
  },
  a: {
    height: 22,
    lineHeight: '130%',
    textDecoration: 'none',
    borderRadius: 50,
    padding: '4px 12px',
    backgroundColor: 'var(--success-green)',
    color: 'white',
    float: 'right',
    marginTop: -2,
    marginLeft: 'auto',
    minWidth: 80,
    textAlign: 'center',
  },
  padding: 18,
  borderTop: '2px solid var(--gray-9)',
  borderBottom: '2px solid var(--gray-9)',
  marginTop: -2,
});

// Project update article styles
export const container = scale({
  margin: 'auto',
  width: ['100%', '100%', 750, 750],
  position: 'relative',
});

export const postContentContainer = scale({
  position: 'relative',
  marginTop: [24, 24, 84],
});

export const heroImage = scale({
  backgroundColor: '#eee',
  objectFit: 'cover',
  width: '100%',
  height: [250, 250, 400],
  zIndex: '9',
  borderRadius: [0, 0, '12px'],
  img: {
    objectFit: 'cover',
  },
});

export const subHeading = scale({
  opacity: 0.6,
});

export const postContent = scale({
  position: 'relative',
  padding: [
    '25px 16px 0px',
    '25px 16px 0px',
    '25px 45px 35px',
    '25px 45px 35px',
  ],
  backgroundColor: 'white',
});

export const postHeader = scale({
  paddingBottom: 16,
  marginBottom: 18,
});

export const shareButtonsContainer = scale({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 8,
});

// Sidebar positions 1/3rd of the way down blog post
export const sideBar = (relativeParentHeight) =>
  sidebarScale({
    position: 'absolute',
    top: relativeParentHeight / 3,
    right: [-200, -200, -220, -280],
    width: [200, 200, 220, 260],
    height: 400,
    display: ['none', 'none', 'block', 'block'],
  });

export const sideBarContent = scale({
  padding: 10,
  hr: {
    border: 'none',
    height: 1,
    backgroundColor: '#ddd',
    marginBottom: 16,
  },
  h4: {
    fontWeight: 700,
  },
  a: {
    textDecoration: 'none',
  },
  div: {
    display: 'flex',
    marginBottom: 16,
    fontWeight: 400,
  },
  'p:first-of-type': {
    fontWeight: 600,
    marginBottom: 4,
    fontSize: oldFontSizes.caption,
    opacity: 0.5,
  },
  p: {
    fontSize: oldFontSizes.button,
    lineHeight: 1.3,
  },
  img: {
    width: 60,
    height: 60,
    borderRadius: 4,
    objectFit: 'cover',
    marginRight: 12,
  },
});

export const backLink = scale({
  color: '#2298ca',
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '16px',
  display: 'inline-block',
  cursor: 'pointer',
  paddingLeft: '4px',
  textDecoration: 'none !important',
});

export const backLinkContainer = scale({
  display: ['none', 'none', 'block', 'block'],
  position: 'absolute',
  left: '-88px',
  top: '12px',
});

export const normalHeader = [articleHeader, header];
export const largeHeader = [header, articleHeader, large];

export const SerifParagraph = styled.p(serifParagraph);
