/** @jsxImportSource @emotion/react */

import { connect } from 'react-redux';
import SpecialOfferPopup from '../../components/experiments/SpecialOfferPopup';
import useLandingAndStartHeaderExperiment from '../../hooks/useLandingAndStartHeaderExperiment';
import { State } from '../../store';
import { SpecialOffer } from '../../types';
import MobileLanding from '../Landing/Mobile';

function SpecialOfferLanding({ specialOffer }: { specialOffer: SpecialOffer }) {
  const headers = useLandingAndStartHeaderExperiment();
  return (
    <>
      <SpecialOfferPopup specialOffer={specialOffer} />
      {/* @ts-expect-error: Translate to TS */}
      <MobileLanding headerText={headers.header} />;
    </>
  );
}

function mapStateToProps(state: State) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(SpecialOfferLanding);
