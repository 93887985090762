import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function usePathHistory() {
  const [paths, setPaths] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (paths[paths.length - 1] !== location.pathname) {
      setPaths((current) => [...current, location.pathname]);
    }
  }, [location]);

  return paths;
}
