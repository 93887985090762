import { Network, Storage, AB } from '@wren/shared';
import { isMobile } from './platform';

const { cookies: cookiesFromStorage } = Storage;

export type Cookies = AB.Cookies;

export type ExperimentName = AB.ExperimentName;
export type Treatment = AB.Treatment;

export function reset() {
  return AB.reset(cookiesFromStorage);
}

export function hasUserActivated(experimentName: ExperimentName) {
  return AB.hasUserActivated(experimentName, cookiesFromStorage);
}

export function getAssignmentForExperiment(
  userId: number,
  experimentName: ExperimentName
) {
  return AB.getAssignmentForExperiment(
    userId,
    experimentName,
    cookiesFromStorage
  );
}

export function assignExperiments(
  userId: number,
  cookies: Cookies = cookiesFromStorage
) {
  const assignments = AB.assignExperiments(userId, cookies);

  Network.post('t/ab/assign', {
    assignments,
    platform: isMobile() ? 'mobile' : 'desktop',
  });
}

export function getTreatmentAndActivate(
  userId: number,
  experimentName: ExperimentName,
  cookies: Cookies = cookiesFromStorage
) {
  return AB.getTreatment({
    userId,
    experimentName,
    cookies,
    activate: (assignment: string) => {
      if (typeof window !== 'undefined') {
        window.analytics.track(
          `Client: User Activated AB test ${experimentName}_${assignment}`
        );

        Network.post('t/ab/activate', {
          experiment: experimentName,
          treatment: assignment,
          platform: isMobile() ? 'mobile' : 'desktop',
        });
      }
    },
  });
}
