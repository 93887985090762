/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import TextInput from '../inputs/TextInput';
import { Network } from '@wren/shared';
import { useDispatch, useSelector } from 'react-redux';
import { SetUserInfo } from '../../actions/user';
import { Session } from '@wren/shared';
const { setSessionToken } = Session;

const Container = styled.div`
  position: fixed;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  width: 400px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 10000;
`;

export default function AdminModeControls() {
  const [isShown, setIsShown] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleKeyPress = (event) => {
    if (!user.isAdmin) {
      return;
    }

    if (event.key === 'p' && event.metaKey && event.shiftKey) {
      setIsShown((current) => !current);
    }

    if (isShown && event.key === 'Escape') {
      setIsShown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isShown, user.userId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const [response, responseBody] = await Network.post('sessions', {
      email: value.trim(),
    });

    if (response.ok) {
      setSessionToken(responseBody.sessionToken);
      dispatch(SetUserInfo(responseBody.user));
      setIsShown(false);
    }
  };

  if (!isShown) {
    return null;
  }

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <TextInput
          autofocus
          css={{
            margin: 0,
            '& > input': {
              height: 34,
              margin: '0 !important',
            },
          }}
          handleInputChange={(val) => setValue(val)}
        />
      </form>
    </Container>
  );
}
