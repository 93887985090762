/** @jsxImportSource @emotion/react */
import * as R from 'ramda';
import PropTypes from 'prop-types';

import {
  shareButtonStyle,
  textStyle,
  smallShareButtonStyle,
  smallTextStyle,
  smallLogoStyle,
  logoStyle,
  smallLinkStyle,
  linkStyle,
} from '../../style/shareButtonStyles';

import track from '../../lib/events';
import Mail from '../svgs/Mail';

const EmailShare = (props) => {
  const {
    subject,
    text,
    url,
    name,
    size,
    button,
    className,
    campaignName = 'share',
    utmMedium = name,
  } = props;

  let urlWithUtm;
  if (R.includes('?', url)) {
    urlWithUtm = R.concat(
      url,
      `&utm_medium=email&utm_campaign=${encodeURIComponent(
        campaignName || ''
      )}&utm_medium=${encodeURIComponent(utmMedium || '')}`
    );
  } else {
    urlWithUtm = R.concat(
      url,
      `?utm_medium=email&utm_campaign=${encodeURIComponent(
        campaignName || ''
      )}&utm_medium=${encodeURIComponent(utmMedium || '')}`
    );
  }

  const encodedProps = R.map(encodeURIComponent, {
    subject,
    text,
    url: urlWithUtm,
  });

  return (
    <a
      href={`mailto:?subject=${encodedProps.subject}&body=${encodedProps.text}%0A${encodedProps.url}`}
      css={size === 'small' ? smallLinkStyle : linkStyle}
      onClick={() => {
        track('Server: User Clicked Share Button', {
          name,
          source: 'email',
          utmMedium,
          campaignName,
        });
      }}
      className={className}
    >
      {button || (
        <button
          css={
            size === 'small'
              ? [smallTextStyle, smallShareButtonStyle]
              : [textStyle, shareButtonStyle]
          }
        >
          <div css={size === 'small' ? smallLogoStyle : logoStyle}>
            <Mail size="100%" fill="rgba(0,0,0,0.7)" />
          </div>
          <p css={size === 'small' ? smallTextStyle : textStyle}>{'  Email'}</p>
        </button>
      )}
    </a>
  );
};

EmailShare.defaultProps = {
  text: 'Check out projectwren.com',
  subject: 'Join me in reversing climate change!',
  url: 'projectwren.com',
  name: '',
};

EmailShare.propTypes = {
  text: PropTypes.string,
  subject: PropTypes.string,
  url: PropTypes.string,
  name: PropTypes.string,
};

export default EmailShare;
