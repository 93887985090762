/** @jsxImportSource @emotion/react */

export default function WrenLogo({
  width,
  fill = 'var(--wren-orange)',
  className,
}: {
  width?: number;
  fill?: string;
  className?: string;
}) {
  return (
    <svg
      className={className}
      width={width || '58'}
      height={width ? width * 0.72 : '42'}
      viewBox="0 0 58 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.4784 10.7239L48.0289 8.46896C46.8601 5.04052 44.9594 2.30154 42.4943 0.924972C36.1025 -2.64278 22.3534 4.90829 12.0609 9.70858C5.59601 12.7262 0 13.4322 0 13.4322C0 13.4322 8.52625 44.7321 30.889 40.7582C45.3253 38.1939 50.298 24.7611 49.2071 13.9989L57.4784 10.7239ZM22.3746 24.2157C15.0478 25.3326 12.2546 16.5301 12.2546 16.5301C12.2546 16.5301 16.0136 17.3919 17.912 16.1263C20.8115 14.1925 24.0794 12.0107 26.1737 13.0142C29.5857 14.6482 29.7014 23.0989 22.3746 24.2157ZM38.7707 9.90456C37.6586 10.2092 36.4945 9.49135 36.171 8.30132C35.8452 7.11128 36.4827 5.90235 37.5948 5.59776C38.7069 5.29317 39.871 6.01097 40.1945 7.201C40.518 8.38868 39.8804 9.59997 38.7707 9.90456Z"
        fill={fill}
      />
    </svg>
  );
}
