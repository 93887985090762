/** @jsxImportSource @emotion/react */

import { CSSInterpolation } from '@emotion/serialize';
import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { scale } from '../../util/scale';

const burgerButton: CSSInterpolation = {
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 0,
  display: 'flex',
  padding: 0,
  position: 'relative',
  transition: 'background-color 200ms ease-in-out',
  '&:hover': {
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
};

const labelContainer = (color: string, hideLabel?: boolean) => ({
  color,
  lineHeight: '15px',
  fontWeight: 600,
  marginLeft: hideLabel ? 0 : 8,
  marginBottom: 2,
  overflow: 'hidden',
  width: hideLabel ? 0 : 'auto',
});

const navBurger: CSSInterpolation = {
  position: 'relative',
  height: 30,
  width: 30,
};

const bunStyle = (color: string) =>
  scale({
    position: 'absolute',
    backgroundColor: color,
    width: '16px',
    height: '2px',
    left: 7,
    transition: 'transform 200ms ease-in-out',
  });

const topBun = (open: boolean) =>
  scale({
    top: 14,
    transform: open ? 'rotate(45deg)' : 'translateY(-3px)',
  });

const bottomBun = (open: boolean) =>
  scale({
    bottom: 14,
    transform: open ? 'rotate(-45deg)' : 'translateY(3px)',
  });

export default function NavBurger({
  children,
  className,
  color,
  expanded,
  hideLabel,
  onToggle,
}: PropsWithChildren<
  {
    color: string;
    className?: string;
    expanded: boolean;
    hideLabel?: boolean; // a hidden label is better for alignment and accessibility than no label
    onToggle: () => void;
  } & ButtonHTMLAttributes<HTMLButtonElement>
>) {
  return (
    <button
      aria-haspopup="menu"
      aria-expanded={expanded}
      className={className}
      css={burgerButton}
      onClick={onToggle}
      role="button"
    >
      {children && <div css={labelContainer(color, hideLabel)}>{children}</div>}
      <div css={navBurger}>
        <div css={[bunStyle(color), topBun(expanded)]} />
        <div css={[bunStyle(color), bottomBun(expanded)]} />
      </div>
    </button>
  );
}
