/** @jsxImportSource @emotion/react */

import { useRef } from 'react';
import { staticImageUrl } from '../../util/images';
import {
  shareButtonStyle,
  smallShareButtonStyle,
  smallTextStyle,
  textStyle,
  smallLogoStyle,
  logoStyle,
  smallLinkStyle,
  linkStyle,
} from '../../style/shareButtonStyles';
import track from '../../lib/events';

const linkedin = staticImageUrl('linkedin.png');

const LinkedInShare = ({
  url = 'projectwren.com',
  name = '',
  size,
  button,
  className,
  campaignName = 'share',
  utmMedium,
}: {
  url: string;
  name: string;
  size?: 'small';
  button?: React.ReactNode;
  className?: string;
  campaignName?: string;
  utmMedium?: string;
}) => {
  const anchorRef = useRef<HTMLAnchorElement>(null);

  const urlObj = new URL(url);
  urlObj.searchParams.set('utm_source', 'linkedin-organic');
  urlObj.searchParams.set('utm_campaign', campaignName);
  urlObj.searchParams.set('utm_medium', utmMedium || name);

  const encodedUrl = urlObj.toString();

  const handleOpenPopup = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    track('Server: User Clicked Share Button', {
      name,
      source: 'linkedin',
      campaignName,
      utmMedium,
    });
    window.open(
      anchorRef.current?.href || '',
      'linkedin',
      'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'
    );
    return false;
  };

  return (
    <a
      href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`}
      onClick={(e) => handleOpenPopup(e)}
      ref={anchorRef}
      css={size === 'small' ? smallLinkStyle : linkStyle}
      className={className}
    >
      {/* TODO: separate these four lines into new component w img or font awesome option */}
      {button || (
        <button
          css={size === 'small' ? smallShareButtonStyle : shareButtonStyle}
        >
          <img
            src={linkedin}
            alt=""
            css={size === 'small' ? smallLogoStyle : logoStyle}
          />
          <p css={size === 'small' ? smallTextStyle : textStyle}>LinkedIn</p>
        </button>
      )}
    </a>
  );
};

export default LinkedInShare;
