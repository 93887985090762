/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useOnboardingFlow } from '../../hooks/useOnboardingFlow';
import useQueryParams from '../../hooks/useQueryParams';
import { State } from '../../store';

export default function ContinueCheckout() {
  const user = useSelector((state: State) => state.user);

  const { getQueryParam } = useQueryParams();

  const history = useHistory();
  const climateCampQueryParam = getQueryParam('climate_camp');

  const flow = useOnboardingFlow();
  let nextStep = flow.getStepToContinue({
    climateCamp: !!climateCampQueryParam,
  });

  if (user.completedOnboarding) {
    nextStep = '/dashboard';
  }

  useEffect(() => {
    history.replace(nextStep);
  }, [user.email]);

  return <div />;
}
