/** @jsxImportSource @emotion/react */
import { useRef } from 'react';

import { largeHeader, articleStyles, closingGlyph } from '../../style/article';
import { scale } from '../../util/scale';

import { fonts } from '../../style/theme';
import ShareButtons from '../sharing/ShareButtons';
import { makeMDYDateFromAnyDate, timeSince } from '../../lib/time';
import { staticImageUrl } from '../../util/images';

const excerpt = scale({
  fontFamily: `${fonts.Sans} !important`,
  fontSize: '20px !important',
  marginBottom: 24,
  lineHeight: '32px',
  fontWeight: 400,
  opacity: 0.7,
});

const tocStyles = scale({
  li: { paddingBottom: '8px', fontSize: '18px' },
});

const TableOfContents = ({ chapters = [] }) => {
  return (
    <div>
      <h3>📚 Table of contents:</h3>
      <ul css={tocStyles}>
        {chapters.map((chapter) => (
          <li key={chapter.id}>
            <a href={`#${chapter.id}`}>{chapter.name} </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const generateTableOfContentsChapters = (articleContentRef) => {
  const chapters = [];
  if (articleContentRef.current) {
    articleContentRef.current.querySelectorAll('h2').forEach((h2) => {
      chapters.push({ name: h2.innerText, id: h2.id });
    });
  }
  return chapters;
};

const Article = (props) => {
  const {
    injectHTML,
    hideDateAndReadTime,
    hideAuthor,
    hideSocialIcons,
    post,
    showClosingGlyph = true,
    sharePath,
  } = props;
  const articleContentRef = useRef();

  // scrolls to anchor after article has loaded
  if (window.location.hash) {
    const elementToScrollTo = document.getElementById(
      window.location.hash.substring(1)
    );
    if (elementToScrollTo !== null) {
      elementToScrollTo.scrollIntoView();
    }
  }

  // only shows TOC if ghost metadata includes toc boolean
  const showTableOfContents = post.codeinjection_head
    ? post.codeinjection_head.includes('toc=true')
    : false;
  const chapters = generateTableOfContentsChapters(articleContentRef);

  return (
    <div>
      <h1 css={largeHeader}>{post.title}</h1>
      <h4 css={excerpt}>{post.excerpt}</h4>
      <PostMetadata
        post={post}
        hideDateAndReadTime={hideDateAndReadTime}
        hideAuthor={hideAuthor}
        hideSocialIcons={hideSocialIcons}
        sharePath={sharePath}
      />
      <hr style={{ border: '1px solid var(--gray-9)', marginBottom: '26px' }} />
      {showTableOfContents && <TableOfContents chapters={chapters} />}
      {injectHTML ? (
        <div
          id="article-content"
          css={[articleStyles, showClosingGlyph && closingGlyph]}
          ref={articleContentRef}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      ) : (
        <div id="article-content" css={articleStyles} ref={articleContentRef}>
          {post.html}
        </div>
      )}
    </div>
  );
};

const backgroundImage = (image) =>
  scale({
    width: '46px',
    height: '46px',
    borderRadius: '50%',
    marginRight: '12px',
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'var(--background-gray)',
  });
const author = scale({
  marginTop: '18px',
  display: 'flex',
  marginBottom: '26px',
  img: {
    borderRadius: '50%',
    marginRight: '12px',
  },
  'h4:first-of-type': {
    fontSize: '15px !important',
  },
  h4: {
    fontFamily: `${fonts.Sans} !important`,
    fontSize: '13px !important',
    fontWeight: 400,
    lineHeight: '20px',
  },
});

const published = scale({
  h4: {
    fontFamily: `${fonts.Sans} !important`,
    fontSize: '16px !important',
    fontWeight: 400,
    lineHeight: '24px',
  },
  p: {
    fontFamily: `${fonts.Sans} !important`,
    fontSize: '16px !important',
    lineHeight: '24px',
    opacity: 0.5,
    paddingBottom: 2,
  },
});

const multipleAuthors = scale({
  div: {
    border: '3px solid white',
    marginRight: '-16px',
    display: 'inline-block',
  },
  marginRight: '24px',
});

const authorImages = {
  'Mimi Tran Zambetti': 'aboutus-mimi.jpg',
  'Boyce Upholt': 'aboutus-boyce-optimized.jpg',
  'Ben Stanfield': 'aboutus-ben.jpg',
  'Taylor Lapeyre': 'aboutus-taylor.jpg',
  'Landon Brand': 'aboutus-landon.jpg',
};

const metadataContainer = scale({
  position: 'relative',
});

const socialIcons = scale({
  position: 'absolute',
  right: [0, 6],
  bottom: 2,
  width: [116, 132],
});

const getAuthorPicture = (name) => {
  const foundName = authorImages[name];
  return staticImageUrl(foundName, { w: 80 });
};

const AuthorSection = ({ post }) => {
  if (post.authors.length === 1) {
    const postAuthor = post.authors[0];
    return (
      <div css={author}>
        <div css={backgroundImage(postAuthor.profile_image)} />
        <div>
          <h4 style={{ marginTop: '2px' }}>{postAuthor.name}</h4>
          <h4 style={{ opacity: 0.5 }}>{postAuthor.location}</h4>
        </div>
      </div>
    );
  }
  return (
    <div css={author}>
      <div css={multipleAuthors}>
        {post.authors.map((author) => (
          <div css={backgroundImage(getAuthorPicture(author.name))} />
        ))}
      </div>

      <div>
        <h4 style={{ marginTop: '2px' }}>
          <strong>By {post.group}</strong>
        </h4>
        <h4>{post.group_title}</h4>
      </div>
    </div>
  );
};

const PublishedDate = ({ post }) => {
  return (
    <div css={published}>
      <h4>{makeMDYDateFromAnyDate(post.published_at)}</h4>
      <p>Updated {timeSince(new Date(post.updated_at))} ago</p>
    </div>
  );
};

const Socials = ({ post, sharePath = 'blog' }) => {
  const { title } = post;
  const url = `https://www.wren.co/${sharePath}/${post.slug}`;
  return (
    <div css={socialIcons}>
      <ShareButtons
        text={`Good read from Wren: "${title}"`}
        twitter={`Good read from @project_wren: "${title}"`}
        url={url}
        name="post_socials"
        socialsArr={['twitter', 'fb', 'linkedin']}
        iconStyle
      />
    </div>
  );
};

export function PostMetadata(props) {
  const { post, hideDateAndReadTime, hideAuthor, hideSocialIcons, sharePath } =
    props;
  return (
    <div css={metadataContainer}>
      {!hideAuthor && <AuthorSection post={post} />}
      {!hideDateAndReadTime && <PublishedDate post={post} />}
      {!hideSocialIcons && <Socials post={post} sharePath={sharePath} />}
    </div>
  );
}
export default Article;
