import {
  GetUserInfo,
  SetAnnualFootprint,
  SetUserInfo,
  UpdateCurrency,
} from './user';
import { Network } from '@wren/shared';
import { guessCurrency } from '../util/conversions';
import { canUserChangeCurrency } from '../util/util';
import { getCalculatorKeysForUser } from '../lib/calculator';

export function ClearCalculator() {
  return {
    type: 'CLEAR_CALCULATOR',
  };
}

export const SetCalculatorQuestionIndex = (questionIndex) => {
  return (dispatch, getState) => {
    const calculatorKeys = getCalculatorKeysForUser(getState());

    dispatch({
      type: 'SET_CALCULATOR_QUESTION_INDEX',
      questionIndex,
      calculatorKeys,
    });
  };
};

export const ShowNextQuestion = () => {
  return (dispatch, getState) => {
    const calculatorKeys = getCalculatorKeysForUser(getState());
    dispatch({ type: 'SHOW_NEXT_QUESTION', calculatorKeys });
  };
};

export const ShowPreviousQuestion = () => {
  return (dispatch, getState) => {
    const calculatorKeys = getCalculatorKeysForUser(getState());
    dispatch({ type: 'SHOW_PREVIOUS_QUESTION', calculatorKeys });
  };
};

export function SetCountryAverage(countryAverage) {
  return {
    type: 'SET_COUNTRY_AVERAGE',
    countryAverage,
  };
}

export function SetFootprintCountry(country) {
  return {
    type: 'SET_COUNTRY',
    country,
  };
}

export function SetFootprintDefaults(zipDefaults) {
  return {
    type: 'SET_FOOTPRINT_DEFAULTS',
    zipDefaults,
  };
}

export function UpdateUserAnswer(answer) {
  return {
    type: 'UPDATE_USER_ANSWER',
    answer,
  };
}

export function UpdateAnnualTotals(totals) {
  return {
    type: 'UPDATE_ANNUAL_TOTALS',
    totals,
  };
}

export const UpdateFootprint = (body) => async (dispatch, getState) => {
  dispatch(UpdateUserAnswer(body));
  const { footprintId } = getState().user;
  const [response, responseBody] = await Network.post(
    `footprints/${footprintId}`,
    body
  );

  if (response.ok) {
    window.analytics.track('Client: User Updated Footprint', body);

    // If a user hasn't interacted with the site in over ~1 day, then this endpoint will
    // create a new footprint object for the user. This results in the user's previous
    // footprint id becoming stale, which would break the site. Here, we update it.
    dispatch(SetUserInfo({ footprintId: responseBody.footprint.id }));

    dispatch(UpdateUserAnswer(responseBody.footprint));
    dispatch(SetAnnualFootprint(responseBody.footprintBreakdown.grandTotal));
    dispatch(UpdateAnnualTotals(responseBody.footprintBreakdown));
  }
};

const dispatchAnswersToReducer = async (dispatch, json) => {
  dispatch(UpdateUserAnswer(json));
  dispatch(SetFootprintCountry(json.country));
  dispatch(GetDefaultFootprint(json.country));
};

export const GetAndSetFootprint = () => async (dispatch, getState) => {
  const { user, calculatorQuestionIndex } = getState();

  if (!user.footprintId) {
    return;
  }

  const [response, responseBody] = await Network.get(
    `footprints/${user.footprintId}`
  );

  if (response.ok) {
    if (!responseBody.footprint.country) {
      dispatch(SetFootprintCountry(null));
      dispatch(SetCalculatorQuestionIndex(0));
      return;
    }
    if (!calculatorQuestionIndex) {
      dispatch(SetCalculatorQuestionIndex(1));
    }
    await dispatchAnswersToReducer(dispatch, responseBody.footprint);

    dispatch(SetAnnualFootprint(responseBody.footprint.annualFootprint));
    dispatch(UpdateAnnualTotals(responseBody.footprintBreakdown));
  }
};

export function GetDefaultFootprint(country) {
  return async (dispatch, getState) => {
    const { userAnswers } = getState().calculator;

    const query = {
      country: country ?? userAnswers.country ?? 'United States',
    };

    if (userAnswers.calculationType === 'household') {
      if (userAnswers.householdAdults) {
        query.adults = userAnswers.householdAdults ?? 1;
      }

      if (userAnswers.householdChildren) {
        query.children = userAnswers.householdChildren ?? 0;
      }
    }

    const [response, responseBody] = await Network.get(
      'footprints/default',
      query
    );

    if (response.ok) {
      dispatch(SetFootprintDefaults(responseBody.zipDefaults));
      const annualFootprint =
        responseBody.zipDefaults.annualFootprint.grandTotal;
      dispatch(SetCountryAverage(annualFootprint));
    }
  };
}

export function SetCountry(country) {
  return async (dispatch, getState) => {
    // this allows us to speed up perceived performance by moving to the
    // next question immediately instead of waiting for the footprint to change
    dispatch(SetFootprintCountry(country));

    const { user } = getState();
    await dispatch(UpdateFootprint({ country }));
    await dispatch(GetDefaultFootprint(country));

    const currentCurrencyCode = guessCurrency(country);

    if (canUserChangeCurrency(user)) {
      dispatch(UpdateCurrency(currentCurrencyCode));
    } else {
      dispatch(GetUserInfo());
    }
  };
}

export function UpdateHouseholdCar(carId, attributes) {
  return async (dispatch) => {
    const [response] = await Network.post(
      `household-cars/${carId}`,
      attributes
    );

    if (response.ok) {
      dispatch(GetAndSetFootprint());
    }
  };
}
