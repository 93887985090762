import { Session } from '@wren/shared';
import { SetError, SetToast } from './toaster';
import { Network } from '@wren/shared';
import * as Sentry from '@sentry/react';
import track from '../lib/events';
import { RecordExperience } from './experiences';
import { Experiences } from '../config/constants';
import { GetCardInfo, GetStripeSubscription } from './stripe';
import { getLatestLapsedSubscription } from '../lib/subscriptions';
import { SerializedUser, Stripe, Subscription, Team } from '../types';
import { Dispatch, Store } from '../store';
import { Token } from '@stripe/stripe-js';
import { RouteComponentProps } from 'react-router-dom';
import { controlPortfolioSlug, variantPortfolioSlug } from './portfolios';
import { findCountryNameByCountryCode } from '../util/util';
import { SetCountry } from './calculator';

export function SetUserInfo(userInfo: Partial<SerializedUser>) {
  // ltv_portfolio_35_cpt_carbon_report_tiers_0523 implementation
  const modifiedUserInfo = { ...userInfo };

  if (
    modifiedUserInfo.portfolio &&
    variantPortfolioSlug === userInfo.portfolio?.slug
  ) {
    modifiedUserInfo.portfolio.slug = controlPortfolioSlug;
  }
  return async function (dispatch: Dispatch) {
    dispatch({
      type: 'SET_USER_INFO',
      userInfo: modifiedUserInfo,
    });
  };
}

export const GetUserInfo = () => async (dispatch: Dispatch) => {
  const [response, responseBody] = await Network.post<{
    userInfo: SerializedUser;
  }>('user-info');
  if (response.ok && responseBody) {
    dispatch(SetUserInfo(responseBody.userInfo));
    return responseBody.userInfo;
  }
};

export function SetCompletedOnboarding() {
  return {
    type: 'SET_COMPLETED_ONBOARDING',
  };
}

export function ClearUserInfo() {
  return {
    type: 'CLEAR_USER_INFO',
  };
}

export function SetAnnualFootprint(annualFootprint: number) {
  return {
    type: 'SET_ANNUAL_FOOTPRINT',
    annualFootprint,
  };
}

export const SetCurrency = (currency: string) => {
  return {
    type: 'SET_CURRENCY',
    currency,
  };
};

export const UpdateCurrency =
  (currency: string) => async (dispatch: Dispatch) => {
    const [response, responseBody] = await Network.post<{
      user: SerializedUser;
    }>('account/update-currency', {
      currency,
    });

    if (response.ok && responseBody) {
      dispatch(SetCurrency(currency));
      dispatch(SetUserInfo(responseBody.user));
    }
  };

export const UpdateUser =
  (attributes: Partial<SerializedUser>) => async (dispatch: Dispatch) => {
    const [response, responseBody] = await Network.post<{
      user: SerializedUser;
      error?: string;
    }>('accounts/update', {
      user: attributes,
    });

    if (response.ok && responseBody) {
      await dispatch(SetUserInfo(responseBody.user));
    } else {
      return responseBody?.error;
    }
  };

export const CreateAccount =
  (
    account: Pick<
      SerializedUser,
      'firstName' | 'lastName' | 'email' | 'phoneNumber'
    > & {
      password: string;
    }
  ) =>
  async (dispatch: Dispatch) => {
    const [response, responseBody] = await Network.post<{
      account: SerializedUser;
      sessionToken: string;
      message?: string;
    }>('accounts', {
      firstName: account.firstName,
      lastName: account.lastName,
      email: account.email,
      password: account.password,
      phoneNumber: account.phoneNumber,
    });

    if (response.ok && responseBody) {
      await dispatch(SetUserInfo(responseBody.account));
      Session.setSessionToken(responseBody.sessionToken);

      Sentry.setUser({
        id: String(responseBody.account.userId),
        email: responseBody.account.email ?? undefined,
        username: responseBody.account.username ?? undefined,
      });

      return { user: responseBody.account, error: null };
    }

    return {
      user: null,
      error:
        responseBody?.message || 'There was an error creating your account',
    };
  };

export const SignUpWithGoogle =
  (googleAccessToken: string) => async (dispatch: Dispatch) => {
    const [response, responseBody] = await Network.post<{
      account: SerializedUser;
      sessionToken: string;
      message?: string;
    }>('accounts', {
      googleAccessToken,
    });

    if (response.ok && responseBody) {
      await dispatch(SetUserInfo(responseBody.account));

      Sentry.setUser({
        id: String(responseBody.account.userId),
        email: responseBody.account.email ?? undefined,
        username: responseBody.account.username ?? undefined,
      });

      Session.setSessionToken(responseBody.sessionToken);
      return { user: responseBody.account, error: null };
    }

    return {
      user: null,
      error:
        responseBody?.message || 'There was an error creating your account',
    };
  };

export const LinkUserToReferrer =
  (referrerUsername: string) =>
  async (dispatch: Dispatch, getState: Store['getState']) => {
    const { user } = getState();

    if (user.completedOnboarding) {
      // can't link a referral if you have already converted.
      return 'Thanks for being a Wren subscriber!';
    }

    const [response, responseBody] = await Network.post<{
      user: SerializedUser;
    }>('accounts/update', {
      user: {
        referredBy: referrerUsername,
      },
    });

    if (response.ok && responseBody) {
      dispatch(SetUserInfo(responseBody.user));
    } else {
      return "We couldn't find a user with that referral link. Did you misspell it?";
    }
  };

export const ClaimSpecialOffer =
  (specialOfferToken: string) => async (dispatch: Dispatch) => {
    const [response, responseBody] = await Network.post<{
      user: SerializedUser;
      message?: string;
    }>('special-offers/claim', {
      token: specialOfferToken,
    });

    if (response.ok && responseBody) {
      dispatch(SetUserInfo(responseBody.user));
      return;
    }

    // There won't be a response body if status is 403.
    return responseBody?.message || 'There was an error claiming your offer.';
  };

export function UpdatePortfolio({ portfolioId }: { portfolioId: number }) {
  return async function (dispatch: Dispatch, getState: Store['getState']) {
    const [response, responseBody] = await Network.post<{
      error?: string;
      message?: string;
    }>('accounts/update', {
      user: {
        portfolioId,
      },
    });

    if (response.ok) {
      const { portfolio } = getState().user;
      // This will fire anytime a user changes their portfolio in
      // settings as well but is the best way to keep analytics
      // tracking consistent for choosing a portfolio in onboarding
      window.analytics.track('Client: User Chose Portfolio', {
        oldPortfolioId: portfolio ? portfolio.id : null, // double check that this gets old portfolio id
        portfolioId,
      });

      await dispatch(GetUserInfo());
      return null;
    }
    dispatch(
      SetError(
        "Something went wrong when changing your project. We've been alerted and are on our way to fix it! Please reach out to support@wren.co if this issue persists."
      )
    );

    return responseBody?.error || responseBody?.message;
  };
}

export function GetStarted({
  platform,
  eventLocation,
  history,
}: {
  platform: string;
  eventLocation: string;
  history: RouteComponentProps['history'];
}) {
  return async (dispatch: Dispatch, getState: Store['getState']) => {
    const { user } = getState();

    window.plausible('Clicked Get started', {
      props: { location: eventLocation },
    });

    track('Server: User clicked get started', {
      location: eventLocation,
      platform,
    });

    // NOTE: This is a pretty bad way of figuring out what referral link to send a visitor
    // to but we don't have access to match.params in the nav
    const getUsernameFromPath = (path: string) =>
      path.includes('profile') && path.split('/')[path.split('/').length - 1];

    const startNowLink = window.location.pathname.includes('profile')
      ? `/join/${getUsernameFromPath(window.location.pathname)}`
      : '/continue';

    if (user.completedOnboarding) {
      history.push(`/dashboard`);
    } else {
      history.push(startNowLink);
    }
  };
}

export function ReferFriendsViaEmails(emails: string[]) {
  return async (dispatch: Dispatch) => {
    const [response, responseBody] = await Network.post<{
      brokenEmails?: string[];
      error?: string;
    }>(`referrals`, {
      emails,
    });

    if (response.ok && responseBody) {
      if (responseBody.brokenEmails?.length) {
        dispatch(
          SetError(
            `Could not invite the following emails: ${responseBody.brokenEmails.join(
              ', '
            )}`
          )
        );
      } else {
        dispatch(
          SetToast({
            text: 'Emails sent! They will receive an invite in their inboxes shortly.',
          })
        );
      }

      dispatch(
        RecordExperience({
          name: Experiences.REFER_FRIEND_VIA_EMAIL,
          progress: 0,
          completed: true,
        })
      );
    } else {
      dispatch(SetError(`Sorry, something went wrong: ${responseBody?.error}`));
    }
  };
}

export function ReactivateTeamWithNewPaymentMethod(
  stripeToken: Token,
  teamId: string
) {
  return async (dispatch: Dispatch) => {
    const [response, responseBody] = await Network.post<{ error?: string }>(
      `teams/${teamId}/payment`,
      {
        token: stripeToken,
      }
    );

    if (!response.ok && responseBody) {
      dispatch(SetError(responseBody.error));
      return { isSuccessful: false };
    }

    dispatch(
      SetToast({
        type: 'success',
        text: 'Your team has a new payment method! 🎉',
        isOnRight: true,
      })
    );

    return { isSuccessful: true };
  };
}

export function ReactivateUserWithNewPaymentMethod(stripeToken: Token) {
  return async (dispatch: Dispatch, getState: Store['getState']) => {
    const user = getState().user;

    const isPastDue = user.subscriptions.some(
      (subscription: Subscription) => subscription.status === 'past_due'
    );

    const latestLapsedSubscription = getLatestLapsedSubscription(user);

    // This form submission can do one of two things:
    //   1. Create a new subscription for a user with an auto-cancelled subscription
    //   2. Update the payment method for a user with an active, but delinquent subscription
    let response: Response, responseBody: { error?: string } | null;

    if (isPastDue) {
      [response, responseBody] = await Network.post<{ error?: string }>(
        'payment/update-payment-info',
        {
          token: stripeToken,
        }
      );
    } else if (latestLapsedSubscription && !user.activeSubscription) {
      // Stripe has automatically cancelled it after exhausting retry attempts
      [response, responseBody] = await Network.post<{ error?: string }>(
        'subscriptions',
        {
          stripeToken,
          subscription: {
            amount: latestLapsedSubscription.amount,
            amountInUSDCents: latestLapsedSubscription.amountPeggedToUsdCents,
            billingCycle: latestLapsedSubscription.billingCycle,
            currency: latestLapsedSubscription.currency,
          },
        }
      );
    } else {
      throw new Error();
    }

    if (!response.ok && responseBody) {
      dispatch(SetError(responseBody.error));
      return { isSuccessful: false };
    }

    dispatch(GetStripeSubscription());
    dispatch(GetCardInfo());
    dispatch(GetUserInfo());
    dispatch(
      SetToast({ type: 'success', text: 'Welcome back! 🎉', isOnRight: true })
    );

    return { isSuccessful: true };
  };
}

export function CreateStripeCustomer() {
  return async (dispatch: Dispatch) => {
    const [response, responseBody] = await Network.post<{
      customerId: string;
      message?: string;
    }>('stripe/customer');

    if (!response.ok && responseBody) {
      dispatch(SetError(responseBody.message));
    } else if (responseBody) {
      dispatch(SetUserInfo({ stripeCustomerId: responseBody.customerId }));
    }
  };
}

export function UpdateTeamSubscription() {
  return async (dispatch: Dispatch, getStore: Store['getState']) => {
    const user = getStore().user;

    if (!user.team) {
      Sentry.captureMessage(
        'User tried to update team subscription but has no team'
      );
      dispatch(SetError('You are not part of any team.'));
      return;
    }

    const [response, responseBody] = await Network.post<{
      subscription: Stripe.Subscription;
    }>(`teams/${user.team.id}/subscriptions/adjust`);

    if (!response.ok && responseBody) {
      dispatch(SetError('Cannot update team subscription'));
      return;
    }
  };
}

export function AddUserToTeam(team: Team, user?: SerializedUser) {
  return async (dispatch: Dispatch, getState: Store['getState']) => {
    if (!user) {
      user = getState().user;
    }

    if (user.hasPassword && !(user.country && user.annualFootprint)) {
      const countryCode =
        findCountryNameByCountryCode(team.countryCode) || 'United States';
      await dispatch(SetCountry(countryCode));
    }

    // This endpoint only ever adds team members with the "member" role
    const [response, responseBody] = await Network.post<{ error?: string }>(
      `teams/${team.id}/members`,
      {
        userId: user.userId,
      }
    );

    if (!response.ok) {
      Sentry.captureMessage(
        `Could not add user ${user.userId} to team ${team.id}`
      );
      return dispatch(
        SetError(responseBody?.error || 'Could not add you to team')
      );
    }

    await dispatch(SetUserInfo({ team: { ...team, roleType: 'member' } }));
  };
}
