/** @jsxImportSource @emotion/react */

import { PropsOf } from '@emotion/react';
import { colors, spacing } from '../../style/theme';
import { ArrowNavButton } from './ArrowNavButton';

export function GetInTouchButton(props: PropsOf<typeof ArrowNavButton>) {
  return (
    <ArrowNavButton
      buttonType="filled"
      color={colors.wrenOrange}
      css={
        {
          fontWeight: 600,
          marginLeft: spacing.small,
          textAlign: 'left',
        } /* reset styles from DesktopNav */
      }
      href="/teams/business#lead-capture-form"
      padding="xTight"
      {...props}
    >
      Get in touch
    </ArrowNavButton>
  );
}
