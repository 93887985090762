import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Notification } from '@wren/schema';
import { Network, ObjectToCamel } from '@wren/shared';

export async function getNotifications() {
  const [response, responseBody] = await Network.get<{
    notifications: ObjectToCamel<Notification>[];
  }>(`notifications`);

  if (response.ok && responseBody) {
    return responseBody.notifications;
  }
}

export async function MarkNotificationAsRead(notificationId: string) {
  const [response, responseBody] = await Network.post<{
    notifications: ObjectToCamel<Notification>[];
  }>(`notifications/${notificationId}`, {
    readAt: new Date(),
  });

  if (response.ok && responseBody) {
    return responseBody.notifications;
  }
}

export async function MarkAllNotificationsAsRead() {
  const [response, responseBody] = await Network.post<{
    notifications: ObjectToCamel<Notification>[];
  }>(`notifications/read`);

  if (response.ok && responseBody) {
    return responseBody.notifications;
  }
}

export const useNotifications = () => {
  const queryClient = useQueryClient();
  const { data: notifications, isLoading } = useQuery(
    ['notifications'],
    getNotifications
  );
  const handleClickNotification = useMutation(['markNotificationAsRead'], {
    mutationFn: MarkNotificationAsRead,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['notifications'] }),
  });
  const handleMarkAllAsReadMutation = useMutation(
    ['markAllNotificationsAsRead'],
    {
      mutationFn: MarkAllNotificationsAsRead,
      onSettled: () =>
        queryClient.invalidateQueries({ queryKey: ['notifications'] }),
    }
  );

  const numberOfUnreadNotifications =
    notifications?.filter((notification) => !notification.readAt).length ?? 0;

  return {
    notifications,
    isLoading,
    handleClickNotification: (notificationId: string) =>
      handleClickNotification.mutate(notificationId),
    handleMarkAllAsReadMutation: () => handleMarkAllAsReadMutation.mutate(),
    numberOfUnreadNotifications,
  };
};
