/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SetToast } from '../../actions/toaster';
import Button from '../buttons/Button';
import TextInput from '../inputs/TextInput';
import { Network } from '@wren/shared';
import { center } from '../../style/text';
import { centerForm } from '../../style/layout';
import { maxWidth, marginAuto } from '../../style/misc';
import InputError from '../inputs/InputError';
import { scale } from '../../util/scale';
import CardContainer from '../UI/CardContainer';
import CardContent from '../UI/CardContent';
import { getEmailValidationError } from '../../lib/validation';

const userInfoForm = scale({
  maxWidth: ['90%', '360px', '360px'],
  margin: 'auto',
});

const errorSpacing = scale({
  margin: 'auto',
  display: 'block',
});

export const requestPasswordResetEmail = async (
  email,
  setToast,
  setLoading
) => {
  const [response, responseBody] = await Network.post(
    'account/initiate-password-reset',
    {
      email,
    }
  );
  if (response.ok) {
    setToast({
      text: 'If the email you entered is in our system, a password reset email is on its way to your inbox.',
      type: 'success',
      isOnRight: true,
    });
  } else {
    setToast({
      text: responseBody.message,
      type: 'alert',
      isOnRight: true,
    });
  }
  if (setLoading) setLoading(false);
  return null;
};

const RequestPassReset = (props) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const submitAsync = async () => {
    setLoading(true);
    setError('');
    await requestPasswordResetEmail(email, props.SetToast, setLoading);
  };

  const clearErrors = () => {
    setLoading(false);
    setError('');
  };

  const checkForm = async (event) => {
    event.preventDefault();
    const error = getEmailValidationError(email);
    if (error) {
      setError(error);
      return;
    }
    if (!loading) {
      submitAsync();
    }
  };

  return (
    <div>
      <Helmet>
        <title>Wren | Request Password Reset</title>
      </Helmet>
      <div css={[centerForm, userInfoForm]}>
        <h1 css={{ marginBottom: 16 }}>Reset your password</h1>
        <form
          autoComplete="on"
          noValidate
          onSubmit={(event) => checkForm(event)}
        >
          <CardContainer>
            <CardContent>
              <p css={[center, maxWidth(300), marginAuto]}>
                Enter your email address below to reset your password. <br />
              </p>
              <br />
              <TextInput
                handleInputChange={(answer) => {
                  setEmail(answer);
                  clearErrors();
                }}
                name="email"
                type="email"
                errorBorder={R.includes('Email', error)}
                placeholder="example@email.com"
                autoComplete="email"
                value={email}
              />
              <InputError error={error} injectCss={error && errorSpacing} />
            </CardContent>
          </CardContainer>
          <br />
          <Button text="Send email" size="medium" loading={loading} />
          <br />
          <br />
          <p css={{ padding: 18 }}>
            If the email address doesn’t exist in our system, you will not
            receive a reset email.
            <br />
            <br /> Reach out to <a mailto="support@wren.co">
              support@wren.co
            </a>{' '}
            if you require any assistance.
          </p>
        </form>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      SetToast,
    },
    dispatch
  );
}

RequestPassReset.defaultProps = {};

RequestPassReset.propTypes = {
  SetToast: PropTypes.func.isRequired,
};

export default withRouter(connect(null, mapDispatchToProps)(RequestPassReset));
