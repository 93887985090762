import * as R from 'ramda';

const initialState = {
  country: null,
  countryAverage: null,
  zipDefaults: {
    calculationType: 'individual',
    householdAdults: 1,
    householdChildren: 0,
    numberOfCats: 0,
    numberOfDogs: 0,
    numberOfLargeDogs: 0,
    // AVG DIET //
    dietRedMeatPerWeek: 6,
    dietOtherProteinsPerWeek: 16,
    dietDairyPerWeek: 12,
    dietPreference: 'Neither',
    // AVG TRANSPORT //
    transportMethod: 'car, transitRail, bus, interCityRail',
    transportMilesDrivenPerWeek: 253.84615384615384,
    transportCarMpg: 22,
    transportCarIsElectric: 0,
    transportBusMilesPerWeek: 2.877760692307692,
    transportTransitRailMilesPerWeek: 3.5972008653846155,
    transportDomesticFlightsPerYear: 0,
    transportInternationalFlightsPerYear: 0,
    transportInterCityRailMilesPerYear: 37.410889,
    // AVG ELECTRICITY //
    electricityKwhPerMonth: 319.7442046666667,
    electricityPercentRenewable: 0,
    // AVG NATURAL GAS //
    naturalGasBurnedPerMonth: 19.69057666666667,
    naturalGasBurnedUnit: null,
    // AVG HOME //
    homeSquareFeet: 1591,
    // AVG GOODS //
    goodsFurnitureAppliancesDollarsSpentPerMonth: 17.595344083333334,
    goodsClothesDollarsSpentPerMonth: 13.453505666666667,
    goodsOtherDollarsSpentPerMonth: 31.342971333333335,
    // AVG SERVICES //
    servicesDollarsSpentPerMonth: 132.27904258333334,
    // MISC //
    zipcode: null,
    surveyResponseId: null,
    userId: null,
    annualFootprint: {
      dietTotal: 2.800765,
      transportTotal: 7.512601018163617,
      electricityTotal: 1.265784,
      naturalGasTotal: 1.292488,
      homeTotal: 3.73617,
      goodsTotal: 4.4394422851946675,
      servicesTotal: 4.6248380611102835,
      petsTotal: 0,
      grandTotal: 25.672088364468564,
    },
  },
  annualFootprint: {
    dietTotal: 2.800765,
    transportTotal: 7.512601018163617,
    electricityTotal: 1.265784,
    naturalGasTotal: 1.292488,
    homeTotal: 3.73617,
    goodsTotal: 4.4394422851946675,
    servicesTotal: 4.6248380611102835,
    petsTotal: 0,
    grandTotal: 25.672088364468564,
  },
  userAnswers: {
    calculationType: 'individual',
    householdAdults: 1,
    householdChildren: 0,
    numberOfCats: 0,
    numberOfDogs: 0,
    numberOfLargeDogs: 0,
    cars: [],

    // AVG DIET //
    dietRedMeatPerWeek: null,
    dietOtherProteinsPerWeek: null,
    dietDairyPerWeek: null,
    dietPreference: null,
    // AVG TRANSPORT //
    transportMethod: null,
    transportMilesDrivenPerWeek: null,
    transportCarMpg: null,
    transportCarIsElectric: null,
    transportBusMilesPerWeek: null,
    transportTransitRailMilesPerWeek: null,
    transportInterCityRailMilesPerYear: null,
    transportDomesticFlightsPerYear: null,
    transportInternationalFlightsPerYear: null,
    // AVG ELECTRICITY //
    electricityKwhPerMonth: null,
    electricityPercentRenewable: null,
    // AVG NATURAL GAS //
    naturalGasBurnedPerMonth: null,
    naturalGasBurnedUnit: null,
    // AVG HOME //
    homeSquareFeet: null,
    // AVG GOODS //
    goodsFurnitureAppliancesDollarsSpentPerMonth: null,
    goodsClothesDollarsSpentPerMonth: null,
    goodsOtherDollarsSpentPerMonth: null,
    // AVG SERVICES //
    servicesDollarsSpentPerMonth: null,
    // MISC //
    zipcode: null,
    surveyResponseId: null,
    userId: null,
  },
};

const SetFootprintDefaults = (state, action) => {
  const stateClone = { ...state };
  return R.assoc('zipDefaults', action.zipDefaults, stateClone);
};

const UpdateUserAnswer = (state, action) => {
  const stateClone = { ...state };
  const keysToOmit = ['annualFootprint', 'surveyResponseId', 'userId'];
  const answersToUpdate = R.omit(keysToOmit, action.answer);
  stateClone.userAnswers = R.mergeRight(
    stateClone.userAnswers,
    answersToUpdate
  );
  return stateClone;
};

const UpdateAnnualTotals = (state, action) => {
  const stateClone = { ...state };
  stateClone.annualFootprint = action.totals;
  return stateClone;
};

const SetCountry = (state, action) => R.assoc('country', action.country, state);
const SetCountryAverage = (state, action) =>
  R.assoc('countryAverage', action.countryAverage.toFixed(1), state);

const ClearCalculator = () => {
  return { ...initialState };
};

export default function (state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case 'SET_FOOTPRINT_DEFAULTS':
      return SetFootprintDefaults(state, action);
    case 'UPDATE_USER_ANSWER':
      return UpdateUserAnswer(state, action);
    case 'UPDATE_ANNUAL_TOTALS':
      return UpdateAnnualTotals(state, action);
    case 'CLEAR_CALCULATOR':
      return ClearCalculator();
    case 'SET_COUNTRY':
      return SetCountry(state, action);
    case 'SET_COUNTRY_AVERAGE':
      return SetCountryAverage(state, action);
    default:
      return state;
  }
}
