/** @jsxImportSource @emotion/react */

export default function Checkmark({
  fill = 'white',
  size = 20,
  className,
}: {
  fill?: string;
  size?: number;
  className?: string;
}) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width={size}
      height={size}
      className={className}
    >
      <path
        d="M39.2929 10.7071C39.6834 10.3166 39.6834 9.68345 39.2929 9.29293L35.7071 5.70711C35.3165 5.31658 34.6834 5.31658 34.2929 5.70711L15.7071 24.293C15.3166 24.6836 14.6834 24.6836 14.2929 24.293L5.70714 15.7072C5.31662 15.3167 4.68345 15.3167 4.29292 15.7072L0.70711 19.293C0.316585 19.6835 0.316586 20.3167 0.707114 20.7072L14.2929 34.2928C14.6834 34.6833 15.3165 34.6833 15.7071 34.2928L39.2929 10.7071Z"
        fill={fill}
      />
    </svg>
  );
}
