import * as R from 'ramda';

export const initialState = {
  completedOnboarding: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  annualFootprint: undefined,
  userId: undefined,
  startDate: undefined,
  updatesList: undefined,
  organizationId: undefined,
  organizationAdmin: undefined,
  userInfo: undefined,
  message: undefined,
  hasReceivedIntroEmail: undefined,
  hasReceivedFollowUpEmail: undefined,
  createdAt: undefined,
  currency: undefined,
  isCustomer: undefined,
  username: undefined,
  teamId: undefined,
  hasPassword: undefined,
  ownedTeams: [],
};

const SetUserInfo = (state, action) => {
  return { ...state, ...action.userInfo };
};

const ClearingUserInfo = (state) => {
  const stateClone = { ...state, ...initialState };
  return stateClone;
};

const SetAnnualFootprint = (state, action) => {
  const stateClone = { ...state };
  stateClone.annualFootprint = action.annualFootprint;
  return stateClone;
};

const SetCurrency = (state, action) => {
  const currencyToSet =
    R.isNil(action.currency) || R.isEmpty(action.currency)
      ? undefined
      : action.currency;
  const stateClone = R.assoc('currency', currencyToSet, state);
  return stateClone;
};

const CompletedOnboarding = (state) => {
  const stateClone = { ...state };
  stateClone.completedOnboarding = true;
  return stateClone;
};

export default function (state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case 'SET_CURRENCY':
      return SetCurrency(state, action);
    case 'SET_ANNUAL_FOOTPRINT':
      return SetAnnualFootprint(state, action);
    case 'SET_USER_INFO':
      return SetUserInfo(state, action);
    case 'CLEAR_USER_INFO':
      return ClearingUserInfo(state, action);
    case 'SET_COMPLETED_ONBOARDING':
      return CompletedOnboarding(state, action);
    default:
      return state;
  }
}
