/** @jsxImportSource @emotion/react */

export default function PaperPlane({ size = 60, fill = 'black' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M184.849 10.4261L11.8531 104.025C9.2569 105.453 9.42999 109.261 12.1128 110.472L41.6234 121.593C43.3542 122.243 44.7389 123.541 45.5178 125.228L68.4512 172.439C68.8839 174.516 72.735 175.078 72.7783 172.958L69.2734 133.926C69.4897 131.027 70.5715 128.301 72.4321 126.051L162.132 38.1638C162.652 37.6446 163.387 37.4715 164.08 37.6013C165.551 37.9475 166.156 39.7217 165.205 40.89L87.8798 135.484C86.149 138.08 85.2835 140.244 84.8508 143.273L80.2209 176.982C80.6103 179.925 82.9037 181.05 84.7643 178.41L101.424 158.851C102.549 157.25 104.755 156.904 106.313 158.029L149.238 189.315C151.271 190.83 154.171 189.705 154.69 187.195L189.912 14.3206C190.561 11.3781 187.489 8.99809 184.849 10.4261Z"
        fill={fill}
      />
    </svg>
  );
}
