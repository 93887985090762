import { breakpoints } from '../util/scale';
import useMediaQuery from './useMediaQuery';

// A default value is required only for SSR/SSG outside an effect
export default function useIsMobile(defaultValue?: boolean) {
  const isTabletOrLarger = useMediaQuery(
    breakpoints.tablet,
    typeof defaultValue === 'undefined' ? defaultValue : !defaultValue // Media query is the inverse of the response boolean
  );
  return !isTabletOrLarger;
}
