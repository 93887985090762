/** @jsxImportSource @emotion/react */

import Link from '../UI/Link';
import { CSSObject, PropsOf } from '@emotion/react';
import { noStyleLink } from '../../style/text';

const bgColorHover = {
  '&:hover': {
    backgroundColor: 'var(--gray-9)',
    cursor: 'pointer',
  },
};

const linkContainer: CSSObject = {
  backgroundColor: 'transparent',
  color: 'var(--gray-2)',
  padding: '7px 14px',
  transition: 'background-color 150ms ease-in',
  textAlign: 'left',
  textDecoration: 'none !important',
  fontWeight: 600,
  fontSize: '16px !important',
  position: 'relative',
};

export default function DropdownLink({
  children,
  ...restOfProps
}: PropsOf<typeof Link>) {
  return (
    <div css={bgColorHover}>
      <Link css={noStyleLink} {...restOfProps}>
        <div css={linkContainer}>{children}</div>
      </Link>
    </div>
  );
}
