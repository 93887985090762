/** @jsxImportSource @emotion/react */
import Flex from './Flex';
import { FounderFaces } from './FounderFaces';
import { Headings } from './TextStyles';
import { NavButton } from '../buttons/NavButton';
import { colors, spacing } from '../../style/theme';
import { HTMLAttributes } from 'react';

export function MessageWrenBox({
  color,
  title = 'Questions?',
  text = 'Message a Wren expert on staff.',
  ...restOfProps
}: {
  color?: string;
  title?: string;
  text?: string;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      css={{
        backgroundColor: colors.backgroundGray,
        borderRadius: 30,
        boxSizing: 'border-box',
        marginBottom: spacing.xLarge,
        marginTop: spacing.large,
        padding: spacing.medium,
        textAlign: 'center',
        width: '40%',
      }}
      {...restOfProps}
    >
      <FounderFaces size={50} css={{ marginTop: '-15%' }} />
      <Headings.Large
        font="sans"
        weight={600}
        css={{ maxWidth: 300, margin: 0, marginTop: spacing.default }}
      >
        <span css={{ color: color ?? colors.textSecondary }}>{title}</span>
        <br />
        {text}
      </Headings.Large>
      <NavButton
        color={color ?? colors.wrenOrange}
        href="mailto:support@wren.co"
        shape="pill"
        buttonType="filled"
      >
        Email
      </NavButton>
    </Flex>
  );
}
