/** @jsxImportSource @emotion/react */

export default function TistGrove({ width = 50 }) {
  return (
    <svg
      width={width}
      height={(width * 154) / 167}
      viewBox="0 0 167 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M128.5 93C143.854 93 156.3 80.5535 156.3 65.2C156.3 49.8465 143.854 37.4 128.5 37.4C113.147 37.4 100.7 49.8465 100.7 65.2C100.7 80.5535 113.147 93 128.5 93Z"
          fill="var(--success-green)"
        />
        <path
          opacity="0.6"
          d="M130.599 62.0071L125.299 61.9939L125.104 139.994L130.404 140.007L130.599 62.0071Z"
          fill="var(--success-green)"
        />
      </g>
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M41.6002 104.2C52.8668 104.2 62.0002 95.0666 62.0002 83.8C62.0002 72.5334 52.8668 63.4 41.6002 63.4C30.3336 63.4 21.2002 72.5334 21.2002 83.8C21.2002 95.0666 30.3336 104.2 41.6002 104.2Z"
          fill="var(--success-green)"
        />
        <path
          opacity="0.3"
          d="M43.2003 81.5H39.3003V138.7H43.2003V81.5Z"
          fill="var(--success-green)"
        />
      </g>
      <path
        d="M83.0001 75.9C103.932 75.9 120.9 58.9316 120.9 38C120.9 17.0684 103.932 0.0999756 83.0001 0.0999756C62.0685 0.0999756 45.1001 17.0684 45.1001 38C45.1001 58.9316 62.0685 75.9 83.0001 75.9Z"
        fill="var(--success-green)"
      />
      <path
        opacity="0.5"
        d="M63.0855 37.625L58.4741 43.2839L81.11 61.7296L85.7214 56.0706L63.0855 37.625Z"
        fill="#4E5B63"
      />
      <path d="M85.9001 33.6H78.6001V140.1H85.9001V33.6Z" fill="#4E5B63" />
      <path
        d="M156.3 154H11.0999C5.49991 154 0.899902 149.5 0.899902 143.8V134.3H166.3V143.8C166.4 149.5 161.9 154 156.3 154Z"
        fill="#72D6A4"
      />
    </svg>
  );
}
