import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetError } from '../actions/toaster';
import { SetUserInfo } from '../actions/user';
import {
  isCustomDurationCartItem,
  isCustomDurationPaymentPlanCartItem,
  isSubscriptionCartItem,
  isTeamSubscriptionCartItem,
} from '../lib/cart';
import { Network } from '@wren/shared';
import { State } from '../store';
import {
  CartItem,
  CartWithItems,
  CustomDurationCartDetails,
  GiftCartDetails,
  FlightCartDetails,
  FundContributionCartDetails,
  OneOffCartDetails,
  SubscriptionCartDetails,
  CustomDurationPaymentPlanDetails,
  TeamSubscriptionCartDetails,
} from '../types';
import { convertCurrency, getCurrencyObjFromCode } from '../util/conversions';

export default function useCart() {
  const dispatch = useDispatch();
  const { cart, currency: userCurrency } = useSelector(
    (state: State) => state.user
  );

  const getSubscriptionCartItem = () => {
    return cart?.items.find(isSubscriptionCartItem);
  };

  const getTeamSubscriptionCartItem = () => {
    return cart?.items.find(isTeamSubscriptionCartItem);
  };

  const getCustomDurationCartItem = () => {
    return cart?.items.find(isCustomDurationCartItem);
  };

  const getCustomDurationPaymentPlanCartItem = () => {
    return cart?.items.find(isCustomDurationPaymentPlanCartItem);
  };

  const getFirstPaymentAmount = () => {
    if (!cart) {
      return 0;
    }

    // A payment amount can't be less than zero
    return Math.max(0, Math.floor(cart.total - cart.discount));
  };

  const addCartItem = useCallback(
    async (
      {
        sku,
        amountInUsdCents,
        detailsJson,
      }: Pick<CartItem, 'sku' | 'amountInUsdCents' | 'detailsJson'>,
      options: {
        quantity?: number;
        replaceItems: boolean;
        isTaxDeductible?: boolean;
      } = {
        quantity: 1,
        replaceItems: false,
        isTaxDeductible: false,
      }
    ) => {
      const [response, responseBody] = await Network.post<CartWithItems>(
        `carts/add-item?quantity=${options.quantity ?? 1}&replaceItems=${
          options.replaceItems ?? false
        }&isTaxDeductible=${options.isTaxDeductible ?? false}`,
        {
          sku,
          amountInUsdCents,
          detailsJson,
        }
      );

      if (response.ok && responseBody) {
        dispatch(SetUserInfo({ cart: responseBody }));
      } else {
        dispatch(
          SetError(
            'Something went wrong and we could not add this item to your cart.'
          )
        );
      }
    },
    [cart?.id]
  );

  const removeCartItem = useCallback(
    async (id: string) => {
      if (!cart) {
        return;
      }

      const [response, responseBody] = await Network.httpDelete<CartWithItems>(
        `carts/${cart.id}/items/${id}`
      );

      if (response.ok && responseBody) {
        dispatch(SetUserInfo({ cart: responseBody }));
      } else {
        dispatch(
          SetError(
            'Something went wrong and we could not remove this item from your cart.'
          )
        );
      }
    },
    [cart?.id]
  );

  const addSubscriptionCartItem = useCallback(
    async (
      {
        amountInUserCurrency,
        ...details
      }: SubscriptionCartDetails & {
        amountInUserCurrency: number;
      },
      options: { replaceItems: boolean } = {
        replaceItems: false,
      }
    ) => {
      const currency = getCurrencyObjFromCode(userCurrency);

      const amountInUsdCents = Math.floor(
        convertCurrency(amountInUserCurrency, currency.code, 'USD')
      );

      await addCartItem(
        {
          sku: 'subscription',
          amountInUsdCents,
          detailsJson: details,
        },
        options
      );
    },
    [cart?.id, userCurrency]
  );

  const addTeamSubscriptionCartItem = useCallback(
    async (
      details: TeamSubscriptionCartDetails,
      options: { replaceItems: boolean } = {
        replaceItems: false,
      }
    ) => {
      await addCartItem(
        {
          sku: 'team-subscription',
          amountInUsdCents: 0, // team subscriptions are always free to begin with
          detailsJson: details,
        },
        options
      );
    },
    [cart?.id, userCurrency]
  );

  const updateSubscriptionCartItem = useCallback(
    async (
      updates: Partial<
        CartItem & { detailsJson: Partial<SubscriptionCartDetails> }
      >
    ) => {
      const existingSubscriptionCartItem = getSubscriptionCartItem();

      if (!existingSubscriptionCartItem) {
        return;
      }

      const {
        detailsJson: { billingCycle },
      } = existingSubscriptionCartItem;

      const newBillingCycle = updates.detailsJson?.billingCycle;
      let amount: number = existingSubscriptionCartItem.amountInUsdCents;

      if (newBillingCycle && newBillingCycle !== billingCycle) {
        if (newBillingCycle === 'month') {
          amount = Math.round(amount / 12);
        } else if (newBillingCycle === 'year') {
          amount *= 12;
        }
      }

      await addCartItem({
        ...existingSubscriptionCartItem,
        ...{
          ...updates,
          amountInUsdCents: amount,
          detailsJson: {
            ...existingSubscriptionCartItem.detailsJson,
            ...updates.detailsJson,
          },
        },
        sku: 'subscription',
      });
    },
    [cart?.id, userCurrency]
  );

  const addCustomDurationCartItem = useCallback(
    async ({
      amountInUsdCents,
      type,
      startDate,
      endDate,
      intent,
      isAddedFromCheckout,
    }: CustomDurationCartDetails & {
      amountInUsdCents: number;
    }) => {
      const details: CustomDurationCartDetails = {
        type,
        startDate,
        endDate,
        intent,
        isAddedFromCheckout,
      };

      await addCartItem({
        sku: 'custom-duration',
        amountInUsdCents,
        detailsJson: details,
      });
    },
    [cart?.id, userCurrency]
  );

  const addCustomDurationPaymentPlanCartItem = useCallback(
    async ({
      amountInUsdCents,
      numberOfPayments,
      portfolioId,
      intent,
    }: CustomDurationPaymentPlanDetails & {
      amountInUsdCents: number;
    }) => {
      const details: CustomDurationPaymentPlanDetails = {
        numberOfPayments,
        portfolioId,
        intent,
      };

      await addCartItem({
        sku: 'custom-duration-payment-plan',
        amountInUsdCents,
        detailsJson: details,
      });
    },
    [cart?.id, userCurrency]
  );

  const addOneOffCartItem = useCallback(
    async ({
      amountInUsdCents,
      ...cartDetails
    }: {
      amountInUsdCents: number;
      isTaxDeductible?: boolean;
      replaceItems?: boolean;
    } & OneOffCartDetails) => {
      await addCartItem(
        {
          sku: 'one-off',
          amountInUsdCents,
          detailsJson: cartDetails,
        },
        {
          replaceItems: !!cartDetails.replaceItems,
          isTaxDeductible: cartDetails.isTaxDeductible,
        }
      );
    },
    [cart?.id, userCurrency]
  );

  const addFlightCartItem = useCallback(
    async ({
      amountInUsdCents,
      replaceItems = false,
      ...cartDetails
    }: {
      amountInUsdCents: number;
      replaceItems?: boolean;
    } & FlightCartDetails) => {
      await addCartItem(
        {
          sku: 'flight',
          amountInUsdCents,
          detailsJson: cartDetails,
        },
        { replaceItems }
      );
    },
    [cart?.id, userCurrency]
  );

  const addGiftCartItem = useCallback(
    async ({
      amountInUsdCents,
      ...cartDetails
    }: {
      amountInUsdCents: number;
      replaceItems?: boolean;
    } & GiftCartDetails) => {
      await addCartItem({
        sku: 'gift',
        amountInUsdCents,
        detailsJson: cartDetails,
      });
    },
    [cart?.id, userCurrency]
  );

  const addFundContributionCartItem = useCallback(
    async ({
      amountInUsdCents,
      ...cartDetails
    }: {
      amountInUsdCents: number;
      replaceItems?: boolean;
      isTaxDeductible?: boolean;
    } & FundContributionCartDetails) => {
      await addCartItem(
        {
          sku: 'fund-contribution',
          amountInUsdCents,
          detailsJson: cartDetails,
        },
        {
          replaceItems: !!cartDetails.replaceItems,
          isTaxDeductible: cartDetails.isTaxDeductible,
        }
      );
    },
    [cart?.id, userCurrency]
  );

  const closeCart = useCallback(async () => {
    if (!cart) {
      return;
    }

    const [response, responseBody] = await Network.post<CartWithItems>(
      `carts/close`
    );

    if (response.ok && responseBody) {
      dispatch(SetUserInfo({ cart: responseBody }));
    } else {
      dispatch(
        SetError('Something went wrong and we could not close your cart.')
      );
    }
  }, [cart?.id]);

  const openCart = useCallback(async () => {
    if (!cart || cart.completedAt) {
      return;
    }

    const [response, responseBody] = await Network.post<CartWithItems>(
      `carts/open`
    );

    if (response.ok && responseBody) {
      dispatch(SetUserInfo({ cart: responseBody }));
    } else {
      dispatch(
        SetError('Something went wrong and we could not re-open your cart.')
      );
    }
  }, [cart?.id]);

  return {
    cart,
    closeCart,
    openCart,
    getSubscriptionCartItem,
    addSubscriptionCartItem,
    addFundContributionCartItem,
    updateSubscriptionCartItem,
    addCustomDurationCartItem,
    addCustomDurationPaymentPlanCartItem,
    getCustomDurationCartItem,
    addOneOffCartItem,
    addCartItem,
    removeCartItem,
    getFirstPaymentAmount,
    addGiftCartItem,
    addFlightCartItem,
    getCustomDurationPaymentPlanCartItem,
    addTeamSubscriptionCartItem,
    getTeamSubscriptionCartItem,
  };
}
