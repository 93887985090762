import { useEffect } from 'react';

declare global {
  interface Window {
    cf_chat_loader?: unknown;
  }
}

let script: HTMLScriptElement | undefined = undefined;

// https://projectwren.slack.com/archives/C8V7RV6TA/p1698853065059009
export default function GetSignals() {
  useEffect(() => {
    if (typeof window !== 'undefined' && !script) {
      window.cf_chat_loader = function cf_chat_loader() {
        return {
          packet_code: 'dedb915defbd30803b6c3451e4b26cc74fa90b68',
          app_url: 'https://app.chatfunnels.com/chat-client/',
          service_address: 'app.chatfunnels.com/api/chat-service/a',
        };
      };
      script = document.createElement('script');
      script.async = true;
      script.src = 'https://app.chatfunnels.com/chat-client/chat-loader.js';
      window.document.body.appendChild(script);
    }
  }, []);
  return null;
}
