// Mirrors css vars defined in ./global.js
export const colors = {
  // by purpose
  backgroundGray: '#fafafa',
  backgroundBeige: '#f4f3ef',
  backgroundDarkGreen: '#072f10',
  backgroundLightBlue: '#ecf4fe',
  textPrimary: '#333333',
  textSecondary: '#666666',
  linkBlue: '#3789c7', // also used #168fbf, or #2E9BD8
  linkBlueHover: '#327bb3',
  linkBlueLowOpacity: '#3789c720',
  borderColor: '#cccccc', // might need border-light & border-dark?
  boxShadowColor: 'rgba(0, 0, 0, 0.15)',

  textLight: 'white', // should be used for text on images for max contrast
  textDark: 'black', // should not be changed in dark mode

  // commonly used accent colors
  successGreen: '#27ae60',
  successGreenHover: '#2dc985',
  errorRed: '#db3f40',
  wrenOrange: '#f79f22',
  wrenOrangeHover: '#e89013', // #FFAC35 optional
  wrenGreen: '#00836c',

  calendarGreen: '#5EBE61',
  calendarGreenLowOpacity: '#5EBE6120',

  // "one-off" accent colors
  blue: '#1269d3',
  gold: '#ffc500',
  purple: '#ce7fd8',

  // grays
  gray0: '#030303', // black
  gray1: '#1a1a1a',
  gray2: '#333333', // common for text, use this for fills
  gray3: '#4d4d4d',
  gray4: '#666666',
  gray5: '#7f7f7f',
  gray6: '#999999',
  gray7: '#b3b3b3', // similar to secondary text
  gray8: '#cccccc', // common for borders
  gray9: '#ebebeb',

  // low opacity colors:
  successGreenLowOpacity: '#e8fcf0', // for actually transparent version: #27ae6015
  wrenOrangeLowOpacity: '#fdf3e5', // #f79f2220
  wrenGreenLowOpacity: '#00836C1A',

  // project categories
  conservationBlue: '#2790f0',
  policyPurple: '#5b27f0',
  policyPurpleLowOpacity: '#EFE9FD',

  // portfolio branding
  trailblazerOrange: '#f67339',
  trailblazerOrangeLowOpacity: 'rgba(236, 54, 65, 0.1)',
} as const;

export const fontSizes = {
  citation: 12,
  smallParagraph: 14,

  paragraph: 16,
  headingSmall: 18,
  headingMedium: 20,
  headingLarge: 30,
  headingXLarge: 40,
  headingMassive: 60,

  paragraphMobile: 15,
  headingSmallMobile: 16,
  headingMediumMobile: 18,
  headingLargeMobile: 24,
  headingXLargeMobile: 28,
  headingMassiveMobile: 38,
} as const;

export const oldFontSizes = {
  // old styles
  caption: '12px',
  button: '14px',
  body: '14px',
  bodyLarge: '16px',
  smallHeader: '20px',
  medium: '26px',
  large: '32px',
  header: '40px',
  massive: '65px',
} as const;

export const fonts = {
  Serif: "'Blanco', serif",
  Sans: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  /* https://rsms.me/inter: "The larger "display" optical-size designs offers clean lines, smooth curves and delicate details for excellent rhythm of large text." */
  Display:
    "'InterDisplay', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
} as const;

export const spacing = {
  tiny: 2,
  xxSmall: 4,
  xSmall: 8,
  small: 12,
  default: 16,
  medium: 24,
  large: 40,
  xLarge: 64,
  xxLarge: 96,
  massive: 144,
} as const;

export const pageWidth = 680;
