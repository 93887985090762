import Cookie from 'js-cookie';

const TEST_KEY = '__test__';
export function hasLocalStorage() {
  if (!(typeof window !== 'undefined' && window)) {
    return false;
  }
  try {
    window.localStorage.setItem(TEST_KEY, '1');
    window.localStorage.removeItem(TEST_KEY);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

const FALLBACK_COOKIE_STORAGE = new Map<string, string>();
const FALLBACK_DOCUMENT_STORAGE = new Map<string, string>();

const HAS_LOCAL_STORAGE = hasLocalStorage();

export const cookies = {
  set(key: string, value: string, options?: Parameters<typeof Cookie.set>[2]) {
    if (navigator.cookieEnabled) {
      Cookie.set(key, value, options);
    } else {
      FALLBACK_COOKIE_STORAGE.set(key, value);
    }
  },
  getall() {
    if (navigator.cookieEnabled) {
      return Cookie.get();
    }

    // Maybe one day CRA will support fromEntries, which would be more efficient.
    const result: { [key: string]: string } = {};
    for (const key of Object.keys(FALLBACK_COOKIE_STORAGE)) {
      const value = FALLBACK_COOKIE_STORAGE.get(key);
      if (value !== undefined) {
        result[key] = value;
      }
    }

    return result;
  },
  get(key: string) {
    if (navigator.cookieEnabled) {
      return Cookie.get(key);
    }

    return FALLBACK_COOKIE_STORAGE.get(key);
  },
  remove(key: string) {
    if (navigator.cookieEnabled) {
      Cookie.remove(key);
    } else {
      FALLBACK_COOKIE_STORAGE.delete(key);
    }
  },
};

export const storage = {
  set(key: string, value: string) {
    if (HAS_LOCAL_STORAGE) {
      window.localStorage.setItem(key, value);
    } else {
      FALLBACK_DOCUMENT_STORAGE.set(key, value);
    }
  },
  get(key: string) {
    if (HAS_LOCAL_STORAGE) {
      return window.localStorage.getItem(key);
    }

    return FALLBACK_DOCUMENT_STORAGE.get(key);
  },
  remove(key: string) {
    if (HAS_LOCAL_STORAGE) {
      window.localStorage.removeItem(key);
    } else {
      FALLBACK_DOCUMENT_STORAGE.delete(key);
    }
  },
  clear() {
    if (HAS_LOCAL_STORAGE) {
      window.localStorage.clear();
    } else {
      FALLBACK_DOCUMENT_STORAGE.clear();
    }
  },
};
