import { sha256 } from 'js-sha256';
import type { CookieAttributes } from 'js-cookie';
import flatten from 'lodash/flatten';

declare global {
  interface Window {
    CI_EXPERIMENT_SETTINGS?: { [key: string]: string };
  }
}

export type Cookies = {
  set: (key: string, value: string, options?: CookieAttributes) => void;
  getall: () => { [key: string]: string };
  get: (key: string) => string | undefined;
  remove: (key: string) => void;
};

export const EXPERIMENTS = {
  join_links_header_desktop: [
    'control',
    'start_link_header',
    'explainer_header',
  ],
  join_links_landing_header_mobile: [
    'control',
    'start_link_header',
    'explainer_header',
  ],
  landing_and_start_header: ['control', 'simple', 'all_in_one'],
} as const;

const allTreatments = flatten(Object.values(EXPERIMENTS));

type Experiments = typeof EXPERIMENTS;
export type ExperimentName = keyof Experiments;
export type Treatment = (typeof allTreatments)[number];

function isValidTreatmentName(name: string | undefined): name is Treatment {
  return allTreatments.includes(name as Treatment);
}

function getKey(experimentName: ExperimentName) {
  return `experiment_${experimentName}`;
}

export function isExperimentKey(
  key: string
): key is `experiment_${ExperimentName}` {
  return /experiment_.*/.test(key);
}

function getActivationKey(experimentName: ExperimentName) {
  return `activated_${getKey(experimentName)}`;
}

export function isActivationKey(
  key: string
): key is `activated_experiment_${ExperimentName}` {
  return /activated_experiment_.*/.test(key);
}

export function assignExperiments(userId: number, cookies: Cookies) {
  const experimentsToAssign = Object.keys(EXPERIMENTS).filter(
    (experiment) => !cookies.get(getKey(experiment as ExperimentName))
  ) as ExperimentName[];

  if (experimentsToAssign.length === 0) {
    return;
  }

  const assignments: Partial<Record<ExperimentName, Treatment>> = {};

  for (const experiment of experimentsToAssign) {
    const key = getKey(experiment);
    const assignment = getAssignmentForExperiment(userId, experiment, cookies);

    assignments[experiment] = assignment;
    const expiry = new Date();
    expiry.setFullYear(expiry.getFullYear() + 1);
    cookies.set(key, assignment, { expires: expiry });
  }

  return assignments;
}

export function getAssignmentForExperiment(
  userId: number,
  experimentName: ExperimentName,
  cookies: Pick<Cookies, 'get'>
): Treatment {
  const key = getKey(experimentName);
  const storedExperiment = cookies.get(key);

  const variants = EXPERIMENTS[experimentName];

  // Experiment name is not listed in EXPERIMENTS (probably typo)
  if (!variants) {
    throw new Error(
      `Unknown experiment name ${experimentName} passed to getTreatmentForExperiment. Did you misspell it?`
    );
  }

  // CI wants to test an experiment
  if (
    typeof window !== 'undefined' &&
    window.CI_EXPERIMENT_SETTINGS &&
    experimentName in window.CI_EXPERIMENT_SETTINGS
  ) {
    const treatment = window.CI_EXPERIMENT_SETTINGS[experimentName];
    if (isValidTreatmentName(treatment)) {
      return treatment;
    }
  }

  // CI is running normally
  if (process.env.REACT_APP_BUILT_WITH_CI) {
    return 'control';
  }

  // We have already determined the treatment for this user
  if (isValidTreatmentName(storedExperiment)) {
    return storedExperiment;
  }

  // To get the value, we hexdigest userId, take the last 13 digits and convert it to an integer.
  // We then use that int to determine which bucket of the ab test it falls into.
  // This is a way to deterministically get a random treatment for a user. Frontend or the backend,
  // it will be the same. If they sign into another machine, it will also be the same.
  const index =
    Number(
      sha256(String(userId) + experimentName) // include the experiment name to get a different result for each experiment
        .replace(/\D/g, '')
        .slice(-13)
    ) % variants.length;

  return variants[index];
}

export function hasUserActivated(
  experimentName: ExperimentName,
  cookies: Pick<Cookies, 'get'>
) {
  const activationKey = getActivationKey(experimentName);
  return cookies.get(activationKey);
}

// This can be run in node (see /backend/src/lib/cookies.ts)
export function getTreatment({
  userId,
  experimentName,
  cookies,
  activate,
}: {
  userId: number;
  experimentName: ExperimentName;
  cookies: Cookies;
  activate: (assignment: string) => void;
}) {
  const assignment = getAssignmentForExperiment(
    userId,
    experimentName,
    cookies
  );

  if (!hasUserActivated(experimentName, cookies)) {
    const expiry = new Date();
    expiry.setFullYear(expiry.getFullYear() + 1);
    cookies.set(getActivationKey(experimentName), assignment, {
      expires: expiry,
    });

    activate(assignment);
  }

  return assignment;
}

export function reset(cookies: Cookies) {
  Object.keys(cookies.getall()).forEach((storageKey) => {
    if (storageKey.includes('experiment')) {
      cookies.remove(storageKey);
    }
  });
}
