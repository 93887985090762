/** @jsxImportSource @emotion/react */
import { CSSInterpolation, CSSObject } from '@emotion/serialize';
import { PropsWithChildren, useEffect, useState } from 'react';
import { NAV_HEIGHT } from '../../config/constants';
import * as dropdownContent from '../../config/navDropdowns';
import { changeOpacity } from '../../lib/colors';
import zIndex from '../../lib/zIndex';
import { colors, fonts, spacing } from '../../style/theme';
import { SerializedUser } from '../../types';
import { getCurrencyObjFromCode } from '../../util/conversions';
import { scale } from '../../util/scale';
import { ArrowNavButton } from '../buttons/ArrowNavButton';
import ArrowDown from '../svgs/ArrowDown';
import Logo from '../svgs/Logo';
import Flex from '../UI/Flex';
import { Link } from '../UI/Link';
import NewTag from '../UI/NewTag';
import CtaButton from './CtaButton';
import CurrencyMenu, { CurrencyButton, CurrencyPopup } from './CurrencyMenu';
import FullWidthDropdown from './FullWidthDropdown';
import NavDropdown from './NavDropdown';
import Notifications from './Notifications';
import { NotificationsButton } from './NotificationsButton';
import UserCircleAvatar from './UserCircleAvatar';

export const divider = (
  <div
    css={[
      {
        backgroundColor: 'rgba(196, 196, 196, 0.8)',
        display: 'inline-block',
        height: '1.25em',
        margin: `0 ${spacing.small}px`,
        width: 1,
      },
    ]}
  >
    &#8203; {/* zero-width space */}
  </div>
);

const linkStyle = (color: string) =>
  ({
    color,
    fontFamily: fonts.Sans,
    textDecoration: 'none !important',
    marginLeft: '2px',
    marginRight: '2px',
    fontSize: '16px',
    padding: '4px 8px',
    borderRadius: '16px',
    cursor: 'pointer',
    display: 'inline',
    fontWeight: 600,
    transition: 'color 200ms ease',
    '&:hover, :active, :focus': {
      color: color,
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
  } as CSSObject);

const navbarOverlay: CSSObject = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: zIndex('navbarOverlay'),
};

export function NavContainer({
  children,
  className,
  fixed,
  isTransparent,
  hasBottomBorder,
}: PropsWithChildren<{
  className?: string;
  fixed: boolean;
  fixedStyle?: CSSInterpolation;
  isTransparent?: boolean;
  hasBottomBorder?: boolean;
}>) {
  return (
    <div css={{ position: 'relative' }}>
      <div
        className={className}
        css={[
          {
            boxSizing: 'border-box',
            display: 'flex',
            height: NAV_HEIGHT,
            justifyContent: 'center',
            padding: '8px 0',
            position: fixed ? 'fixed' : 'absolute',
            top: 0,
            transition: 'background-color 150ms linear',
            width: '100%',
            zIndex: zIndex('navbar'),
          },
          isTransparent
            ? {
                backgroundColor: 'transparent',
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.00) 100%)',
                backdropFilter: 'blur(3px)',
              }
            : {
                backgroundColor: 'white',
              },
          (hasBottomBorder || fixed) && {
            borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
          },
        ]}
      >
        <nav
          css={{
            alignItems: 'center',
            boxSizing: 'border-box',
            display: 'flex',
            gap: spacing.small,
            height: '100%',
            justifyContent: 'space-between',
            padding: `0 ${spacing.default}px`,
            width: '100%',
          }}
        >
          {children}
        </nav>
      </div>
    </div>
  );
}

export function DesktopNav({
  user,
  className,
  isTransparent = false,
  hasBottomBorder = true,
  fixedScrollY,
  pathname,
  handleCTAClick,
  onCurrencyChange,
}: {
  user?: SerializedUser;
  className?: string;
  isTransparent?: boolean;
  hasBottomBorder?: boolean;
  fixedScrollY?: number; // Distance from the top at which scrolling causes the nav position to become 'fixed' to the window
  handleCTAClick: (eventLocation: string) => void;
  onCurrencyChange: (currencyCode: string) => void;
  pathname: string;
}) {
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const userCurrencyObj = getCurrencyObjFromCode(user?.currency || 'USD');
  const [isUserHoveringOverNav, setIsUserHoveringOverNav] = useState(false);
  const [showCurrencyPopup, setShowCurrencyPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState('');

  // Check what the current page is
  useEffect(() => {
    if (pathname.includes('business')) {
      setCurrentPage('business');
    }
  }, [pathname]);

  useEffect(() => {
    const onScroll = () => {
      if (
        typeof fixedScrollY !== 'undefined' &&
        window.scrollY >= fixedScrollY
      ) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [fixedScrollY]);

  const color =
    isTransparent && !isScrolledDown && !isUserHoveringOverNav
      ? 'white'
      : colors.textPrimary;

  const isActuallyTransparent =
    isTransparent && !isScrolledDown && !isUserHoveringOverNav;

  return (
    <>
      <NavContainer
        className={className}
        fixed={isScrolledDown}
        isTransparent={isActuallyTransparent}
        hasBottomBorder={hasBottomBorder && !isActuallyTransparent}
      >
        <Flex align="center" gap={spacing.medium}>
          <Link
            to={
              user?.hasPassword
                ? '/dashboard'
                : currentPage.includes('business')
                ? '/business'
                : '/' // If the user is logged in, the Wren logo takes them to their dashboard. If not, it either takes them to the business or consumer landing page.
            }
          >
            <Logo
              color={color}
              css={{
                lineHeight: 0,
                maxWidth: '100px',
              }}
              width="90"
            />
          </Link>
          <FullWidthDropdown
            dropdownContent={
              location.pathname.includes('/business')
                ? dropdownContent.businessSolutions
                : dropdownContent.products(user)
            }
            name={
              location.pathname.includes('/business')
                ? 'Business solutions'
                : 'Products'
            }
            css={linkStyle(color)}
            onDropdownEnter={() => setIsUserHoveringOverNav(true)}
            onDropdownLeave={() => setIsUserHoveringOverNav(false)}
          />
          <FullWidthDropdown
            dropdownContent={dropdownContent.resources}
            name="Resources"
            onDropdownEnter={() => setIsUserHoveringOverNav(true)}
            onDropdownLeave={() => setIsUserHoveringOverNav(false)}
            css={linkStyle(color)}
          />
          {!user?.team && currentPage !== 'business' && (
            <Link
              to="/teams"
              css={[
                linkStyle(color),
                // we hide this link earlier
                `@media (max-width: 950px) {
                  display: none;
                }`,
              ]}
            >
              For business
              <NewTag
                css={scale({
                  position: 'relative',
                  top: -8,
                  marginLeft: spacing.xxSmall,
                  backgroundColor: colors.wrenOrange,
                  textTransform: 'none',
                })}
              />
            </Link>
          )}
          {!user?.team && currentPage.includes('business') && (
            <Link
              to="/"
              css={[
                linkStyle(color),
                // we hide this link earlier
                `@media (max-width: 950px) {
                  display: none;
                }`,
              ]}
            >
              For individuals
            </Link>
          )}
          {currentPage == 'business' && (
            <Link
              to="/business/jameo-case-study"
              css={[
                linkStyle(color),
                // we hide this link earlier
                `@media (max-width: 950px) {
                  display: none;
                }`,
              ]}
            >
              ROI case study
              <NewTag
                css={scale({
                  position: 'relative',
                  top: -8,
                  marginLeft: spacing.xxSmall,
                  backgroundColor: colors.wrenOrange,
                  textTransform: 'none',
                })}
              />
            </Link>
          )}
          {/* {!user?.hasPassword && (
            <Link
              to="/dashboard/actions"
              css={[
                linkStyle(color),
                `@media (max-width: 1050px) {
                  display: none;
                }`,
              ]}
            >
              Actions
            </Link>
          )} */}
        </Flex>
        <Flex align="center">
          <CtaButton
            user={user}
            handleCTAClick={handleCTAClick}
            pathname={pathname}
            customizedOffsetButton={
              <FullWidthDropdown
                css={[
                  linkStyle(color),
                  {
                    '&:hover, :active, :focus': {
                      backgroundColor: 'transparent',
                    },
                  },
                ]}
                name="Offset"
                dropdownContent={dropdownContent.offsets}
                onDropdownEnter={() => setIsUserHoveringOverNav(true)}
                onDropdownLeave={() => setIsUserHoveringOverNav(false)}
                customNavItem={
                  <ArrowNavButton
                    href="/offset-anything"
                    buttonType="filled"
                    padding="xTight"
                    color={colors.wrenOrange}
                    css={[
                      scale({
                        fontSize: ['inherit', 16],
                        fontWeight: 500,
                      }),
                      isUserHoveringOverNav && {
                        boxShadow:
                          '0px 3.13342px 7.83355px 0px rgba(247, 159, 34, 0.7)',
                        backgroundColor: changeOpacity(colors.wrenOrange, 0.85),
                        borderColor: changeOpacity(colors.wrenOrange, 0.85),
                      },
                    ]}
                  >
                    Offset
                  </ArrowNavButton>
                }
              />
            }
          />
          {divider}
          {user?.hasPassword ? (
            <>
              {user.completedOnboarding && (
                <NavDropdown
                  isWide
                  position="right"
                  customNavItem={
                    <NotificationsButton
                      css={{
                        color,
                        '&:hover': {
                          color,
                        },
                      }}
                    />
                  }
                  css={{
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    cursor: 'pointer',
                    width: 32,
                    height: 32,
                    marginLeft: '4px',
                    marginRight: '8px',
                  }}
                  renderDropdownContent={() => <Notifications />}
                />
              )}
              <NavDropdown
                customNavItem={
                  <Link
                    to="/dashboard"
                    css={{
                      textDecoration: 'none',
                      color: color,
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      '&:hover, :active, :focus': {
                        color: color,
                      },
                    }}
                  >
                    <UserCircleAvatar user={user} fill={color} />
                    <div css={{ width: 6, height: 0 }} />
                    <ArrowDown color="currentColor" size={10} />
                  </Link>
                }
                css={[
                  linkStyle(color),
                  {
                    background: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: 100,
                    padding: spacing.tiny,
                    position: 'relative',
                  },
                ]}
                position="right"
                dropdownContent={dropdownContent.profile(user)}
              />
            </>
          ) : (
            <>
              <Link to="/login" css={linkStyle(color)}>
                Log in
              </Link>
              {divider}
              {userCurrencyObj && (
                <CurrencyButton
                  user={user}
                  onClick={() => setShowCurrencyPopup(!showCurrencyPopup)}
                >
                  <Flex
                    align="center"
                    justify="center"
                    style={{
                      boxSizing: 'border-box',
                      fontSize: 16,
                    }}
                    css={linkStyle(color)}
                  >
                    {userCurrencyObj.symbol} {userCurrencyObj.code}
                  </Flex>
                </CurrencyButton>
              )}
            </>
          )}
        </Flex>
      </NavContainer>
      <CurrencyPopup
        user={user}
        onCurrencyChange={onCurrencyChange}
        setShowDialog={setShowCurrencyPopup}
        showDialog={showCurrencyPopup}
      ></CurrencyPopup>
      {isUserHoveringOverNav && <div css={navbarOverlay} />}
    </>
  );
}
