/** @jsxImportSource @emotion/react */

import { connect, useDispatch, useSelector } from 'react-redux';
import { scale } from '../../util/scale';

import Button from '../../components/buttons/Button';
import DesktopHero from '../Landing/DesktopHero';
import DesktopLanding from '../Landing/Desktop';

import { GetStarted } from '../../actions/user';
import useMainCTAText from '../../hooks/useMainCTAText';

import RoundedCountrySelectWithCTA from '../../components/calculator/RoundedCountrySelectWithCTA';
import {
  SetCalculatorQuestionIndex,
  SetCountry,
} from '../../actions/calculator';
import useLandingAndStartHeaderExperiment from '../../hooks/useLandingAndStartHeaderExperiment';
import SpecialOfferPopup from '../../components/experiments/SpecialOfferPopup';
import { State } from '../../store';
import { useHistory } from 'react-router-dom';
import { SpecialOffer } from '../../types';

const ctaButton = scale({
  width: 'fit-content',
  fontSize: '22px',
});

const contentStyles = {
  width: '100%',
};

export default function SpecialOfferLanding({
  specialOffer,
}: {
  specialOffer: SpecialOffer;
}) {
  const history = useHistory();
  const user = useSelector((state: State) => state.user);
  const dispatch = useDispatch();

  const ctaText = useMainCTAText();

  function handleCTAClick(eventLocation: string) {
    dispatch(
      GetStarted({
        history: history,
        eventLocation,
        platform: 'desktop',
      })
    );
  }

  async function handleSubmitCountry(country: string) {
    dispatch(SetCountry(country));
    dispatch(SetCalculatorQuestionIndex(1));
    handleCTAClick('hero');
  }

  const cta = !user.annualFootprint ? (
    <RoundedCountrySelectWithCTA
      handleSubmitCountry={handleSubmitCountry}
      handleCTAClick={() => handleCTAClick('hero')}
    />
  ) : (
    <Button
      css={ctaButton}
      text={ctaText}
      size="extraLarge"
      onClickFn={() => handleCTAClick('hero')}
    />
  );

  const headers = useLandingAndStartHeaderExperiment();

  return (
    <>
      <SpecialOfferPopup specialOffer={specialOffer} />

      <DesktopLanding
        /* @ts-expect-error: Translate to TS */
        hero={
          <DesktopHero
            {...headers}
            sub={`Join Wren today and we’ll ${specialOffer.incentiveDescription}.`}
            cta={cta}
            imageUrl="irrawady.png"
            backgroundGradient="linear-gradient(0deg, rgba(0, 91, 75, 0.80) 100%, rgba(0,91,75,0.80) 100%)"
            contentStyles={contentStyles}
            tickerColor="rgba(31, 161, 139, 0.3)"
          />
        }
      />
    </>
  );
}
