/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RecordExperience } from '../../actions/experiences';
import useQueryParams from '../../hooks/useQueryParams';
import { Experiences } from '../../config/constants';
import PromoDialog from '../UI/PromoDialog';

export default function BonusIncentive() {
  const { removeQueryParam, getQueryParam } = useQueryParams();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const bonusType = getQueryParam('bonus_type');

  const incentiveName = `INCENTIVE_${bonusType}`.toUpperCase();

  const incentive = {
    INCENTIVE_TREE_PLANTING: '10 bonus trees planted',
    INCENTIVE_BONUS_TON: 'a bonus ton of CO₂ offset',
    INCENTIVE_RAINFOREST_PROTECTION: 'an extra hectare of rainforest protected',
    INCENTIVE_DOUBLE_IMPACT: 'your first month of impact doubled',
    INCENTIVE_2020_EOY_DOUBLE_IMPACT: 'your first month of impact doubled', // this text doesn't matter
  }[incentiveName];

  const experience = user.experiences.find(
    (experience) => experience.name === Experiences[incentiveName]
  );

  useEffect(() => {
    if (!experience && incentive) {
      dispatch(
        RecordExperience({
          name: Experiences[incentiveName],
          completed: false,
        })
      );
    }
  }, []);

  // if bonus type isn't tied to a valid incentive, remove query param
  if (!incentive) {
    removeQueryParam('bonus_type');
  }

  if (incentiveName !== 'INCENTIVE_2020_EOY_DOUBLE_IMPACT') {
    return (
      <PromoDialog
        onRequestClose={() => removeQueryParam('bonus_type')}
        title={`Offset your footprint today and get ${incentive} on us!`}
        subtitle={'Welcome back to Wren.'}
        list={[
          'Get a sense of your carbon footprint with our carbon calculator',
          'Choose from a selection of scalable, effective projects to offset your emissions',
          'Subscribe and receive monthly data and story updates on your project',
        ]}
      />
    );
  }

  return <div />;
}
