import styled from '@emotion/styled';
import { fonts } from '../../style/theme';
import * as fontStyles from '../../style/fontStyles';

type FontType = 'sans' | 'serif';

function getFontForFontName(fontName: FontType) {
  return {
    sans: fonts.Sans,
    serif: 'Blanco, serif',
  }[fontName];
}

interface Props {
  font?: FontType;
  weight?: number | string;
}

function getOptionalFamilyAndWeightCss(props: Props) {
  const fontFamilyStyle = props.font
    ? { fontFamily: getFontForFontName(props.font) }
    : {};
  const fontWeightStyle = props.weight ? { fontWeight: props.weight } : {};
  return {
    ...fontFamilyStyle,
    ...fontWeightStyle,
  };
}

export const Headings = {
  Massive: styled.h1<Props>((props) => [
    fontStyles.headingMassive,
    getOptionalFamilyAndWeightCss(props),
  ]),
  XLarge: styled.h1<Props>((props) => [
    fontStyles.headingXLarge,
    getOptionalFamilyAndWeightCss(props),
  ]),
  Large: styled.h1<Props>((props) => getOptionalFamilyAndWeightCss(props)),
  Medium: styled.h2<Props>((props) => getOptionalFamilyAndWeightCss(props)),
  Small: styled.h3<Props>((props) => getOptionalFamilyAndWeightCss(props)),
};

export const Paragraph = styled.p<Props>((props) =>
  getOptionalFamilyAndWeightCss(props)
);

export const Citation = styled.span<Props>((props) => [
  fontStyles.citation,
  getOptionalFamilyAndWeightCss(props),
]);
