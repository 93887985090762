/** @jsxImportSource @emotion/react */
import 'url-search-params-polyfill';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import Scroll from './components/ScrollToTop';
import PageContent from './PageContent';
import { Store } from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import invariant from './lib/invariant';
import { ExperimentContext } from './hooks/useExperiment';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const queryClient = new QueryClient();

export default function Root({ store }: { store: Store }) {
  invariant(GOOGLE_CLIENT_ID, 'Missing Google Client ID');
  const { userId } = store.getState().user;
  const experimentContext = {
    userId,
    useExperimentServerSide: () => 'control' as const, // unused
  };

  return (
    <ExperimentContext.Provider value={experimentContext}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Router>
              <Scroll>
                <PageContent />
              </Scroll>
            </Router>
          </Provider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </ExperimentContext.Provider>
  );
}
