/** @jsxImportSource @emotion/react */
import { scale } from '../../util/scale';
import { getImageSetForStaticImageUrl } from '../../util/images';
import useIncrementalLoad from '../../hooks/useIncrementalLoad';
import { Headings, Paragraph } from '../UI/TextStyles';
import { formatNumber } from '../../lib/format';
import useNewSignups from '../../hooks/useNewSignups';
import { css, SerializedStyles } from '@emotion/react';
import { CSSInterpolation } from '@emotion/serialize';
import { ReactNode } from 'react';
import { fadeIn } from '../../style/misc';

const container = scale({
  width: '100%',
  height: ['460px', '500px', '580px'],
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'scale(1)', // Creates a stacking context
});

const gradient = (backgroundGradient?: string) =>
  scale({
    position: 'absolute',
    pointerEvents: 'none',
    zIndex: 10,
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background:
      backgroundGradient ||
      'linear-gradient(0deg, rgba(0, 91, 75, 0.80) 100%, rgba(0,91,75,0.80) 100%)',
  });

const content = scale({
  position: 'absolute',
  alignSelf: 'flex-start',
  textAlign: 'center',
  height: '100%',
  width: '100%',
  zIndex: 100,
});

const heroImage = scale({
  width: '100%',
  minHeight: ['460px', '500px', '580px'],
  objectFit: 'cover',

  height: '100%',
  overflow: 'hidden',
});

const mainTitle = (mainTitleWidth?: number) =>
  scale({
    color: 'white',
    fontSize: ['36px', '40px', '60px', '60px'],
    letterSpacing: '-0.5px',
    lineHeight: 1,
    maxWidth: mainTitleWidth ?? 800,
    paddingTop: ['70px', '100px', '110px'],
    width: ['90%', 'auto'],
  });

const subTitle = scale({
  fontSize: ['20px', '22px', '24px', '24px'],
  margin: 'auto',
  marginBottom: '25px',
  marginTop: 8,
  lineHeight: 1.3,
  fontWeight: 400,
  color: 'white',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: ['90%', '400px', '510px', '510px'],
});

export default function HeroImageLanding(props: {
  backgroundGradient?: string;
  bottomMatter?: ReactNode;
  button?: ReactNode;
  className?: string;
  contentStyles?: CSSInterpolation;
  header?: ReactNode;
  imageUrl: string;
  mainTitleWidth?: number;
  preloadGradient?: string;
  sub?: ReactNode;
  withSignupCounter?: boolean;
}) {
  const [imageUrl, imageLoadingCss] = useIncrementalLoad(props.imageUrl, {
    h: '800',
    fit: 'clip',
    ch: 'Height,DPR',
  }) as [string, SerializedStyles];

  const newSignups = useNewSignups();

  const preloadGradient = css(gradient(props.preloadGradient), {
    zIndex: -1,
  });

  return (
    <div css={container} className={props.className}>
      <div css={gradient(props.backgroundGradient)} className="gradient" />
      {/* A preload gradient is important if the text color and backgrouund color are similar, so users can start reading while images load */}
      {props.preloadGradient && (
        <div css={preloadGradient} className="gradient" />
      )}
      <div css={[content, props.contentStyles]}>
        <Headings.Massive css={[mainTitle(props.mainTitleWidth)]}>
          {props.header}
        </Headings.Massive>
        {typeof props.sub === 'string' ? (
          <p css={subTitle} dangerouslySetInnerHTML={{ __html: props.sub }} />
        ) : (
          <p css={subTitle}>{props.sub}</p>
        )}
        {props.button}
        {props.withSignupCounter && !!newSignups && (
          <Paragraph
            css={[
              {
                color: 'white',
                margin: '12px auto 0',
                maxWidth: 200,
                fontSize: '14px !important',
              },
              fadeIn(0.5),
            ]}
          >
            Join the{' '}
            <span css={{ fontWeight: 600 }}>{formatNumber(newSignups, 0)}</span>{' '}
            people who signed up this month!
          </Paragraph>
        )}
        <div css={{ position: 'absolute', bottom: 0, width: '100%' }}>
          {props.bottomMatter}
        </div>
      </div>
      <img
        css={[heroImage, imageLoadingCss]}
        src={imageUrl}
        srcSet={getImageSetForStaticImageUrl(imageUrl)}
      />
    </div>
  );
}
