/** @jsxImportSource @emotion/react */

import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import Footer from '../../components/frontfacing/Footer';
import { GetStarted } from '../../actions/user';
import LandingContent from './LandingContent';
import useMainCTAText from '../../hooks/useMainCTAText';
import {
  SetCalculatorQuestionIndex,
  SetCountry,
} from '../../actions/calculator';
import { lazy, Suspense } from 'react';

const HowItWorksLazy = lazy(() =>
  import('../../components/landing/HowItWorks')
);

const HowItWorks = () => (
  <Suspense fallback={<p></p>}>
    {/* TODO: skeleton */}
    <HowItWorksLazy />
  </Suspense>
);

const Desktop = (props) => {
  const dispatch = useDispatch();
  const ctaText = useMainCTAText();

  async function handleCTAClick(eventLocation, event) {
    if (event) {
      event.preventDefault();
    }
    dispatch(
      GetStarted({
        history: props.history,
        location: eventLocation,
        platform: 'desktop',
      })
    );
  }

  async function handleSubmitCountry(country) {
    dispatch(SetCountry(country));
    dispatch(SetCalculatorQuestionIndex(1));
    handleCTAClick('hero');
  }

  return (
    <div css={{ backgroundColor: 'white' }}>
      <Helmet>
        <title>
          Wren | {props.headers?.header || 'Erase your carbon footprint'}
        </title>
      </Helmet>
      <LandingContent
        ctaText={ctaText}
        onCtaClick={handleCTAClick}
        handleSubmitCountry={handleSubmitCountry}
        country={props.user?.country}
        howItWorks={<HowItWorks />}
        {...props}
      />
      <Footer hideCTA />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

Desktop.propTypes = {};

Desktop.defaultProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Desktop)
);
