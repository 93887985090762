/** @jsxImportSource @emotion/react */

import { ReactNode } from 'react';
import { PropsOf } from '@emotion/react';

import CountryInput from '../inputs/CountryInput';
import flex from '../../style/flex';
import { fullWidth } from '../../style/misc';
import { scale } from '../../util/scale';

const countrySelectContainer = scale({
  maxWidth: ['none', '400px'],
  margin: 'auto',
  alignItems: 'center',
});

export default function CountrySelect({
  button,
  className,
  ...restOfProps
}: {
  button?: ReactNode;
  className?: string;
} & PropsOf<typeof CountryInput>) {
  return (
    <div
      className={className}
      css={[countrySelectContainer, flex.flexUntilMobile]}
    >
      <div css={fullWidth}>
        <CountryInput
          id="country-select"
          name="country-input"
          {...restOfProps}
        />
      </div>
      {button}
    </div>
  );
}
