/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { scale } from '../../util/scale';
import { childrenPropType } from '../../util/propTypes';
import { ReactNode, Suspense, lazy } from 'react';
import zIndex from '../../lib/zIndex';
const Tippy = lazy(() => import('@tippy.js/react'));

const bottomArrow = css`
  ::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
`;

const leftArrow = css`
  ::after {
    content: ' ';
    position: absolute;
    top: 50%; /* At the bottom of the tooltip */
    right: 100%;
    margin-top: -11px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }
`;

const fullDefContainer = scale({
  width: 'fit-content',
  paddingRight: '0px',
  cursor: 'pointer',
  display: 'inline',
  zIndex: zIndex('tooltipOverlay'),
  '&:focus': {
    outline: 0,
    MozOutlineStyle: 'none',
  },
});

const strongStyle = scale({
  display: 'block',
  marginBottom: '5px',
  fontSize: '14px',
});

const bodyStyle = scale({
  fontSize: '14px !important',
  lineHeight: 1.4,
  color: 'var(--primary-text)',
});

const reactTippy = scale({
  color: 'var(--primary-text)',
  textAlign: 'left',
  backgroundColor: 'white !important',
  lineHeight: '16px',
  padding: '8px 8px 10px 14px',
  border: '1px solid var(--border-color)',
  textDecoration: 'none !important',
  marginBottom: '5px',
  marginTop: '-32px',
  boxShadow: '0 10px 15px rgba(0, 0, 0, 0.15), 0 0px 15px rgba(0, 0, 0, 0.05)',
  '.tippy-arrow': {
    borderTop: 'none !important',
    '&::after': {
      display: 'none',
    },
  },
});

interface Props {
  onPageContent: ReactNode;
  title?: string;
  children?: ReactNode;
  maxWidth?: string;
  showArrow?: boolean;
  placement?: 'top' | 'right';
  id?: string;
  className?: string;
  delay?: number; // ms
  isInteractive?: boolean;
  interactiveDebounce?: number;
}

// import mq to override media query breakpoints for a given page
export default function TooltipWrapper(props: Props) {
  const {
    title,
    children,
    onPageContent,
    maxWidth = '280px',
    showArrow = false,
    placement = 'top',
    isInteractive = true,
    interactiveDebounce,
    className,
    delay = 0,
    id,
  } = props;

  return (
    <Suspense
      fallback={
        <span css={fullDefContainer} id={id} className={className}>
          {onPageContent}
        </span>
      }
    >
      <Tippy
        content={
          <div
            style={{ maxWidth }}
            css={
              showArrow && placement === 'right'
                ? leftArrow
                : showArrow && placement === 'top'
                ? bottomArrow
                : null
            }
          >
            <div>
              <strong css={strongStyle}>{title}</strong>
            </div>
            <div css={bodyStyle}>{children}</div>
          </div>
        }
        delay={delay}
        arrow={showArrow} // TODO
        interactive={isInteractive}
        placement={placement}
        animateFill={false}
        interactiveDebounce={interactiveDebounce}
        css={reactTippy}
        hideOnClick={false}
        popperOptions={{
          modifiers: {
            addZIndex: {
              enabled: true,
              order: 810,
              // TODO: Doesn't seem like this is used for anything but need to double check
              // fn: (data) => ({
              //   ...data,
              //   styles: {
              //     ...data.styles,
              //     zIndex: 99999999,
              //   },
              // }),
            },
          },
        }}
      >
        <span css={fullDefContainer} id={id} className={className}>
          {onPageContent}
        </span>
      </Tippy>
    </Suspense>
  );
}

TooltipWrapper.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
  children: childrenPropType,
  onPageContent: PropTypes.node,
};

TooltipWrapper.defaultProps = {
  body: '',
  title: '',
  children: undefined,
};
