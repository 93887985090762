/** @jsxImportSource @emotion/react */
import { Interpolation, PropsOf, Theme } from '@emotion/react';
import { HTMLAttributes, useState } from 'react';
import flex from '../../style/flex';
import * as misc from '../../style/misc';
import text from '../../style/text';
import { fonts } from '../../style/theme';
import { Currency, SerializedUser } from '../../types';
import {
  getCurrencyObjFromCode,
  getSupportedCurrencies,
} from '../../util/conversions';
import { scale } from '../../util/scale';
import CardContent from '../UI/CardContent';
import Dialog from '../layout/Dialog';
import Popup from '../UI/Popup';

const noPadding = scale({
  margin: '-8px',
  padding: 0,
  maxWidth: '600px',
});

const textStyle = scale({
  fontWeight: 400,
  color: 'var(--text-primary)',
  textAlign: 'left',
  fontFamily: fonts.Sans,
});

const currencyNameStyle = (matches: boolean) =>
  scale({
    fontWeight: matches ? 600 : 500,
  });

const noOutlineStyle = {
  outline: 'none',
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: fonts.Sans,
  opacity: 1,
  color: 'var(--text-primary)',
  padding: '2px 5px',
  marginLeft: '-5px',
};

const darkFooter = scale({
  color: 'white !important',
  '&:hover': {
    backgroundColor: 'var(--background-dark-green)',
  },
});

const buttonStyle = (matches: boolean) =>
  scale({
    padding: '9px 12px',
    lineHeight: '18px !important',
    fontSize: '14px !important',
    fontFamily: fonts.Sans,
    backgroundColor: 'transparent',
    border: matches ? '1px solid var(--gray-5)' : 'transparent',
    color: 'var(--text-secondary)',
    borderRadius: '8px',
    transition:
      'border 200ms ease-in-out, background-color 200ms ease-in-out, color 200s ease-in-out',
    '&:hover': {
      color: 'var(--text-primary)',
      backgroundColor: 'var(--gray-9)',
      cursor: 'pointer',
    },
  });

const listItem = scale({
  verticalAlign: 'top !important',
  width: ['100%', '50%', '33.3%'],
  padding: '8px',
  boxSizing: 'border-box',
  display: 'inline-block !important',
  listStyle: 'none !important',
  lineHeight: '16px',
});

const RenderCurrency = (
  updateCurrency: (currencyCode: string) => void,
  currentCurrency: Currency,
  setShowPopup: (showPopup: boolean) => void,
  currency: Currency
) => {
  const matches = currency.code === currentCurrency.code;
  return (
    <li css={listItem} key={currency.code}>
      <button
        css={[misc.noStyle, buttonStyle(matches), textStyle]}
        onClick={async () => {
          await updateCurrency(currency.code);
          setShowPopup(false);
        }}
      >
        <p css={currencyNameStyle(matches)}>{currency.name}</p>
        <p css={[text.light, matches && text.medium]}>
          {currency.code}
          {currency.symbol && ` - ${currency.symbol}`}
        </p>
      </button>
    </li>
  );
};

export function CurrencyPopup({
  onCurrencyChange,
  showDialog,
  setShowDialog,
  user,
  ...restOfProps
}: Omit<PropsOf<typeof Dialog>, 'children'> & {
  onCurrencyChange: (currencyCode: string) => void;
  user?: SerializedUser;
}) {
  const currentCurrency = getCurrencyObjFromCode(user?.currency || 'USD');

  if (!showDialog) {
    return null;
  }

  return (
    <Popup
      onRequestClose={() => setShowDialog(false)}
      id="Choose Currency"
      aria-label="Currencies"
      {...restOfProps}
    >
      <div css={flex.genericFlex}>
        <div css={[textStyle, text.smallHeader]}>Choose a currency</div>
      </div>
      <br />
      <ul css={noPadding}>
        {currentCurrency &&
          getSupportedCurrencies().map((currency: Currency) =>
            RenderCurrency(
              onCurrencyChange,
              currentCurrency,
              setShowDialog,
              currency
            )
          )}
      </ul>
    </Popup>
  );
}

export function CurrencyButton({
  children,
  user,
  ...restOfProps
}: HTMLAttributes<HTMLButtonElement> & {
  user?: SerializedUser;
}) {
  const currentCurrency = getCurrencyObjFromCode(user?.currency || 'USD');
  return (
    <button type="button" css={misc.buttonCssReset} {...restOfProps}>
      {children || (
        <p css={[misc.noStyleButton, noOutlineStyle, darkFooter]}>
          {currentCurrency && currentCurrency.symbol}{' '}
          {currentCurrency && currentCurrency.code}
        </p>
      )}
    </button>
  );
}

export default function CurrencyMenu({
  user,
  children,
  onCurrencyChange,
  style,
}: {
  children?: React.ReactNode;
  user?: SerializedUser;
  onCurrencyChange: (currencyCode: string) => void;
  style?: Interpolation<Theme>;
}) {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div css={style}>
      <CurrencyPopup
        showDialog={showPopup}
        setShowDialog={setShowPopup}
        onCurrencyChange={onCurrencyChange}
        user={user}
      />
      <CurrencyButton
        onClick={() => setShowPopup(!showPopup)}
        css={[misc.noStyle]}
        user={user}
      >
        {children}
      </CurrencyButton>
    </div>
  );
}
