/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { colors } from '../../style/theme';
import { SerializedUser } from '../../types';
import Link from '../UI/Link';
import Logo from '../svgs/Logo';
import { default as CtaButton } from './CtaButton';
import { NavContainer, divider } from './DesktopNav';
import MobileNavDropdown from './MobileNavDropdown';
import NavBurger from './NavBurgerNext';
import { NotificationsButton } from './NotificationsButton';

export type DropdownType =
  | 'Offset'
  | 'Notifications'
  | 'Products'
  | 'Resources'
  | 'Main menu'
  | '';

export default function MobileNav({
  user,
  handleCTAClick,
  isTransparent,
  isFixed,
  hasBottomBorder,
  onCurrencyChange,
  pathname,
}: {
  user?: SerializedUser;
  handleCTAClick: (eventLocation: string) => void;
  isTransparent?: boolean;
  isFixed?: boolean;
  hasBottomBorder?: boolean;
  onCurrencyChange: (currencyCode: string) => void;
  pathname: string;
}) {
  const [dropdownExpanded, setDropdownExpanded] = useState<DropdownType>('');
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  const isActuallyFixed = isScrolledDown || !!dropdownExpanded;
  const isActuallyTransparent =
    isTransparent && !dropdownExpanded && !isScrolledDown;

  const color = isActuallyTransparent ? 'white' : colors.textPrimary;

  useEffect(() => {
    const onScroll = () => {
      if (isFixed && window.scrollY >= 60) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (dropdownExpanded) {
      document.body.style.overflow = 'hidden !important';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [dropdownExpanded]);

  return (
    <NavContainer fixed={isActuallyFixed} isTransparent={isActuallyTransparent}>
      <Link to={user?.hasPassword ? '/dashboard' : '/'}>
        <Logo color={color} width="90" />
      </Link>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <CtaButton
          user={user}
          pathname={pathname}
          handleCTAClick={handleCTAClick}
          handleOffsetClick={() => setDropdownExpanded('Offset')}
        />
        {divider}
        {user?.completedOnboarding && (
          <NotificationsButton
            css={{ color, '&:hover': { color } }}
            onClick={() => {
              dropdownExpanded === 'Notifications'
                ? setDropdownExpanded('')
                : setDropdownExpanded('Notifications');
            }}
          />
        )}
        <NavBurger
          color={color}
          expanded={!!dropdownExpanded}
          hideLabel={true}
          onToggle={() =>
            dropdownExpanded
              ? setDropdownExpanded('')
              : setDropdownExpanded('Main menu')
          }
        >
          Menu
        </NavBurger>
      </div>
      <MobileNavDropdown
        dropdownType={dropdownExpanded}
        setDropdownType={(dropdownType: DropdownType) =>
          setDropdownExpanded(dropdownType)
        }
        expanded={!!dropdownExpanded}
        onCloseRequested={() => setDropdownExpanded('')}
        user={user}
        onCurrencyChange={onCurrencyChange}
      />
    </NavContainer>
  );
}
