export type SensitiveUserField =
  | 'pass'
  | 'api_token'
  | 'updates_list'
  | 'has_received_intro_email'
  | 'has_received_follow_up_email';
// I really wish there was a less-dumb way to do this.

export const SENSITIVE_USER_FIELDS: SensitiveUserField[] = [
  'pass',
  'api_token',
  'updates_list',
  'has_received_intro_email',
  'has_received_follow_up_email',
];
