/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { scale } from '../../util/scale';

import CarouselDot from './CarouselDot';
import text from '../../style/text';
import layout from '../../style/layout';
import useInterval from '../../hooks/useInterval';
import Flex from './Flex';
import { hideOnMobile } from '../../style/misc';
import Fade from '../animation/Fade';

const Bell = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 9 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.16055 7.01997C8.12624 6.97865 8.09256 6.93732 8.0595 6.89744C7.60489 6.34757 7.32986 6.01571 7.32986 4.45909C7.32986 3.6532 7.13706 2.99196 6.75705 2.49602C6.47685 2.12965 6.09808 1.85172 5.59884 1.64632C5.59242 1.64275 5.58668 1.63806 5.5819 1.63248C5.40233 1.03116 4.91094 0.628418 4.35673 0.628418C3.80253 0.628418 3.31135 1.03116 3.13178 1.63186C3.12698 1.63724 3.12133 1.64178 3.11504 1.64529C1.95001 2.1249 1.38382 3.04506 1.38382 4.45847C1.38382 6.01571 1.10919 6.34757 0.654174 6.89682C0.621111 6.9367 0.587429 6.9772 0.553127 7.01935C0.46452 7.12621 0.408381 7.25622 0.391352 7.39398C0.374323 7.53175 0.397118 7.67151 0.45704 7.79673C0.584536 8.06536 0.856267 8.23212 1.16643 8.23212H7.54931C7.85803 8.23212 8.1279 8.06557 8.25581 7.79818C8.31599 7.67293 8.339 7.53305 8.32212 7.39513C8.30523 7.2572 8.24916 7.12701 8.16055 7.01997V7.01997ZM4.35673 9.88585C4.65533 9.88561 4.9483 9.80456 5.20457 9.65129C5.46083 9.49802 5.67083 9.27824 5.8123 9.01528C5.81896 9.00268 5.82226 8.98857 5.82186 8.97432C5.82146 8.96007 5.81738 8.94616 5.81001 8.93395C5.80265 8.92175 5.79226 8.91165 5.77984 8.90465C5.76742 8.89764 5.75341 8.89397 5.73915 8.89398H2.97473C2.96046 8.89393 2.94641 8.89758 2.93397 8.90457C2.92152 8.91156 2.9111 8.92165 2.90371 8.93387C2.89633 8.94608 2.89223 8.96 2.89182 8.97427C2.89141 8.98853 2.8947 9.00266 2.90137 9.01528C3.04283 9.27821 3.2528 9.49796 3.50902 9.65123C3.76524 9.8045 4.05817 9.88557 4.35673 9.88585Z"
      fill="white"
    />
  </svg>
);

const carouselDotsContainer = (style) =>
  scale({
    width: '100%',
    height: '40px',
    margin: '20px 0 0 20px',
    textAlign: style === 'new' ? 'left' : 'center',
    bottom: '20px',
    left: 0,
    right: 0,
  });

const carouselContainer = (style) =>
  scale({
    marginTop: style === 'new' ? 0 : '20px',
    backgroundColor: 'var(--gray-9)',
    borderRadius: '5px',
  });

const carouselCaption = (style, captionStyle) => {
  if (captionStyle === 'notification') {
    return scale({
      position: 'absolute',
      bottom: 36,
      right: 36,
      padding: 16,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
      borderBottom: '10px solid var(--success-green)',
      boxSizing: 'border-box',
      maxWidth: style === 'new' && [300, 300, 320],
      backgroundColor: style === 'new' ? 'white' : 'var(--gray-3)',
      color: [
        'rgba(255, 255, 255, 0.70)',
        'rgba(255, 255, 255, 0.70)',
        'white',
        'white',
      ],
      textAlign: 'left',
      minHeight: style !== 'new' && '70px',
      borderRadius: '5px',
    });
  }

  return scale({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    height: 100,
    boxSizing: 'border-box',
    maxWidth: style === 'new' && ['none', 'none', 350],
    marginTop: 16,
    backgroundColor: style === 'new' ? 'transparent' : 'var(--gray-3)',
    color: [
      'rgba(255, 255, 255, 0.70)',
      'rgba(255, 255, 255, 0.70)',
      'white',
      'white',
    ],
    textAlign: 'left',
    minHeight: style !== 'new' && '70px',
    borderRadius: '5px',
    p: {
      padding: style === 'new' ? '0' : '15px',
    },
  });
};

const backgroundImage = (imageUrl) =>
  scale({
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    borderRadius: '5px',
  });

const innerBoxStyle = scale({
  boxSizing: 'border-box',
  height: ['300px', '300px', '350px', '400px'],
});

const ImageCarousel = (props) => {
  const { images, interval, style, className, captionStyle } = props;
  const [carouselCycle, setCarouselCycle] = useState(0);

  useInterval(() => {
    setCarouselCycle(carouselCycle < images.length - 1 ? carouselCycle + 1 : 0);
  }, interval);

  const renderCarouselDot = (imageUrl, index) => {
    return (
      <CarouselDot
        carouselCycle={carouselCycle}
        position={index}
        onClickFn={() => setCarouselCycle(index)}
        key={index}
        style={style}
      />
    );
  };

  return (
    <div css={layout.relative} className={className}>
      <div css={carouselContainer(style)}>
        <Fade animationKey={images[carouselCycle].imageUrl} durationMs={300}>
          <div css={backgroundImage(images[carouselCycle].imageUrl)}>
            <div id="image-height" css={innerBoxStyle} />
            {style !== 'new' && (
              <div css={carouselCaption}>
                <p css={[text.caption, text.center]}>
                  {images[carouselCycle].caption}
                </p>
              </div>
            )}
            {style === 'new' && images.length > 1 && (
              <div css={carouselDotsContainer(style)}>
                {images.map((imageUrl, i) => renderCarouselDot(imageUrl, i))}
              </div>
            )}
          </div>
        </Fade>
      </div>
      {style !== 'new' && images.length > 1 && (
        <div css={carouselDotsContainer(style)}>
          {images.map((imageUrl, i) => renderCarouselDot(imageUrl, i))}
        </div>
      )}
      {style === 'new' && images.every((i) => i.caption) && (
        <div css={carouselCaption(style, captionStyle)} className="caption">
          {captionStyle === 'notification' ? (
            <Flex
              align="center"
              justify="center"
              css={[
                {
                  width: 30,
                  height: 30,
                  borderRadius: 30,
                  background: 'var(--success-green)',
                  position: 'absolute',
                  top: -14,
                  left: -14,
                },
                hideOnMobile,
              ]}
            >
              <Bell />
            </Flex>
          ) : (
            <p css={[{ marginRight: 8 }, hideOnMobile]}>↑</p>
          )}
          <div key={carouselCycle}>{images[carouselCycle].caption}</div>
        </div>
      )}
    </div>
  );
};

ImageCarousel.defaultProps = {
  interval: 4000,
};

ImageCarousel.propTypes = {
  interval: PropTypes.number,
};

export default ImageCarousel;
