import { css } from '@emotion/react';
import facepaint from 'facepaint';

// Open to name suggestions for this constant:
export const COMPACT_NAV_BREAKPOINT = 770;

export const breakpoints = {
  mobile: '(min-width: 431px)',
  tablet: '(min-width: 720px)',
  desktop: '(min-width: 1000px)',
  xLarge: `(min-width: 1500px)`,
};

// import mq to override media query breakpoints for a given page
export const mq = facepaint(
  [
    breakpoints.mobile,
    breakpoints.tablet,
    breakpoints.desktop,
    breakpoints.xLarge,
  ].map((query) => `@media${query}`)
);

export const mqForProjectPage = facepaint([
  '@media(min-width: 431px)',
  '@media(min-width: 520px)',
  '@media(min-width: 720px)',
  '@media(min-width: 1080px)',
]);

export const mqForSidebar = facepaint([
  '@media(min-width: 720px)',
  '@media(min-width: 1200px)',
  '@media(min-width: 1350px)',
  '@media(min-width: 1600px)',
]);

export const navScale = facepaint([
  `@media(min-width: ${COMPACT_NAV_BREAKPOINT}px)`,
]);

// import scale to use media queries with emotion classes
export const scale = (...args: Parameters<typeof mq>) => css(mq(...args));
export const sidebarScale = (...args: Parameters<typeof mqForSidebar>) =>
  css(mqForSidebar(...args));
export const projectCardScale = (
  ...args: Parameters<typeof mqForProjectPage>
) => css(mqForProjectPage(...args));
