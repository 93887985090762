/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import { scale } from '../../util/scale';
import Definition from './Definition';

const disabledStyle = (opacity?: number) =>
  scale({ opacity: opacity || 0.6, cursor: 'not-allowed !important' });

interface Props {
  children: ReactNode;
  text: string | undefined;
  customOpacity?: number;
}

const DisableContentWrapper = (props: Props) => {
  const { children, text, customOpacity } = props;
  if (text) {
    return (
      <Definition plain body={text}>
        <div css={disabledStyle(customOpacity)}>{children}</div>
      </Definition>
    );
  }
  return <div css={disabledStyle(customOpacity)}>{children}</div>;
};

export default DisableContentWrapper;
