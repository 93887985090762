/** @jsxImportSource @emotion/react */

import { Helmet } from 'react-helmet';
import { scale } from '../util/scale';
import { Link, withRouter } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Network } from '@wren/shared';
import Footer from '../components/frontfacing/Footer';

import {
  whiteArticle,
  articleContainer,
  articleContentContainer,
  articleStyles,
  container,
  heroImage,
  postContentContainer,
  postContent,
  postHeader,
} from '../style/article';
import { center } from '../style/text';
import SkeletonLine from '../components/UI/SkeletonLine';
import Button from '../components/buttons/Button';
import { ReferralsHeader } from '../components/account/Referrals';
import { Headings, Paragraph } from '../components/UI/TextStyles';
import { PostMetadata } from '../components/layout/Article';
import BackLink from '../components/frontfacing/BackLink';

const floatingContainer = scale({
  marginTop: [`0px !important`, `0px !important`],
});

const getContentFromGhost = async (slug, setPost) => {
  const [response, responseBody] = await Network.get(`ghost/posts/${slug}`);

  if (response.ok) {
    setPost(responseBody.post);
  }
};

const formatDate = (date) => {
  const dt = new Date(date);
  const day = dt.getDate();
  const suffix = ['th', 'st', 'nd', 'rd'][((day % 100) - 20) % 10] || 'th';
  const month = dt.toLocaleString('default', { month: 'long' });
  const year = dt.getFullYear();
  return `${month} ${day}${suffix}, ${year}`;
};

const BlogPost = ({ match, user }) => {
  const [post, setPost] = useState(false);
  const { slug } = match.params;

  useEffect(() => {
    getContentFromGhost(slug, setPost);
  }, [slug]);

  const wrapperRef = useRef(null);

  if (!post) {
    return <SkeletonLoader />;
  }

  return (
    <div style={{ backgroundColor: 'white', overflowX: 'hidden' }}>
      <Helmet>
        <title>Wren | {post.title || 'Blog'}</title>
      </Helmet>

      <div ref={wrapperRef} css={container}>
        <div css={postContentContainer}>
          <img
            css={heroImage}
            src={post.feature_image}
            layout="fill"
            alt={post.title}
            unsized
          />
          <BackLink destination="/blog" />
          <div css={postContent}>
            <header css={postHeader}>
              <div style={{ opacity: 0.4, marginBottom: 8 }}>
                <Paragraph>{formatDate(post.published_at)}</Paragraph>
              </div>
              <Headings.Large
                style={{ fontSize: 40, fontWeight: 700, lineHeight: 1.1 }}
                font="serif"
              >
                {post.title}
              </Headings.Large>
              <PostMetadata
                sharePath={'updates'}
                hideDateAndReadTime
                post={post}
              />
            </header>
            <div css={[articleStyles]}>
              <div
                id="html-content"
                dangerouslySetInnerHTML={{
                  __html: post.html,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {user.hasPassword && user.username && (
        <div css={articleContainer}>
          <div css={[whiteArticle, floatingContainer]}>
            <div css={[articleContentContainer, articleStyles, center]}>
              <hr />
              <br />
              <ReferralsHeader css={{ maxWidth: 560, margin: 'auto' }}>
                <Paragraph>
                  <strong>{user.firstName && `${user.firstName}:`}</strong> Want
                  to have an even bigger impact? Refer a friend to Wren! We'll
                  have {user.referralBonusEmailDescription} for every friend who
                  signs up using your personal referral link.
                </Paragraph>
              </ReferralsHeader>
              <br />
              <Link
                style={{ textDecoration: 'none ' }}
                to={{ search: '?refer=1' }}
              >
                <Button
                  css={{
                    margin: 'auto',
                    display: 'block',
                    maxWidth: 200,
                    marginTop: 26,
                  }}
                  size="large"
                  text="Refer a friend"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

const SkeletonLoader = () => (
  <div style={{ backgroundColor: 'white', overflowX: 'hidden' }}>
    <div css={container}>
      <div css={postContentContainer}>
        <br />
        <SkeletonLine height="400px" margin="32px 0 32px 0" />
        <SkeletonLine height="36px" />
        <SkeletonLine width="40%" height="36px" margin="0px 0px 64px 0px" />
        <SkeletonLine />
        <SkeletonLine width="96%" />
        <SkeletonLine />
        <SkeletonLine width="60%" margin="0px 0px 32px 0px" />
        <SkeletonLine />
        <SkeletonLine width="94%" />
        <SkeletonLine />
        <SkeletonLine />
        <SkeletonLine />
        <SkeletonLine />
        <SkeletonLine height="300px" margin="32px 0 32px 0" />
        <SkeletonLine />
        <SkeletonLine />
        <SkeletonLine />
      </div>
    </div>
  </div>
);

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps)(BlogPost));
