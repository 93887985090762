export const DEFAULT_EMAIL_PREFERENCES = {
  subscribed_to_advocacy_updates: true,
  subscribed_to_project_updates: true,
  subscribed_to_product_updates: true,
  subscribed_to_company_updates: true,
  subscribed_to_climate_offers_and_news: true,
  subscribed_to_daily_climate_habit_series: false,
  subscribed_to_go_plant_based_series: false,
  subscribed_to_climate_camp_course: false,
  subscribed_to_receipts: false,
  subscribed_to_action_reminders_weekly: false,
  subscribed_to_action_reminders_monthly: false,
  subscribed_to_challenge_11_23: false,
  unsubscribed: false,
};

export type EmailPreferences = typeof DEFAULT_EMAIL_PREFERENCES;

export type CustomerIOResponse = {
  attributes: EmailPreferences;
};
