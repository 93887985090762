export const treatAsUTC = (date: Date | string | number) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

export const daysSince = (startDate: Date | string | number) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (
    (treatAsUTC(Date.now()).getTime() - treatAsUTC(startDate).getTime()) /
    millisecondsPerDay
  );
};

export const minutesSince = (startDate: Date) => {
  const millisecondsPerMinute = 60 * 60 * 1000;
  const minutesSinceDate =
    (treatAsUTC(Date.now()).getTime() - treatAsUTC(startDate).getTime()) /
    millisecondsPerMinute;
  return Number(minutesSinceDate).toFixed(0);
};

export const createBrowserFriendlyDate = (date: Date | string) => {
  return new Date(date).toLocaleDateString();
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MONTH_SHORTHANDS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const makeMDYDateFromAnyDate = (date: Date | string) => {
  const d = new Date(date);

  if (isNaN(d.getTime())) {
    return null;
  }

  return `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
};

export const isGreaterThanOneHourAgo = (timestamp: Date | string) => {
  const oneHour = 60 * 60 * 1000; /* ms */
  return new Date().getTime() - new Date(timestamp).getTime() > oneHour;
};

const isPlural = (num: number) => (num > 1 ? 's' : '');
const isRelevant = (num: number, text: string) =>
  num > 0 ? `${num} ${text}${isPlural(num)}` : '';

export const translateMonthsToMonthYearFormat = (months: number) => {
  const years = Math.trunc(months / 12);
  const monthsRemaining = months % 12;
  return `${isRelevant(years, 'year')}${
    years > 0 && monthsRemaining > 0 ? ' and ' : ''
  }${isRelevant(monthsRemaining, 'month')}`;
};

export function timeSince(date: Date) {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years`;
  }

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months`;
  }

  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days`;
  }

  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours`;
  }

  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes`;
  }

  return `${Math.floor(seconds)} seconds`;
}

export function wait(ms: number) {
  return new Promise((res) => setTimeout(res, ms));
}

export function todaysDate() {
  return new Date().toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  });
}

export function makeVerboseDate(date: Date) {
  return date.toLocaleDateString(undefined, {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  });
}

export function makeConciseDate(date: Date) {
  return date.toLocaleDateString(undefined, {
    month: 'short',
    year: 'numeric',
  });
}

export function daysUntilDate(date: Date) {
  const today = new Date();
  const diffTime = date.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}
