/** @jsxImportSource @emotion/react */
import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';
import { Headings } from '../UI/TextStyles';

const mobileHeroImage = (url, backgroundColor) => ({
  width: '100%',
  minHeight: '230px',
  backgroundColor,
  backgroundSize: 'cover',
  backgroundPositionY: 'center',
  backgroundImage: `url('${url}')`,
  clipPath: 'ellipse(140% farthest-side at 50% -100%)',
  marginBottom: 50,
});

const headerText = scale({
  boxSizing: 'border-box',
  color: 'white',
  fontSize: 38,
  margin: '0 auto',
  padding: '12px 12px 40px',
  textAlign: 'center',
  width: '80%',
});

const contentContainer = scale({
  textAlign: 'center',
  left: 0,
  right: 0,
  paddingTop: 60,
});

export default function MobileHeroImageLanding(props) {
  const imageUrl = staticImageUrl(props.imageUrl, {
    h: 400,
    fit: 'crop',
    crop: 'focalpoint',
    'fp-y': 0.35,
    'fp-x': 0.6,
    'fp-z': 1.4,
    ch: 'Height,DPR',
    q: 80,
  });

  return (
    <div
      className={props.className}
      css={mobileHeroImage(imageUrl, props.backgroundColor)}
    >
      <div css={contentContainer}>
        {props.content || (
          <Headings.Massive css={[headerText, props.headingStyles]}>
            {props.header}
          </Headings.Massive>
        )}
      </div>
    </div>
  );
}
