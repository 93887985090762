import * as R from 'ramda';

const initialState = {
  airports: [
    {
      id: 3484,
      airportName: 'Los Angeles International Airport',
      city: 'Los Angeles',
      country: 'United States',
      iata: 'LAX',
      icao: 'KLAX',
      latitude: 33.94250107,
      longitude: -118.4079971,
    },
    {
      id: 3797,
      airportName: 'John F Kennedy International Airport',
      city: 'New York',
      country: 'United States',
      iata: 'JFK',
      icao: 'KJFK',
      latitude: 40.63980103,
      longitude: -73.77890015,
    },
    {
      id: 1382,
      airportName: 'Charles de Gaulle International Airport',
      city: 'Paris',
      country: 'France',
      iata: 'CDG',
      icao: 'LFPG',
      latitude: 49.012798,
      longitude: 2.55,
    },
    {
      id: 3205,
      airportName: 'Tan Son Nhat International Airport',
      city: 'Ho Chi Minh City',
      country: 'Vietnam',
      iata: 'SGN',
      icao: 'VVTS',
      latitude: 10.8187999725,
      longitude: 106.652000427,
    },
    {
      id: 3453,
      airportName: 'Metropolitan Oakland International Airport',
      city: 'Oakland',
      country: 'United States',
      iata: 'OAK',
      icao: 'KOAK',
      latitude: 37.721298,
      longitude: -122.221001,
    },
    {
      id: 3949,
      airportName: 'Santa Barbara Municipal Airport',
      city: 'Santa Barbara',
      country: 'United States',
      iata: 'SBA',
      icao: 'KSBA',
      latitude: 34.42620087,
      longitude: -119.8399963,
    },
    {
      id: 3469,
      airportName: 'San Francisco International Airport',
      city: 'San Francisco',
      country: 'United States',
      iata: 'SFO',
      icao: 'KSFO',
      latitude: 37.61899948120117,
      longitude: -122.375,
    },
  ],
  loggedFlights: [],
  offsetFlights: [],
};

const SetFlights = (state, action) => {
  const stateClone = { ...state };
  const offsetDateIsNil = (flight) => R.isNil(flight.offsetDate);
  stateClone.loggedFlights = R.filter(offsetDateIsNil, action.flights);
  stateClone.offsetFlights = R.difference(
    action.flights,
    stateClone.loggedFlights
  );
  return stateClone;
};

const AddFlight = (state, action) => {
  const stateClone = { ...state };
  stateClone.loggedFlights = R.append(action.flight, stateClone.loggedFlights);
  return stateClone;
};

const DeleteFlight = (state, action) => {
  const stateClone = Object.assign([], state);
  const idMatches = (flight) => flight.flightId === action.flightId;
  stateClone.loggedFlights = R.reject(idMatches, stateClone.loggedFlights);
  return stateClone;
};

export default function (state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case 'SET_FLIGHTS':
      return SetFlights(state, action);
    case 'ADD_FLIGHT':
      return AddFlight(state, action);
    case 'DELETE_FLIGHT':
      return DeleteFlight(state, action);
    case 'CLEAR_FLIGHTS':
      return { ...initialState };
    default:
      return state;
  }
}
