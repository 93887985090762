/** @jsxImportSource @emotion/react */

import { oldFontSizes, fonts } from '../../style/theme';
import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';
import ShareButtons from '../sharing/ShareButtons';
import CardContainer from './CardContainer';

const welcome = staticImageUrl('hand-wave-emoji.png');
const celebrate = staticImageUrl('celebrate.png');

const outerContainer = scale({
  margin: 'auto',
  marginTop: ['50px', '140px'],
  maxWidth: '680px',
  position: 'relative',
  marginBottom: ['20px', '30px', '30px', '40px'],
  textAlign: 'center',
});

const contentStyle = scale({
  padding: ['32px', '40px'],
  position: 'relative',
});

const shareContainer = (isSmall) =>
  scale({
    paddingTop: '20px',
    maxWidth: isSmall ? '200px' : '450px',
    margin: 'auto',
    textAlign: 'center',
  });

const iconStyle = scale({
  height: 'auto',
  width: ['50px', '50px', '50px', '60px'],
  marginBottom: '15px',
});

const iconPosition = scale({
  position: 'absolute',
  top: ['-30px', '-30px', '-30px', '-40px'],
  left: '0',
  right: '0',
  margin: 'auto',
});

const paragraphStyle = scale({
  fontSize: '18px',
  lineHeight: '26px',
  color: 'var(--text-secondary)',
});

const headerStyle = scale({
  fontSize: [
    oldFontSizes.medium,
    oldFontSizes.medium,
    oldFontSizes.large,
    oldFontSizes.large,
  ],
  lineHeight: ['24pt', '24pt', '28pt', '28pt'],
  fontWeight: [600, 600, 700, 700],
  fontFamily: fonts.Sans,
  cursor: 'default',
  margin: '16px 0 8px 0',
  sub: {
    lineHeight: 0,
  },
});

const MessageCard = (props) => {
  const {
    header,
    description,
    signed,
    socialText,
    noShare = false,
    type,
    shareButtonSize,
    hasNoWrapper,
  } = props;
  const iconToDisplay = type === 'welcome' ? welcome : celebrate;

  function MessageContent() {
    return (
      <div>
        <img
          src={iconToDisplay}
          css={[iconStyle, !hasNoWrapper && iconPosition]}
          alt={type}
        />
        <h1 css={headerStyle}>{header}</h1>
        <p css={paragraphStyle}>{description}</p>
        <br />
        <p>
          <strong>{signed}</strong>
        </p>
        {!noShare && (
          <div css={shareContainer(shareButtonSize === 'small')}>
            <ShareButtons
              name="message_card"
              url="https://www.wren.co"
              text={socialText}
              subject="Join me in reversing climate change on @project_wren"
              hashtags=""
              size={shareButtonSize}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div css={outerContainer}>
      {hasNoWrapper ? (
        <MessageContent />
      ) : (
        <CardContainer>
          <div css={contentStyle}>
            <MessageContent />
          </div>
        </CardContainer>
      )}
    </div>
  );
};

export default MessageCard;
