/** @jsxImportSource @emotion/react */

import { ReactNode } from 'react';
import { fadeIn, hideOnMobile, showOnMobile } from '../../style/misc';
import { scale } from '../../util/scale';
import Flex from '../UI/Flex';
import HorizontalSeparator from '../UI/HorizontalSeparator';
import { Citation, Headings } from '../UI/TextStyles';

const proofItem = (color?: string) =>
  scale({
    padding: 16,
    cite: {
      color: 'var(--gray-6)',
      lineHeight: 1.2,
      display: 'block',
      paddingTop: 8,
    },
    h1: {
      fontSize: [24, 24, 36, 36],
      fontWeight: 700,
      color: color || 'var(--success-green)',
    },
  });

export default function ValueProof({
  cta,
  header = (
    <>
      Wren is your <em>all-in-one</em>
      <br /> climate subscription.
    </>
  ),
  color,
}: {
  cta?: ReactNode;
  header?: ReactNode;
  color?: string;
}) {
  return (
    <div css={scale({ textAlign: 'center', marginBottom: ['-30px', 0] })}>
      {header && (
        <Headings.Large
          font="serif"
          css={scale({
            maxWidth: ['95%', '95%', 700, 700],
            fontSize: [26, 26, '40px !important', '40px !important'],
            marginBottom: [16, 16, undefined, undefined],
          })}
        >
          {header}
        </Headings.Large>
      )}
      {cta}

      {(header || cta) && (
        <HorizontalSeparator
          css={[
            showOnMobile,
            {
              marginTop: 50,
              backgroundColor: 'var(--background-gray)',
              marginBottom: 16,
            },
          ]}
        />
      )}

      <Flex align="flex-start" justify="center">
        <div css={fadeIn(0.5)}>
          <div css={proofItem(color)}>
            <Headings.Large
              font="sans"
              css={{
                margin: 0,
                padding: 0,
                lineHeight: '1 !important',
              }}
            >
              $12M+
            </Headings.Large>
            <Citation>Raised for projects</Citation>
          </div>
        </div>
        <div css={fadeIn(0.6)}>
          <div css={[proofItem(color), hideOnMobile]}>
            <Headings.Large
              font="sans"
              css={{
                color: 'var(--success-green)',
                margin: 0,
                padding: 0,
                lineHeight: '1 !important',
              }}
            >
              2M+
            </Headings.Large>
            <Citation>Contributions</Citation>
          </div>
        </div>
        <div css={fadeIn(0.7)}>
          <div css={proofItem(color)}>
            <Headings.Large
              font="sans"
              css={{
                color: 'var(--success-green)',
                margin: 0,
                padding: 0,
                lineHeight: '1 !important',
              }}
            >
              30K+
            </Headings.Large>
            <Citation>Supporters</Citation>
          </div>
        </div>
        <div css={fadeIn(0.8)}>
          <div css={proofItem(color)}>
            <Headings.Large
              font="sans"
              css={{
                color: 'var(--success-green)',
                margin: 0,
                padding: 0,
                lineHeight: '1 !important',
              }}
            >
              670K+
            </Headings.Large>
            <Citation>Tons of CO₂ offset</Citation>
          </div>
        </div>
      </Flex>
    </div>
  );
}
