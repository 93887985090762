import { SpecialOffer } from '@wren/schema';

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

export function getExpirationTime(
  claim: Pick<SpecialOffer, 'created_at' | 'days_until_expire'>
) {
  if (claim.days_until_expire === null) {
    return null;
  }
  return claim.created_at.getTime() + claim.days_until_expire * ONE_DAY_IN_MS;
}

export function filterToApplicableSpecialOfferClaimsForSubscription<
  ClaimType extends {
    updated_at: Date;
    special_offer: Pick<
      SpecialOffer,
      'created_at' | 'incentive_type' | 'days_until_expire'
    >;
  }
>(claims: ClaimType[]) {
  // We currently assume all unexpired claims are applicable to a subscription sign-up, except covered_months and campaign_match, of which there can only be one

  // We sort this way so that we can pick the most recent claim, if there are competing claims
  const sortedClaims = claims.sort(
    (a, b) => b.updated_at.getTime() - a.updated_at.getTime()
  ); // Sort by desc time

  let alreadyHasApplicableCoveredMonthsOrCampaignMatch = false;

  return sortedClaims.filter((claim) => {
    // filter out expired offers
    const expirationTime = getExpirationTime(claim.special_offer);
    if (expirationTime && expirationTime < Date.now()) {
      return false;
    }

    // Note: We do not consider claim limits, since those should prevent claims, not prevent applying offers that have already been claimed

    if (
      claim.special_offer.incentive_type === 'covered_months' ||
      claim.special_offer.incentive_type === 'campaign_match'
    ) {
      if (alreadyHasApplicableCoveredMonthsOrCampaignMatch) {
        return false;
      }
      alreadyHasApplicableCoveredMonthsOrCampaignMatch = true;
      return true;
    }

    // We assume most (and any new) incentive types all are applicable to a new subscription, in any quantity
    return true;
  });
}
