import { useRef, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { staticImageUrl } from '../util/images';

export default function useIncrementalLoad(
  imagePath: string,
  imageOptions: Record<string, string> & { url?: string }
): [string, SerializedStyles] {
  const [status, setStatus] = useState('none');
  const startRef = useRef(new Date());

  const lowQualityUrl = staticImageUrl(imagePath, {
    h: 0.05, // sets image height to 5% of source image from CDN
    q: 0, // quality of 0/100 reduces file size
  });

  // This sets the initial low-quality image
  const [imageUrl, setImageUrl] = useState(lowQualityUrl);

  const onLoad = () => {
    if (imageUrl === lowQualityUrl) {
      setImageUrl(staticImageUrl(imagePath, imageOptions));
      setStatus('low');
    } else if (status === 'low') {
      setStatus('high');
    }
  };

  if (typeof Image !== 'undefined') {
    const image = new Image();
    image.onload = onLoad;
    image.src = imageUrl;
  }

  // If loading takes more than 500ms, include a smooth transition
  const time = new Date().getTime() - startRef.current.getTime();
  const transition =
    time > 500
      ? '300ms filter ease, 300ms opacity, backgroundColor linear'
      : undefined;

  const imageCss = (() => {
    if (status === 'none') {
      return css({ filter: 'blur(20px)', opacity: 0 });
    } else if (status === 'low') {
      return css({ filter: 'blur(20px)', opacity: 1, transition });
    }
    // status === 'high'
    return css({ transition });
  })();

  return [imageUrl, imageCss];
}
