/** @jsxImportSource @emotion/react */

export default function MessengerLogo({ color = 'black', size = 20 }) {
  return (
    <svg viewBox="0 0 1325.3333 1325.3199" width={size} id="svg2" version="1.1">
      <defs id="defs6" />
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,1325.32)" id="g10">
        <g transform="scale(0.1)" id="g12">
          <path
            id="path14"
            fill={color}
            d="m 4970,9939.87 c -2800,0 -4970,-2051 -4970,-4821 0,-1449 594,-2701 1561,-3566 81,-73 130,-174 134,-283 l 27,-883.999 c 9,-282 300,-464.9999 558,-351.9999 l 986,434.9999 c 84,37 177,44 265,20 453,-125 936,-191 1439,-191 2800,0 4970,2050.999 4970,4820.999 0,2770 -2170,4821 -4970,4821"
          />
          <path
            fill="white"
            d="m 1986,3708.87 1460,2316 c 232,368 730,460 1078,199 l 1161,-871 c 107,-80 253,-79 359,1 l 1568,1190 c 209,159 483,-92 342,-314 l -1460,-2316 c -232,-368 -730,-460 -1078,-199 l -1161,871 c -107,80 -253,79 -359,-1 l -1568,-1190 c -209,-159 -483,92 -342,314"
          />
        </g>
      </g>
    </svg>
  );
}
