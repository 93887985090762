/** @jsxImportSource @emotion/react */

import { scale } from '../../util/scale';
import { fonts } from '../../style/theme';

const newTag = scale({
  backgroundColor: 'var(--error-red)',
  color: 'white',
  textTransform: 'uppercase',
  width: 'auto',
  height: '12px',
  boxSizing: 'border-box',
  fontSize: '9px',
  lineHeight: '9px',
  padding: '1px 4px',
  borderRadius: '2px',
  display: 'inline-block',
  position: 'relative',
  zIndex: 0,
  top: '-6px',
  left: ['2px', '2px', 0, 0],
  fontFamily: `${fonts.Sans} !important`,
});

const NewTag = ({ className, text }: { className?: string; text?: string }) => {
  return (
    <div css={newTag} className={className}>
      {text ?? 'New'}
    </div>
  );
};

export default NewTag;
