/** @jsxImportSource @emotion/react */
import {
  createStore,
  applyMiddleware,
  compose,
  Store as ReduxStore,
  Action,
} from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import throttle from 'lodash/throttle';
import rootReducers from '../reducers';
import { loadState, saveState } from '../util/localStorage';
import { Countries, Network, Session } from '@wren/shared';
import * as Sentry from '@sentry/react';
import {
  BootstrappedData,
  Calculator,
  ReduxCart,
  CompiledPortfolio,
  Currency,
  SerializedUser,
  Stripe,
  Flight,
} from '../types';
import { Card, PaymentMethod, Source } from '@stripe/stripe-js';

const { getSessionToken, setSessionToken, removeSessionToken } = Session;

declare global {
  interface Window {
    bootstrappedData?: BootstrappedData;
  }
}

// The "trace" stuff allows the devtools to show us what lines of code
// triggered certain redux actions.
const composeEnhancers =
  // @ts-expect-error: This is a feature of redux-devtools-extension that is not yet available in types.
  ((window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // @ts-expect-error: This is a feature of redux-devtools-extension that is not yet available in types.
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
    compose) as typeof compose;

async function fetchCurrentUser() {
  const [response, responseBody] = await Network.post<{
    userInfo: SerializedUser;
  }>('user-info');

  if (response.ok && responseBody) {
    return responseBody.userInfo;
  }
  return null;
}

async function fetchExchangeRatesAndCurrencies() {
  const [response, responseBody] = await Network.get<{
    exchangeRates: { [currencyCode: string]: number };
    currencies: Currency[];
  }>('payment/get-exchange-rates');

  if (response.ok && responseBody) {
    return responseBody;
  }
}

async function fetchCountries() {
  const [response, responseBody] = await Network.get<Countries.Country[]>(
    'countries'
  );

  if (response.ok && responseBody) {
    return responseBody;
  }
}

async function fetchBootstrapData() {
  const sessionToken = getSessionToken();

  if (!sessionToken) {
    const [response, responseBody] = await Network.post<{
      sessionToken: string;
    }>('generate-session-id');

    if (response.ok && responseBody) {
      setSessionToken(responseBody.sessionToken);
    } else {
      throw new Error('Session is super broken.');
    }
  }

  const user = await fetchCurrentUser();
  const exchangeRatesAndCurrencies = await fetchExchangeRatesAndCurrencies();

  if (!(exchangeRatesAndCurrencies && user)) {
    throw new Error('Could not get fetch bootstrappedData.');
  }

  const data: BootstrappedData = {
    user,
    exchangeRates: exchangeRatesAndCurrencies.exchangeRates,
    currencies: exchangeRatesAndCurrencies.currencies,
  };

  window.bootstrappedData = data;

  return data;
}

async function initializeSession() {
  let user;

  // the server has the ability to bootstrap the user onto the page to save this request.
  if (window.bootstrappedData) {
    user = window.bootstrappedData.user;
  } else {
    user = await fetchCurrentUser();
  }

  if (user) {
    Sentry.setUser({
      id: String(user.userId),
      email: user.email ?? undefined,
      username: user.username ?? undefined,
    });

    return user;
  }

  // 3. if the request fails, log them out
  removeSessionToken();
  window.location.href = `/logout?redirectTo=${encodeURIComponent(
    window.location.href
  )}`;
}

export interface State {
  user: SerializedUser;
  exchangeRates: { [currencyCode: string]: number };
  calculator: Calculator;
  calculatorQuestionIndex: number;
  portfolios: CompiledPortfolio[];
  flights: {
    offsetFlights: Flight[] | undefined;
    loggedFlights: Flight[] | undefined;
  };
  announcement?: string;
  stripe: {
    subscription?: Stripe.Subscription;
    cardInfo?: PaymentMethod | Source | Card;
    charges?: Stripe.Charge[];
  };
  cart: ReduxCart;
}

export type Store = ReduxStore<State>;

let store: Store;
export async function initializeStore() {
  const persistedState = loadState() || {};

  // The store relies on data from an object called `window.bootstrappedData` to initialize properly.
  // When the backend is serving this page, that data will be inserted into the HTML itself.
  // However, during development we use a different webpack dev server for development.
  // Therefore on development we will emulate that behavior by fetching that data and setting the
  // window object directly.
  let { bootstrappedData } = window;

  if (!bootstrappedData) {
    bootstrappedData = await fetchBootstrapData();
    window.bootstrappedData = bootstrappedData;
  }

  const user = await initializeSession();

  store = createStore(
    rootReducers,
    {
      ...persistedState,
      user,
      exchangeRates: bootstrappedData.exchangeRates,
    },
    composeEnhancers(applyMiddleware(thunk))
  );

  store.subscribe(
    throttle(() => {
      saveState({
        cart: store.getState().cart,
        calculatorQuestionIndex: store.getState().calculatorQuestionIndex,
        portfolios: store.getState().portfolios,
        announcement: store.getState().announcement,
      });
    }, 1000)
  );

  return store;
}

export default function getStore() {
  if (store) {
    return store;
  }

  throw new Error('There is no store to return');
}

export type Dispatch = ThunkDispatch<State, void, Action>;
