import {
  CartItem,
  CustomDurationCartDetails,
  SubscriptionCartDetails,
  SponsorshipCartDetails,
  OneOffCartDetails,
  GiftCartDetails,
  FlightCartDetails,
  FundContributionCartDetails,
  CustomDurationPaymentPlanDetails,
  TeamSubscriptionCartDetails,
} from '../types';

// NOTE: This file contains logic about carts which is directly duplicated
// on the backend in db/carts.ts. In the future, we should try to consolidate
// this logic into a single place.

export type SubscriptionCartItem = CartItem & {
  sku: 'subscription';
  detailsJson: SubscriptionCartDetails;
};

export type CustomDurationCartItem = CartItem & {
  sku: 'custom-duration';
  detailsJson: CustomDurationCartDetails;
};

export type SponsorshipCartItem = CartItem & {
  sku: 'sponsorship';
  detailsJson: SponsorshipCartDetails;
};

export type OneOffCartItem = CartItem & {
  sku: 'one-off';
  detailsJson: OneOffCartDetails;
};

export type GiftCartItem = CartItem & {
  sku: 'gift';
  detailsJson: GiftCartDetails;
};

export type FlightCartItem = CartItem & {
  sku: 'flight';
  detailsJson: FlightCartDetails;
};

export type FundContributionCartItem = CartItem & {
  sku: 'fund-contribution';
  detailsJson: FundContributionCartDetails;
};

export type CustomDurationPaymentPlanCartItem = CartItem & {
  sku: 'custom-duration-payment-plan';
  detailsJson: CustomDurationPaymentPlanDetails;
};

export type TeamSubscriptionCartItem = CartItem & {
  sku: 'team-subscription';
  detailsJson: TeamSubscriptionCartDetails;
};

export function isSubscriptionCartItem(
  cartItem: CartItem
): cartItem is SubscriptionCartItem {
  return cartItem.sku === 'subscription';
}

export function isCustomDurationCartItem(
  cartItem: CartItem
): cartItem is CustomDurationCartItem {
  return cartItem.sku === 'custom-duration';
}

export function isSponsorshipCartItem(
  cartItem: CartItem
): cartItem is SponsorshipCartItem {
  return cartItem.sku === 'sponsorship';
}

export function isOneOffCartItem(
  cartItem: CartItem
): cartItem is OneOffCartItem {
  return cartItem.sku === 'one-off';
}

export function isGiftCartItem(cartItem: CartItem): cartItem is GiftCartItem {
  return cartItem.sku === 'gift';
}

export function isFlightCartItem(
  cartItem: CartItem
): cartItem is FlightCartItem {
  return cartItem.sku === 'flight';
}

export function isFundContributionCartItem(
  cartItem: CartItem
): cartItem is FundContributionCartItem {
  return cartItem.sku === 'fund-contribution';
}

export function isCustomDurationPaymentPlanCartItem(
  cartItem: CartItem
): cartItem is CustomDurationPaymentPlanCartItem {
  return cartItem.sku === 'custom-duration-payment-plan';
}

export function isTeamSubscriptionCartItem(
  cartItem: CartItem
): cartItem is TeamSubscriptionCartItem {
  return cartItem.sku === 'team-subscription';
}

export function doesCartItemCreateStripeSubscription(
  cartItem: CartItem
): cartItem is SubscriptionCartItem | TeamSubscriptionCartItem {
  return (
    isSubscriptionCartItem(cartItem) || isTeamSubscriptionCartItem(cartItem)
  );
}

export function getBillingCycleForSubscriptionCartItem(
  cartItem: SubscriptionCartItem | TeamSubscriptionCartItem
) {
  return cartItem.sku === 'team-subscription'
    ? 'month' // team subscriptions are always monthly
    : cartItem.detailsJson.billingCycle;
}
