/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { fonts, oldFontSizes } from '../../style/theme';
import { staticImageUrl } from '../../util/images';
import { scale } from '../../util/scale';
import AnimateEnterAndExit from '../animation/AnimateEnterAndExit';

const checkmark = staticImageUrl('checkmark.svg');
const alert = staticImageUrl('alert.svg');

const toastStyle = css`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0 var(--box-shadow-color);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  p {
    font-size: ${oldFontSizes.body};
    margin-right: 10px;
  }
`;

const toastContentStyle = (buttonText) => ({
  maxWidth: '450px',
  padding: '20px',
  display: 'flex',
  minWidth: '200px',
  width: 'fit-content',
  justifyContent: 'flex-start',
  alignItems: buttonText ? 'flex-start' : 'center',
});

const buttonStyle = css`
  color: var(--text-primary);
  padding: 3px 5px;
  font-family: ${fonts.Sans};
  font-size: ${oldFontSizes.caption};
  border: 1px solid var(--wren-orange);
  background-color: var(--wren-orange);
  color: white;
  border-radius: 4px;
  transition: 0.3;
  text-align: center;
  width: fit-content;
  display: inline;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-right: 5px;
  :hover {
    border: 1px solid var(--wren-orange-hover);
    color: var(--wren-orange-hover);
    background-color: white;
    cursor: pointer;
  }
`;

const buttonWrapper = scale({
  marginTop: '5px',
});

const dismissStyle = css`
  border: 1px solid var(--gray-9);
  color: var(--border-color);
  background-color: white;
  :hover {
    color: var(--text-primary);
    border: 1px solid var(--border-color);
  }
`;

const typeIconStyle = css`
  width: 20px;
  margin-right: 15px;
  margin-top: 3px;
`;

const textStyle = css`
  max-width: 200px;
`;

// TODO: Maybe should just be consolidated with Toaster
const Toast = (props) => {
  const {
    toastIn,
    text,
    buttonText,
    buttonAction,
    closeAction,
    type,
    isDismissable,
    isOnRight,
  } = props;

  const toastTypeIcon = () => {
    if (type === 'success') {
      return checkmark;
    }
    if (type === 'alert') {
      return alert;
    }
    return undefined;
  };

  const container = scale({
    position: 'fixed',
    bottom: ['10px', '-30px', '-30px', '-30px'],
    left: !isOnRight
      ? ['0px', '30px', '30px', '30px']
      : ['0px', 'auto', 'auto', 'auto'],
    right: isOnRight
      ? ['0px', '75px', '75px', '75px']
      : ['0px', 'auto', 'auto', 'auto'],
    zIndex: 1000000,
    maxWidth: ['90%', 'fit-content', 'fit-content', 'fit-content'],
    margin: ['auto', '0', '0', '0'],
  });

  const animation = {
    keyframes: [
      { opacity: 0, marginBottom: '0' },
      { opacity: 1, marginBottom: '60px' },
    ],
    options: {
      duration: 200,
    },
  };

  return (
    <div css={container}>
      <AnimateEnterAndExit animate={animation}>
        {toastIn && (
          <div css={{ marginBottom: 60 }}>
            <div css={toastStyle}>
              <div css={toastContentStyle(buttonText)}>
                <img css={typeIconStyle} src={toastTypeIcon()} alt={type} />
                <div>
                  <p css={textStyle}>{text}</p>
                  {buttonText && (
                    <div css={buttonWrapper}>
                      <div
                        css={buttonStyle}
                        role="button"
                        tabIndex={0}
                        onClick={() => buttonAction()}
                        onKeyDown={() => buttonAction()}
                      >
                        {buttonText}
                      </div>
                      <div
                        css={[buttonStyle, dismissStyle]}
                        role="button"
                        tabIndex={0}
                        onClick={() => buttonAction()}
                        onKeyDown={() => buttonAction()}
                      >
                        Dismiss
                      </div>
                    </div>
                  )}
                </div>
                {isDismissable && (
                  <div
                    css={[buttonStyle, dismissStyle]}
                    role="button"
                    tabIndex={0}
                    onClick={() => closeAction()}
                    onKeyDown={() => closeAction()}
                  >
                    Dismiss
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </AnimateEnterAndExit>
    </div>
  );
};

Toast.defaultProps = {
  text: '',
  buttonText: '',
  buttonAction: undefined,
  closeAction: undefined,
  type: undefined,
  isDismissable: false,
  toastIn: false,
  isOnRight: false,
};

Toast.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonAction: PropTypes.func,
  closeAction: PropTypes.func,
  type: PropTypes.string,
  isDismissable: PropTypes.bool,
  toastIn: PropTypes.bool,
  isOnRight: PropTypes.bool,
};

export default Toast;
