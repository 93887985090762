const initialState = {
  subscriptionInterval: 'month',
  addOn: {},
  subscription: null,
};

function setSubscriptionInterval(state, action) {
  const newState = { ...state, subscriptionInterval: action.interval };
  let subscriptionChanges = {};

  if (state.subscription) {
    if (
      action.interval === 'year' &&
      state.subscription.billingCycle === 'month'
    ) {
      subscriptionChanges = {
        billingCycle: 'year',
        amount: state.subscription.amount * 12,
        amountInUSD: state.subscription.amountInUSD * 12,
      };
    }

    if (
      action.interval === 'month' &&
      state.subscription.billingCycle === 'year'
    ) {
      subscriptionChanges = {
        billingCycle: 'month',
        amount: state.subscription.amount / 12,
        amountInUSD: state.subscription.amountInUSD / 12,
      };
    }

    newState.subscription = {
      ...newState.subscription,
      ...subscriptionChanges,
    };
  }

  return newState;
}

function setAddOn(state, action) {
  return { ...state, addOn: action.addOn };
}

function removeAddOn(state) {
  return { ...state, addOn: {} };
}

function setDonation(state, action) {
  return { ...state, donation: action.donation };
}

function setSubscription(state, action) {
  return { ...state, subscription: action.subscription };
}

export default function cart(state = initialState, action) {
  switch (action.type) {
    case 'SET_DONATION':
      return setDonation(state, action);
    case 'SET_ADD_ON':
      return setAddOn(state, action);
    case 'REMOVE_ADD_ON':
      return removeAddOn(state, action);
    case 'SET_SUBSCRIPTION_INTERVAL':
      return setSubscriptionInterval(state, action);
    case 'SET_SUBSCRIPTION':
      return setSubscription(state, action);
    case 'CLEAR_CART':
      return initialState;
    default:
      return state;
  }
}
