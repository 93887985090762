/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Button } from '../buttons/ButtonNext';
import LoadingButton from '../buttons/LoadingButton';
import { scale } from '../../util/scale';
import HeaderBlock from '../UI/HeaderBlock';
import CardContainer from '../UI/CardContainer';
import CardContent from '../UI/CardContent';
import StripeSetupForm from './StripeSetupForm';
import { Paragraph } from '../UI/TextStyles';
import { colors, spacing } from '../../style/theme';
import useQueryParams from '../../hooks/useQueryParams';
import { getLatestSubscription, isTerminal } from '../../lib/subscriptions';
import { State } from '../../store';
import { useSelector } from 'react-redux';
import { SerializedUser } from '../../types';
import {
  convertCurrency,
  getCurrencyObjFromCode,
} from '../../util/conversions';
import { Stripe } from '@wren/shared';
import { AcceptedPaymentMethodType } from '@wren/shared/dist/lib/stripe';
import * as Sentry from '@sentry/react';
import Price from '../UI/Price';
import { fadeIn } from '../../style/misc';

const { calculateFeeFromGoal, isAcceptedPaymentMethodType } = Stripe;

const primaryCol = scale({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: ['center', 'center', 'center', 'space-around'],
  alignContent: ['center', 'center', 'center', 'space-around'],
  flexDirection: ['column-reverse', 'column-reverse', 'column-reverse', 'row'],
  maxWidth: '900px',
  width: ['90%', '100%'],
  margin: 'auto',
  marginBottom: '50px',
  paddingTop: [spacing.xLarge, spacing.xLarge, spacing.medium],
  paddingBottom: [spacing.large, spacing.xLarge],
});

const UpdatePayment = () => {
  const { getQueryParam } = useQueryParams();

  const [showSetupForm, setShowSetupForm] = useState(
    getQueryParam('showSetupForm') === 'true'
  );

  const [paymentMethodType, setPaymentMethodType] =
    useState<AcceptedPaymentMethodType>('card');

  const user: SerializedUser = useSelector((state: State) => state.user);
  if (!user.currency) {
    return null;
  }
  let subscription = getLatestSubscription(user);

  if (subscription && isTerminal(subscription.status)) {
    subscription = undefined;
  }
  let newFeeAmount = 0;
  if (subscription && subscription.feeAmount) {
    const newFeeInUsdCents = calculateFeeFromGoal(
      convertCurrency(
        subscription.amount /
          getCurrencyObjFromCode(user.currency).smallestDenominationDivider,
        user.currency,
        'USD'
      ) * 100,
      paymentMethodType,
      user.currency !== 'USD'
    );
    newFeeAmount = convertCurrency(
      newFeeInUsdCents / 100,
      'USD',
      user.currency
    );
  }

  function handleChangePaymentMethodType(type: string) {
    if (type === 'apple_pay' || type === 'google_pay') {
      // These are actually not payment method types. See https://stripe.com/docs/payments/customize-payment-element#payment-method-order
      setPaymentMethodType('card');
    } else if (isAcceptedPaymentMethodType(type)) {
      setPaymentMethodType(type);
    } else {
      Sentry.captureMessage(
        `Churn notice encountered an unacceptable payment method type: ${type}`,
        'error'
      );
    }
  }

  return (
    <div>
      <Helmet>
        <title>Wren | Update Payment Method</title>
      </Helmet>
      <div css={primaryCol}>
        <div css={fadeIn()}>
          {
            <div>
              <HeaderBlock
                preamble="UPDATE INFO"
                header="Update your payment method"
                description="Enter your preferred credit card information with the card name to change the payment method for your next payment."
              />
              <CardContainer css={{ maxWidth: 400 }}>
                <CardContent>
                  {showSetupForm ? (
                    <>
                      <StripeSetupForm
                        forTeam={false}
                        redirectTo="/success"
                        redirectQueryParams={{
                          finalPath: '/settings/subscription',
                        }}
                        button={
                          <LoadingButton
                            buttonType="filled"
                            color={colors.successGreen}
                            css={{ width: '100%' }}
                            size="medium"
                            shape="rounded rectangle"
                          >
                            Add payment method
                          </LoadingButton>
                        }
                        isCheckout={false}
                        onChange={handleChangePaymentMethodType}
                      />

                      {subscription && (
                        <Paragraph
                          css={{
                            marginTop: spacing.default,
                            color: colors.textSecondary,
                            textAlign: 'center',
                            fontSize: '14px !important',
                          }}
                        >
                          Your next subscription charge will be{' '}
                          <b>
                            <Price
                              amount={
                                subscription.amount /
                                  getCurrencyObjFromCode(user.currency)
                                    .smallestDenominationDivider +
                                newFeeAmount
                              }
                              currencyCode={user.currency}
                            />
                          </b>
                          {newFeeAmount > 0 && `, including processing fee`}.
                        </Paragraph>
                      )}
                    </>
                  ) : (
                    <div css={{ textAlign: 'center' }}>
                      <Button
                        buttonType="filled"
                        color={colors.successGreen}
                        css={{ width: '100%' }}
                        size="medium"
                        shape="rounded rectangle"
                        onClick={() => setShowSetupForm(true)}
                      >
                        Enter new payment method
                      </Button>
                    </div>
                  )}
                </CardContent>
              </CardContainer>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default UpdatePayment;
