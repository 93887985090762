/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import { MouseEvent, PropsWithChildren, lazy } from 'react';
import { Button } from '../../components/buttons/ButtonNext';
import { NavButton } from '../../components/buttons/NavButton';
import ArrowWiggleUpRight from '../../components/svgs/ArrowWiggleUpRight';
import { hideOnMobile } from '../../style/misc';
import { colors, oldFontSizes, spacing } from '../../style/theme';
import { ReferrerUser } from '../../types';
import { staticImageUrl } from '../../util/images';
import { breakpoints, scale } from '../../util/scale';

const ShareButtons = lazy(
  () => import('../../components/sharing/ShareButtons')
);
import useMediaQuery from '../../hooks/useMediaQuery';

export function StickToBottom({
  children,
  ...restOfProps
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      css={{
        top: '100vh',
        position: 'sticky',
        width: '100%',
      }}
      {...restOfProps}
    >
      <div
        css={{
          justifyContent: 'stretch',
          display: 'flex',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          '&>*': {
            flexGrow: 1,
          },
        }}
      >
        {children}
      </div>
    </div>
  );
}

export function StickAbove({
  children,
  ...restOfProps
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      css={{
        position: 'absolute',
        width: '100%',
      }}
      {...restOfProps}
    >
      <div
        css={{
          bottom: 0,
          position: 'absolute',
          width: '100%',
        }}
      >
        {children}
      </div>
    </div>
  );
}

const GreenBox = styled.div(
  scale({
    alignItems: ['center', 'center', 'flex-start'],
    backgroundColor: colors.successGreenLowOpacity,
    borderRadius: [
      Number.MAX_SAFE_INTEGER,
      Number.MAX_SAFE_INTEGER,
      spacing.xSmall,
    ],
    display: 'flex',
    flexDirection: ['row', 'row', 'column'],
    fontSize: oldFontSizes.caption,
    gap: spacing.small,
    justifyContent: 'space-between',
    margin: spacing.small,
    padding: spacing.small,
  })
);

export function CompletedCallout({
  numCompletedActions,
  onSignUpClick,
}: {
  numCompletedActions: number;
  onSignUpClick: (event: MouseEvent) => void;
}) {
  return (
    <GreenBox>
      <ArrowWiggleUpRight css={{ flexShrink: 0 }} />
      <h3 css={hideOnMobile}>Keep up the momentum!</h3>
      <div>
        <span css={scale({ fontWeight: [600, 400] })}>
          You’ve completed {numCompletedActions} action
          {numCompletedActions > 1 ? 's' : ''}!
        </span>{' '}
        Create an account on Wren to save your progress.
      </div>
      <Button
        buttonType="filled"
        color={colors.successGreen}
        css={{ flexShrink: 0 }}
        onClick={onSignUpClick}
        padding="tight"
      >
        Save<span css={hideOnMobile}> progress</span>
      </Button>
    </GreenBox>
  );
}

export function ReferrerCallout({
  referrer,
  onSignUpClick,
}: {
  referrer: ReferrerUser;
  onSignUpClick: (event: MouseEvent) => void;
}) {
  return (
    <GreenBox>
      <img
        src={referrer.profileImageUrl ?? staticImageUrl('wren_profile.svg')}
        css={{
          width: 44,
          height: 44,
          borderRadius: 44,
          objectFit: 'cover',
        }}
      />
      <div>
        <b>Join {referrer.firstName}</b> and make sustainable lifestyle choices
        for free.
      </div>
      <Button
        buttonType="filled"
        color={colors.successGreen}
        css={{ flexShrink: 0 }}
        onClick={onSignUpClick}
        padding="tight"
      >
        {/* this is a button instead of a SignUpButton to avoid trying to display a pop-up inside a 'sticky' element*/}
        Sign up
      </Button>
    </GreenBox>
  );
}

export function WelcomeCallout() {
  return (
    <GreenBox css={scale({ paddingLeft: [spacing.medium, spacing.small] })}>
      <h3 css={hideOnMobile}>Welcome</h3>
      <div>You’ve unlocked a whole dashboard of impact. Check it out now!</div>
      <NavButton
        buttonType="filled"
        color={colors.successGreen}
        css={{ flexShrink: 0 }}
        href={'/dashboard'}
        padding="tight"
      >
        Start tour
      </NavButton>
    </GreenBox>
  );
}

export function ShareCallout() {
  const isDesktop = useMediaQuery(breakpoints.tablet);
  return (
    <GreenBox
      css={scale({
        paddingRight: [spacing.medium, spacing.medium, spacing.small],
        paddingLeft: [spacing.medium, spacing.medium, spacing.small],
        backgroundColor: colors.backgroundBeige,
      })}
    >
      {isDesktop ? (
        <>
          <h3>Anyone can complete our climate actions.</h3>
          <div>
            Share with a friend today—they can tick off actions for free, and
            you’ll amplify your impact.
          </div>
        </>
      ) : (
        <div>
          <strong>Anyone can complete our climate actions.</strong> Share with a
          friend today!
        </div>
      )}
      {/* TODO: Type ShareButtons */}
      <ShareButtons
        name="actions_share"
        className=""
        text="Check out these sustainable suggestions!"
        url="https://www.wren.co/actions"
        size={16}
        socialsArr={
          isDesktop ? ['copy', 'twitter', 'fb', 'linkedin'] : ['copy']
        }
        iconStyle
        utmMedium={undefined}
        campaignName={undefined}
      />
    </GreenBox>
  );
}
