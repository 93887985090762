import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Leaderboard({
  color = colors.wrenGreen,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...restOfProps}
    >
      <path
        fill={color}
        fill-opacity=".7"
        d="M18 11H22c.6 0 1.1.5 1.1 1.1v8.8c0 .6-.5 1.1-1.1 1.1h-3.8c-.7 0-1.2-.5-1.2-1.1v-8.8c0-.6.5-1.1 1.2-1.1Z"
      />
      <path
        fill={color}
        d="M14 2.2h-4c-.5 0-1 .5-1 1.1v17.6c0 .6.5 1.1 1 1.1h4c.5 0 1-.5 1-1.1V3.3c0-.6-.5-1.1-1-1.1Z"
      />
      <path
        fill={color}
        fill-opacity=".3"
        d="M2.1 22h3.8c.7 0 1.1-.5 1.1-1.1v-11c0-.6-.4-1.1-1-1.1H2c-.6 0-1.1.5-1.1 1.1v11c0 .6.5 1.1 1.1 1.1Z"
      />
    </svg>
  );
}
