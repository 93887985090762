import { Network } from '@wren/shared';

export function SetPortfolios(portfolios) {
  return {
    type: 'SET_PORTFOLIOS',
    portfolios,
  };
}

// TODO: Implement a proper fix for eslint here - just hotfixing till Taylor is back:
// See: https://sentry.io/organizations/project-wren/issues/2910157359/tags/user/?project=5672308&referrer=slack
// hasFetchedPortfolios was set to true simply bc there was > 0 portfolios in state, but
// if user is missing one of the presets for some reason (i.e. returning to the site after awhile)
// we need to refresh the portfolios or the carbon report breaks

export const controlPortfolioSlug = 'universal-fund';
export const variantPortfolioSlug = 'universal-fund-35';

let hasFetchedPortfolios = false;

export function FetchPresetPortfolios() {
  return async function (dispatch, getState) {
    if (!hasFetchedPortfolios) {
      // only assign experiment if no portfolios have been fetched before
      const user = getState().user;

      // we only need to make this request once
      const [response, responseBody] = await Network.get(
        'portfolios?expanded=true'
      );
      if (response.ok) {
        if ('universal-fund-35' === user.portfolio?.slug) {
          const variantPortfolios = responseBody.portfolios.filter(
            (portfolio) => portfolio.slug !== 'universal-fund'
          );

          const variantPortfoliosWithNewSlug = variantPortfolios.map(
            (portfolio) => {
              if (portfolio.slug === 'universal-fund-35') {
                return { ...portfolio, slug: 'universal-fund' };
              }
              return portfolio;
            }
          );
          dispatch(SetPortfolios(variantPortfoliosWithNewSlug));
        } else {
          const controlPortfolios = responseBody.portfolios.filter(
            (portfolio) => portfolio.slug !== variantPortfolioSlug
          );

          dispatch(SetPortfolios(controlPortfolios));
        }
        // eslint-disable-next-line require-atomic-updates
        hasFetchedPortfolios = true;
      }
    }
  };
}

export function ClearPortfolios() {
  return {
    type: 'CLEAR_PORTFOLIOS',
  };
}
