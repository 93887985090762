/** @jsxImportSource @emotion/react */

export default function ArrowRight({ width = 16 }) {
  return (
    <svg
      width={width}
      height={(width * 5) / 6}
      viewBox="0 0 48 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M46.6001 18L30.7001 1.09998C29.7001 -1.54972e-05 27.8001 0.699981 27.8001 2.29998V11.7H4.50009C2.30009 11.7 0.600098 13.5 0.600098 15.6V24.1C0.600098 26.3 2.40009 28 4.50009 28H27.8001V37.4C27.8001 38.9 29.7001 39.7 30.7001 38.6L46.6001 21.7C47.5001 20.6 47.5001 19 46.6001 18Z"
        fill={'var(--fill-slate)'}
      />
    </svg>
  );
}
