/** @jsxImportSource @emotion/react */
import { Contentful } from '@wren/shared';
import { useQuery } from '@tanstack/react-query';

import track from '../../lib/events';
import ExpandableContent from './ExpandableContent';
import RichText from '../contentful/RichText';
import useContentful from '../../hooks/useContentful';

const { getFaqs } = Contentful;

export default function CompactFAQSection({
  faqTags,
  className,
  onDarkBackground = false,
}: {
  faqTags: string[];
  className?: string;
  onDarkBackground?: boolean;
}) {
  const { client } = useContentful();
  const { data: faqs } = useQuery({
    queryKey: ['faqPageFeature'], // not sure how specific the queryKey should be, is 'faq' enough?
    queryFn: () => getFaqs(client),
    staleTime: Infinity, // never uncache
  });

  return (
    <div className={className}>
      {faqs &&
        Object.values(faqs)
          .sort(
            (a, b) =>
              new Date(a.sys.createdAt).getTime() -
              new Date(b.sys.createdAt).getTime()
          )
          .map(
            (faq) =>
              faq.fields.faqTags &&
              faq.fields.faqTags.some((tag) => faqTags.includes(tag)) && (
                <ExpandableContent
                  onDarkBackground={onDarkBackground}
                  onExpand={() => {
                    track('Server: User expanded FAQ question', {
                      questionTitle: faq.fields.heading,
                    });
                  }}
                  isSmall={true}
                  header={faq.fields.heading}
                  key={faq.fields.heading}
                  expandedByDefault={faq.fields.expandedByDefault}
                  children={
                    <RichText
                      withArticleStyle={false}
                      document={faq.fields.content}
                    />
                  }
                />
              )
          )}
    </div>
  );
}
