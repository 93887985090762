/** @jsxImportSource @emotion/react */

import { forwardRef, HTMLAttributes, Ref } from 'react';

const fullBleed = { width: '100%', gridColumn: '1 / 4 !important' };

type Props = {
  width: number | string;
} & HTMLAttributes<HTMLDivElement>;

const Container = forwardRef(
  ({ children, width, ...props }: Props, ref: Ref<HTMLDivElement>) => {
    return (
      <section
        ref={ref}
        css={{
          display: 'grid',
          gridTemplateColumns: `1fr min(${
            typeof width === 'number' ? `${width}px` : width
          }, 90%) 1fr`,
          '& > *': {
            gridColumn: 2,
          },
        }}
        {...props}
      >
        {children}
      </section>
    );
  }
);

export const FullBleedSection = forwardRef(
  (
    { children, ...props }: HTMLAttributes<HTMLDivElement>,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <section ref={ref} {...props} css={fullBleed}>
        {children}
      </section>
    );
  }
);

export default Container;
