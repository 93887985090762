import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Sliders({
  color = colors.wrenGreen,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...restOfProps}
    >
      <path fill={color} fill-opacity=".3" d="M18 21v-7h2v7a1 1 0 0 1-2 0Z" />
      <path
        fill={color}
        d="M5 2a1 1 0 0 0-1 1v1h-.5a1.5 1.5 0 0 0 0 3h3a1.5 1.5 0 0 0 0-3H6V3a1 1 0 0 0-1-1Zm7 0a1 1 0 0 0-1 1v11h-.5a1.5 1.5 0 1 0 0 3h3a1.5 1.5 0 0 0 0-3H13V3a1 1 0 0 0-1-1Zm7 0a1 1 0 0 0-1 1v6h-.5a1.5 1.5 0 0 0 0 3h3a1.5 1.5 0 0 0 0-3H20V3a1 1 0 0 0-1-1Z"
      />
      <path
        fill={color}
        fill-opacity=".3"
        d="M4 21V9h2v12a1 1 0 0 1-2 0ZM11 21v-2h2v2a1 1 0 0 1-2 0Z"
      />
    </svg>
  );
}
