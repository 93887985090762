/** @jsxImportSource @emotion/react */

import { JSXElementConstructor, Suspense, useState } from 'react';
import { ButtonProps } from '../../../components/buttons/ButtonNext';
import LoadingButton from '../../../components/buttons/LoadingButton';
import Popup from '../../../components/UI/Popup';

export function asPopupButton<
  PopUpProps extends { onRequestClose: () => void }
>(
  PopUpComponent: JSXElementConstructor<
    PopUpProps & { onRequestClose: () => void }
  > /* can be lazy */
) {
  return ({
    children,
    popUpProps,
    ...restOfProps
  }: ButtonProps & {
    popUpProps?: Omit<PopUpProps, 'onRequestClose'>;
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <Suspense
        fallback={
          <LoadingButton loading={true} {...restOfProps}>
            {children}
          </LoadingButton>
        }
      >
        <LoadingButton
          onClick={() => {
            setIsOpen(true);
          }}
          {...restOfProps}
        >
          {children}
        </LoadingButton>
        {isOpen && (
          <PopUpComponent
            {...(popUpProps as PopUpProps)}
            onRequestClose={() => setIsOpen(false)}
          />
        )}
      </Suspense>
    );
  };
}

export const PopUpButton = asPopupButton(Popup);
