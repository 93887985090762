/** @jsxImportSource @emotion/react */

interface Props {
  fill?: string;
  width?: number;
  height?: number;
  size?: number;
  className?: string;
}

export default function Stopwatch({
  fill,
  width = 52,
  height = 57,
  className,
  size,
}: Props) {
  return (
    <svg
      viewBox="0 0 52 57"
      fill={fill}
      width={size ?? width}
      height={size ?? height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3083 46.9651C36.3037 46.9651 42.7592 40.4504 42.7592 32.5143C42.7592 24.5782 36.2444 18.0635 28.3083 18.0635C27.6569 18.0635 27.1238 18.5965 27.1238 19.248V32.0405L17.411 40.332C17.1741 40.5096 17.0556 40.8058 16.9964 41.1019C16.9372 41.398 17.0556 41.6941 17.2925 41.931C20.0761 45.1292 24.0442 46.9651 28.3083 46.9651Z"
        fill={fill}
      />
      <path
        d="M48.2078 19.5442L50.0438 17.7082C50.8137 16.9383 50.8137 15.6353 50.0438 14.8062L45.9573 10.7197C45.1874 9.94975 43.8844 9.94975 43.0553 10.7197L41.2193 12.5556C38.1396 10.542 34.6454 9.23905 30.855 8.82448V5.0341H34.1716C35.593 5.0341 36.7182 3.90883 36.7182 2.48744C36.7182 1.06604 35.5337 0 34.1716 0H22.4451C21.0237 0 19.8984 1.12527 19.8984 2.54666C19.8984 3.96806 21.0237 5.09333 22.4451 5.09333H25.7617V8.88371C21.2606 9.3575 16.9372 11.1342 13.3837 14.0363C12.3177 14.9246 12.14 16.5237 13.0284 17.5897C13.9167 18.6558 15.5158 18.8335 16.5818 17.9451C19.9576 15.2207 23.9849 13.7994 28.3083 13.7994C38.6134 13.7994 46.9641 22.15 46.9641 32.4551C46.9641 42.7602 38.6134 51.1701 28.3083 51.1701C18.2401 51.1701 10.0079 43.1156 9.65255 33.1658H12.3769C13.7391 33.1658 14.5682 31.626 13.7983 30.5007L8.46806 22.5646C7.75736 21.5578 6.27674 21.5578 5.62527 22.5646L0.295047 30.5007C-0.474874 31.626 0.354271 33.1658 1.71644 33.1658H4.5C4.85535 45.9584 15.3381 56.2635 28.2491 56.2635C41.3378 56.2635 51.9982 45.603 51.9982 32.5144C52.0574 27.7172 50.6361 23.2753 48.2078 19.5442Z"
        fill={fill}
      />
    </svg>
  );
}
