/* eslint-disable @typescript-eslint/no-var-requires */
const { scale } = require('../util/scale');
const { fonts, fontSizes } = require('./theme');

// TODO: deprecate file?

export const textContainer = scale({
  maxWidth: '650px',
});

export const smallCaps = scale({
  fontSize: ['12px', '14px'],
  color: 'rgba(0,0,0,0.4)',
  textTransform: 'uppercase',
  letterSpacing: 0.5,
  fontWeight: 600,
  fontFamily: fonts.Sans,
  marginBottom: '5px',
});

export const title = scale({
  fontSize: ['25px', '34px', '34px', '37px'],
  lineHeight: ['30px', '45px', '45px', '50px'],
  fontFamily: fonts.Sans,
  marginBottom: '10px',
  color: 'var(--gray-1)',
});

export const header = scale({
  fontSize: ['23px', '30px', '30px', '35px'],
  lineHeight: ['30px', '35px', '35px', '40px'],
  fontFamily: fonts.Sans,
  marginBottom: '5px',
});

export const webHeader = scale({
  fontSize: ['30px', '30px', '30px', '35px'],
  lineHeight: ['35px', '35px', '35px', '40px'],
});

export const capsHeader = scale({
  fontSize: ['15px', '18px', '18px', '18px'],
  lineHeight: ['18px', '20px', '20px', '20px'],
  letterSpacing: ['0px', '0px'],
  fontWeight: [600, 700],
  fontFamily: fonts.Sans,
  marginBottom: '3px',
});

export const capsHeaderWithUnderline = scale({
  fontSize: ['14px', '15px'],
  lineHeight: ['20px', '25px'],
  fontWeight: 600,
  textTransform: 'uppercase',
  fontFamily: fonts.Sans,
  marginBottom: '20px',
  borderBottom: '2px solid var(--border-color)',
  paddingBottom: '5px',
  span: {
    paddingBottom: '9px',
  },
});

export const smallHeaderWithUnderline = scale({
  fontSize: ['18px', '20px', '20px', '20px'],
  lineHeight: ['22px', '25px', '25px', '25px'],
  fontFamily: fonts.Sans,
  fontWeight: 700,
  marginBottom: '10px',
  paddingBottom: '5px',
  borderBottom: '1px solid var(--border-color)',
});

export const mediumHeader = scale({
  fontSize: ['21px', '24px', '24px', '24px'],
  lineHeight: ['24px', '28px', '28px', '28px'],
  marginBottom: '5px',
  fontFamily: fonts.Sans,
  fontWeight: 600,
});

export const thinHeader = scale({
  fontSize: ['23px', '30px', '30px', '35px'],
  lineHeight: ['30px', '35px', '35px', '45px'],
  fontFamily: fonts.Sans,
  fontWeight: 600,
  marginBottom: '5px',
});

export const mediumThinHeader = scale({
  fontSize: ['24px', '28px'],
  lineHeight: ['30px', '34px'],
  fontFamily: fonts.Sans,
  fontWeight: 600,
});

export const subHeader = scale({
  fontSize: ['20px', '25px'],
  lineHeight: ['25px', '30px', '30px', '30px'],
  margin: '30px 0px 10px 0px',
  fontFamily: fonts.Sans,
});

export const smallHeader = scale({
  fontSize: ['18px', '20px', '20px', '20px'],
  lineHeight: ['22px', '25px', '25px', '25px'],
  marginBottom: '8px',
  fontFamily: fonts.Sans,
  fontWeight: 600,
});

export const thinSmallHeader = scale({
  fontSize: ['19px', '20px', '22px'],
  lineHeight: ['24px', '26px', '28px'],
  marginBottom: '8px',
  fontFamily: fonts.Sans,
  fontWeight: 600,
});

export const medium = scale({
  fontWeight: 500,
});

export const semibold = scale({
  fontWeight: 600,
});

export const uppercase = scale({
  textTransform: 'uppercase',
});

export const h3 = scale({
  fontFamily: fonts.Sans,
  fontWeight: 500,
  fontSize: ['26px', '28px'],
  lineHeight: ['30px', '33px'],
  color: 'var(--text-primary)',
  marginBottom: '10px',
});

export const h4 = scale({
  fontFamily: fonts.Sans,
  fontWeight: 400,
  fontSize: '21px',
  lineHeight: '25px',
  color: 'var(--primary-text)',
  marginBottom: '10px',
});

export const h5 = scale({
  fontSize: '14px',
  textTransform: 'uppercase',
  fontWeight: 500,
});

export const paragraph = scale({
  fontFamily: fonts.Sans,
  fontSize: ['16px', '18px'],
  lineHeight: ['24px', '30px'],
  color: 'var(--text-primary)',
  marginBottom: '10px',
  li: {
    marginBottom: '10px',
  },
});

// This differs from the style above
// in that there is no difference between
// the desktop and mobile font size.
// It's not great, but it was part of
// css in js.
export const simpleParagraph = {
  fontSize: '18px',
  lineHeight: '30px',
  color: 'var(--text-primary)',
  marginBottom: '10px',
};

export const smallParagraph = scale({
  fontFamily: fonts.Sans,
  opacity: 0.95,
  lineHeight: ['22px', '24px'],
  fontSize: ['15px', '16px'],
});

export const boldSup = scale({
  color: 'var(--wren-green)',
  fontWeight: '800',
  margin: 0,
});

export const footnote = scale({
  fontSize: ['13px', '15px'],
  lineHeight: '20px',
  color: 'var(--gray-3)',
  marginBottom: '10px',
});

export const caption = scale({
  fontSize: ['12px', '14px'],
  lineHeight: 1.3,
  opacity: 0.95,
  margin: '10px auto',
});

export const tinyText = scale({
  fontSize: ['11px', '12px'],
  lineHeight: '14px',
});

export const orange = scale({
  color: 'var(--wren-orange)',
});

export const green = scale({
  color: 'var(--success-green)',
});

export const light = scale({
  opacity: 0.7,
});

export const center = scale({
  textAlign: 'center',
});

export const align = (align) =>
  scale({
    align,
  });

export const alignRight = scale({
  textAlign: 'right',
});

export const alignLeft = scale({
  textAlign: 'left',
});

export const noLineHeight = scale({
  lineHeight: 0,
});

export const navLink = scale({
  marginLeft: '2px',
  marginRight: '2px',
  fontSize: '15px',
  padding: '4px 8px',
  borderRadius: '3px',
  fontWeight: 600,
});

export const textColor = (color) => ({
  color,
});

export const noStyleLink = {
  textDecoration: 'none',
  fontWeight: 400,
};

export const markdownStyle = {
  'p, li': {
    fontFamily: fonts.Serif,
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.4,
    fontStyle: 'normal',
    paddingRight: 16,
  },
  p: {
    paddingBottom: 10,
  },
  li: {
    marginBottom: 12,
  },
};

export const tinyUppercase = scale({
  fontSize: '11px',
  lineHeight: '15px',
  fontStretch: 'condensed',
  fontWeight: 600,
  textTransform: 'uppercase',
});

export const tiniestText = scale({
  fontSize: '9px',
  marginLeft: '2px',
  lineHeight: '15px',
  fontWeight: 700,
});

export const listStyle = scale({
  margin: 0,
  marginBottom: '15px',
  li: {
    marginBottom: '10px !important',
    fontSize: fontSizes.bodyLarge,
    lineHeight: '20pt !important',
    position: 'relative',
  },
  a: {
    color: 'var(--primary-text)',
    textDecoration: 'underline',
    ':hover': {
      color: 'black',
    },
  },
});

const allTextStyles = {
  tinyUppercase,
  tiniestText,
  textColor,
  textContainer,
  smallCaps,
  subHeader,
  footnote,
  smallHeader,
  title,
  webHeader,
  header,
  thinHeader,
  capsHeader,
  capsHeaderWithUnderline,
  h3,
  h4,
  h5,
  paragraph,
  smallParagraph,
  caption,
  light,
  orange,
  green,
  noLineHeight,
  center,
  boldSup,
  navLink,
  tinyText,
  alignRight,
  medium,
  semibold,
  uppercase,
  alignLeft,
  markdownStyle,
  noStyleLink,
  mediumThinHeader,
  align,
  listStyle,
  smallHeaderWithUnderline,
  mediumHeader,
  thinSmallHeader,
};

export default allTextStyles;
