/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ClearToast, SetToast, DismissToast } from '../actions/toaster';

import Toast from './UI/Toast';

const Toaster = (props) => {
  const { toast } = props;
  const { show, text, type, isOnRight } = toast;

  useEffect(() => {
    let timeout;
    if (show) {
      timeout = setTimeout(() => {
        props.DismissToast();
      }, 20000);
    }
    if (!show) clearTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [show]);

  return (
    <Toast
      toastIn={show}
      text={text}
      type={type}
      isOnRight={isOnRight}
      closeAction={() => {
        props.DismissToast();
      }}
      isDismissable
    />
  );
};

function mapStateToProps(state) {
  return {
    toast: state.toaster,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      SetToast,
      ClearToast,
      DismissToast,
    },
    dispatch
  );
}

Toaster.defaultProps = {
  toast: {
    text: 'I’m a toast',
    type: 'success',
    show: false,
    isOnRight: false,
  },
};

Toaster.propTypes = {
  DismissToast: PropTypes.func.isRequired,
  ClearToast: PropTypes.func.isRequired,
  toast: PropTypes.shape({
    text: PropTypes.string,
    type: PropTypes.string,
    show: PropTypes.bool,
    isOnRight: PropTypes.bool,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);
