/** @jsxImportSource @emotion/react */

import sortBy from 'lodash/sortBy';
import { ReactNode } from 'react';
import { CompiledPortfolio, SerializedUser, Subscription } from '../types';

import { UserStatus } from '../config/constants';

export function getLatestSubscription(user: SerializedUser) {
  const latestSubscription = sortBy(
    user.subscriptions,
    'createdAt'
  ).reverse()[0];

  return latestSubscription as Subscription | undefined;
}

export function getLatestLapsedSubscription(user: SerializedUser) {
  const latestLapsedSubscription = sortBy(user.subscriptions, 'createdAt')
    .reverse()
    .find((subscription) => subscription.status === 'lapsed');

  return latestLapsedSubscription;
}

export interface Plan {
  name: string;
  offsetPercentage: number;
  description: ReactNode;
  extendedDescription?: string;
  isPopular?: boolean;
  amountInUSDCents?: number;
  amountInUserCurrency?: number;
}

export function getLowerCostPlans(isHousehold: boolean): Plan[] {
  return [
    {
      name: 'Climate starter',
      offsetPercentage: 0.5,
      description: (
        <span>
          Offsets <b>50%</b> of your {isHousehold ? 'household’s' : ''}{' '}
          emissions.
        </span>
      ),
    },
    {
      name: 'Climate steward',
      offsetPercentage: 1,
      isPopular: true,
      description: (
        <span>
          Offsets <b>100%</b> of your {isHousehold ? 'household’s' : ''}{' '}
          emissions.
        </span>
      ),
    },
    {
      name: 'Climate hero',
      offsetPercentage: 2,
      description: (
        <span>
          Offsets <b>200%</b> of your {isHousehold ? 'household’s' : ''}{' '}
          emissions.
        </span>
      ),
    },
  ];
}

export function getDefaultPlans(
  portfolio: CompiledPortfolio,
  isHousehold: boolean
): Plan[] {
  return [
    {
      name: 'Climate neutral',
      offsetPercentage: 1,
      description: (
        <span>
          Offsets <b>100%</b> of your {isHousehold ? 'household’s' : ''}{' '}
          emissions.
        </span>
      ),
    },
    {
      name: 'Climate steward',
      offsetPercentage: 2,
      isPopular: true,
      description: (
        <span>
          Offsets <b>200%</b> of your {isHousehold ? 'household’s' : ''}{' '}
          emissions.
        </span>
      ),
    },
    {
      name: 'Climate hero',
      offsetPercentage: 3,
      description: (
        <span>
          Offsets <b>300%</b> of your {isHousehold ? 'household’s' : ''}{' '}
          emissions.
        </span>
      ),
    },
  ];
}

export function isDelinquent(userStatus: SerializedUser['status']) {
  switch (userStatus) {
    case UserStatus.LAPSED:
    case UserStatus.PAST_DUE:
      return true;
    default:
      return false;
  }
}

export function isDelinquentSubscriptionStatus(
  subscriptionStatus: Subscription['status']
) {
  switch (subscriptionStatus) {
    case 'past_due':
    case 'lapsed':
      return true;
    default:
      return false;
  }
}

export function isTerminal(status: Subscription['status']) {
  switch (status) {
    case 'cancelled':
    case 'lapsed':
      return true;
    case 'active':
    case 'past_due':
    case 'paused':
      return false;
    default:
      // Will not compile if someone adds a new status
      return ((unexpectedStatus: never) => {
        throw `unexpected subscription status: ${unexpectedStatus}`;
      })(status);
  }
}
