/** @jsxImportSource @emotion/react */

import { HTMLAttributes } from 'react';

export default function ArrowWiggleUpRight({
  fill = '#00B465',
  width = 28,
  ...restOfProps
}: {
  fill?: string;
  width?: number;
} & HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      fill={fill}
      viewBox="0 0 28 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...restOfProps}
    >
      <path
        clip-rule="evenodd"
        d="M18.66.98c0-.54.44-.98.98-.98h6.88c.54 0 .98.44.98.98v6.88a.98.98 0 1 1-1.96 0v-5.9h-5.9a.98.98 0 0 1-.98-.98Z"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M26.23 1.27c.38.38.38 1 0 1.39l-8.43 8.43a2.95 2.95 0 0 1-4.17 0l-3.11-3.11a.98.98 0 0 0-1.4 0l-7.44 7.45a.98.98 0 1 1-1.4-1.4L7.75 6.6a2.95 2.95 0 0 1 4.17 0l3.11 3.11a.98.98 0 0 0 1.39 0l8.43-8.43a.98.98 0 0 1 1.39 0Z"
        fill-rule="evenodd"
      />
    </svg>
  );
}
