import { Network } from '@wren/shared';
import { GetUserInfo } from './user';
import { SetToast, SetError } from './toaster';
import { Dispatch, Store } from '../store';
import { Stripe } from '../types';
import { Card, PaymentMethod, Source } from '@stripe/stripe-js';
import { convertCurrency, getCurrencyObjFromCode } from '../util/conversions';
import { MIN_SUBSCRIPTION_IN_USD_CENTS } from '../config/constants';

export function SetSubscription(subscription: Stripe.Subscription) {
  return {
    type: 'SET_SUBSCRIPTION',
    subscription,
  };
}

export function SetCardInfo(cardInfo: Source | PaymentMethod | Card) {
  return {
    type: 'SET_CARD_INFO',
    cardInfo,
  };
}

export function SetCharges(charges: Stripe.Charge[]) {
  return {
    type: 'SET_USER_CHARGES',
    charges,
  };
}

export function GetCardInfoFromPaymentMethods(
  defaultPaymentMethodId: string | null
) {
  return async (dispatch: Dispatch) => {
    if (!defaultPaymentMethodId) {
      return;
    }

    const [response, responseBody] = await Network.get<{
      paymentMethods: PaymentMethod[];
    }>('stripe/payment-methods');

    if (response.ok && responseBody) {
      const defaultPaymentMethod = responseBody.paymentMethods.find(
        (paymentMethod) => paymentMethod.id === defaultPaymentMethodId
      );

      const paymentMethod =
        defaultPaymentMethod || responseBody.paymentMethods[0];

      if (paymentMethod) {
        dispatch(SetCardInfo(paymentMethod));
      }
    }
  };
}

export function GetCardInfo() {
  return async function (dispatch: Dispatch, getState: Store['getState']) {
    if (getState().user.stripeCustomerId) {
      const [response, responseBody] = await Network.get<{
        customer: Stripe.Customer;
      }>('stripe/customer');

      if (!responseBody) {
        return;
      }

      const source = responseBody.customer.sources.data[0];

      if (
        !source ||
        responseBody.customer.invoice_settings.default_payment_method
      ) {
        await dispatch(
          GetCardInfoFromPaymentMethods(
            responseBody.customer.invoice_settings.default_payment_method
          )
        );
      } else if (response.ok) {
        await dispatch(SetCardInfo(source));
      }
    }
  };
}

export function GetStripeSubscription() {
  return async (dispatch: Dispatch, getState: Store['getState']) => {
    if (getState().user.stripeCustomerId) {
      const [response, responseBody] = await Network.get<{
        subscription: Stripe.Subscription;
      }>('stripe/subscription');
      if (response.ok && responseBody?.subscription) {
        const { subscription } = responseBody;
        if (subscription) {
          dispatch(SetSubscription(subscription));
        }
      }
    }
  };
}

export function GetUserCharges() {
  return async function (dispatch: Dispatch, getState: Store['getState']) {
    if (getState().user.stripeCustomerId) {
      const [response, responseBody] = await Network.get<{
        charges: Stripe.Charge[];
      }>('stripe/charges');
      if (response.ok && responseBody) {
        dispatch(SetCharges(responseBody.charges));
      }
    }
  };
}

export function PauseSubscription(reason: string) {
  return async function (dispatch: Dispatch) {
    const [response] = await Network.post('subscriptions/pause', {
      reason,
    });
    if (response.ok) {
      dispatch(GetUserInfo());
      dispatch(
        SetToast({
          text: 'We’ve paused your subscription. Hope to see you back soon!',
          type: 'success',
          isOnRight: true,
        })
      );
    } else {
      dispatch(
        SetError(
          'We ran into a problem. Please contact us to pause your subscription.'
        )
      );
    }
  };
}

export function ResumeSubscription() {
  return async function (dispatch: Dispatch) {
    const [response] = await Network.post(`subscriptions/resume`);
    if (response.ok) {
      dispatch(GetUserInfo());
      dispatch(
        SetToast({
          text: "We successfully resumed your subscription. We're excited to have you back!",
          type: 'success',
          isOnRight: true,
        })
      );
    } else {
      dispatch(SetError('Something went wrong. Please contact us for help!'));
    }
  };
}

export function UpdateSubscription({ totalAmount }: { totalAmount: number }) {
  return async (dispatch: Dispatch, getState: Store['getState']) => {
    const currency = getState().user.currency ?? 'USD';
    const minInUserCurrencyLowestDenomination = Math.ceil(
      convertCurrency(MIN_SUBSCRIPTION_IN_USD_CENTS / 100, 'USD', currency) *
        getCurrencyObjFromCode(currency).smallestDenominationDivider
    );
    if (totalAmount < minInUserCurrencyLowestDenomination) {
      dispatch(
        SetError(
          `The minimum subscription amount is ${
            MIN_SUBSCRIPTION_IN_USD_CENTS / 100
          } ${currency}.`
        )
      );
      return;
    }
    const [response, responseBody] = await Network.post<{
      error?: string;
    }>('subscriptions/update', { totalAmount });
    if (response.ok) {
      await Promise.all([
        dispatch(GetUserInfo()),
        dispatch(GetStripeSubscription()),
      ]);
    } else {
      dispatch(SetError(responseBody?.error));
    }
  };
}

export function ClearSubscription() {
  return {
    type: 'CLEAR_SUBSCRIPTION',
  };
}
