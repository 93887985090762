/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { scale } from '../../util/scale';
import { isValidEmail } from '../../lib/validation';
import TextInput from './TextInput';
import Button from '../buttons/Button';
import InputError from './InputError';

const flexedEmailInput = scale({
  width: ['100%', 'auto'],
  display: ['block', 'flex'],
  margin: '16px auto 12px',
  alignItems: 'stretch',
  button: { width: ['100%', 'auto'], fontSize: 16 },
});

const textInput = scale({
  marginBottom: [10, 0],
  marginRight: [0, 10],
  width: '100%',
  input: {
    boxSizing: 'border-box',
    padding: '12px 10px',
    height: 'auto',
    lineHeight: '16px',
    fontSize: '16px',
  },
});

export default function EmailsInput(props) {
  const [emails, setEmails] = useState('');
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSendInvites(emails) {
    if (loading || emails.length === 0) {
      return;
    }

    setEmailError('');
    setLoading(true);

    const arrayOfEmails = emails.split(',').map((email) => email.trim());
    const invalidEmails = arrayOfEmails.filter((email) => !isValidEmail(email));

    if (invalidEmails.length > 0) {
      setEmailError(`${invalidEmails[0]} is invalid`);
      setLoading(false);
      return;
    }

    await props.onSend(arrayOfEmails);

    setLoading(false);
    setSuccess(true);
    setEmails('');
  }

  function handleInputChange(emails) {
    setSuccess(false);
    setEmails(emails);
  }

  return (
    <div className={props.className}>
      <form
        css={flexedEmailInput}
        onSubmit={(event) => {
          event.preventDefault();
          handleSendInvites(emails);
        }}
      >
        <TextInput
          errorBorder={emailError}
          name="referral-email-invites"
          placeholder="Enter emails, separated by commas"
          value={emails}
          handleInputChange={handleInputChange}
          css={textInput}
        />
        <Button
          submit
          isDisabled={!emails}
          type={emails.length === 0 ? 'actuallyGray' : 'green'}
          text={success ? 'Invites sent' : props.ctaText}
          size="large"
          css={{ whiteSpace: 'nowrap' }}
          loading={loading}
        />
      </form>
      <InputError error={emailError} />
    </div>
  );
}

EmailsInput.defaultProps = {
  ctaText: 'Invite',
};
