import { css, keyframes } from '@emotion/react';
import { scale } from '../util/scale';
import { fonts } from './theme';

export const clickable = scale({
  cursor: 'pointer',
});

export const fullWidth = scale({
  width: '100%',
});

export const marginRight = scale({
  marginRight: '10px',
});

export const marginRightScale = (margin: string | number) =>
  scale({
    marginRight: margin || '10px',
  });

export const noMargin = scale({
  margin: 0,
});

export const marginAuto = scale({
  margin: 'auto',
});

export const marginAutoOnWide = scale({
  margin: [0, 0, 0, 'auto'],
});

export const fitHeight = scale({
  height: 'fit-content',
  MozAppearanceMinHeight: 'fit-content',
  WebkitAppearanceMinHeight: 'fit-content',
});

export const fitWidth = scale({
  width: 'fit-content',
  MozAppearanceMinWidth: 'fit-content',
  WebkitAppearanceMinWidth: 'fit-content',
});

export const minWidth = (min: string | number, width: string | number) =>
  scale({
    minWidth: min, //px
    width, //px
  });

export const minHeight = (min: string | number, height: string | number) =>
  scale({
    minHeight: min, //px
    height, //px
  });

export const marginTop = (num: string | number) =>
  scale({
    marginTop: num || '10px',
  });

export const marginLeft = (num: string | number) =>
  scale({
    marginLeft: num || '10px',
  });

export const marginBottom = (num: string | number) =>
  scale({
    marginBottom: num || '10px',
  });

export const maxWidth = (max: string | number) =>
  scale({
    maxWidth: max, //px
  });

export const hideOnMobile = css({
  '@media(max-width: 430px)': {
    display: 'none',
  },
});

export const showOnMobile = css({
  '@media(min-width: 431px)': {
    display: 'none',
  },
});

export const noStyle = scale({
  backgroundColor: 'transparent',
  width: '100%',
  cursor: 'pointer',
  border: 'none',
  padding: 0,
});

export const noStyleButton = scale({
  backgroundColor: 'transparent',
  cursor: 'pointer',
  border: 'none',
  width: 'fit-content',
  fontFamily: fonts.Sans,
  transition: 'background-color 200ms ease-in-out',
  borderRadius: '3px',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
  '&:focus': {
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
});

export const hover = scale({
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
  '&:focus': {
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
});

export const opacity = (opacity: string | number) => ({
  opacity,
});

export const clickableX = scale({
  color: 'var(--wren-orange)',
  cursor: 'pointer',
  fontSize: '24px',
  fontWeight: 'bold',
  userSelect: 'none',
});

export const verticalBar = scale({
  position: 'relative',
  width: '3px',
  backgroundColor: 'rgba(0,0,0,0.08)',
  minHeight: '80px',
});

export const relative = scale({
  position: 'relative',
});

export const absolute = scale({
  position: 'absolute',
});

export const navLinkStyle = {
  fontFamily: fonts.Sans,
  textDecoration: 'none !important',
  fontWeight: '600 !important',
  outline: 'none',
  transition: '0.3s',
  fontSize: '16px',
  padding: '5px 7px',
  borderRadius: '3px',
  position: 'relative',
  cursor: 'pointer',
  color: 'var(--gray-6) !important',
  '&:hover, :focus, :active': {
    color: 'inherit',
    backgroundColor: 'rgb(228, 228, 228)',
  },
};

export const zIndex = (zIndex: string | number) =>
  scale({
    zIndex,
  });

export const padding = (padding: string | number) =>
  scale({
    padding,
  });

const fadeInKeyframes = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const fadeIn = (durationSecondsOrString: string | number = '150ms') => {
  const duration =
    typeof durationSecondsOrString === 'number'
      ? `${durationSecondsOrString.toString()}s`
      : durationSecondsOrString;
  return css({
    animationDuration: duration,
    animationName: fadeInKeyframes,
  });
};

export const iconDropShadow = css({
  filter: 'drop-shadow(0px 4px 4px #00000080)',
});

export const buttonCssReset = css({
  backgroundColor: 'transparent',
  border: 'none',
  boxSizing: 'initial',
  color: 'inherit',
  cursor: 'pointer',
  display: 'block',
  fontFamily: 'inherit',
  fontSize: '100%',
  margin: 0,
  overflow: 'visible',
  padding: 0,
  textAlign: 'start',
});
