/** @jsxImportSource @emotion/react */

import { scale } from '../util/scale';
import { header, paragraph } from '../style/text';
import { maxWidth, marginAuto } from '../style/misc';
import { Button } from './buttons/ButtonNext';
import TistGrove from './svgs/TistGrove';
import { useEffect, useState } from 'react';
import { colors } from '../style/theme';

const centerBoxStyle = scale({
  maxWidth: '600px',
  margin: 'auto',
  textAlign: 'center',
  padding: '50px',
  marginTop: '50px',
});

export default function ErrorMessage({ error, resetError }) {
  const [text, setText] = useState('Loading...');

  useEffect(() => {
    setTimeout(() => {
      setText('Nearly there...');
    }, 3000);

    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    }
  }, [error]);

  if (error.name === 'ChunkLoadError') {
    window.analytics.track('Client: User experienced ChunkLoadError');
    return (
      <div css={centerBoxStyle}>
        <TistGrove width={80} />
        <h1 css={header}>{text}</h1>
      </div>
    );
  }

  return (
    <div css={centerBoxStyle}>
      <h1 css={header}>Something’s gone wrong!</h1>
      {(process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_BUILT_WITH_CI) && (
        <p css={paragraph}>{error.toString()}</p>
      )}
      <p css={paragraph}>
        We’ve logged the issue and are working on fixing it now. Please reload
        the page, and don’t hesitate to email us at support@wren.co if you need
        further assistance.
      </p>
      <br />
      <div css={[maxWidth(200), marginAuto]}>
        <Button
          buttonType="filled"
          color={colors.wrenOrange}
          css={{ width: '100%' }}
          onClick={() => {
            resetError();
          }}
          shape="rounded rectangle"
          size="medium"
        >
          Reload
        </Button>
      </div>
    </div>
  );
}
