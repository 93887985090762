/** @jsxImportSource @emotion/react */
import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NAV_HEIGHT } from '../../config/constants';
import { CSSObject } from '@emotion/react';
import {
  DropdownLink as DropdownLinkType,
  DropdownSection as DropdownSectionType,
} from '../../config/navDropdowns';
import zIndex from '../../lib/zIndex';
import { inlineBlock } from '../../style/layout';
import * as misc from '../../style/misc';
import { noStyleLink } from '../../style/text';
import { colors, fontSizes, spacing } from '../../style/theme';
import { scale } from '../../util/scale';
import Fade from '../animation/Fade';
import { Button, getFontSizeForButtonSize } from '../buttons/ButtonNext';
import ArrowDown from '../svgs/ArrowDown';
import Flex from '../UI/Flex';
import NewTag from '../UI/NewTag';
import { Headings } from '../UI/TextStyles';
import DropdownLink from './DropdownLink';

const container = scale({
  position: 'absolute',
  outline: 'none',
  zIndex: zIndex('navbar'),
  transition: '200ms ease-in',
});

const innerContainer = scale({
  backgroundColor: 'white',
  boxShadow: '0px 100% 25px var(--box-shadow-color)',
  cursor: 'auto',
  width: '100%',
  height: '100%',
  borderTop: '1px solid rgba(0, 0, 0, 0.15)',
  boxSizing: 'border-box',
  padding: spacing.xSmall,
});

const sectionStyle = scale({
  padding: '12px 0px',
  fontSize: '12px !important',
  margin: `0 calc((100vw - min(98%, 1440px))/2 - 12px)`,
  paddingBottom: spacing.medium,
  boxSizing: 'border-box',
});

const customizedStyle = (isActive?: boolean) =>
  ({
    position: 'relative',
    ...(isActive && {
      '&::before': {
        content: '""',
        height: NAV_HEIGHT,
        width: '120%',
        top: '50%',
        transform: 'translate(-10%, -50%)',
        position: 'absolute',
      },
    }),
  } as CSSObject);

const positionStyle = scale({
  position: 'absolute',
  left: 0,
  right: 0,
  top: '100%',
});

function DropdownSection({
  links,
  onShowDropdown,
  title,
}: {
  links: DropdownLinkType[];
  onShowDropdown?: (show: boolean) => void;
  title?: string;
}) {
  return (
    <div css={sectionStyle}>
      {title && (
        <Headings.Small
          css={{
            color: colors.textPrimary,
            padding: `${spacing.small}px 0 ${spacing.small}px 12px`,
            fontWeight: 600,
          }}
        >
          {title}
        </Headings.Small>
      )}
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gridGap: spacing.medium,
          // 300px is the max width of a column
          maxWidth: 300 * 4 + spacing.medium * 3,
        }}
      >
        {links.map((link, idx) => {
          if (!link) return;
          const href = link.to + (link.hash ? `#${link.hash}` : '');
          const externalLinkAttributes = link.to.includes('http') // This is a bit hacky
            ? {
                target: '_blank',
                rel: 'noopener noreferrer',
              }
            : {};
          return (
            <DropdownLink
              key={idx}
              to={href}
              onClick={() => onShowDropdown?.(false)}
              {...externalLinkAttributes}
            >
              <Flex direction="row" gap={spacing.small} align="center">
                {link.icon && (
                  <Flex
                    align="center"
                    justify="center"
                    css={{
                      width: 48,
                      height: 48,
                      backgroundColor: link.icon.backgroundColor,
                      borderRadius: 4,
                    }}
                  >
                    {link.icon.component}
                  </Flex>
                )}
                <Flex direction="column" gap={spacing.tiny}>
                  <div
                    css={{
                      display: 'inline-block',
                      fontSize: fontSizes.paragraph,
                      flex: '1',
                    }}
                  >
                    {link.name}
                    {link.isNew && (
                      <NewTag
                        css={{
                          position: 'relative',
                          top: -8,
                          marginLeft: 4,
                          backgroundColor: colors.wrenOrange,
                          textTransform: 'none',
                        }}
                      />
                    )}
                  </div>
                  <p
                    css={{
                      fontSize: fontSizes.citation,
                      color: colors.textSecondary,
                      textTransform: 'none',
                    }}
                  >
                    {link.description}
                  </p>
                </Flex>
              </Flex>
            </DropdownLink>
          );
        })}
      </div>
    </div>
  );
}

const FullWidthDropdown = ({
  dropdownContent,
  renderDropdownContent,
  onDropdownEnter,
  onDropdownLeave,
  customNavItem,
  name,
  ...restOfProps
}: {
  dropdownContent: DropdownSectionType[];
  renderDropdownContent?: (
    dropdownContent: DropdownSectionType[]
  ) => JSX.Element;
  onDropdownEnter?: () => void;
  onDropdownLeave?: () => void;
  customNavItem?: React.ReactNode;
  name: string;
} & HTMLAttributes<HTMLButtonElement>) => {
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (showDropdown) {
      onDropdownEnter?.();
    } else {
      onDropdownLeave?.();
    }
  }, [showDropdown]);

  const enterTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const leaveTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      // Clean up timers when the component unmounts
      if (enterTimer.current) {
        clearTimeout(enterTimer.current);
      }
      if (leaveTimer.current) {
        clearTimeout(leaveTimer.current);
      }
    };
  }, []);

  const handleMouseOver = useCallback(() => {
    if (enterTimer.current) {
      clearTimeout(enterTimer.current);
    }

    if (leaveTimer.current) {
      clearTimeout(leaveTimer.current);
    }

    enterTimer.current = setTimeout(() => {
      setShowDropdown(true);
    }, 300);
  }, [enterTimer, leaveTimer, setShowDropdown]);

  const handleMouseLeave = useCallback(() => {
    if (enterTimer.current) {
      clearTimeout(enterTimer.current);
    }

    if (leaveTimer.current) {
      clearTimeout(leaveTimer.current);
    }

    leaveTimer.current = setTimeout(() => {
      setShowDropdown(false);
    }, 200);
  }, [enterTimer, leaveTimer, setShowDropdown]);
  return (
    <div
      css={noStyleLink}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div css={customizedStyle(showDropdown)}>
        <Button
          css={misc.noStyleButton}
          aria-expanded={showDropdown}
          {...restOfProps}
        >
          {customNavItem || (
            <>
              <span css={inlineBlock}>{name}</span>
              <span css={misc.marginLeft(5)}>
                <ArrowDown
                  color="currentColor"
                  size={getFontSizeForButtonSize('small')}
                />
              </span>
            </>
          )}
        </Button>
      </div>
      <div css={[container, positionStyle]}>
        {showDropdown && (
          <Fade>
            <div css={innerContainer}>
              {renderDropdownContent ? (
                renderDropdownContent(dropdownContent)
              ) : (
                <>
                  {dropdownContent.map((section, idx) => (
                    <DropdownSection
                      onShowDropdown={setShowDropdown}
                      links={section.links}
                      title={section.title}
                      key={idx}
                    />
                  ))}
                </>
              )}
            </div>
          </Fade>
        )}
      </div>
    </div>
  );
};

export default FullWidthDropdown;
