/** @jsxImportSource @emotion/react */

import { Link } from 'react-router-dom';
import Button from '../components/buttons/Button';
import { staticImageUrl } from '../util/images';
import { scale } from '../util/scale';
import * as misc from '../style/misc';
import text from '../style/text';

const lock = staticImageUrl('lock.svg');

const centerBoxStyle = scale({
  minWidth: '400px',
  margin: 'auto',
  textAlign: 'center',
  padding: '50px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const Private = () => (
  <div css={centerBoxStyle}>
    <img src={lock} alt="a plant" css={misc.maxWidth(80)} />
    <br />
    <br />
    <h1 css={text.header}>This page is private.</h1>
    <div className="smallBr" />
    <p css={text.paragraph}>
      This page is private — only the person who created it can access it. If
      you think this is an error, please{' '}
      <a href="mailto:support@wren.co?subject=I found a bug on Wren!">
        reach out.
      </a>
    </p>
    <br />
    <div css={[misc.maxWidth(200), misc.marginAuto]}>
      <Link to="/">
        <Button text="← Go back home" size="large" />
      </Link>
    </div>
  </div>
);

export default Private;
