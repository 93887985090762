/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { Login } from '../../actions/sessions';
import { SetError } from '../../actions/toaster';

import { Network } from '@wren/shared';
import { centerForm } from '../../style/layout';
import { colors, oldFontSizes, spacing } from '../../style/theme';
import { userDefaultProp, userPropType } from '../../util/propTypes';
import { scale } from '../../util/scale';

import { getLoginValidationError } from '../../lib/validation';
import { ArrowNavButton } from '../buttons/ArrowNavButton';
import Button from '../buttons/Button';
import TextInput from '../inputs/TextInput';
import CardContainer from '../UI/CardContainer';
import CardContent from '../UI/CardContent';
import { Link } from '../UI/Link';
import MessageCard from '../UI/MessageCard';

const userInfoForm = scale({
  maxWidth: ['90%', '90%', '400px'],
});

const formContainer = scale({
  width: '100%',
  margin: 'auto',
});

const errorStyle = css`
  color: var(--error-red);
  padding: 5px;
  font-size: ${oldFontSizes.caption};
  border-radius: 5px;
  border: 1px solid var(--error-red);
  max-width: 270px;
  margin: 10px auto;
`;

function ConfirmEmail(props) {
  const { location } = props;

  let parsed;
  if (location.search) {
    parsed = queryString.parse(location.search);
  }

  const [email, setEmail] = useState(parsed.email);
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const clearErrors = () => {
    setLoading(false);
    setError('');
  };

  const submit = async (customerId, email, password) => {
    // this is a super weird endpoint by the way. we get the user
    // by the customer id and update that user's email.
    const [response] = await Network.post('account/update-email', {
      customerId,
      email,
      password,
    });

    let error;

    if (response.ok) {
      error = await props.Login(email, password).error;
      if (!error) {
        return null;
      }
    }

    // i think these are incorrect status codes
    if (response.status === 409) {
      error = 'Email is already in use. Please contact support for help.';
    }
    if (response.status === 422) {
      error = 'Invalid credentials. Please try again.';
    }

    return (
      error ||
      'Something went wrong with updating your email. Please contact us for help.'
    );
  };

  const checkForm = async (event) => {
    event.preventDefault();
    const error = getLoginValidationError({ email, password });
    if (error) {
      setError(error);
    }
    if (!error && !loading) {
      setLoading(true);
      const error = await submit(parsed.id, email, password);
      if (error) {
        props.SetError(error);
      } else {
        setSuccess(true);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Wren | Update email </title>
      </Helmet>

      {!success ? (
        <div css={[centerForm, userInfoForm]}>
          <CardContainer>
            <CardContent>
              <h3 css={{ marginBottom: spacing.medium }}>
                Log in with your new email to confirm
              </h3>
              <form
                autoComplete="on"
                noValidate
                onSubmit={(event) => checkForm(event)}
              >
                <div css={formContainer}>
                  <TextInput
                    type="email"
                    handleInputChange={(answer) => {
                      setEmail(answer);
                      clearErrors();
                    }}
                    name="email"
                    placeholder="jane@mail.com"
                    value={email}
                    errorBorder={error.includes('Email')}
                    autoComplete="email"
                    label="Email"
                  />
                  <TextInput
                    handleInputChange={(answer) => {
                      setPassword(answer);
                      clearErrors();
                    }}
                    name="pw"
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    value={password}
                    errorBorder={error.includes('Password')}
                    label="Password"
                  />
                  <div css={{ marginBottom: spacing.small }} />
                  {error && <div css={errorStyle}>{error}</div>}
                  <p css={{ textAlign: 'right', marginBottom: spacing.medium }}>
                    <Link
                      to="/reset"
                      css={{
                        textDecoration: 'none',
                        color: colors.linkBlue,
                        fontSize: 15,
                      }}
                    >
                      Forgot password?
                    </Link>
                  </p>

                  <Button
                    text="Confirm email"
                    size="medium"
                    loading={loading}
                  />
                </div>
              </form>
            </CardContent>
          </CardContainer>
        </div>
      ) : (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <MessageCard
            header="We've updated your email"
            description={`All emails will now go to ${email}.`}
            noShare
          />

          <ArrowNavButton
            to="/dashboard"
            shape="rounded rectangle"
            buttonType="filled"
            color={colors.wrenOrange}
          >
            Go to dashboard
          </ArrowNavButton>
        </div>
      )}
      <br />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      Login,
      SetError,
    },
    dispatch
  );
}

ConfirmEmail.defaultProps = {
  user: userDefaultProp,
};

ConfirmEmail.propTypes = {
  SetUserInfo: PropTypes.func.isRequired,
  user: userPropType,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail)
);
