/** @jsxImportSource @emotion/react */

import Supporters from './Supporters';

export function FounderFaces({
  className,
  size,
}: {
  className?: string;
  size?: number;
}) {
  return (
    // @ts-expect-error: translate to ts
    <Supporters
      size={size}
      className={className}
      supporters={{
        latestFourSupporters: [
          {
            firstName: 'Landon',
            profileImageUrl:
              'https://projectwren.imgix.net/team/wren-landon-brand.jpg?auto=format%2Ccompress&q=35&w=100',
            country: 'United States',
          },
          {
            firstName: 'Mimi',
            profileImageUrl:
              'https://projectwren.imgix.net/team/wren-mimi-tran-zambetti.jpg?auto=format%2Ccompress&q=35&w=100',
            country: 'United States',
          },
          {
            firstName: 'Ben',
            profileImageUrl:
              'https://projectwren.imgix.net/team/wren-ben-stanfield.jpg?auto=format%2Ccompress&q=35&w=100',
            country: 'United States',
          },
        ],
      }}
    />
  );
}
