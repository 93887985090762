/** @jsxImportSource @emotion/react */
import { useSelector } from 'react-redux';

import { scale } from '../../util/scale';
import Flex from '../../components/UI/Flex';
import { Paragraph, Citation } from '../../components/UI/TextStyles';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../../lib/platform';

const container = scale({
  backgroundColor: 'var(--link-blue)',
  width: '100%',
  color: 'white',
  borderRadius: 16,
  boxSizing: 'border-box',
  padding: [8, 8, 16, 16],
  marginBottom: 16,
  boxShadow:
    'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  border: '6px solid var(--background-light-blue)',
});

const slashStyle = scale({ margin: ['auto 6px', 'auto 8px'], opacity: 0.6 });

export function CheckoutOrb({ group, float, bottom = 20, className }) {
  const user = useSelector((state) => state.user);
  const groupToUse = group || user.groups[0];

  return (
    <Flex
      align="center"
      css={[
        container,
        float &&
          scale({
            position: 'fixed',
            bottom,
            left: ['50%', 20],
            transform: ['translate(-50%, 0)', 'none'],
          }),
      ]}
      className={className}
    >
      <img
        src={groupToUse.profileImageUrl}
        css={{
          width: 36,
          height: 36,
          borderRadius: 36,
          objectFit: 'cover',
          marginRight: 16,
        }}
      />
      <div>
        <Paragraph>
          Joining <b>{groupToUse.name}</b>
        </Paragraph>
      </div>
    </Flex>
  );
}

export function Banner({ group }) {
  const user = useSelector((state) => state.user);
  const groupToUse = group || user.groups[0];

  const { pathname } = useLocation();
  const TextStyle = isMobile() ? Citation : Paragraph;

  function Step({ children, isSelected }) {
    return (
      <TextStyle
        css={{
          fontWeight: isSelected && '700 !important',
          opacity: isSelected ? 1 : 0.8,
        }}
      >
        {children}
      </TextStyle>
    );
  }

  return (
    <Flex
      align="center"
      css={scale({
        position: 'relative',
        top: 0,
        backgroundColor: 'var(--link-blue)',
        width: '100%',
        color: 'white',
        padding: [12, 12],
        boxSizing: 'border-box',
        textAlign: ['left', 'center'],
        justifyContent: ['flex-start', 'center'],
        zIndex: 999, // appear over offset my emissions button on /choose-plan page
      })}
    >
      <Step isSelected={pathname === '/calculator'}>1. Calculate</Step>
      <TextStyle css={slashStyle}>/</TextStyle>
      <Step
        isSelected={
          pathname === '/choose-plan' || pathname.includes('checkout')
        }
      >
        2. Subscribe
      </Step>
      <TextStyle css={slashStyle}>/</TextStyle>
      <Step>
        3. Join{' '}
        {isMobile() && groupToUse.name.length > 12 ? 'group' : groupToUse.name}
      </Step>
    </Flex>
  );
}
