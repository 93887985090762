/** @jsxImportSource @emotion/react */

import AnimateEnterAndExit, {
  AnimateEnterAndExitProps,
} from './AnimateEnterAndExit';

export default function Fade({
  durationMs: durationMs = 150,
  ...restOfProps
}: { durationMs?: number } & AnimateEnterAndExitProps) {
  return (
    <AnimateEnterAndExit
      animate={{
        keyframes: [{ opacity: 0 }, { opacity: 1 }],
        options: { duration: durationMs },
      }}
      {...restOfProps}
    />
  );
}
