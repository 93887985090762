/** @jsxImportSource @emotion/react */
import React from 'react';

export const GoogleMaps = ({ lat, long }) => {
  return (
    <div id="map" css={{ width: '100%' }}>
      <iframe
        css={{ width: '100%', height: 400, border: 'none', borderRadius: 8 }}
        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${lat},${long}&zoom=5&maptype=satellite`}
      ></iframe>
    </div>
  );
};
