/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import TwitterShare from './TwitterShare';
import FacebookShare from './FacebookShare';
import FBMessengerShare from './FBMessengerShare';
import WhatsAppShare from './WhatsAppShare';
import LinkedInShare from './LinkedInShare';
import SMSShare from './SMSShare';
import EmailShare from './EmailShare';
import CopyLink from './CopyLink';

import TwitterLogo from '../svgs/TwitterLogo';
import WhatsAppLogo from '../svgs/WhatsAppLogo';
import MessengerLogo from '../svgs/MessengerLogo';
import FacebookLogo from '../svgs/FacebookLogo';
import LinkedinLogo from '../svgs/LinkedinLogo';
import EmailIcon from '../svgs/Envelope';
import SMSIcon from '../svgs/PaperPlane';

import { scale } from '../../util/scale';

import { shareButtonsContainer, icon } from '../../style/shareButtonStyles';

const iconsContainer = scale({
  display: 'flex',
  justifyContent: 'space-between',
});

function removeUtms(url) {
  const [urlBeforeQuery, query] = url.split('?');

  if (!query) {
    return url;
  }

  const searchParams = new URLSearchParams(query);
  const newSearchParams = new URLSearchParams();

  for (const key of searchParams.keys()) {
    if (!key.startsWith('utm_')) {
      newSearchParams.set(key, searchParams.get(key));
    }
  }

  if (!newSearchParams.toString()) {
    return urlBeforeQuery;
  }

  return `${urlBeforeQuery}?${newSearchParams.toString()}`;
}

const ShareButtons = ({
  subject,
  text,
  sms,
  twitter,
  email,
  whatsapp,
  linkedin,
  url,
  hashtags,
  name,
  size,
  socialsArr,
  iconStyle,
  className,
  campaignName,
  utmMedium,
}) => {
  const iconContainer = iconStyle && {
    width: 'fit-content !important',
    margin: 'auto',
  };

  // Remove existing UTMs on url - share buttons will reconstruct with appropriate UTMs
  const urlWithoutUtms = removeUtms(url);

  return (
    <div
      css={iconStyle ? iconsContainer : shareButtonsContainer}
      className={className}
    >
      {(!socialsArr || socialsArr.includes('twitter')) && (
        <TwitterShare
          text={twitter || text}
          options={{
            via: 'project_wren',
            hashtags,
            url: urlWithoutUtms,
          }}
          campaignName={campaignName}
          utmMedium={utmMedium}
          name={name}
          size={size}
          css={iconContainer}
          button={
            iconStyle && (
              <div css={icon}>
                <TwitterLogo size={16} color="black" />
              </div>
            )
          }
        />
      )}
      {(!socialsArr || socialsArr.includes('whats app')) && (
        <WhatsAppShare
          text={whatsapp || text}
          url={urlWithoutUtms}
          campaignName={campaignName}
          utmMedium={utmMedium}
          name={name}
          size={size}
          css={iconContainer}
          button={
            iconStyle && (
              <div css={icon}>
                <WhatsAppLogo size={16} color="black" />
              </div>
            )
          }
        />
      )}
      {(!socialsArr || socialsArr.includes('linkedin')) && (
        <LinkedInShare
          text={linkedin || text}
          url={urlWithoutUtms}
          campaignName={campaignName}
          utmMedium={utmMedium}
          name={name}
          size={size}
          css={iconContainer}
          button={
            iconStyle && (
              <div css={icon}>
                <LinkedinLogo size={18} color="black" />
              </div>
            )
          }
        />
      )}
      {(!socialsArr || socialsArr.includes('fb')) && (
        <FacebookShare
          url={urlWithoutUtms}
          campaignName={campaignName}
          utmMedium={utmMedium}
          name={name}
          size={size}
          css={iconContainer}
          button={
            iconStyle && (
              <div css={icon}>
                <FacebookLogo size={18} color="black" />
              </div>
            )
          }
        />
      )}
      {(!socialsArr || socialsArr.includes('messenger')) && (
        <FBMessengerShare
          url={urlWithoutUtms}
          campaignName={campaignName}
          utmMedium={utmMedium}
          name={name}
          size={size}
          css={iconContainer}
          button={
            iconStyle && (
              <div css={icon}>
                <MessengerLogo size={16} color="black" />
              </div>
            )
          }
        />
      )}
      {(!socialsArr || socialsArr.includes('email')) && (
        <EmailShare
          subject={subject}
          text={email || text}
          url={urlWithoutUtms}
          campaignName={campaignName}
          utmMedium={utmMedium}
          name={name}
          size={size}
          css={iconContainer}
          button={
            iconStyle && (
              <div css={icon}>
                <EmailIcon size={16} color="black" />
              </div>
            )
          }
        />
      )}
      {(!socialsArr || socialsArr.includes('sms')) && (
        <SMSShare
          text={sms || text}
          url={urlWithoutUtms}
          campaignName={campaignName}
          utmMedium={utmMedium}
          name={name}
          size={size}
          css={iconContainer}
          button={
            iconStyle && (
              <div css={icon}>
                <SMSIcon size={16} color="black" />
              </div>
            )
          }
        />
      )}
      {(!socialsArr || socialsArr.includes('copy')) && (
        <CopyLink
          url={urlWithoutUtms}
          campaignName={campaignName}
          utmMedium={utmMedium}
          name={name}
          size={size}
          css={iconContainer}
          iconStyle={iconStyle}
        />
      )}
      {/* Might be better for each share link to take iconStyle as a prop */}
      {/* and manage styling internally.... */}
    </div>
  );
};

ShareButtons.defaultProps = {
  url: 'https://www.wren.co',
  text: 'Check out projectwren.com',
  subject: 'Join me in reversing climate change',
  hashtags: '',
  sms: undefined,
  twitter: undefined,
  email: undefined,
  whatsapp: undefined,
  linkedin: undefined,
  name: '',
};

ShareButtons.propTypes = {
  text: PropTypes.string,
  subject: PropTypes.string,
  hashtags: PropTypes.string,
  url: PropTypes.string,
  sms: PropTypes.string,
  twitter: PropTypes.string,
  email: PropTypes.string,
  whatsapp: PropTypes.string,
  linkedin: PropTypes.string,
  name: PropTypes.string,
};

export default ShareButtons;
