/** @jsxImportSource @emotion/react */

import { Elements } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeElementsOptionsClientSecret,
} from '@stripe/stripe-js';
import { colors, fonts } from '../../style/theme';

let stripePromise: Promise<Stripe | null>;

const elementOptions: StripeElementsOptionsClientSecret = {
  appearance: {
    theme: 'stripe',
    variables: {
      fontFamily: fonts.Sans,
      colorText: colors.textPrimary,
    },
  },
};

export function getStripe() {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);
  }

  return stripePromise;
}

export default function StripeElementsWrapper({
  children,
  options,
}: {
  children: React.ReactNode;
  options?: StripeElementsOptionsClientSecret;
}) {
  return (
    <Elements stripe={getStripe()} options={{ ...elementOptions, ...options }}>
      {children}
    </Elements>
  );
}

export function withStripeElementsWrapper(
  WrappedComponent: React.ComponentType<any>
) {
  return function Wrapper(props: any) {
    return (
      <StripeElementsWrapper>
        <WrappedComponent {...props} />
      </StripeElementsWrapper>
    );
  };
}
