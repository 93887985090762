export const initialState = {
  USD: undefined,
  AUD: undefined,
  CAD: undefined,
  PLN: undefined,
  MXN: undefined,
  GBP: undefined,
  CHF: undefined,
  JPY: undefined,
  NZD: undefined,
  CNY: undefined,
  SEK: undefined,
  base: undefined,
  timestamp: undefined,
};

export default function (state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case 'SET_EXCHANGE_RATES':
      return action.rates;
    default:
      return state;
  }
}
