import React, { useState, useEffect } from 'react';
import { Storage } from '@wren/shared';

const { storage } = Storage;

export default function usePersistedState<StateType>(
  initialState: StateType,
  key: string
): [StateType, React.Dispatch<React.SetStateAction<StateType>>] {
  const value = storage.get(key);

  let persistedState: StateType;

  if (value && value !== 'undefined') {
    persistedState = JSON.parse(value);
  } else {
    persistedState = initialState;
  }

  const [state, setState] = useState<StateType>(persistedState);

  useEffect(() => {
    storage.set(key, JSON.stringify(state));
  }, [state]);

  return [state, setState];
}
