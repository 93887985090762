/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';

import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';
import LocationPin from '../svgs/LocationPin';
import useIncrementalLoad from '../../hooks/useIncrementalLoad';

// 3 different spacing options
const tallHeight = {
  height: '400px',
};

const height = {
  height: [240, 300],
};

const regularHeightWithNoPadding = {
  height: [240, 300],
  bottom: 0,
};
// end spacing options

const spacer = (params) =>
  scale({
    position: 'relative',
    width: '100%',
    height: params.height,
    marginBottom: params.bottom || 0,
    backgroundColor: 'var(--background-gray)',
  });

const container = (params) =>
  scale({
    position: 'absolute',
    top: 0,
    width: '100%',
    height: params.height,
    overflow: 'hidden',
  });

const text = scale({
  maxWidth: '800px',
  fontSize: ['40px', '50px', '58px', '65px'],
  color: 'white',
  textAlign: 'center',
  position: 'absolute',
  top: ['40%', '40%', '45%'],
  left: '0',
  right: '0',
  bottom: '0',
  margin: 'auto',
});

const hero = scale({
  width: '100%',
  objectFit: 'cover',
  minHeight: '400px',
});

const colorOverlay = scale({
  width: '100%',
  height: '20%',
  position: 'absolute',
  background:
    'linear-gradient(180deg, rgba(37,37,46,0.40) 0%, rgba(0,0,0,0) 100%)',
});

const captionContainer = scale({
  position: 'absolute',
  width: 'auto',
  bottom: '15px',
  right: '15px',
  borderRadius: '3px',
  padding: '2px 8px 5px 8px',
  a: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '13px',
  },
  zIndex: 1,
  backgroundColor: 'rgba(0, 0, 0, 0.50)',
  transition: '0.1s ease-in-out background-color',
  display: ['none', 'none', 'inline-block', 'inline-block'],
  '&:hover': {
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 1.0)',
  },
});

const HeroImage = ({
  imageUrl,
  imageParams,
  pageName,
  isTall,
  noPaddingBottom,
  caption,
  className,
}) => {
  let params;
  if (isTall) {
    params = tallHeight;
  } else if (noPaddingBottom) {
    params = regularHeightWithNoPadding;
  } else {
    params = height;
  }

  const [image] = useIncrementalLoad(imageUrl, imageParams);

  return (
    <div css={spacer(params)} className={className}>
      <div css={container(params)}>
        <div css={colorOverlay} />
        {imageUrl && (
          <img
            alt={caption ? caption.name : 'header image'}
            css={hero}
            src={image}
          />
        )}
        <h1 css={text} style={{ letterSpacing: '-1px' }}>
          {pageName}
        </h1>
        {caption && (
          <span css={captionContainer}>
            <a target="_blank" href={caption.link} rel="noreferrer">
              <LocationPin
                css={{
                  marginBottom: '-2px',
                  marginRight: '5px',
                }}
              />
              {caption.name}
            </a>
          </span>
        )}
      </div>
    </div>
  );
};

export default HeroImage;
