/** @jsxImportSource @emotion/react */
import 'url-search-params-polyfill';
import 'polyfill-object.fromentries';

import ReactDOM from 'react-dom';
import getStore, { initializeStore } from './store';
import track from './lib/events';
import Root from './Root';
import ErrorMessage from './components/ErrorMessage';
import * as AB from './lib/ab';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import SentryFullStory from '@sentry/fullstory';
import { Storage } from '@wren/shared';
import patchNode from './patches/node';

const { hasLocalStorage } = Storage;

declare global {
  interface Window {
    _fs_dev_mode: boolean | undefined;
    appVersion: string | undefined;
    plausible: (eventName: string, properties?: any) => void;
  }
}

function getReleaseStageFromURL() {
  const { hostname } = window.location;

  if (hostname.includes('staging')) {
    return 'staging';
  }

  if (hostname.includes('herokuapp')) {
    return 'review-app';
  }

  if (hostname === 'www.wren.co') {
    return 'production';
  }

  return 'development';
}

if (
  process.env.NODE_ENV !== 'production' ||
  process.env.REACT_APP_BUILT_WITH_CI
) {
  window._fs_dev_mode = true; // Make fullstory shut down if it starts later
  FullStory.shutdown(); // Shuts down fullstory if it exists and is already started.
}

Sentry.init({
  // Really don't want anything to be sent to sentry on CI
  dsn:
    getReleaseStageFromURL() === 'development'
      ? undefined
      : 'https://f1c807ea0e314a5e8e2c9b0b6532dcde@o549471.ingest.sentry.io/5672308',
  integrations: [
    new Sentry.BrowserTracing(),
    new SentryFullStory('project-wren'),
    new Sentry.Replay({ maskAllText: false }),
  ],
  environment: getReleaseStageFromURL(),
  release: window.appVersion,
  allowUrls: ['https://www.wren.co/', 'https://d2fh6tinglf8qi.cloudfront.net'],
  ignoreErrors: [
    'disguiseToken',
    'cancelled',
    'Illegal invocation',
    'Failed to load Stripe.js',
    'Non-Error promise rejection captured',
    'chunk',
    'Failed to fetch',
    'Load failed',
    'TypeError: Load failed',
    'play method is not allowed',
    'ResizeObserver',
    'NetworkError when attempting to fetch resource',
    "Can't find variable: PaymentAutofillConfig", // instagram code injection bug which does not effect page functionality
    'The node before which the new node is to be inserted is not a child of this node', // translation extension error
    'logMutedMessage',
    'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
  ],
  beforeSend(event) {
    const { user } = getStore().getState();
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        user: {
          name: String(user.firstName),
          email: String(user.email),
        },
      });
    }

    return event;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

async function run() {
  const store = await initializeStore();

  if (!hasLocalStorage()) {
    store.dispatch({
      type: 'SET_TOAST',
      toast: {
        type: 'alert',
        text: 'We noticed that your browser doesn’t allow our website to store data on your computer (cookies). This means you will lose all your progress when you leave the site.',
      },
    });
  }

  // Since some third party trackers block segment, we want to make sure that
  // we at least track this user's visit via this API request.
  track('Server: User Arrived On Page', {
    location: window.location.pathname,
  });
  if (window.analytics) {
    window.analytics.page(window.location.pathname);
  }

  const { userId } = store.getState().user;

  AB.assignExperiments(userId);

  document.documentElement.prepend(
    document.createComment('Work at Wren: ZXJpY0Bwcm9qZWN0d3Jlbi5jb20=')
  );

  // Monkey patch to prevent site-translation / React error
  // based on https://github.com/facebook/react/issues/11538#issuecomment-417504600
  // Run before ReactDOM.render
  patchNode();

  ReactDOM.render(
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <ErrorMessage error={error} resetError={resetError} />
      )}
    >
      <Root store={store} />
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
  );
}

run();
