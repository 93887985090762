/** @jsxImportSource @emotion/react */

import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';

import Footer from '../../components/frontfacing/Footer';
import RoundedButton from '../../components/buttons/RoundedButton';
import PressBox from '../../components/frontfacing/PressBox';
import LandingFAQ from '../../components/frontfacing/LandingFAQ';
import MobileHeroImageLanding from '../../components/frontfacing/MobileHeroImageLanding';
import * as Sections from './Sections';
import { GetStarted } from '../../actions/user';
import { Headings, Citation } from '../../components/UI/TextStyles';
import { formatNumber } from '../../lib/format';
import useNewSignups from '../../hooks/useNewSignups';
import useMainCTAText from '../../hooks/useMainCTAText';
import RoundedCountrySelectWithCTA from '../../components/calculator/RoundedCountrySelectWithCTA';
import { spacing } from '../../style/theme';
import {
  SetCalculatorQuestionIndex,
  SetCountry,
} from '../../actions/calculator';
import ValueProof from '../../components/landing/ValueProof';
import { lazy, Suspense } from 'react';

const HowItWorksLazy = lazy(() =>
  import('../../components/landing/HowItWorks')
);

const HowItWorks = () => (
  <Suspense fallback={<p></p>}>
    {/* TODO: skeleton */}
    <HowItWorksLazy />
  </Suspense>
);

const contentContainer = scale({
  display: 'grid',
  gridTemplateColumns: '1fr min(1000px, 90%) 1fr',
  '& > *': {
    gridColumn: 2,
  },
});

const fullBleed = { width: '100%', gridColumn: '1 / 4' };

const finalSection = scale({
  textAlign: 'center',
  margin: '100px auto',
  img: {
    width: '200px',
    maxWidth: '90%',
  },
});

const Mobile = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const newSignups = useNewSignups();
  const ctaText = useMainCTAText();

  function handleCTAClick(eventLocation, event) {
    if (event) {
      event.preventDefault();
    }
    dispatch(
      GetStarted({
        history: props.history,
        eventLocation,
        platform: 'mobile',
      })
    );
  }

  async function handleSubmitCountry(country) {
    dispatch(SetCountry(country));
    dispatch(SetCalculatorQuestionIndex(1));
    handleCTAClick('hero');
  }

  const renderCTA = (eventLocation, size) => (
    <RoundedButton
      size={size}
      color="orange"
      id="landing-cta"
      onClick={(event) => handleCTAClick(eventLocation, event)}
    >
      {ctaText}
    </RoundedButton>
  );

  return (
    <div css={{ backgroundColor: 'white', overflowX: 'hidden' }}>
      <Helmet>
        <title>
          Wren | {props.headerText || 'Erase your carbon footprint'}
        </title>
      </Helmet>
      {props.hero || (
        <MobileHeroImageLanding
          backgroundColor="#0b5643" // Dominant color of wren-mobile-hero.jpg
          header={props.headerText}
          imageUrl="wren-mobile-hero.png"
        />
      )}
      <div css={[contentContainer, { marginTop: 0 }]}>
        <ValueProof
          cta={
            <div>
              {!user.annualFootprint ? (
                <div css={{ marginBottom: spacing.default }}>
                  <RoundedCountrySelectWithCTA
                    handleSubmitCountry={handleSubmitCountry}
                    handleCTAClick={(event) => handleCTAClick('hero', event)}
                  />
                </div>
              ) : (
                renderCTA('hero', 'large')
              )}
              <Citation
                css={{
                  display: 'block',
                  maxWidth: 150,
                  margin: '8px auto 24px',
                  color: 'var(--gray-6)',
                }}
              >
                Join the {newSignups && <b>{formatNumber(newSignups)}</b>}{' '}
                people who signed up this month!
              </Citation>
            </div>
          }
        />

        <HowItWorks />

        <Sections.MidPageCTA cta={renderCTA('mid-page')} />

        <PressBox css={[fullBleed, { background: 'white' }]} />

        <Sections.MobileUserTestimonials css={fullBleed} />

        <Sections.Method
          css={[fullBleed, { backgroundColor: 'var(--success-green)' }]}
        />
        <Sections.Partners css={fullBleed} />
        <Sections.Transparency css={fullBleed} />
        <div css={{ textAlign: 'center', marginTop: 16 }}>
          <img
            css={{ width: 40, marginBottom: 16 }}
            src={staticImageUrl('circle-question-mark.png')}
          />
          <LandingFAQ />
        </div>
        <div css={finalSection}>
          <img src={staticImageUrl('mascot/wren-bird.png', { w: 300 })} />
          <Headings.Large font="serif" css={[{ marginBottom: 24 }]}>
            Join thousands of people taking climate action on Wren!
          </Headings.Large>
          {renderCTA('final-landing-section')}
        </div>
        <PressBox css={[fullBleed, { background: 'white' }]} />
        <div
          css={[
            fullBleed,
            {
              padding: '80px 0',
              backgroundColor: 'var(--background-gray)',
              margin: '0px auto -80px',
              textAlign: 'center',
            },
          ]}
        >
          <strong css={{ display: 'block', marginBottom: 32 }}>
            What people are tweeting:
          </strong>
          <img
            loading="lazy"
            src={staticImageUrl('mobile-tweets-fade-out.png', { w: 1200 })}
            css={{
              margin: '0 auto',
              display: 'block',
              maxWidth: 400,
              width: '100%',
            }}
          />
        </div>
      </div>
      <Footer hideCTA />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

Mobile.propTypes = {};

Mobile.defaultProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mobile));
