/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { colors, fonts, oldFontSizes, spacing } from '../../style/theme';
import { scale } from '../../util/scale';
import SlideOut from '../animation/SlideOut';

const defaultToggleStyle = scale({
  paddingBottom: '10px',
});

const toggleContentStyle = (onDarkBackground?: boolean, isSmall?: boolean) =>
  scale({
    margin: '0px 20px',
    paddingLeft: 36,
    marginTop: isSmall ? -8 : -12,
    borderLeft: onDarkBackground
      ? '2px solid rgba(0, 0, 0, 0.2)'
      : `2px solid ${colors.gray9}`,
    p: {
      fontSize: isSmall ? 18 : 20,
      fontFamily: fonts.Serif,
      lineHeight: 1.6,
      color: onDarkBackground
        ? 'rgba(255, 255, 255, 0.8)'
        : 'var(--text-secondary)',
      paddingBottom: 12,
    },
    a: {
      fontSize: isSmall ? 18 : 20,
      fontFamily: fonts.Serif,
      lineHeight: 1.6,
      color: 'var(--link-blue)',
    },
    li: {
      fontSize: isSmall ? 18 : 20,
      lineHeight: 1.6,
      fontFamily: fonts.Serif,
      color: onDarkBackground
        ? 'rgba(255, 255, 255, 0.8)'
        : 'var(--text-secondary)',
      paddingBottom: 8,
    },
    '& > p:last-child': {
      paddingBottom: 0,
    },
  });

const sectionStyle = (onDarkBackground?: boolean) =>
  scale({
    position: 'relative',
    fontSize: oldFontSizes.bodyLarge,
    padding: `${spacing.xSmall}px 0`,
    cursor: 'pointer',
    outline: 'none',
    '&:hover .icon': {
      backgroundColor: onDarkBackground
        ? 'rgba(0, 0, 0, 0.2)'
        : `${colors.gray9}`,
    },
    userSelect: 'none',
  });

const iconBar = (open?: boolean, onDarkBackground?: boolean) =>
  scale({
    position: 'absolute',
    backgroundColor: onDarkBackground ? 'white' : 'var(--text-primary)',
    width: '2px',
    height: '16px',
    left: '50%',
    top: '30%',
    transition: 'transform 200ms ease-in-out',
    transform: open ? 'rotate(45deg)' : 'rotate(00deg)',
  });

const verticalBar = (open?: boolean) =>
  scale({
    transform: open ? 'rotate(-45deg)' : 'rotate(-90deg)',
  });

const icon = (open?: boolean, onDarkBackground?: boolean) =>
  scale({
    display: 'inline-block',
    float: 'left',
    verticalAlign: 'middle',
    position: 'relative',
    width: '40px',
    height: '40px',
    transition: 'all 150ms ease-in-out',
    borderRadius: 4,
    marginRight: '10px',
    backgroundColor: open
      ? onDarkBackground
        ? 'rgba(0, 0, 0, 0.2)'
        : `${colors.gray9}`
      : 'none',
  });

const iconHeaderWrapper = scale({
  display: 'inline-block',
  width: '100%',
});

const headerStyle = (isSmall?: boolean) =>
  scale({
    display: 'block',
    overflow: 'hidden',
    verticalAlign: 'middle',
    fontSize: isSmall ? '16px !important' : 18,
    lineHeight: 1.3,
    fontFamily: fonts.Sans,
    fontWeight: 600,
    margin: '0 !important',
    paddingTop: 8,
    paddingLeft: 6,
  });

const ExpandableContent = ({
  header = '',
  children,
  isSmall = false,
  className,
  onDarkBackground,
  expandedByDefault = false,
  onExpand,
}: {
  header?: string;
  children: React.ReactNode;
  isSmall?: boolean;
  className?: string;
  onDarkBackground?: boolean;
  expandedByDefault?: boolean;
  onExpand?: () => void;
}) => {
  const [showSection, toggleSection] = useState(expandedByDefault);

  return (
    <div className={className} key={header}>
      <div
        onClick={() => {
          if (!showSection) {
            onExpand?.();
          }
          toggleSection(!showSection);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            toggleSection(!showSection);
          }
        }}
        role="button"
        tabIndex={0}
        css={sectionStyle(onDarkBackground)}
      >
        <div css={iconHeaderWrapper}>
          <div className="icon" css={icon(showSection, onDarkBackground)}>
            <div css={iconBar(showSection, onDarkBackground)} />
            <div
              css={[
                iconBar(showSection, onDarkBackground),
                verticalBar(showSection),
              ]}
            />
          </div>
          <h4 css={headerStyle(isSmall)}>{header}</h4>
        </div>
      </div>
      <SlideOut>
        {showSection && (
          <div
            className="toggle-content"
            css={[
              defaultToggleStyle,
              toggleContentStyle(onDarkBackground, isSmall),
            ]}
          >
            {children}
          </div>
        )}
      </SlideOut>
    </div>
  );
};

export default ExpandableContent;
