/** @jsxImportSource @emotion/react */

import { useMemo } from 'react';
import uniq from 'lodash/uniq';

import { DEFAULT_COUNTRIES } from '../../config/constants';
import { guessCountry } from '../../util/util';
import { colors, spacing } from '../../style/theme';
import { Countries } from '@wren/shared';

/* 
  SelectInput component had some built in styles & options formatting
  that were challenging to work with, and AsyncSelectInput had
  build in filter functionality that was incompatible with grouped
  options, so re-built CountrySelect as custom react-select.
 */
import Select, {
  Props,
  GroupBase,
  OptionsOrGroups,
  components,
} from 'react-select';
import { css, PropsOf } from '@emotion/react';
import ArrowDown from '../svgs/ArrowDown';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: 12,
};

const groupBadgeStyles = {
  backgroundColor: colors.backgroundBeige,
  borderRadius: '2em',
  color: colors.textSecondary,
  display: 'inline-block',
  fontSize: 12,
  fontWeight: '500',
  minWidth: 1,
  padding: `${spacing.xxSmall}px ${spacing.xSmall}px`,
};

type Country = Pick<
  Countries.Country,
  'country_name' | 'flag_emoji' | 'two_char_country_code'
>;

function createGroups({
  countries,
  commonCountries,
}: {
  countries: Country[];
  commonCountries: string[];
}): GroupBase<Country>[] {
  // react-select was having some reference issues
  // where it would scroll the user to the first common country option
  // but in the alphabeticalOptions group. couldn't be solved with a slice,
  // but a deep clone seems to work
  const commonSearches = countries
    .filter(({ country_name }) => commonCountries.includes(country_name))
    .map<Country>((opt) => ({ ...opt }))
    .reverse();

  return [
    {
      label: 'Common searches',
      options: commonSearches,
    },
    {
      label: 'Alphabetical',
      options: countries,
    },
  ];
}

function GroupLabel({ group }: { group: GroupBase<Country> }) {
  return (
    <div css={groupStyles}>
      <span>{group.label}</span>
      <span css={groupBadgeStyles}>{group.options.length}</span>
    </div>
  );
}

const Input = (
  props: PropsOf<typeof components.Input<Country, false, GroupBase<Country>>>
) => <components.Input {...props} autoComplete="none" css={{ height: 36 }} />;

export default function CountryInput({
  className,
  countries = Countries.alphabetical(),
  formatGroupLabel = (group) => <GroupLabel group={group} />,
  formatOptionLabel,
  handleSubmit,
  onChange,
  placeholder = '🌏 Enter your country',
  showFlagEmojis = true,
  showCommonSearches = false,
  value,
  ...restOfProps
}: {
  className?: string;
  countries?: Country[];
  handleSubmit?: (country: string) => void;
  placeholder?: string;
  showFlagEmojis?: boolean;
  showCommonSearches?: boolean;
  value?: string;
} & Omit<Props<Country, false>, 'isMulti' | 'value'>) {
  formatOptionLabel ||= (country) => (
    <>
      {showFlagEmojis === false ? '' : `${country.flag_emoji} `}{' '}
      {country.country_name}
    </>
  );

  const { groups, options } = useMemo(() => {
    if (showCommonSearches) {
      const guessedCountry =
        (typeof window !== 'undefined' &&
          (guessCountry(window.navigator.language) as string | null)) ||
        'United States';

      const commonCountries = uniq([guessedCountry, ...DEFAULT_COUNTRIES]);

      return {
        groups: createGroups({
          commonCountries,
          countries,
        }),
      };
    }

    return {
      options: countries,
    };
  }, [countries, showCommonSearches]);

  const optionsOrGroups: OptionsOrGroups<
    Country,
    { label?: string; options: readonly Country[] }
  > = groups || options;

  const selectedCountry = countries.find((c) => c.country_name === value);

  return (
    <Select
      className={className}
      components={{ Input }}
      formatGroupLabel={formatGroupLabel}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={(country) => country.country_name}
      onChange={(option, actionMeta) => {
        onChange?.(option, actionMeta);
        if (option) {
          handleSubmit?.(option.country_name);
        }
      }}
      options={optionsOrGroups}
      placeholder={placeholder}
      value={selectedCountry}
      {...restOfProps}
    />
  );
}

function getFills({
  isDisabled,
  isFocused,
}: {
  isDisabled: boolean;
  isFocused: boolean;
}): [fillColor: string, hoverFillColor: string] {
  if (isDisabled) {
    return [colors.gray9, colors.gray9];
  }
  if (isFocused) {
    return [colors.gray4, colors.gray3];
  }
  return [colors.gray8, colors.gray7];
}

export const CompactCountryInput = (props: PropsOf<typeof CountryInput>) => (
  <CountryInput
    css={css`
      display: inline-block;
    `}
    formatOptionLabel={(country, meta) =>
      meta.context === 'value' ? (
        <span aria-label={country.country_name} role="img">
          {country.flag_emoji}
        </span>
      ) : (
        <>
          {country.flag_emoji} {country.country_name}
        </>
      )
    }
    components={{
      IndicatorSeparator: null,
      DropdownIndicator: ({ innerProps, isDisabled, isFocused }) => (
        <div
          css={{
            display: 'flex',
            '& > svg': {
              fill: getFills({ isDisabled, isFocused })[0],
            },
            ':hover': {
              '& > svg': {
                fill: getFills({ isDisabled, isFocused })[1],
              },
            },
          }}
          {...innerProps}
        >
          <ArrowDown size={10} />
        </div>
      ),
    }}
    placeholder="🌏"
    styles={{
      control: (base) => ({
        ...base,
        paddingRight: spacing.xSmall,
      }),
      menu: (base) => ({ ...base, minWidth: 200 }),
    }}
    {...props}
  />
);
