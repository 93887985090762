/** @jsxImportSource @emotion/react */

export default function Envelope({ size = 60, fill = 'black' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M114.705 121.538C110.169 124.408 105.067 125.861 99.9646 125.861C94.9331 125.861 89.9016 124.479 85.5433 121.68L12.7992 72.7116C11.5945 71.8967 10 72.7471 10 74.1998V161.897C10 166.857 14.0394 170.897 19 170.897H181C185.961 170.897 190 166.857 190 161.897V74.1998C190 72.7471 188.406 71.8967 187.201 72.7116L114.705 121.538Z"
        fill={fill}
      />
      <path
        d="M95.3583 109.724C98.0866 111.461 101.878 111.461 104.819 109.583L189.22 52.748C189.717 52.4291 190 51.8622 190 51.2598V39C190 34.0394 185.961 30 181 30H19C14.0394 30 10 34.0394 10 39V51.2598C10 51.8622 10.2835 52.4291 10.7795 52.748L95.3583 109.724Z"
        fill={fill}
      />
    </svg>
  );
}
