const initialState = {
  subscription: undefined,
  cardInfo: {},
  charges: [],
};

function setSubscription(state, action) {
  return { ...state, subscription: action.subscription };
}

function setCardInfo(state, action) {
  return { ...state, cardInfo: action.cardInfo };
}

function setCharges(state, action) {
  return { ...state, charges: action.charges };
}

export default function (state, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case 'SET_SUBSCRIPTION':
      return setSubscription(state, action);
    case 'SET_CARD_INFO':
      return setCardInfo(state, action);
    case 'SET_USER_CHARGES':
      return setCharges(state, action);
    case 'CLEAR_SUBSCRIPTION':
      return { ...initialState };
    default:
      return state;
  }
}
