/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// This does a lot more than scrolling. TODO: refactor
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      // track segment page views
      if (window.analytics) {
        window.analytics.page(location.pathname);
      }

      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.defaultProps = {
  children: null,
  location: {},
};

ScrollToTop.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default withRouter(ScrollToTop);
