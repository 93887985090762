/** @jsxImportSource @emotion/react */
import { Fragment } from 'react'; //useContext
// import { PathHistoryContext } from '../../PageContent';
import { backLink, backLinkContainer } from '../../style/article';
import { noStyleLink } from '../../style/text';

function BackLink({ destination }) {
  //   const pathHistory = useContext(PathHistoryContext);

  //   function goBack() {
  //     console.log('pathHistory', pathHistory);
  //     if (pathHistory.length > 1) {
  //       console.log('going back on Wren');
  //       //   window.history.back();
  //     } else {
  //       console.log('going to projects'); // i fucking hate you copilot. learn from that!
  //       //   window.location.href = destination;
  //     }
  //   }

  return (
    <a href={destination}>
      <div css={backLinkContainer}>
        <Fragment>
          <svg
            styles={{ display: 'inline-block' }}
            width="18"
            height="14"
            viewBox="0 0 19 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0963 4.67164H3.40437L6.03257 2.04344H6.03277C6.25665 1.83179 6.3915 1.54272 6.41004 1.23513C6.42837 0.927534 6.32896 0.624414 6.13198 0.387501C5.91788 0.149165 5.61539 0.00921794 5.29515 0.000456926C4.97492 -0.00850643 4.66509 0.114736 4.43813 0.340848L0.755534 4.01756C0.271733 4.50115 0 5.15727 0 5.84136C0 6.5254 0.271743 7.18132 0.755534 7.66516L4.37967 11.2922C4.59234 11.514 4.8814 11.6468 5.1884 11.6635C5.49518 11.6804 5.79708 11.58 6.03274 11.3826C6.27108 11.1685 6.41103 10.866 6.41979 10.5458C6.42855 10.2255 6.30551 9.91572 6.0794 9.68877L3.40431 7.008H11.0963C12.3356 7.008 13.5241 7.50036 14.4004 8.3765C15.2765 9.25286 15.7689 10.4413 15.7689 11.6806V12.8486C15.7689 13.1585 15.8919 13.4555 16.1109 13.6747C16.3301 13.8937 16.6271 14.0167 16.9369 14.0167C17.2468 14.0167 17.544 13.8937 17.763 13.6747C17.982 13.4555 18.1052 13.1585 18.1052 12.8486V11.6806C18.1052 9.82159 17.3668 8.03894 16.0523 6.72463C14.7379 5.41011 12.9551 4.67168 11.0963 4.67168L11.0963 4.67164Z"
              fill="#2298CA"
            />
          </svg>
          <span css={noStyleLink}>
            <span css={backLink}>Back</span>
          </span>
        </Fragment>
      </div>
    </a>
  );
}

export default BackLink;
