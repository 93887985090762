/** @jsxImportSource @emotion/react */

import sortBy from 'lodash/sortBy';
import {
  MarkAllNotificationsAsRead,
  MarkNotificationAsRead,
} from '../../actions/notifications';
import { timeSince } from '../../lib/format';
import { noStyleLink } from '../../style/text';
import { spacing } from '../../style/theme';
import { Notification } from '../../types';
import { scale } from '../../util/scale';
import Flex from '../UI/Flex';
import { Citation, Headings, Paragraph } from '../UI/TextStyles';
import Bell from '../svgs/Bell';
import { useNotifications } from '../../hooks/useNotifications';

export default function Notifications() {
  const {
    isLoading,
    notifications,
    handleClickNotification,
    handleMarkAllAsReadMutation,
  } = useNotifications();

  if (isLoading || !notifications) return null;

  return (
    <div
      css={scale({
        overflowY: 'scroll',
        maxHeight: ['100%', '100%', 500, 500],
        width: '100%',
        paddingBottom: spacing.default,
      })}
    >
      <Flex
        justify="space-between"
        align="center"
        css={{ padding: '16px 20px 12px 20px' }}
      >
        <Headings.Medium
          css={{
            margin: 0,
            padding: 0,
            fontWeight: '600 !important',
          }}
        >
          Notifications
        </Headings.Medium>
        <Paragraph
          role="button"
          onClick={handleMarkAllAsReadMutation}
          css={{
            color: notifications?.every((n) => n.readAt)
              ? 'var(--border-color)'
              : 'var(--gray-4)',
            borderRadius: 4,
            height: 'fit-content',
            cursor: 'pointer',
            margin: 0,
            padding: '4px 6px',
            transition: 'all 300ms linear',
            pointerEvents: notifications.every((n) => n.readAt)
              ? 'none'
              : 'auto',
            '&:hover': {
              color: 'var(--text-primary)',
              background: 'rgba(0, 0, 0, 0.08)',
            },
          }}
        >
          ✓ Mark all as read
        </Paragraph>
      </Flex>
      {sortBy(notifications, ['createdAt', 'id'])
        .reverse()
        .map((notification) => (
          <a
            key={notification.id}
            onClick={() => handleClickNotification?.(notification.id)}
            href={notification.linkUrl ?? undefined}
            css={[
              noStyleLink,
              {
                width: '100%',
                boxSizing: 'border-box',
                opacity: notification.readAt ? 0.7 : 1,
                display: 'block',
                padding: '12px 20px',
                '&:hover': {
                  backgroundColor: 'var(--gray-9)',
                  cursor: 'pointer',
                },
              },
            ]}
          >
            <Flex justify="space-between">
              <img
                src={notification.imageUrl?.replace('w=2000', 'w=200')}
                css={{
                  objectFit: 'cover',
                  width: 64,
                  height: 64,
                  borderRadius: 2,
                  marginRight: 24,
                }}
              />
              <div css={{ width: '100%' }}>
                <Paragraph>{notification.message}</Paragraph>
                <Citation
                  css={{
                    color: 'var(--gray-6)',
                  }}
                >
                  {timeSince(new Date(notification.createdAt))} ago
                </Citation>
              </div>
              <Flex
                align="center"
                css={{
                  marginLeft: 24,
                  flexShrink: 0,
                }}
              >
                {!notification.readAt && (
                  <div css={{ fontSize: 30, color: 'var(--error-red)' }}>•</div>
                )}
              </Flex>
            </Flex>
          </a>
        ))}
      {!notifications?.length && (
        <div
          css={{
            textAlign: 'center',
            margin: '16px auto 24px',
            maxWidth: 200,
          }}
        >
          <Bell color="var(--gray-7)" size={14} />
          <Citation
            css={{
              display: 'block',
              color: 'var(--gray-6)',
              textAlign: 'center',
              marginTop: 8,
            }}
          >
            No notifications yet! Check back later.
          </Citation>
        </div>
      )}
    </div>
  );
}
