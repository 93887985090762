/** @jsxImportSource @emotion/react */

import PressBox from '../../components/frontfacing/PressBox';
import LandingFAQ from '../../components/frontfacing/LandingFAQ';
import * as Sections from './Sections';
import DesktopHero from './DesktopHero';
import { Headings, Citation } from '../../components/UI/TextStyles';
import { scale } from '../../util/scale';
import { staticImageUrl } from '../../util/images';
import { ReactNode } from 'react';
import { hideOnMobile, showOnMobile } from '../../style/misc';
import { formatNumber } from '../../lib/format';
import useNewSignups from '../../hooks/useNewSignups';
import MobileHeroImageLanding from '../../components/frontfacing/MobileHeroImageLanding';
import Container, { FullBleedSection } from '../../components/UI/Container';
import { NavButton } from '../../components/buttons/NavButton';
import { colors, spacing } from '../../style/theme';
import { ArrowNavButton } from '../../components/buttons/ArrowNavButton';
import ValueProof from '../../components/landing/ValueProof';
import RoundedCountrySelectWithCTA from '../../components/calculator/RoundedCountrySelectWithCTA';

const ctaButton = scale({
  boxShadow: '0 3px 2px 0 var(--box-shadow-color)',
  fontSize: '22px',
  fontWeight: 500,
  padding: '13px 36px',
  '&:hover': {
    boxShadow: '0 3px 2px 0 var(--box-shadow-color)',
  },
});

const finalSection = scale({
  textAlign: 'center',
  margin: '100px auto',
});

export default function LandingContent({
  ctaText,
  hero,
  headers,
  onCtaClick,
  withSignupCounter = true,
  valueProofHeader,
  country,
  handleSubmitCountry,
  howItWorks,
}: {
  ctaText: string;
  hero?: ReactNode;
  headers: {
    header?: ReactNode;
    sub?: ReactNode;
    mainTitleWidth?: number;
  };
  onCtaClick: (eventLocation: string, event?: React.MouseEvent) => void;
  handleSubmitCountry: (country: string) => void;
  withSignupCounter?: boolean;
  valueProofHeader?: ReactNode;
  country: string | null | undefined;
  howItWorks: ReactNode;
}) {
  const newSignups = useNewSignups();

  const heroCta = !country ? (
    <RoundedCountrySelectWithCTA
      handleSubmitCountry={handleSubmitCountry}
      handleCTAClick={(event) => onCtaClick('hero', event)}
    />
  ) : (
    <NavButton
      id="landing-cta"
      buttonType="filled"
      color={colors.wrenOrange}
      css={ctaButton}
      href="/continue"
      shape="rounded rectangle"
      size="large"
      onClick={(event) => onCtaClick('hero', event)}
    >
      {ctaText}
    </NavButton>
  );

  const mobileCTA = !country ? (
    <div css={{ marginBottom: spacing.default }}>
      <RoundedCountrySelectWithCTA
        handleSubmitCountry={handleSubmitCountry}
        handleCTAClick={(event) => onCtaClick('hero', event)}
      />
    </div>
  ) : (
    <ArrowNavButton
      buttonType="filled"
      color={colors.wrenOrange}
      css={{ fontWeight: 500 }}
      href="/continue"
      onClick={(event) => onCtaClick('hero', event)}
    >
      {ctaText}
    </ArrowNavButton>
  );

  return (
    <>
      <div css={{ position: 'relative', zIndex: 1 }}>
        {hero || (
          <>
            <div css={hideOnMobile}>
              <DesktopHero
                {...headers}
                cta={heroCta}
                withSignupCounter={withSignupCounter}
              />
            </div>
            <MobileHeroImageLanding
              backgroundColor="#0b5643" // Dominant color of wren-mobile-hero.jpg
              css={showOnMobile}
              header={headers.header}
              imageUrl="wren-mobile-hero.png"
            />
          </>
        )}
      </div>
      <PressBox
        css={[
          {
            position: 'relative',
            zIndex: 0,
            background: 'white',
            marginBottom: 42,
          },
          hideOnMobile,
        ]}
      />
      <Container width={1000}>
        <ValueProof
          cta={
            <div css={showOnMobile}>
              {mobileCTA}
              <Citation
                css={{
                  display: 'block',
                  maxWidth: 150,
                  margin: '8px auto 24px',
                  color: 'var(--gray-6)',
                }}
              >
                Join the {newSignups && <b>{formatNumber(newSignups)}</b>}{' '}
                people who signed up this month!
              </Citation>
            </div>
          }
          header={valueProofHeader}
        />

        {howItWorks}

        <Sections.MidPageCTA
          cta={
            <NavButton
              buttonType="filled"
              color={colors.wrenOrange}
              css={ctaButton}
              href="/continue"
              shape="rounded rectangle"
              size="large"
              onClick={(event) => onCtaClick('mid-page', event)}
            >
              {ctaText}
            </NavButton>
          }
        />
        <FullBleedSection>
          {/* @ts-expect-error these props are implicitly optional */}
          <Sections.UserTestimonials />
        </FullBleedSection>

        {/* @ts-expect-error these props are implicitly optional */}
        <Sections.Method css={scale({ gridColumn: ['1/4', '1/4', 2] })} />
        <FullBleedSection>
          {/* @ts-expect-error these props are implicitly optional */}
          <Sections.Partners />
          {/* @ts-expect-error these props are implicitly optional */}
          <Sections.Transparency />
        </FullBleedSection>
        <br />
        <br />
        <br />
        <div css={{ textAlign: 'center' }}>
          <img
            css={{ width: 40, marginBottom: 16 }}
            src={staticImageUrl('circle-question-mark.png')}
          />
          <LandingFAQ />
        </div>
        <div css={finalSection}>
          <img
            src={staticImageUrl('mascot/wren-bird.png', { w: 600 })}
            css={{ width: 200 }}
          />
          <Headings.Large
            font="serif"
            css={[{ marginBottom: 24, maxWidth: 360 }]}
          >
            We all have a part to play. Take climate action today.
          </Headings.Large>

          <NavButton
            buttonType="filled"
            color={colors.wrenOrange}
            css={ctaButton}
            href="/continue"
            shape="rounded rectangle"
            size="large"
            onClick={(event) => onCtaClick('final-landing-section', event)}
          >
            {ctaText}
          </NavButton>
        </div>

        <FullBleedSection>
          {/* @ts-expect-error these props are implicitly optional */}
          <Sections.Tweets />
        </FullBleedSection>
      </Container>
    </>
  );
}
