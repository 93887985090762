import { Currency } from '../types';

export const UserStatus = {
  ACTIVE: 1,
  PAUSED: 2,
  CANCELLED: 3,
  // COVERED: 4, We do not use this status anymore.
  PAST_DUE: 5,
  LAPSED: 6,
  DELETED: 7,
};

// Custom-coded fundraiser IDs
export const GIVING_TUESDAY_TAX_DEDUCTIBLE_FUNDRAISER_ID =
  '708f1ff5-ead5-4efb-a416-375afdf3951b';

export const TOTAL_TIST_TREES_PLANTED = 2270000;

export const DEFAULT_PORTFOLIO_NAME = 'Wren Classic Portfolio';

export const CALCULATOR_KEYS = [
  // 'country',
  'transportDomesticFlightsPerYear',
  'transportInternationalFlightsPerYear',
  'dietPreference',
  'dietRedMeatPerWeek',
  'dietOtherProteinsPerWeek',
  'dietDairyPerWeek',
  'homeSquareFeet',
  'transportMethod',
  'transportMilesDrivenPerWeek',
  'transportCarMpg',
  'transportBusMilesPerWeek',
  'transportTransitRailMilesPerWeek',
  'transportInterCityRailMilesPerYear',
  'goodsFurnitureAppliancesDollarsSpentPerMonth',
  'goodsClothesDollarsSpentPerMonth',
  'goodsOtherDollarsSpentPerMonth',
  'servicesDollarsSpentPerMonth',
  'electricityKwhPerMonth',
  'electricityPercentRenewable',
  'naturalGasBurnedPerMonth',
];

export const APPLE_MAPS_JWT_KEY =
  'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjhKM00ySDQyOFYifQ.eyJpc3MiOiIyQlEyS1RTVEs4IiwiaWF0IjoxNjgxNDI0MDg4LCJleHAiOjE3MTIxODg4MDB9.V5GiAFeVgPrPBTkzm7_hnvPdQy_uvqzkbPhHneavisFI94sFewF0cI6mg9J6Lkuc0qF__wVHHDNtAfjIJ7HlTA';

export const DEFAULT_COUNTRIES = [
  'United States',
  'United Kingdom',
  'Germany',
] as const;

export const Experiences = {
  PROFILE_TOUR: 'profile_tour',
  PROFILE_TOUR_2: 'profile_tour_2' as const,
  GROUP_TOUR: (group: { id: string }) => `group_tour_${group.id}`,
  TEAM_TOUR: 'team_tour' as const,
  TEAM_ADMIN_TOUR: 'team_admin_tour' as const,
  CARBON_REPORT: 'carbon_report',
  CARBON_REPORT_TOUR: 'carbon_report_tour',
  INCENTIVE_TREE_PLANTING: 'incentive_ten_trees_planted',
  INCENTIVE_BONUS_TON: 'incentive_bonus_ton',
  INCENTIVE_RAINFOREST_PROTECTION: 'incentive_hectare_rainforest_protected',
  INCENTIVE_DOUBLE_IMPACT: 'incentive_double_impact_first_month',
  INCENTIVE_2020_EOY_DOUBLE_IMPACT: 'incentive_2020_eoy_double_impact',
  ACTIONS_SETUP: 'actions_setup',
  CARBON_REPORT_FEEDBACK: 'carbon_report_feedback',
  FB_LANDING_PAGE: 'fb_landing_page',
  REFER_FRIEND_VIA_EMAIL: 'refer_friend_via_email',
  DASHBOARD_FEEDBACK: 'dashboard_feedback',
  ACTIONS_FEEDBACK: 'actions_feedback',
  OFFSETS_POST_FEEDBACK: 'offsets_post_feedback',
  LAYERS_OF_IMPACT_POST_FEEDBACK: 'layers_of_impact_post_feedback',
  ADD_LINKEDIN_WORK_EXPERIENCE: 'add_linkedin_work_experience',
  JOIN_WREN_DISCORD: 'join_wren_discord',
  IMPACT_SUMMARY_DISMISSAL: 'impact_summary_dismissal',
  ACTIONS_COMMUNITY_CHALLENGE_POLL_0523:
    'actions_community_challenge_poll_0523',
};

export const LeaderboardTypes = {
  TOTAL_TONS_OFFSET: 'TOTAL_TONS_OFFSET',
  SMALLEST_FOOTPRINT: 'SMALLEST_FOOTPRINT',
  REFERRALS: 'REFERRALS',
  GROUP_SIZE: 'GROUP_SIZE',
};

export const WORLD_AVERAGE_ANNUAL_CO2_EMISSIONS = 4.9;

export const AddOnType = {
  THIS_YEAR_SO_FAR: 'THIS_YEAR_SO_FAR',
  PAST_YEAR: 'PAST_YEAR',
  PAST_DECADE: 'PAST_DECADE',
  LIFE: 'LIFE',
  CUSTOM_DURATION: 'CUSTOM_DURATION',
} as const;

export const COMMUNITY_TREE_PLANTING_PROJECT_ID = 12;
export const FALLBACK_CURRENCIES: Currency[] = [
  {
    name: 'Pound sterling',
    smallestDenominationDivider: 100,
    symbol: '£',
    smallestDenominationName: 'Penny',
    code: 'GBP',
  },
  {
    name: 'Euro',
    smallestDenominationDivider: 100,
    symbol: '€',
    smallestDenominationName: 'Cent',
    code: 'EUR',
  },
  {
    name: 'Canadian dollar',
    smallestDenominationDivider: 100,
    symbol: '$',
    smallestDenominationName: 'Cent',
    code: 'CAD',
  },
  {
    name: 'Swiss franc',
    smallestDenominationDivider: 100,
    symbol: 'CHF',
    smallestDenominationName: 'Rappen',
    code: 'CHF',
  },
  {
    name: 'Mexican peso',
    smallestDenominationDivider: 100,
    symbol: '$',
    smallestDenominationName: 'Centavo',
    code: 'MXN',
  },
  {
    name: 'Japanese yen',
    smallestDenominationDivider: 1,
    symbol: '¥',
    smallestDenominationName: 'Yen',
    code: 'JPY',
  },
  {
    name: 'Australian dollar',
    smallestDenominationDivider: 100,
    symbol: '$',
    smallestDenominationName: 'Cent',
    code: 'AUD',
  },
  {
    name: 'Indian Rupee',
    smallestDenominationDivider: 100,
    symbol: '₹',
    smallestDenominationName: 'Paisa',
    code: 'INR',
  },
  {
    name: 'United States Dollar',
    smallestDenominationDivider: 100,
    symbol: '$',
    smallestDenominationName: 'Cent',
    code: 'USD',
  },
  {
    name: 'New Zealand dollar',
    smallestDenominationDivider: 100,
    symbol: '$',
    smallestDenominationName: 'Cent',
    code: 'NZD',
  },
];

export const DEFAULT_MARKETING_CHANNEL_SOURCE_OPTIONS = [
  {
    label: 'YouTube',
    value: 'youtube',
  },
  {
    label: 'Twitter',
    value: 'twitter',
  },
  {
    label: 'Twitch',
    value: 'twitch',
  },
  {
    label: 'Instagram Post',
    value: 'instagram',
  },
  {
    label: 'Email Newsletter',
    value: 'newsletter',
  },
  {
    label: 'Internet search',
    value: 'internet_search',
  },
  {
    label: 'Friends & family',
    value: 'friends_and_family',
  },
  {
    label: 'News article',
    value: 'news_article',
  },
  {
    label: 'Podcast',
    value: 'podcast',
  },
  {
    label: 'Ad on social media',
    value: 'fb_ad',
  },
  {
    label: 'Linkedin',
    value: 'linkedin',
  },
  {
    label: 'TV',
    value: 'tv',
  },
];

export const AVERAGE_RED_MEAT_SERVINGS_PER_WEEK = 6;

export const SpecialOfferTokensThatBetterBeInTheDb = {
  ALL_TRAFFIC_FREE_MONTH: 'all-traffic-free-month',
  FALLBACK_BONUS: 'referral-bonus',
  GROUP_AND_REFERRAL_BONUS: 'group-join-bonus',
  MERCURY: 'mercury',
};

export const NEWEST_FINANCIAL_REPORT_URL =
  'https://www.wren.co/blog/wren-financial-report-year-five';

export const MAX_COVERED_MONTHS_DISCOUNT_IN_USD = 30; // manually sync with wren/backend/src/config/constants.ts
export const MAX_COVERED_MONTHS_DISCOUNT_IN_USD_FOR_TEAM = 100; // manually sync with wren/backend/src/config/constants.ts

export const MIN_SUBSCRIPTION_IN_USD_CENTS = 300;

export const MIN_STRIPE_CHARGE_AMOUNT_IN_USD_CENTS = 100; // manually sync with wren/backend/src/config/constants.ts

export const COST_OF_SPONSORSHIP_IN_USD_CENTS = 3000;
export const MARKETING_COST_PER_NEW_USER_IN_USD_CENTS = 300;
export const NEW_USERS_PER_SPONSORSHIP =
  COST_OF_SPONSORSHIP_IN_USD_CENTS / MARKETING_COST_PER_NEW_USER_IN_USD_CENTS;

export const DASHBOARD_TASKS_ACTION_SLUG = 'dashboard-next-up-tasks';

export default {
  UserStatus,
  Experiences,
  CALCULATOR_KEYS,
  LeaderboardTypes,
  WORLD_AVERAGE_ANNUAL_CO2_EMISSIONS,
  DEFAULT_COUNTRIES,
  AddOnType,
  COMMUNITY_TREE_PLANTING_PROJECT_ID,
  FALLBACK_CURRENCIES,
  TOTAL_TIST_TREES_PLANTED,
  APPLE_MAPS_JWT_KEY,
  DEFAULT_MARKETING_CHANNEL_SOURCE_OPTIONS,
  AVERAGE_RED_MEAT_SERVINGS_PER_WEEK,
  DEFAULT_PORTFOLIO_NAME,
  SpecialOfferTokensThatBetterBeInTheDb,
  NEWEST_FINANCIAL_REPORT_URL,
  MAX_COVERED_MONTHS_DISCOUNT_IN_USD,
  MAX_COVERED_MONTHS_DISCOUNT_IN_USD_FOR_TEAM,
  MIN_SUBSCRIPTION_IN_USD_CENTS,
  MIN_STRIPE_CHARGE_AMOUNT_IN_USD_CENTS,
};

export const NAV_HEIGHT = 60;

export const teamOnboardingSteps = [
  {
    pathnames: ['/teams/signup'],
    text: 'Tell us more about your Team',
  },
  {
    pathnames: ['/checkout/create-account', '/checkout/source-survey'],
    text: 'Create a primary admin account',
  },
  {
    pathnames: ['checkout/payment'],
    text: 'Save a payment method to your account',
  },
];
