/** @jsxImportSource @emotion/react */
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Session } from '@wren/shared';
const { clearSession } = Session;

import * as NavConfig from '../../config/nav';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../store';
import { DesktopNav } from './DesktopNav';
import { GetStarted, UpdateCurrency } from '../../actions/user';
import useMediaQuery from '../../hooks/useMediaQuery';
import { COMPACT_NAV_BREAKPOINT } from '../../util/scale';
import MobileNav from './MobileNav';

export default function Nav() {
  const user = useSelector((state: State) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isMobileViewport = useMediaQuery(
    `(max-width: ${COMPACT_NAV_BREAKPOINT}px)`,
    true
  );

  const { isExact: isTransparent } =
    useRouteMatch(NavConfig.transparentRoutes) || {};
  const { isExact: isFixedOnMobile } =
    useRouteMatch(NavConfig.mobileFixedRoutes) || {};
  const { isExact: isUnfixedOnDesktop } =
    useRouteMatch(NavConfig.desktopUnfixedRoutes) || {};

  const { isExact: noBottomBorderOnDesktop } =
    useRouteMatch(NavConfig.noBottomBorderOnDesktopRoutes) || {};

  const handleCTAClick = (eventLocation: string) => {
    dispatch(GetStarted({ platform: 'desktop', eventLocation, history }));
  };

  const handleCurrencyChange = (currencyCode: string) => {
    dispatch(UpdateCurrency(currencyCode));
  };

  return isMobileViewport ? (
    <MobileNav
      user={user}
      isTransparent={isTransparent}
      isFixed={isFixedOnMobile}
      handleCTAClick={handleCTAClick}
      onCurrencyChange={handleCurrencyChange}
      hasBottomBorder={!noBottomBorderOnDesktop}
      pathname={location.pathname}
    />
  ) : (
    <DesktopNav
      user={user}
      hasBottomBorder={!noBottomBorderOnDesktop}
      isTransparent={isTransparent}
      handleCTAClick={handleCTAClick}
      fixedScrollY={!isUnfixedOnDesktop || isTransparent ? 600 : undefined}
      onCurrencyChange={handleCurrencyChange}
      pathname={location.pathname}
    />
  );
}
