/** @jsxImportSource @emotion/react */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { Network } from '@wren/shared';
import useCart from '../../hooks/useCart';
import useQueryParams from '../../hooks/useQueryParams';
import { spacing } from '../../style/theme';
import Button from '../buttons/Button';
import InputError from '../inputs/InputError';
import StripeElementsWrapper from './StripeElementsWrapper';

interface Props {
  button?: ReactElement<React.HTMLProps<HTMLButtonElement>, 'button'>;
  forTeam?: boolean;
  onChange?: (value: string) => void;
  redirectTo: string;
  redirectQueryParams?: { [key: string]: string };
  setupIntentClientSecret?: string | null;
  isCheckout: boolean; // We need to know this so we can close the cart
}

export default function StripeSetupFormWrapper(props: Props) {
  const [clientSecret, setClientSecret] = useState<string | null | undefined>(
    props.setupIntentClientSecret
  );

  const createSetupIntent = async () => {
    const [response, responseBody] = await Network.get<{
      message?: string;
      clientSecret: string;
    }>('stripe/setup-intent-secret', {
      forTeam: props.forTeam ? 'true' : 'false',
    });

    if (response.ok && responseBody) {
      setClientSecret(responseBody.clientSecret);
    } else {
      throw new Error(
        `Could not create setup intent: ${response.status} ${responseBody?.message}`
      );
    }
  };

  useEffect(() => {
    if (!clientSecret) {
      createSetupIntent();
    }
  }, [clientSecret]);

  if (!clientSecret) {
    return null;
  }

  return (
    <StripeElementsWrapper options={{ clientSecret }}>
      <StripeSetupForm {...props} setupIntentClientSecret={clientSecret} />
    </StripeElementsWrapper>
  );
}

function StripeSetupForm({
  button,
  isCheckout,
  onChange,
  redirectTo,
  redirectQueryParams,
  setupIntentClientSecret,
}: Props) {
  const { getQueryParam } = useQueryParams();
  const [error, setError] = useState<string | null | undefined>(
    getQueryParam('error')
  );
  const elements = useElements();
  const { closeCart, openCart } = useCart();
  const stripe = useStripe();
  const handleChangePaymentMethodType = useCallback(
    (event: StripePaymentElementChangeEvent) => onChange?.(event.value.type),
    [onChange]
  );

  const getRedirectURL = () => {
    const rootRedirectUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : `${window.location.origin}`;

    const redirectQueryParamObj = new URLSearchParams({
      ...redirectQueryParams,
    });

    return `${rootRedirectUrl}${redirectTo}?${redirectQueryParamObj.toString()}`;
  };

  const handleSubmit = useCallback(async () => {
    let closeCartPromise;
    try {
      if (!elements || !stripe || !setupIntentClientSecret) {
        return;
      }

      if (isCheckout) {
        closeCartPromise = closeCart(); // We don't want to await this yet because ApplePay is impatient
      }

      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: getRedirectURL(),
        },
      });

      if (error) {
        if (closeCartPromise) {
          await closeCartPromise;
          await openCart();
        }
        setError(error.message);
      }
    } catch (err) {
      if (closeCartPromise) {
        await closeCartPromise;
        await openCart();
      }
      setError((err as Error).message);
    }
  }, [elements, stripe]);

  const submitButton = button || (
    <Button size="medium" text="Submit">
      &nbsp;
    </Button>
  );

  return (
    <div>
      <PaymentElement
        id="stripe-card-element"
        onChange={handleChangePaymentMethodType}
      />

      <div css={{ marginTop: spacing.default }}>
        {error && (
          <InputError
            injectCss={{ margin: '16px 0', display: 'block' }}
            error={error}
          />
        )}

        {React.cloneElement(submitButton, {
          onClick: async (event: MouseEvent) => {
            await submitButton.props.onClick?.(event);
            return handleSubmit();
          },
        })}
      </div>
    </div>
  );
}
