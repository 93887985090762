/** @jsxImportSource @emotion/react */
import text from '../../style/text';
import Flex from '../UI/Flex';
import { Paragraph } from '../UI/TextStyles';
import { Link } from '../UI/Link';
import * as dropdownContent from '../../config/navDropdowns';

import { colors, spacing } from '../../style/theme';
import { CSSInterpolation } from '@emotion/serialize';
import { navScale } from '../../util/scale';
import SlideOut from '../animation/SlideOut';
import { SerializedUser } from '../../types';
import { DropdownType } from './MobileNav';
import { NavButton } from '../buttons/NavButton';
import NewTag from '../UI/NewTag';
import NavBurger from './NavBurgerNext';
import ArrowDown from '../svgs/ArrowDown';
import Notifications from './Notifications';
import MobileMainMenu from './MobileMainMenu';
import { Button } from '../buttons/ButtonNext';
import { StickToBottom } from '../../routes/Actions/Callouts';
import { NAV_HEIGHT } from '../../config/constants';

const dropdownContainer = navScale({
  bottom: '0px !important',
  height: '100% !important',
  left: '0px !important',
  position: 'fixed !important',
  right: '0px !important',
  top: '0px !important',
  overflowX: 'hidden !important',
  overflowY: 'scroll !important',
  overscrollBehavior: 'contain',
});

const navMenuDropdown = navScale({
  backgroundColor: 'white',
  boxShadow: '0px 2px 1px var(--box-shadow-color)',
  paddingTop: NAV_HEIGHT,
  paddingBottom: 120,
  overflowX: 'hidden !important',
  overflowY: 'scroll !important',
  boxSizing: 'border-box',
  minHeight: '100% !important',
});

const navSection = navScale({
  padding: '12px 20px 16px 20px',
});

export const header: CSSInterpolation = {
  backgroundColor: colors.backgroundGray,
  width: '100%',
  boxSizing: 'border-box',
  fontWeight: 600,
  padding: '20px 0 20px 20px',
};

function getBackButton(dropdownType: DropdownType): {
  name: string;
  to: DropdownType;
} {
  switch (dropdownType) {
    case 'Products':
    case 'Resources':
      return { name: 'Main menu', to: 'Main menu' };
    default:
      return { name: 'Back', to: '' };
  }
}

function mapDropdownTypeToDropdownContent(
  dropdownType: DropdownType,
  user?: SerializedUser
) {
  switch (dropdownType) {
    case 'Products':
      return dropdownContent.products(user);
    case 'Resources':
      return dropdownContent.resources;
    case 'Offset':
      return dropdownContent.offsets;
    default:
      return [];
  }
}

function BigIconNavLink({
  title,
  subtitle,
  icon,
  to,
  isNew,
  onClick,
}: {
  title: string;
  subtitle?: string;
  icon?: {
    component: React.ReactNode;
    backgroundColor: string;
  };
  to: string;
  isNew?: boolean;
  onClick?: () => void;
}) {
  return (
    <Link to={to} css={text.noStyleLink} onClick={onClick}>
      <Flex align="center" css={{ margin: '20px 0' }}>
        <Flex
          align="center"
          justify="center"
          css={{
            width: 48,
            height: 48,
            borderRadius: 8,
            backgroundColor: icon?.backgroundColor,
          }}
        >
          {icon?.component}
        </Flex>
        <Flex direction="column" gap={spacing.tiny} css={{ marginLeft: 16 }}>
          <Paragraph css={{ fontWeight: 600 }}>
            {title}
            {isNew && (
              <NewTag
                css={{
                  marginLeft: spacing.tiny,
                  backgroundColor: colors.wrenOrange,
                  textTransform: 'none',
                }}
              />
            )}
          </Paragraph>
          <p
            css={{
              color: 'var(--text-secondary)',
            }}
          >
            {subtitle}
          </p>
        </Flex>
      </Flex>
    </Link>
  );
}

function NavContent({
  dropdownType,
  setDropdownType,
  user,
  onCurrencyChange,
}: {
  dropdownType: DropdownType;
  setDropdownType: (dropdownType: DropdownType) => void;
  user?: SerializedUser;
  onCurrencyChange: (currencyCode: string) => void;
}) {
  if (dropdownType === 'Notifications') {
    return <Notifications />;
  } else if (dropdownType === 'Main menu') {
    return (
      <MobileMainMenu
        user={user}
        setDropdownType={setDropdownType}
        onCurrencyChange={onCurrencyChange}
      />
    );
  }

  const dropdownContent = mapDropdownTypeToDropdownContent(dropdownType, user);
  return (
    <>
      {dropdownContent?.map((section) => (
        <div key={section.title}>
          <div css={header}>{section.title}</div>
          <div css={navSection}>
            {section.links.map((link) => {
              if (!link) return;
              return (
                <BigIconNavLink
                  key={link.name}
                  title={link.name}
                  subtitle={link.description}
                  icon={link.icon}
                  to={link.to}
                  isNew={link.isNew}
                  onClick={() => setDropdownType('')}
                />
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
}
export default function MobileNavDropdown({
  className,
  expanded,
  onCloseRequested,
  user,
  dropdownType,
  setDropdownType,
  onCurrencyChange,
}: {
  className?: string;
  expanded: boolean;
  onCloseRequested: () => void;
  user?: SerializedUser;
  dropdownType: DropdownType;
  setDropdownType: (dropdownType: DropdownType) => void;
  onCurrencyChange: (currencyCode: string) => void;
}) {
  return (
    <SlideOut durationMs={300}>
      {expanded && (
        <div className={className} css={dropdownContainer}>
          <Flex
            align="center"
            justify="space-between"
            css={[
              navSection,
              {
                position: 'fixed',
                height: NAV_HEIGHT,
                backgroundColor: 'white',
                width: '100%',
                boxSizing: 'border-box',
                borderBottom: `1px solid ${colors.borderColor}`,
                zIndex: 1,
              },
            ]}
          >
            {
              <Button
                buttonType="text"
                css={{
                  fontWeight: 600,
                  paddingLeft: 0,
                  gap: spacing.xSmall,
                }}
                onClick={() => setDropdownType(getBackButton(dropdownType).to)}
              >
                <ArrowDown
                  css={{ transform: 'rotate(90deg)' }}
                  size={12}
                  color={colors.textPrimary}
                />
                {getBackButton(dropdownType).name}
              </Button>
            }
            <NavBurger
              color={colors.textPrimary}
              onToggle={onCloseRequested}
              expanded={true}
            />
          </Flex>
          <nav css={navMenuDropdown}>
            <NavContent
              dropdownType={dropdownType}
              setDropdownType={setDropdownType}
              user={user}
              onCurrencyChange={onCurrencyChange}
            />
          </nav>
          <StickToBottom>
            <div
              css={[
                navSection,
                {
                  width: '100%',
                  boxSizing: 'border-box',
                  backgroundColor: 'white',
                  paddingTop: spacing.default,
                  borderTop: `1px solid ${colors.borderColor}`,
                },
              ]}
            >
              {user?.hasPassword ? (
                <>
                  <Button
                    onClick={() => setDropdownType('Offset')}
                    shape="rounded rectangle"
                    buttonType="filled"
                    color={colors.wrenOrange}
                    css={{
                      width: '100%',
                    }}
                  >
                    Offset
                  </Button>
                  <NavButton
                    onClick={onCloseRequested}
                    shape="rounded rectangle"
                    buttonType="text"
                    href="/logout"
                    css={{ width: '100%' }}
                  >
                    Log out
                  </NavButton>
                </>
              ) : (
                <>
                  <NavButton
                    href="/continue"
                    shape="rounded rectangle"
                    buttonType="filled"
                    color={colors.wrenOrange}
                    css={{ width: '100%' }}
                  >
                    Get started
                  </NavButton>
                  <NavButton
                    onClick={onCloseRequested}
                    shape="rounded rectangle"
                    buttonType="text"
                    href="/login"
                    css={{ width: '100%' }}
                  >
                    Log in
                  </NavButton>
                </>
              )}
            </div>
          </StickToBottom>
        </div>
      )}
    </SlideOut>
  );
}
