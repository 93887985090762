function isValidHexColorCode(hex: string) {
  return /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);
}

export function hexToRGBA(hex: string, opacity = 1) {
  if (isValidHexColorCode(hex)) {
    let chars = hex.substring(1).split('');
    if (chars.length === 3) {
      chars = [chars[0], chars[0], chars[1], chars[1], chars[2], chars[2]];
    }
    const value = Number(`0x${chars.join('')}`);
    return `rgba(${[(value >> 16) & 255, (value >> 8) & 255, value & 255].join(
      ','
    )},${opacity})`;
  }
  throw new Error('Bad Hex');
}

/** Only works on hex colors, for now */
export function changeOpacity(color: string, opacity: number) {
  if (/^#/.test(color)) {
    return hexToRGBA(color, opacity);
  }
  return color;
}
