/*
 * These types are generated by ts-content-types-generator on contentful's website, and copy-pasted here.
 * The config for ts-content-types-generator is v10 with type guards.
 * https://app.contentful.com/spaces/lwiibj1tt6mj/apps/app_installations/2MXG5AIr2DtUvjAAmM7LV3/ts-content-types-generator
 * Any non-generated types can go in ./contentful.ts instead
 */

import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';

export interface TypeAdvocacyPartnerFields {
  name?: EntryFieldTypes.Symbol;
  description?: EntryFieldTypes.RichText;
  link?: EntryFieldTypes.Symbol;
  logotype?: EntryFieldTypes.AssetLink;
  logomark?: EntryFieldTypes.AssetLink;
}

export type TypeAdvocacyPartnerSkeleton = EntrySkeletonType<
  TypeAdvocacyPartnerFields,
  'advocacyPartner'
>;
export type TypeAdvocacyPartner<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeAdvocacyPartnerSkeleton, Modifiers, Locales>;

export function isTypeAdvocacyPartner<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeAdvocacyPartner<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'advocacyPartner';
}

export interface TypeButtonFields {
  text: EntryFieldTypes.Symbol;
  href: EntryFieldTypes.Symbol;
}

export type TypeButtonSkeleton = EntrySkeletonType<TypeButtonFields, 'button'>;
export type TypeButton<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeButtonSkeleton, Modifiers, Locales>;

export function isTypeButton<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeButton<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'button';
}

export interface TypeCalloutBoxFields {
  title?: EntryFieldTypes.Symbol;
  content: EntryFieldTypes.RichText;
}

export type TypeCalloutBoxSkeleton = EntrySkeletonType<
  TypeCalloutBoxFields,
  'calloutBox'
>;
export type TypeCalloutBox<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeCalloutBoxSkeleton, Modifiers, Locales>;

export function isTypeCalloutBox<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeCalloutBox<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'calloutBox';
}

export interface TypeCategoryFields {
  name?: EntryFieldTypes.Symbol;
  slug: EntryFieldTypes.Symbol;
  illustration?: EntryFieldTypes.AssetLink;
  description?: EntryFieldTypes.RichText;
}

export type TypeCategorySkeleton = EntrySkeletonType<
  TypeCategoryFields,
  'category'
>;
export type TypeCategory<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeCategorySkeleton, Modifiers, Locales>;

export function isTypeCategory<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeCategory<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'category';
}

export interface TypeClimateActionFields {
  guides?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeClimateActionSkeleton>
  >;
  name: EntryFieldTypes.Symbol;
  slug: EntryFieldTypes.Symbol;
  body?: EntryFieldTypes.RichText;
  image: EntryFieldTypes.AssetLink;
  externalLink?: EntryFieldTypes.Symbol;
  countries?: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  timeDescription?: EntryFieldTypes.Symbol;
  costDescription?: EntryFieldTypes.Symbol;
  impactDescription?: EntryFieldTypes.Symbol;
  actionItems?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeClimateActionSkeleton>
  >;
  readMore?: EntryFieldTypes.RichText;
  completionText?: EntryFieldTypes.Symbol;
  showAsTask?: EntryFieldTypes.Boolean;
  startDate?: EntryFieldTypes.Date;
  endDate?: EntryFieldTypes.Date;
  sunsetMessage?: EntryFieldTypes.Text;
  actionType?: EntryFieldTypes.Array<
    EntryFieldTypes.Symbol<'campaign' | 'content'>
  >;
  authors?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeAdvocacyPartnerSkeleton>
  >;
  organizationMessage?: EntryFieldTypes.Text;
  senate?: EntryFieldTypes.Boolean;
  house?: EntryFieldTypes.Boolean;
  isActionItem?: EntryFieldTypes.Boolean;
  actionCategory?: EntryFieldTypes.EntryLink<TypeCategorySkeleton>;
}

export type TypeClimateActionSkeleton = EntrySkeletonType<
  TypeClimateActionFields,
  'climateAction'
>;
export type TypeClimateAction<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeClimateActionSkeleton, Modifiers, Locales>;

export function isTypeClimateAction<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeClimateAction<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'climateAction';
}

export interface TypeCollectionFields {
  title?: EntryFieldTypes.Symbol;
  internalTitle?: EntryFieldTypes.Symbol;
  description?: EntryFieldTypes.Text;
  items?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeClimateActionSkeleton>
  >;
}

export type TypeCollectionSkeleton = EntrySkeletonType<
  TypeCollectionFields,
  'collection'
>;
export type TypeCollection<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeCollectionSkeleton, Modifiers, Locales>;

export function isTypeCollection<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeCollection<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'collection';
}

export interface TypeCustomPortfolioReceiptsFields {
  portfolioId: EntryFieldTypes.Integer;
}

export type TypeCustomPortfolioReceiptsSkeleton = EntrySkeletonType<
  TypeCustomPortfolioReceiptsFields,
  'customPortfolioReceipts'
>;
export type TypeCustomPortfolioReceipts<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeCustomPortfolioReceiptsSkeleton, Modifiers, Locales>;

export function isTypeCustomPortfolioReceipts<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeCustomPortfolioReceipts<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'customPortfolioReceipts';
}

export interface TypeDocumentLinkFields {
  title: EntryFieldTypes.Symbol;
  href: EntryFieldTypes.Symbol;
  type: EntryFieldTypes.Symbol;
}

export type TypeDocumentLinkSkeleton = EntrySkeletonType<
  TypeDocumentLinkFields,
  'documentLink'
>;
export type TypeDocumentLink<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeDocumentLinkSkeleton, Modifiers, Locales>;

export function isTypeDocumentLink<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeDocumentLink<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'documentLink';
}

export interface TypeDonorPortalFields {
  content?: EntryFieldTypes.RichText;
  image?: EntryFieldTypes.AssetLink;
  portfolioSlug: EntryFieldTypes.Symbol;
}

export type TypeDonorPortalSkeleton = EntrySkeletonType<
  TypeDonorPortalFields,
  'donorPortal'
>;
export type TypeDonorPortal<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeDonorPortalSkeleton, Modifiers, Locales>;

export function isTypeDonorPortal<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeDonorPortal<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'donorPortal';
}

export interface TypeFaqFields {
  heading?: EntryFieldTypes.Text;
  content: EntryFieldTypes.RichText;
  anchor?: EntryFieldTypes.Symbol;
  expandedByDefault?: EntryFieldTypes.Boolean;
  faqPageFeature?: EntryFieldTypes.Boolean;
  faqSection?: EntryFieldTypes.Symbol<
    | 'Business model & structure'
    | 'Carbon calculator'
    | 'Carbon offsets'
    | 'For members'
    | 'General'
    | 'Support'
    | 'The Wren Classic Portfolio'
    | 'The climate crisis'
  >;
  faqTags?: EntryFieldTypes.Array<
    EntryFieldTypes.Symbol<
      | 'carbonPrice'
      | 'charm-fundraiser-faq'
      | 'checkout'
      | 'company'
      | 'donations'
      | 'donor-portal'
      | 'fundraiser'
      | 'gifts'
      | 'hnw'
      | 'hnw-checkout'
      | 'minifaq'
      | 'offsets'
      | 'product'
      | 'tax-deductible-fundraiser'
      | 'tax-deductible-fundraiser-checkout'
      | 'teams'
      | 'verification'
    >
  >;
}

export type TypeFaqSkeleton = EntrySkeletonType<TypeFaqFields, 'faq'>;
export type TypeFaq<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeFaqSkeleton, Modifiers, Locales>;

export function isTypeFaq<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeFaq<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'faq';
}

export interface TypeMessageWrenBoxFields {
  title?: EntryFieldTypes.Symbol;
  text?: EntryFieldTypes.Text;
}

export type TypeMessageWrenBoxSkeleton = EntrySkeletonType<
  TypeMessageWrenBoxFields,
  'messageWrenBox'
>;
export type TypeMessageWrenBox<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeMessageWrenBoxSkeleton, Modifiers, Locales>;

export function isTypeMessageWrenBox<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeMessageWrenBox<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'messageWrenBox';
}

export interface TypeMilestoneFields {
  statistic?: EntryFieldTypes.Symbol;
  title: EntryFieldTypes.Symbol;
  image?: EntryFieldTypes.Object;
  numberOfContributors?: EntryFieldTypes.Integer;
  date: EntryFieldTypes.Date;
  updateUrl?: EntryFieldTypes.Symbol;
}

export type TypeMilestoneSkeleton = EntrySkeletonType<
  TypeMilestoneFields,
  'milestone'
>;
export type TypeMilestone<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeMilestoneSkeleton, Modifiers, Locales>;

export function isTypeMilestone<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeMilestone<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'milestone';
}

export interface TypePhotoCarouselFields {
  title?: EntryFieldTypes.Symbol;
  photos: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
}

export type TypePhotoCarouselSkeleton = EntrySkeletonType<
  TypePhotoCarouselFields,
  'photoCarousel'
>;
export type TypePhotoCarousel<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypePhotoCarouselSkeleton, Modifiers, Locales>;

export function isTypePhotoCarousel<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypePhotoCarousel<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'photoCarousel';
}

export interface TypePortfolioBreakdownChartFields {
  portfolioSlug?: EntryFieldTypes.Symbol;
  bonusMap?: EntryFieldTypes.EntryLink<TypeWorldMapSkeleton>;
}

export type TypePortfolioBreakdownChartSkeleton = EntrySkeletonType<
  TypePortfolioBreakdownChartFields,
  'portfolioBreakdownChart'
>;
export type TypePortfolioBreakdownChart<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypePortfolioBreakdownChartSkeleton, Modifiers, Locales>;

export function isTypePortfolioBreakdownChart<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypePortfolioBreakdownChart<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'portfolioBreakdownChart';
}

export interface TypePortfolioFundingAllocationTableFields {
  portfolioSlug?: EntryFieldTypes.Symbol;
}

export type TypePortfolioFundingAllocationTableSkeleton = EntrySkeletonType<
  TypePortfolioFundingAllocationTableFields,
  'portfolioFundingAllocationTable'
>;
export type TypePortfolioFundingAllocationTable<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypePortfolioFundingAllocationTableSkeleton, Modifiers, Locales>;

export function isTypePortfolioFundingAllocationTable<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypePortfolioFundingAllocationTable<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'portfolioFundingAllocationTable';
}

export interface TypePortfolioPageFields {
  slug: EntryFieldTypes.Symbol;
  title: EntryFieldTypes.Symbol;
  icon?: EntryFieldTypes.Object;
  heroImage?: EntryFieldTypes.Object;
  specialization?: EntryFieldTypes.Symbol;
  content: EntryFieldTypes.RichText;
}

export type TypePortfolioPageSkeleton = EntrySkeletonType<
  TypePortfolioPageFields,
  'portfolioPage'
>;
export type TypePortfolioPage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypePortfolioPageSkeleton, Modifiers, Locales>;

export function isTypePortfolioPage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypePortfolioPage<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'portfolioPage';
}

export interface TypePortfolioProjectsGridFields {
  portfolioSlug?: EntryFieldTypes.Symbol;
}

export type TypePortfolioProjectsGridSkeleton = EntrySkeletonType<
  TypePortfolioProjectsGridFields,
  'portfolioProjectsGrid'
>;
export type TypePortfolioProjectsGrid<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypePortfolioProjectsGridSkeleton, Modifiers, Locales>;

export function isTypePortfolioProjectsGrid<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypePortfolioProjectsGrid<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'portfolioProjectsGrid';
}

export interface TypePostalRegionFields {
  country: EntryFieldTypes.Symbol;
  postalCodes?: EntryFieldTypes.Text;
}

export type TypePostalRegionSkeleton = EntrySkeletonType<
  TypePostalRegionFields,
  'postalRegion'
>;
export type TypePostalRegion<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypePostalRegionSkeleton, Modifiers, Locales>;

export function isTypePostalRegion<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypePostalRegion<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'postalRegion';
}

export interface TypeProductRecommendationFields {
  name?: EntryFieldTypes.Symbol;
  description?: EntryFieldTypes.RichText;
  image?: EntryFieldTypes.AssetLink;
  ctaText?: EntryFieldTypes.Symbol;
  productLink?: EntryFieldTypes.Symbol;
  costDescription?: EntryFieldTypes.Symbol;
  featured?: EntryFieldTypes.Boolean;
  offerDescription?: EntryFieldTypes.Symbol;
  countries?: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  postalRegions?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypePostalRegionSkeleton>
  >;
}

export type TypeProductRecommendationSkeleton = EntrySkeletonType<
  TypeProductRecommendationFields,
  'productRecommendation'
>;
export type TypeProductRecommendation<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeProductRecommendationSkeleton, Modifiers, Locales>;

export function isTypeProductRecommendation<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeProductRecommendation<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'productRecommendation';
}

export interface TypeProjectLocationPinFields {
  name?: EntryFieldTypes.Symbol;
  location: EntryFieldTypes.Location;
}

export type TypeProjectLocationPinSkeleton = EntrySkeletonType<
  TypeProjectLocationPinFields,
  'projectLocationPin'
>;
export type TypeProjectLocationPin<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeProjectLocationPinSkeleton, Modifiers, Locales>;

export function isTypeProjectLocationPin<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeProjectLocationPin<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'projectLocationPin';
}

export interface TypeProjectPageFields {
  slug: EntryFieldTypes.Symbol;
  title: EntryFieldTypes.Symbol;
  content: EntryFieldTypes.RichText;
}

export type TypeProjectPageSkeleton = EntrySkeletonType<
  TypeProjectPageFields,
  'projectPage'
>;
export type TypeProjectPage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeProjectPageSkeleton, Modifiers, Locales>;

export function isTypeProjectPage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeProjectPage<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'projectPage';
}

export interface TypeProjectUpdatesFields {
  slug?: EntryFieldTypes.Symbol;
}

export type TypeProjectUpdatesSkeleton = EntrySkeletonType<
  TypeProjectUpdatesFields,
  'projectUpdates'
>;
export type TypeProjectUpdates<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeProjectUpdatesSkeleton, Modifiers, Locales>;

export function isTypeProjectUpdates<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeProjectUpdates<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'projectUpdates';
}

export interface TypeVimeoVideoFields {
  pathAndQuery: EntryFieldTypes.Symbol;
}

export type TypeVimeoVideoSkeleton = EntrySkeletonType<
  TypeVimeoVideoFields,
  'vimeoVideo'
>;
export type TypeVimeoVideo<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeVimeoVideoSkeleton, Modifiers, Locales>;

export function isTypeVimeoVideo<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeVimeoVideo<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'vimeoVideo';
}

export interface TypeWorldMapFields {
  title?: EntryFieldTypes.Symbol;
  projectLocations?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeProjectLocationPinSkeleton>
  >;
}

export type TypeWorldMapSkeleton = EntrySkeletonType<
  TypeWorldMapFields,
  'worldMap'
>;
export type TypeWorldMap<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeWorldMapSkeleton, Modifiers, Locales>;

export function isTypeWorldMap<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeWorldMap<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'worldMap';
}

export interface TypeYouTubeVideoFields {
  pathAndQuery: EntryFieldTypes.Symbol;
}

export type TypeYouTubeVideoSkeleton = EntrySkeletonType<
  TypeYouTubeVideoFields,
  'youTubeVideo'
>;
export type TypeYouTubeVideo<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode
> = Entry<TypeYouTubeVideoSkeleton, Modifiers, Locales>;

export function isTypeYouTubeVideo<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeYouTubeVideo<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'youTubeVideo';
}
