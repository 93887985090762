import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Experiences } from '../config/constants';
import { State } from '../store';
import { Experience } from '../types';

export type FlowVariation = 'Fb' | 'PayWhatYouWant';

// TODO: Add the missing intro survey steps (/intro/role, etc.). Their absence causes some nav bugs.
export type Step =
  | '/'
  | '/fb'
  | '/pwyw'
  | '/intro/role'
  | '/calculator'
  | '/choose-plan'
  | '/checkout'
  | '/success';

// For reference, see react/test/hooks/userOnboardingFlow.test.ts
export const defaultFlow: Step[] = [
  '/',
  '/intro/role',
  '/calculator',
  '/choose-plan',
  '/checkout',
  '/success',
];

const currentFlow = new Set<FlowVariation>();

export function addFlowVariation(flow: FlowVariation) {
  currentFlow.add(flow);
}

export function clearFlow() {
  currentFlow.clear();
}

export function getOnboardingSteps(
  flow: Set<FlowVariation> = currentFlow
): Step[] {
  const initialSteps = (() => {
    if (flow.has('Fb')) {
      return ['/fb'];
    }

    if (flow.has('PayWhatYouWant')) {
      return ['/pwyw'];
    }

    return ['/', '/intro/role', '/calculator', '/choose-plan'];
  })() as Step[];

  const steps: (Step | null)[] = [...initialSteps, '/checkout', '/success'];
  return steps.filter<Step>((step): step is Step => !!step);
}

// The return value doesn't necessarily indicate whether the user has visited the page, just whether they need to, assuming the step is in their flow
function isComplete(step: Step, state: State) {
  const { user } = state;

  switch (step) {
    case '/':
      return true;
    case '/fb':
    case '/pwyw':
      !!user.cart?.items.some((item) => item.sku === 'subscription');
    case '/choose-plan':
      return !!user.cart?.items.some((item) => item.sku === 'subscription');
    case '/intro/role':
      return (
        !!user.surveyAnswers ||
        user.experiences.find(
          (experience) => experience.name === Experiences.CARBON_REPORT
        )
      ); // We assume the carbon report directly follows the calculator, and the intro survey is effectively part of the calculator experience
    case '/calculator':
      return user.experiences.find(
        (experience) => experience.name === Experiences.CARBON_REPORT
      ); // We assume the carbon report directly follows the calculator
    default:
      // In all other cases, we haven't tracked whether the user needs to go there
      return undefined;
  }
}

function getCurrentStepIndex(
  currentStep = location.pathname,
  onboardingSteps: Step[]
) {
  return onboardingSteps.findIndex((step) => {
    if (step === '/') {
      return step === currentStep;
    } else {
      return currentStep.startsWith(step);
    }
  });
}

function getNextStep(
  currentStep = location.pathname,
  onboardingSteps: Step[]
): Step {
  const currentIndex = getCurrentStepIndex(currentStep, onboardingSteps);
  if (currentIndex === -1) {
    throw new Error(`No step named ${currentStep}`);
  }

  const nextStep = onboardingSteps.find((step, index) => index > currentIndex);

  if (!nextStep) {
    throw new Error(`No more steps to continue to`);
  }

  return nextStep;
}

function getPreviousStep(
  currentStep = location.pathname,
  onboardingSteps: Step[]
): Step {
  return getNextStep(currentStep, [...onboardingSteps].reverse());
}

function getStepToContinue(steps: Step[], state: State) {
  const foundStep = steps.find((step) => !isComplete(step, state));

  if (!foundStep) {
    throw new Error(`No more steps to continue to`);
  }

  return foundStep;
}

export type OnboardingFlowOptions = { climateCamp?: boolean };

function addParams(step: Step, { climateCamp }: OnboardingFlowOptions): string {
  switch (step) {
    case '/calculator':
      return climateCamp ? `${step}?climate_camp=true` : step;
  }
  return step;
}

export function useOnboardingFlow(flow: Set<FlowVariation> = currentFlow) {
  const steps = getOnboardingSteps(flow);
  const state: State = useSelector((state: State) => state);

  if (flow === currentFlow) {
    useEffect(() => {
      const isUserFromFbAdsFlow = state.user.experiences.some(
        (experience: Experience) =>
          experience.name === Experiences.FB_LANDING_PAGE
      );
      if (isUserFromFbAdsFlow) {
        addFlowVariation('Fb');
      }
    }, [state.user.experiences]);
  }

  return {
    isCurrentlyOnStep: (currentStep?: string) =>
      getCurrentStepIndex(currentStep, steps) !== -1,
    getOnboardingSteps: () => steps,
    getNextStep: (currentStep?: string, options?: OnboardingFlowOptions) =>
      addParams(getNextStep(currentStep, steps), options || {}),
    getPreviousStep: (currentStep?: string, options?: OnboardingFlowOptions) =>
      addParams(getPreviousStep(currentStep, steps), options || {}),
    getStepToContinue: (options?: OnboardingFlowOptions) =>
      addParams(getStepToContinue(steps, state), options || {}),
  };
}
