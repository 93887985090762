/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';

import { scale } from '../../util/scale';
import Darkener from '../UI/Darkener';
import CardContainer from '../UI/CardContainer';
import { Paragraph } from '../UI/TextStyles';
import Fade from '../animation/Fade';

const dialogStyle = scale({
  position: 'fixed',
  minHeight: 'fit-content',
  maxWidth: ['90%', '90%', '1032px'],
  MozAppearanceMinHeight: 'fit-content',
  WebkitAppearanceMinHeight: 'fit-content',
  width: ['90%', 'fit-content', 'fit-content'],
  boxShadow: '0 12px 16px var(--box-shadow-color)',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflowX: 'hidden',
  overflowY: 'auto',
  backgroundColor: 'transparent',
  minWidth: 300,
  zIndex: 10000,
  borderRadius: '5px',
});

const dialogInnerContainer = (hasBeigeBg?: boolean) =>
  scale({
    position: 'relative',
    maxHeight: '80vh',
    width: '100%',
    overflowY: 'auto',
    backgroundColor: hasBeigeBg && 'var(--background-beige)',
  });

const backLinkContainer = scale({
  zIndex: 999,
  position: 'sticky',
  top: 0,
  paddingTop: '15px',
  paddingLeft: '15px',
});

const backLink = scale({
  width: 'fit-content',
  textDecoration: 'none',
  padding: '5px 7px',
  borderRadius: '4px',
  color: 'black',
  backgroundColor: 'white',
  fontSize: '16px',
  border: 'none',
  boxShadow: '0 1px 4px 0 var(--box-shadow-color)',
  cursor: 'pointer',
  WebkitTransition: 'box-shadow 150ms ease',
  transition: 'box-shadow 150ms ease',
  zIndex: 2,
  '&:hover': {
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.4)',
  },
});

const closeButton = (color?: string) =>
  scale({
    fontWeight: 500,
    fontSize: ['20px', '22px'],
    lineHeight: ['20px', '16px'],
    cursor: 'pointer',
    opacity: '0.6',
    color: color || 'black',
    transition: 'opacity, background-color 200ms ease-in-out',
    borderRadius: '20px',
    textAlign: 'center',
    width: '20px',
    height: '20px',
    padding: '5px',
    backgroundColor: 'var(--gray-9)',
    position: 'absolute',
    top: [8, 15, 20],
    right: [8, 15, 20],
    zIndex: 2,
    '&:hover': {
      backgroundColor: 'var(--gray-8)',
      opacity: '1',
    },
  });

function Dialog(props: {
  children: React.ReactNode;
  setShowDialog: (value: boolean) => void;
  showDialog: boolean;
  id?: string;
  hasBeigeBg?: boolean;
  withBackButton?: boolean;
  withCloseButton?: boolean;
  closeButtonColor?: string;
  className?: string;
}) {
  const {
    children,
    setShowDialog,
    showDialog,
    id,
    hasBeigeBg,
    withBackButton,
    withCloseButton,
    closeButtonColor,
    className,
  } = props;

  return (
    <div className={className}>
      <div css={dialogStyle} id={id} role="dialog">
        <Fade durationMs={100}>
          {showDialog && (
            <div css={{ zIndex: 9999 }}>
              <CardContainer>
                <div
                  css={dialogInnerContainer(hasBeigeBg)}
                  id={`${id}-inner-container`}
                >
                  {withBackButton && (
                    <div css={backLinkContainer} id="back-button">
                      <button
                        onClick={() => setShowDialog(!showDialog)}
                        css={backLink}
                        type="button"
                      >
                        <Paragraph>← Back</Paragraph>
                      </button>
                    </div>
                  )}
                  {withCloseButton && (
                    <div
                      onClick={() => setShowDialog(false)}
                      css={closeButton(closeButtonColor)}
                    >
                      &times;
                    </div>
                  )}
                  {children}
                </div>
              </CardContainer>
            </div>
          )}
        </Fade>
      </div>
      <Darkener name={id} setShow={setShowDialog} show={showDialog} />
    </div>
  );
}

export default Dialog;
