import React, { SVGAttributes } from 'react';
import { colors } from '../../style/theme';

export function Copy({
  color = colors.gray5,
  size = 24,
  ...restOfProps
}: SVGAttributes<SVGSVGElement> & { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Copy"
      {...restOfProps}
    >
      <path d="M4.63 3.06c-.92 0-1.66.75-1.66 1.67V16.4h1.66V4.73H16.3V3.06H4.63ZM7.97 6.4A1.66 1.66 0 0 0 6.3 8.06v10c0 .93.75 1.67 1.67 1.67h10c.92 0 1.66-.75 1.66-1.67v-10c0-.92-.74-1.66-1.66-1.66h-10Zm0 1.66h10v10h-10v-10Z" />
    </svg>
  );
}
