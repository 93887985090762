/** @jsxImportSource @emotion/react */

import EndorsementMarquee from '../../components/frontfacing/EndorsementMarquee';
import Footer from '../../components/frontfacing/Footer';
import GetSignals from '../../components/teams/GetSignals';
import { staticImageUrl } from '../../util/images';
import { Headings, Paragraph } from '../../components/UI/TextStyles';
import { hideOnMobile } from '../../style/misc';
import { scale } from '../../util/scale';
import { colors, spacing } from '../../style/theme';
import { ArrowNavButton } from '../../components/buttons/ArrowNavButton';

const nametag = scale({
  borderRadius: 10,
  overflow: 'hidden',
  display: 'inline-block',
  img: {
    height: [50, 50, 60],
  },
});

const green = scale({
  color: colors.wrenGreen,
});

const Sources = () => {
  return (
    <>
      <div css={nametag}>
        <img src={staticImageUrl('benoit-nametag.png')} />
      </div>
    </>
  );
};

const ProductsUsed = () => {
  return (
    <div css={nametag}>
      <img src={staticImageUrl('integrations-nametag.png')} />
    </div>
  );
};

const InsightCard = () => {
  return (
    <div
      css={scale({
        display: ['block', 'block', 'flex'],
        marginTop: spacing.large,
        marginBottom: spacing.large,
        '& > *': {
          marginRight: ['none', 'none', spacing.large],
          marginBottom: [spacing.large, spacing.large, 'none'],
        },
      })}
    >
      <div
        css={scale({
          paddingRight: spacing.large,
          borderRight: ['none', 'none', `1px solid #ccc`],
        })}
      >
        <p>Insights from:</p>
        <Sources />
      </div>
      <div>
        <p>Products used:</p>
        <ProductsUsed />
      </div>
    </div>
  );
};

export default function CaseStudy() {
  return (
    <>
      <div
        css={scale({
          width: '90%',
          maxWidth: 740,
          overflowX: ['hidden', 'hidden', 'visible'],
          margin: 'auto',
          section: {
            padding: `0 ${spacing.default}px`,
            marginBottom: spacing.xLarge,
          },
          h1: {
            marginBottom: spacing.small,
          },
          p: {
            color: colors.textSecondary,
            marginBottom: spacing.medium,
            lineHeight: 1.4,
            fontSize: '110%',
          },
          h3: {
            marginBottom: spacing.small,
            fontWeight: 600,
            fontSize: '110%',
          },
        })}
      >
        <div
          css={[
            {
              display: 'flex',
              justifyContent: 'center',
              marginBottom: -spacing.large,
              position: 'relative',
            },
            hideOnMobile,
          ]}
        ></div>
        <div css={scale({ marginTop: ['none', 'none', spacing.massive] })} />

        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            a: {
              textDecoration: 'none !important',
            },
            marginBottom: spacing.xLarge,
          }}
        >
          <a href="/business">← Back to all solutions</a>
        </div>
        <img
          src={staticImageUrl('jameo-logo.png')}
          css={{
            width: 120,
            marginBottom: spacing.medium,
          }}
        />
        <Headings.XLarge
          css={scale({
            paddingRight: ['none', 'none', spacing.massive],
          })}
        >
          How Jameo achieves a <span css={green}>40× ROI</span> on their Wren
          Climate API spend.
        </Headings.XLarge>
        <Paragraph>
          <a
            css={{ color: colors.linkBlue, textDecoration: 'none' }}
            target="_blank"
            href="www.jameo.com"
          >
            Jameo
          </a>{' '}
          makes hyper-custom clothing for businesses. They have incorporated a
          forward thinking approach within their business model by understanding
          the growing importance of sustainability in business.
        </Paragraph>
        <InsightCard />
        <Headings.XLarge>1.</Headings.XLarge>
        <Headings.Small>
          How Jameo integrates sustainability into every customer’s purchase
          flow.
        </Headings.Small>
        <Paragraph>
          As Jameo’s customers design and purchase their custom clothing orders
          online, there are several touch-points where Jameo’s carbon neutral
          value proposition is highlighted.
        </Paragraph>
        <Paragraph>
          During the design and edit workflow, customers can learn about Jameo’s
          sustainability efforts with Wren, and how a portion of the company’s
          budget goes towards offsetting every purchase.
        </Paragraph>
        <Paragraph>
          With thousands of custom orders, Jameo needed a way to back up this
          carbon neutrality claim at scale. They utilize Wren’s API Integrations
          service to programmatically offset orders with the climate portfolio
          of their choice.
        </Paragraph>
        <img
          css={{
            maxWidth: '120%',
            marginTop: spacing.medium,
            marginBottom: spacing.large,
            marginLeft: '-10%',
          }}
          src={staticImageUrl('jameo-process.png', { w: 2000 })}
        />
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: spacing.large,
          }}
        >
          {/* <img
            css={{
              width: '90%',
              maxWidth: 600,
            }}
            src={staticImageUrl('benoit-quote.png', { w: 2000 })}
          /> */}
        </div>
        <br />
        <br />
        <Headings.XLarge>2.</Headings.XLarge>
        <Headings.Small>
          Cost-benefit analysis: Do customers care about Jameo’s climate action?
        </Headings.Small>
        <Paragraph>
          Qualitatively, Jameo’s stakeholders and customers appreciate the
          company’s commitment to sustainability. But is there a noticeable
          effect to Jameo’s bottom-line metrics? The shorter answer:{' '}
          <strong>Yes.</strong>
        </Paragraph>
        <Paragraph>
          Customer acquisition and retention rates have seen a significant
          uptick from Jameo’s sustainability push, boasting a{' '}
          <strong>remarkable 20% increase.</strong> Additionally, ad
          click-through rates have shown substantial improvement.
        </Paragraph>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            css={{
              maxWidth: 500,
              marginTop: spacing.medium,
              marginBottom: spacing.large,
              width: '90%',
            }}
            src={staticImageUrl('jameo-cost-benefit.png', { w: 2000 })}
          />
        </div>
        <Paragraph>
          A meticulous cost-benefit analysis reveals that the investment in
          Wren's carbon offsets is significantly outweighed by the tangible
          returns. The initial cost has proven to be a fraction of the increased
          revenue, enhanced customer acquisition, and improved ad performance,
          resulting in a compelling 40× return on investment.
        </Paragraph>
        <br />
        <br />
        <br />
        <br />
        <Headings.XLarge>3.</Headings.XLarge>
        <Headings.Small>Customer loyalty, and future plans.</Headings.Small>
        <Paragraph>
          Moving forward, Jameo plans to experiment with custom campaigns
          focused on their eco-conscious practices, which act as a hook and
          differentiator.
        </Paragraph>
        <Paragraph>
          Jameo’s commitment to sustainability has also fostered stronger brand
          loyalty, according to recent survey results. In 2024, Jameo plans to
          expand on this sentiment, pushing the envelope on how else they can
          decarbonize, purchase high-quality offsets, and more.
        </Paragraph>
        {/* <img
          css={{ maxWidth: '120%', marginLeft: '-10%' }}
          src={staticImageUrl('jameo-customer-quote.png', { w: 2000 })}
        /> */}
        <video
          controls
          css={{
            width: '100%',
            marginTop: spacing.large,
            marginBottom: spacing.large,
          }}
          src={staticImageUrl('rosy-soil-and-jameo-webinar.mp4')}
          poster={staticImageUrl('jameo-webinar-cover.png')}
        ></video>
        <Paragraph
          css={{
            marginTop: -spacing.medium,
            color: colors.wrenGreen,
            textAlign: 'center',
          }}
        >
          Listen to Jameo CEO, Benoit Quimper, discuss Jameo’s sustainability
          strategy in this Wren webinar recording from January 2024.
        </Paragraph>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 500,
            margin: 'auto',
            textAlign: 'center',
            marginTop: spacing.massive,
          }}
        >
          <Headings.Large>Inspired by Jameo’s story?</Headings.Large>
          <Paragraph>
            Schedule a call with Wren’s sustainability team to start planning
            your own eco-conscious programs!
          </Paragraph>
          <ArrowNavButton
            color={colors.textPrimary}
            href={`/teams/contact/api-integrations`}
            buttonType="filled"
            shape="pill"
            css={{
              fontWeight: '600',
            }}
          >
            Contact sales
          </ArrowNavButton>
        </div>
      </div>
      <section css={{ marginTop: spacing.massive }}>
        <EndorsementMarquee />
      </section>
      <Footer hideCTA />
      <GetSignals />
    </>
  );
}
