import React, { PropsWithChildren } from 'react';
import { AnchorHTMLAttributes } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  HashLink as ReactRouterHashLink,
  HashLinkProps,
} from 'react-router-hash-link';
import { isPathRenderedByNext } from '../../config/nextPaths';

type Props = PropsWithChildren<
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> &
    HashLinkProps & { to: string | undefined }
>;

// Renders the right kind of link depending on the URL and rendering context (SSG/SSR/Browser)
export const Link = React.forwardRef<HTMLAnchorElement, Props>(
  ({ to, ...restOfProps }: Props, ref) => {
    const absolute = /\/\/|:/.test(to ?? ''); // covers urls with a different protocol, or protocol-relative urls like mailto:x or //google.com
    const serverside = typeof window === 'undefined';
    const hasHash = /#/.test(to ?? '');
    const isStaticContext =
      typeof location === 'undefined' ||
      isPathRenderedByNext(location.pathname);
    const isStaticDestination = isPathRenderedByNext(to);
    if (
      serverside ||
      absolute ||
      isStaticContext ||
      isStaticDestination // some day we may want to use next/link when both context and destination are static
    ) {
      return <a href={to} ref={ref} {...restOfProps} />;
    }
    if (hasHash) {
      return <ReactRouterHashLink to={to} ref={ref} {...restOfProps} />;
    }
    return <ReactRouterLink to={to} ref={ref} {...restOfProps} />;
  }
);

export default Link;
