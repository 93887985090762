/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { forwardRef, JSXElementConstructor, ReactNode } from 'react';
import { fonts, oldFontSizes } from '../../style/theme';
import { scale } from '../../util/scale';

const inputBoxContainer = css({
  boxSizing: 'border-box',
  marginBottom: '10px',
});

const unitStyle = scale({
  marginRight: ['0px', '10px', '10px'],
  display: 'inline',
  marginLeft: '10px',
  marginTop: '8px',
});

const errorStyle = css`
  border: 1px solid var(--error-red);
  color: var(--error-red);
  padding: 3px 5px;
  font-family: ${fonts.Sans};
  font-size: ${oldFontSizes.caption};
  border-radius: 4px;
  transition: 0.3;
  text-align: center;
  width: fit-content;
  height: fit-content;
  display: inline;
  margin-left: 10px;
  margin-top: 6px;
  position: absolute;
`;

const labelStyle = css`
  font-family: ${fonts.Sans};
  font-weight: 600;
  font-size: ${oldFontSizes.body};
  text-align: left;
  padding-left: 5px;
  max-width: 300px;
  padding-bottom: 8px;
`;

export type FieldContainerProps = {
  className?: string;
  children: ReactNode;
  error?: ReactNode;
  errorWidth?: number | string;
  label?: ReactNode;
  unit?: ReactNode;
};

export default function FieldContainer({
  children,
  className,
  error,
  errorWidth, // could probably be eliminated
  label,
  unit,
}: FieldContainerProps) {
  return (
    <div css={inputBoxContainer} className={className}>
      {/* could use some accessibility work: <label> and aria-labelled-by? */}
      {label && <h4 css={labelStyle}>{label}</h4>}
      {children}
      {unit && <div css={unitStyle}>{unit}</div>}
      {error && (
        <div
          css={css`
            ${errorStyle};
            width: ${errorWidth && errorWidth};
          `}
        >
          {error}
        </div>
      )}
    </div>
  );
}

export function withFieldContainer<FieldProps>(
  Field: JSXElementConstructor<
    Omit<FieldProps, keyof Omit<FieldContainerProps, 'children'>>
  >
) {
  return forwardRef(
    (
      {
        className,
        error,
        errorWidth, // could probably be eliminated
        label,
        unit,
        ...restOfProps
      }: FieldProps & Omit<FieldContainerProps, 'children'>,
      ref
    ) => {
      return (
        <FieldContainer {...{ className, error, errorWidth, label, unit }}>
          <Field ref={ref} {...restOfProps} />
        </FieldContainer>
      );
    }
  );
}
