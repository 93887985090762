import * as R from 'ramda';
import { Countries } from '@wren/shared';
import { FALLBACK_CURRENCIES } from '../config/constants';
import { Currency } from '../types';
import { guessCountry } from './util';

export const getSupportedCurrencies = () =>
  (typeof window !== 'undefined' && window.bootstrappedData?.currencies) ||
  FALLBACK_CURRENCIES;

export const isSupportedCode = (code: string | null): code is string => {
  if (code === null) {
    return false;
  }

  const supportedCurrencyCodes = getSupportedCurrencies().map(
    (currency: Currency) => currency.code
  );
  return supportedCurrencyCodes.includes(code);
};

export const getCurrencyObjFromCode = (code: string | null) => {
  const codeToUse = code && isSupportedCode(code) ? code : 'USD';
  const matchesCurrentCurrencyCode = (x: Currency) => x.code === codeToUse;
  return R.filter(matchesCurrentCurrencyCode, getSupportedCurrencies())[0];
};

export function getExchangeRates() {
  const bootstrappedData = window.bootstrappedData;
  return bootstrappedData?.exchangeRates || {};
}

export const convertCurrency = (
  amount: number,
  currencyIn: string,
  currencyOut: string
) => {
  if (currencyIn === currencyOut) {
    return amount;
  }

  const exchangeRates = getExchangeRates();

  if (currencyIn === 'EUR') {
    return amount * exchangeRates[currencyOut];
  }

  const amountInEuros = Number(amount) / exchangeRates[currencyIn];

  if (currencyOut === 'EUR') {
    return amountInEuros;
  }

  return amountInEuros * exchangeRates[currencyOut];
};

export function convertCurrencySmallestDenomination(
  amount: number,
  inCurrency: string,
  outCurrency: string
) {
  if (inCurrency === outCurrency) {
    return amount;
  }
  return (
    convertCurrency(
      amount / getCurrencyObjFromCode(inCurrency).smallestDenominationDivider,
      inCurrency,
      outCurrency
    ) * getCurrencyObjFromCode(outCurrency).smallestDenominationDivider
  );
}

export const handleCurrencyConversionFromUSD = (
  value: number,
  userCurrency: string
) => {
  if (userCurrency === 'USD') {
    return value;
  }

  if (value !== null) {
    const convertedValue = convertCurrency(value, 'USD', userCurrency);
    return convertedValue;
  }
  throw Error('Impossible Currency Conversion');
};

export const handleCurrencyConversionToUSD = (
  value: number,
  userCurrency: string
) => {
  if (userCurrency === 'USD') {
    return value;
  }
  if (value !== null) {
    const convertedValue = convertCurrency(value, userCurrency, 'USD');
    return convertedValue;
  }
  throw Error('Impossible Currency Conversion To USD');
};

export const getCurrencyFromCountryName = (countryName: string) => {
  if (!Countries.isSupportedCountryName(countryName)) {
    return 'USD';
  }
  const country = Countries.COUNTRIES[countryName];
  return Countries.hasCurrency(country) ? country.currency_code : 'USD';
};

export const guessCurrency = (country?: string) => {
  let countryToUse;
  if (!country) {
    const { language } = window.navigator;
    countryToUse = guessCountry(language) || 'United States';
  } else {
    countryToUse = country;
  }
  return getCurrencyFromCountryName(countryToUse);
};
