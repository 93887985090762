import { Subscription } from '@wren/schema';

export function calculateOffsetPercentage({
  amountPerBillingPeriod,
  billingCycle,
  costPerTon,
  annualFootprint,
}: {
  amountPerBillingPeriod: number; // In the same currency/denomination as costPerTon, does not include fees
  billingCycle: Subscription['billing_cycle'];
  costPerTon: number; // In the same currency/denomination as amountPerBillingPeriod
  annualFootprint: number;
}) {
  const annualAmount =
    amountPerBillingPeriod * getBillingPeriodsPerYear(billingCycle); // $/yr = $/period * periods/yr
  const annualTonsOffset = annualAmount / costPerTon; // tons/yr = $/yr / $/ton
  return annualTonsOffset / annualFootprint; // tons/yr / tons/yr = unitless quotient
}

export function getBillingPeriodsPerYear(
  billingCycle: Subscription['billing_cycle']
): number {
  switch (billingCycle) {
    case 'month':
      return 12;
    case 'year':
      return 1;
    default:
      // Will not compile if someone adds a new Subscription['billingCycle']
      return ((unexpectedBillingCycle: never) => {
        throw `unexpected billing cycle: ${unexpectedBillingCycle}`;
      })(billingCycle);
  }
}

// Returns an amount per billing period in the same currency/denomination as the costPerTon parameter
// Does not include fees
export function calculateAmountFromOffsetPercentage({
  offsetPercentage,
  billingCycle,
  costPerTon,
  annualFootprint,
}: {
  offsetPercentage: number;
  billingCycle: Subscription['billing_cycle'];
  costPerTon: number;
  annualFootprint: number;
}) {
  const annualTonsOffset = annualFootprint * offsetPercentage; // tons/yr
  const annualAmount = annualTonsOffset * costPerTon; // $/yr = tons/yr * $/ton
  return annualAmount / getBillingPeriodsPerYear(billingCycle); // $/yr / periods/yr = $/period
}

// Does not include fees
export function calculateSubscriptionPrice(
  costPerTon: number,
  offsetPercentage: number,
  annualFootprint: number,
  subscriptionInterval: 'month' | 'year' = 'month'
) {
  return calculateAmountFromOffsetPercentage({
    offsetPercentage,
    billingCycle: subscriptionInterval,
    costPerTon,
    annualFootprint,
  });
}

export const DAYS_TO_WAIT_UNTIL_FIRST_TEAM_SUBSCRIPTION_CHARGE = 7;

// This calculates the appropriate billing date of each year or month, based on Stripe's logic for billing dates
// See https://docs.stripe.com/billing/subscriptions/billing-cycle
// To calculate annual billing cycles, monthIndex is not required
//
export function getBillingDate(
  billingAnchorDate: Date,
  year: number,
  monthIndex?: number
) {
  const normalizedBillingMonthIndex =
    (monthIndex === undefined ? billingAnchorDate.getUTCMonth() : monthIndex) %
    12;
  let date =
    monthIndex === undefined
      ? new Date(billingAnchorDate.setUTCFullYear(year))
      : new Date(billingAnchorDate.setUTCFullYear(year, monthIndex)); // This may change Feb 29 to Mar 1 on leap years
  // The month of newDate may now be wrong because the day of the month of anchor may have pushed it into the following month (29-31 could become 1-3)
  if (date.getUTCMonth() !== normalizedBillingMonthIndex) {
    // In which case, the billing date will be the last day of the month
    date = new Date(date.setUTCDate(0)); // because dates have 1-based indexing, this decrements the month by 1, and sets the date to the last day of that month
  }
  return date;
}

// See https://www.notion.so/projectwren/Computing-last-and-next-subscription-payment-attributes-Customer-io-164a7ff9834b4e93a4aa95f7d6d8ad84?pvs=4
// and https://docs.stripe.com/billing/subscriptions/billing-cycle
// Returns a date which is not before startFrom
export function calculateNextBillingDate(
  anchorDate: Date,
  interval: 'month' | 'year',
  startFrom: Date
) {
  // Interval here means "year" or "month" on the UTC calendar, as applicable - could be in the past relative to startFrom
  const billingDateThisInterval = getBillingDate(
    anchorDate,
    startFrom.getUTCFullYear(),
    interval === 'month' ? startFrom.getUTCMonth() : undefined
  );
  if (billingDateThisInterval < startFrom) {
    if (interval === 'year') {
      return getBillingDate(
        anchorDate,
        billingDateThisInterval.getUTCFullYear() + 1
      );
    } else {
      return getBillingDate(
        anchorDate,
        billingDateThisInterval.getUTCFullYear(),
        billingDateThisInterval.getUTCMonth() + 1 // this can be 12 to indicate January, the following year
      );
    }
  }
  return billingDateThisInterval;
}
